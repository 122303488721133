"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deserializeExchangeBufferData = exports.serializeExchangeBufferData = void 0;
var constants_1 = require("../../../constants");
var getPlaceholderEntity_1 = require("../../../utils/getPlaceholderEntity");
/* TODO: need refactoring */
// blocks-trees - выбранные чекбоксами блоки
function serializeExchangeBufferData(blocksList, sectionsList, selectedIds, allPlaceholders) {
    var trees = [];
    var placeholdersRefs = new Set();
    var ids = new Set(selectedIds.reverse());
    var allBlocks = blocksList === null || blocksList === void 0 ? void 0 : blocksList.concat(sectionsList);
    var treesMap = allBlocks.reduce(function (_map, _) {
        var node = _map.get(_.id) || { value: _, children: [] };
        node.value = _;
        _map.set(_.id, node);
        if (_.sectionId) {
            var parent_1 = _map.get(_.sectionId) || { value: null, children: [] };
            parent_1.children.push(node);
            _map.set(_.sectionId, parent_1);
        }
        return _map;
    }, new Map());
    var deepTraverseNodeAndCollectPlaceholdersRef = function (node) {
        if (node.value) {
            var id_1 = node.value.id;
            var nodeInDoc_1 = document.getElementById(id_1);
            allPlaceholders.map(function (placeholder) {
                var hasInChild = nodeInDoc_1 === null || nodeInDoc_1 === void 0 ? void 0 : nodeInDoc_1.hasChildNodes();
                if (placeholder.elementId === id_1 || hasInChild) {
                    placeholdersRefs.add(placeholder);
                }
                else if (node.children.length > 0) {
                    for (var _i = 0, _a = node.children; _i < _a.length; _i++) {
                        var childNode = _a[_i];
                        deepTraverseNodeAndCollectPlaceholdersRef(childNode);
                    }
                }
                else
                    return [];
            });
        }
    };
    Array.from(ids.values()).forEach(function (id) {
        var node = treesMap.get(id);
        if (node) {
            if (node.value) {
                trees.push(node);
                deepTraverseNodeAndCollectPlaceholdersRef(node);
            }
        }
    });
    var data = {
        type: 'blocks-trees',
        trees: trees,
        placeholdersRefs: (0, getPlaceholderEntity_1.getPlaceholdersEntities)(Array.from(placeholdersRefs.values()), null, false),
    };
    var dataString = "".concat(constants_1.COPY_BLOCKS_ID).concat(JSON.stringify(data));
    return dataString;
}
exports.serializeExchangeBufferData = serializeExchangeBufferData;
function deserializeExchangeBufferData(value) {
    try {
        if (value.indexOf(constants_1.COPY_BLOCKS_ID) === 0) {
            var parsable = value.slice(10);
            if (parsable) {
                return JSON.parse(parsable);
            }
        }
    }
    catch (e) {
        console.error(e);
    }
}
exports.deserializeExchangeBufferData = deserializeExchangeBufferData;
