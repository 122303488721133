"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getListNodeOptions = exports.createNewListWrapper = void 0;
var uuid_1 = require("uuid");
var constants_1 = require("../../../constants");
var common_1 = require("../../../utils/common");
function createNewListWrapper(tagName) {
    var p = document.createElement(tagName);
    p.setAttribute('id', (0, uuid_1.v4)());
    p.classList.add(constants_1.CLASS_NAMES.listWrapper);
    return p;
}
exports.createNewListWrapper = createNewListWrapper;
function getListNodeOptions(id) {
    var parentPhNode = common_1.DomUtils.selectPlaceholder(id);
    var options = {
        templatedListPh: {
            attr: 'data-templated-list-ph',
            value: parentPhNode === null || parentPhNode === void 0 ? void 0 : parentPhNode.getAttribute('data-templated-list-ph'),
        },
        templatedListName: {
            attr: 'data-templated-list-name',
            value: parentPhNode === null || parentPhNode === void 0 ? void 0 : parentPhNode.getAttribute('data-templated-list-name'),
        },
        templatedListType: {
            attr: 'data-placeholder-type',
            value: parentPhNode === null || parentPhNode === void 0 ? void 0 : parentPhNode.getAttribute('data-placeholder-type'),
        },
        templatedListRequired: {
            attr: 'data-templated-list-required',
            value: parentPhNode === null || parentPhNode === void 0 ? void 0 : parentPhNode.getAttribute('data-templated-list-required'),
        },
        templatedListField: {
            attr: 'data-placeholder-field',
            value: parentPhNode === null || parentPhNode === void 0 ? void 0 : parentPhNode.getAttribute('data-placeholder-field'),
        },
        templatedListOptions: {
            attr: 'data-placeholder-options',
            value: parentPhNode === null || parentPhNode === void 0 ? void 0 : parentPhNode.getAttribute('data-placeholder-options'),
        },
    };
    return options;
}
exports.getListNodeOptions = getListNodeOptions;
