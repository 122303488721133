"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var TemplateVerifyFormPlaceholder = function (_a) {
    var placeholder = _a.placeholder, number = _a.number;
    return ((0, jsx_runtime_1.jsx)(react_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("div", { children: [number, ". ", placeholder.name] }) }));
};
TemplateVerifyFormPlaceholder.defaultProps = {
    placeholder: {},
    number: 0
};
exports.default = TemplateVerifyFormPlaceholder;
