"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requireNotNull = void 0;
function requireNotNull(value, message) {
    if (value === undefined || value === null) {
        throw new TypeError(message !== null && message !== void 0 ? message : 'Required value is not defined');
    }
    return value;
}
exports.requireNotNull = requireNotNull;
