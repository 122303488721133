"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
require("./FolderTree/FolderTree.less");
var actions_1 = require("./redux/actions");
var selectors_1 = require("./redux/selectors");
var entitiesArrayPrevStr = '';
var ChooseEntityTree = function (_a) {
    var props = __rest(_a, []);
    var _b = props.className, className = _b === void 0 ? '' : _b, _c = props.entitiesArray, entitiesArray = _c === void 0 ? [] : _c, entityType = props.entityType, _d = props.expandedKeys, expandedKeys = _d === void 0 ? [] : _d, _e = props.folderCategory, folderCategory = _e === void 0 ? '' : _e, _f = props.folders, folders = _f === void 0 ? [] : _f, _g = props.getFolderList, getFolderList = _g === void 0 ? function () { } : _g, _h = props.knownFolders, knownFolders = _h === void 0 ? [] : _h, onSelect = props.onSelect, _j = props.perimeterId, perimeterId = _j === void 0 ? null : _j, _k = props.searchPerformed, searchPerformed = _k === void 0 ? false : _k, _l = props.selectedKey, selectedKey = _l === void 0 ? '' : _l, _m = props.setMoveModalExpandedKeys, setMoveModalExpandedKeys = _m === void 0 ? function () { } : _m, _o = props.setMoveModalSelectedKey, setMoveModalSelectedKey = _o === void 0 ? function () { } : _o;
    (0, react_1.useEffect)(function () {
        getFolderList('', false, perimeterId);
    }, []);
    (0, react_1.useEffect)(function () {
        if (entitiesArray.length) {
            var parentFolders_1 = findFolders(folders, entitiesArray);
            if (searchPerformed
                && JSON.stringify(entitiesArray) !== entitiesArrayPrevStr) {
                setMoveModalExpandedKeys(parentFolders_1);
            }
            entitiesArrayPrevStr = JSON.stringify(entitiesArray);
        }
        return function () {
            setMoveModalExpandedKeys([]);
            setMoveModalSelectedKey('');
        };
    }, [entitiesArray]);
    var expandCollapse = function (clickedKey) {
        var _expandedKeys = expandedKeys.length
            ? expandedKeys
            : [knownFolders[entityType]];
        if (_expandedKeys.some(function (key) { return key === clickedKey; })) {
            setMoveModalExpandedKeys(_expandedKeys.filter(function (key) { return key !== clickedKey; }));
        }
        else {
            setMoveModalExpandedKeys(__spreadArray(__spreadArray([], _expandedKeys, true), [clickedKey], false));
        }
    };
    // проверяет, есть ли родительская папка папки folderToCheck в массиве папок folders
    var isDaddyHome = function (folderToCheck, folders) {
        if (folders === void 0) { folders = []; }
        return folders.find(function (folder) { return folder['id'] === folderToCheck.parentFolderId; });
    };
    var findFolders = function (folders, entities) {
        var folderIds = [];
        var findParents = function (folderId) {
            var folder = folders.find(function (item) { return item.id === folderId; });
            // failed to find parent folder for entity
            if (!folder) {
                return;
            }
            if (!folderIds.some(function (item) { return item === folder.id; })) {
                folderIds.push(folder.id);
            }
            if (folder.parentFolderId) {
                findParents(folder.parentFolderId);
            }
        };
        // recursively find parent folders for each entity
        entities.forEach(function (entity) { return findParents(entity.folderId); });
        return folderIds;
    };
    var entitiesLoop = function (entites) { return entites.map(function (entity) { return ({
        className: 'folder-tree__node entity-node',
        key: entity.id,
        title: entity.name || '[Без имени]',
        dragOver: true,
    }); }); };
    var getTreeData = function (folders, foldersData) {
        return folders.map(function (folder) {
            var nodeClassName = [
                'folder-tree__node',
                'folder_unselectable',
                folder.id === knownFolders[entityType] && 'root-node',
            ].join(' ');
            var entities = entitiesLoop(entitiesArray.filter(function (entity) { return entity['folderId'] === folder.id; }).sort(function (a, b) { return a['name'].localeCompare(b['name']); }));
            if (folder.childrenFolderIds && folder.childrenFolderIds.length) {
                // find children nodes
                var childrenFolders = foldersData.filter(function (item) { return folder.childrenFolderIds.includes(item.id); })
                    .sort(function (a, b) { return a.name.localeCompare(b.name); });
                return ({
                    key: folder.id,
                    className: nodeClassName,
                    title: folder.name || '[Папка без имени]',
                    children: __spreadArray(__spreadArray([], getTreeData(childrenFolders, foldersData), true), entities, true),
                    selectable: false,
                });
            }
            return ({
                className: nodeClassName,
                key: folder.id,
                title: folder.name || '[Папка без имени]',
                children: entities,
                selectable: false,
            });
        });
    };
    var foldersData = folders.filter(function (folder) { return !folder.status || folder.status !== 'Архив'; });
    if (folderCategory.length && typeof folderCategory === 'string') {
        var getFolderCategories = folderCategory.split(',');
        var foundFoldersByCategories_1 = [];
        getFolderCategories.forEach(function (category) {
            // @ts-ignore
            var filteredFoldersData = foldersData.filter(function (folder) { var _a; return (_a = folder.categories) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.id === category; }); });
            filteredFoldersData.forEach(function (item) {
                // @ts-ignore
                if (!foundFoldersByCategories_1.includes(item)) {
                    // @ts-ignore
                    foundFoldersByCategories_1.push(item);
                }
            });
        });
        foldersData = __spreadArray([], foundFoldersByCategories_1, true);
    }
    // оторванные ветви, от которых следует начинать строить деревья
    var entries = foldersData.filter(function (folder) { return !isDaddyHome(folder, foldersData)
        // ROOT - безотцовщина
        && (folder.id !== knownFolders.root); });
    // если есть оторванные ветви, строим от них
    if (entries.length) {
        foldersData = entries;
    }
    else {
        // если оторванных ветвей нет, то строим из корня текущего entityType
        foldersData = foldersData.filter(function (folder) { return folder.id === knownFolders[entityType]; });
    }
    var parentFolders = findFolders(folders, entitiesArray);
    if (entitiesArray.length) {
        foldersData = foldersData.filter(function (folder) { return parentFolders.some(function (parentFolderId) { return parentFolderId === folder.id; }); });
    }
    var treeData = getTreeData(foldersData, parentFolders.map(function (folderId) { return folders.find(function (folder) { return folder.id === folderId; }); }));
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: entitiesArray.length && parentFolders.length ? ((0, jsx_runtime_1.jsx)(antd_1.Tree, { treeData: treeData, switcherIcon: (0, jsx_runtime_1.jsx)("p", {}), className: ['folder-tree', 'folder-tree_modal', className].join(' '), expandedKeys: expandedKeys.length ? expandedKeys : [knownFolders[entityType]], selectedKeys: [selectedKey], onSelect: function (selectedEntity) {
                // set parentID of selected folder
                setMoveModalSelectedKey(selectedEntity[0]);
                onSelect(selectedEntity[0]);
            }, onExpand: function (alrdyExpanded, target) { return expandCollapse(target.node['props'].eventKey); } }))
            : (0, jsx_runtime_1.jsx)("div", __assign({ className: 'no-results' }, { children: "\u041D\u0435\u0442 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u043E\u0432" })) });
};
// TODO: [Mouseee] Избавиться от connect
exports.default = (0, react_redux_1.connect)(function (state) { return ({
    folders: (0, selectors_1.getFolders)(state),
    expandedKeys: (0, selectors_1.getMoveModalExpandedKeys)(state),
    selectedKey: (0, selectors_1.getMoveModalSelectedKey)(state),
    singleEntityAction: (0, selectors_1.getSingleEntityAction)(state),
    knownFolders: (0, selectors_1.getKnownFolders)(state),
}); }, function (dispatch) { return ({
    setMoveModalExpandedKeys: function (payload) { return dispatch((0, actions_1.setMoveModalExpandedKeys)(payload)); },
    setMoveModalSelectedKey: function (payload) { return dispatch((0, actions_1.setMoveModalSelectedKey)(payload)); },
    // TODO: [Mouseee] Пока добавим any, так как в следующем этапе все равно избавляемся от connect
    getFolderList: function (folderId, shouldSelectFolder, perimeterId) { return dispatch((0, actions_1.getFolderList)(folderId, shouldSelectFolder, perimeterId)); },
}); })(ChooseEntityTree);
