"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsonParse = void 0;
function jsonParse(value) {
    if (typeof value !== 'string') {
        return null;
    }
    try {
        return JSON.parse(value);
    }
    catch (e) {
        return null;
    }
}
exports.jsonParse = jsonParse;
