export const moduleName = 'editor';

const GET_PAGE = `${moduleName}/GET_PAGE`;


const initialState = {
  data: {},
};

export default function editor(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PAGE:
      return { ...state, data: payload };
    default:
      return state;
  }
}
