"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkUserPermissions = exports.existUserPermissions = void 0;
var PermissionsAll_1 = require("./PermissionsAll");
var arrayUtils_1 = require("./arrayUtils");
var entityTypes_1 = require("./entityTypes");
var storage_1 = require("./storage");
/**
 * Проверка на существование каких-либо разрешений пользователя
 */
var existUserPermissions = function () {
    var responsibilities = (0, storage_1.getLocalStorage)(['responsibilities']).responsibilities;
    var respArray = (responsibilities || "").split(",");
    if (respArray.length === 0)
        return false;
    return true;
};
exports.existUserPermissions = existUserPermissions;
/**
 * Проверка текущих разрешений пользователя на требуемые разрешения
 * @param requiredPermissions требуемое разрешение или список возможных разрешений для доступа к ресурсу
 */
var checkUserPermissions = function (requiredPermissions) {
    if (requiredPermissions) {
        var responsibilities = (0, storage_1.getLocalStorage)(['responsibilities']).responsibilities;
        var respArray = (responsibilities || "").split(",");
        if (respArray.length === 0)
            return false;
        if (typeof requiredPermissions === 'string') {
            var status_1 = respArray.includes(requiredPermissions);
            return status_1;
        }
        else {
            var status_2 = (0, arrayUtils_1.checkArrayInArrayAny)(requiredPermissions, respArray);
            return status_2;
        }
    }
    else {
        return true;
    }
};
exports.checkUserPermissions = checkUserPermissions;
var RESPONSIBILITIES = {
    create: (_a = {
            // Общие
            library: PermissionsAll_1.PermissionsAll.canCreateBlocks,
            attachment: PermissionsAll_1.PermissionsAll.canCreateTemplateAttachment,
            templatedList: PermissionsAll_1.PermissionsAll.canCreateTemplatedList,
            // Контракты
            contract: PermissionsAll_1.PermissionsAll.canCreateTemplateContract,
            terminationAgreement: PermissionsAll_1.PermissionsAll.canCreateTemplateTerminationAgreement,
            additionalAgreement: PermissionsAll_1.PermissionsAll.canCreateTemplateAdditionalAgreement,
            cession: PermissionsAll_1.PermissionsAll.canCreateTemplateTripartiteAgreement,
            // Судебные документы
            statementOfClaim: PermissionsAll_1.PermissionsAll.canCreateTemplateStatementOfClaim,
            statements: PermissionsAll_1.PermissionsAll.canCreateTemplateStatements,
            revocationStatementOfClaim: PermissionsAll_1.PermissionsAll.canCreateTemplateRevocationStatementOfClaim,
            petitions: PermissionsAll_1.PermissionsAll.canCreateTemplatePetitions,
            // Довереность
            powerOfAttorney: PermissionsAll_1.PermissionsAll.canCreatePowerOfAttorney,
            revokePowerOfAttorney: PermissionsAll_1.PermissionsAll.canCreateRevokePowerOfAttorney,
            authority: PermissionsAll_1.PermissionsAll.canCreateAuthority,
            // Документы
            documents: [PermissionsAll_1.PermissionsAll.canCreateDocumentAttachment,
                PermissionsAll_1.PermissionsAll.canCreateContractualDocuments,
                PermissionsAll_1.PermissionsAll.canCreateCourtDocuments,
                PermissionsAll_1.PermissionsAll.canCreateOfAttorneyDocuments]
        },
        _a[entityTypes_1.ENTITY_TYPES.attachmentHeader] = PermissionsAll_1.PermissionsAll.canCreateTemplateContract,
        _a[entityTypes_1.ENTITY_TYPES.attachmentFooter] = PermissionsAll_1.PermissionsAll.canCreateTemplateContract,
        // Возможность создания любого шаблона
        _a.createAll = [PermissionsAll_1.PermissionsAll.canCreateTemplateAttachment,
            PermissionsAll_1.PermissionsAll.canCreateTemplatedList,
            PermissionsAll_1.PermissionsAll.canCreateTemplateContract,
            PermissionsAll_1.PermissionsAll.canCreateTemplateTerminationAgreement,
            PermissionsAll_1.PermissionsAll.canCreateTemplateAdditionalAgreement,
            PermissionsAll_1.PermissionsAll.canCreateTemplateTripartiteAgreement,
            PermissionsAll_1.PermissionsAll.canCreateTemplateStatementOfClaim,
            PermissionsAll_1.PermissionsAll.canCreateTemplateStatements,
            PermissionsAll_1.PermissionsAll.canCreateTemplateRevocationStatementOfClaim,
            PermissionsAll_1.PermissionsAll.canCreateTemplatePetitions,
            PermissionsAll_1.PermissionsAll.canCreatePowerOfAttorney,
            PermissionsAll_1.PermissionsAll.canCreateRevokePowerOfAttorney,
            PermissionsAll_1.PermissionsAll.canCreateAuthority
        ],
        _a),
    edit: (_b = {
            // Общие
            library: PermissionsAll_1.PermissionsAll.canEditBlocks,
            attachment: PermissionsAll_1.PermissionsAll.canEditTemplateAttachment,
            templatedList: PermissionsAll_1.PermissionsAll.canEditTemplatedList,
            placeholderLibraryAdmin: PermissionsAll_1.PermissionsAll.canEditPlaceholderLibraryAdmin,
            // Контракты
            contract: PermissionsAll_1.PermissionsAll.canEditTemplateContract,
            terminationAgreement: PermissionsAll_1.PermissionsAll.canEditTemplateTerminationAgreement,
            additionalAgreement: PermissionsAll_1.PermissionsAll.canEditTemplateAdditionalAgreement,
            cession: PermissionsAll_1.PermissionsAll.canEditTemplateTripartiteAgreement,
            // Все контракты
            contractAll: [PermissionsAll_1.PermissionsAll.canEditTemplateContract,
                PermissionsAll_1.PermissionsAll.canEditTemplateTerminationAgreement,
                PermissionsAll_1.PermissionsAll.canEditTemplateAdditionalAgreement,
                PermissionsAll_1.PermissionsAll.canEditTemplateTripartiteAgreement],
            // Судебные документы
            statementOfClaim: PermissionsAll_1.PermissionsAll.canEditTemplateStatementOfClaim,
            statements: PermissionsAll_1.PermissionsAll.canEditTemplateStatements,
            revocationStatementOfClaim: PermissionsAll_1.PermissionsAll.canEditTemplateRevocationStatementOfClaim,
            petitions: PermissionsAll_1.PermissionsAll.canEditTemplatePetitions,
            // Довереность
            powerOfAttorney: PermissionsAll_1.PermissionsAll.canEditPowerOfAttorney,
            revokePowerOfAttorney: PermissionsAll_1.PermissionsAll.canEditRevokePowerOfAttorney,
            authority: PermissionsAll_1.PermissionsAll.canCreateAuthority,
            // Документы
            documents: [PermissionsAll_1.PermissionsAll.canCreateDocumentAttachment,
                PermissionsAll_1.PermissionsAll.canCreateContractualDocuments,
                PermissionsAll_1.PermissionsAll.canCreateCourtDocuments,
                PermissionsAll_1.PermissionsAll.canCreateOfAttorneyDocuments]
        },
        _b[entityTypes_1.ENTITY_TYPES.attachmentHeader] = PermissionsAll_1.PermissionsAll.canCreateTemplateContract,
        _b[entityTypes_1.ENTITY_TYPES.attachmentFooter] = PermissionsAll_1.PermissionsAll.canCreateTemplateContract,
        // Возможность редактирования любого шаблона
        _b.editAll = [PermissionsAll_1.PermissionsAll.canEditTemplateAttachment,
            PermissionsAll_1.PermissionsAll.canEditTemplatedList,
            PermissionsAll_1.PermissionsAll.canEditTemplateContract,
            PermissionsAll_1.PermissionsAll.canEditTemplateTerminationAgreement,
            PermissionsAll_1.PermissionsAll.canEditTemplateAdditionalAgreement,
            PermissionsAll_1.PermissionsAll.canEditTemplateTripartiteAgreement,
            PermissionsAll_1.PermissionsAll.canEditTemplateStatementOfClaim,
            PermissionsAll_1.PermissionsAll.canEditTemplateStatements,
            PermissionsAll_1.PermissionsAll.canEditTemplateRevocationStatementOfClaim,
            PermissionsAll_1.PermissionsAll.canEditTemplatePetitions,
            PermissionsAll_1.PermissionsAll.canEditPowerOfAttorney,
            PermissionsAll_1.PermissionsAll.canEditRevokePowerOfAttorney,
            PermissionsAll_1.PermissionsAll.canEditAuthority
        ],
        _b),
    read: {
        // Документы
        documents: [PermissionsAll_1.PermissionsAll.canCreateDocumentAttachment,
            PermissionsAll_1.PermissionsAll.canCreateContractualDocuments,
            PermissionsAll_1.PermissionsAll.canCreateCourtDocuments,
            PermissionsAll_1.PermissionsAll.canCreateOfAttorneyDocuments],
    },
    view: {
        // Общие
        library: PermissionsAll_1.PermissionsAll.canViewBlocks,
        attachment: PermissionsAll_1.PermissionsAll.canViewTemplateAttachment,
        templatedList: PermissionsAll_1.PermissionsAll.canViewTemplatedList,
        placeholderLibraryAdmin: PermissionsAll_1.PermissionsAll.canViewPlaceholderLibraryAdmin,
        // Контракты
        contract: PermissionsAll_1.PermissionsAll.canViewTemplateContract,
        terminationAgreement: PermissionsAll_1.PermissionsAll.canViewTemplateTerminationAgreement,
        additionalAgreement: PermissionsAll_1.PermissionsAll.canViewTemplateAdditionalAgreement,
        cession: PermissionsAll_1.PermissionsAll.canViewTemplateTripartiteAgreement,
        // Судебные документы
        statementOfClaim: PermissionsAll_1.PermissionsAll.canViewTemplateStatementOfClaim,
        statements: PermissionsAll_1.PermissionsAll.canViewTemplateStatements,
        revocationStatementOfClaim: PermissionsAll_1.PermissionsAll.canViewTemplateRevocationStatementOfClaim,
        petitions: PermissionsAll_1.PermissionsAll.canViewTemplatePetitions,
        // Довереность
        powerOfAttorney: PermissionsAll_1.PermissionsAll.canViewPowerOfAttorney,
        revokePowerOfAttorney: PermissionsAll_1.PermissionsAll.canViewRevokePowerOfAttorney,
        authority: PermissionsAll_1.PermissionsAll.canViewAuthority,
    }
};
exports.default = RESPONSIBILITIES;
