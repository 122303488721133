"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcNumerationWidth = void 0;
var maxDigitWidth = 8;
var dotWidth = 4;
var spaceWidth = 3;
// numeration имеет вид [3, 10, 1]
// итоговая строка нумерации 3.10.1.
// последняя точка добавляется при рендере
var calcNumerationWidth = function (numeration) {
    var dotsNum = numeration.length;
    var digitsNum = (numeration.toString().length - dotsNum) + 1;
    return (digitsNum * maxDigitWidth) + (dotsNum * dotWidth) + spaceWidth;
};
exports.calcNumerationWidth = calcNumerationWidth;
