"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colorTheme = exports.darkTheme = exports.lightTheme = void 0;
exports.lightTheme = {
    body: '#FFF',
    text: 'rgba(0, 0, 0, 0.45)',
    textDark: 'rgba(0, 0, 0, 0.85)',
    toggleBorder: '#f6f6f6',
    background: 'linear-gradient(#39598A, #79D7ED)',
    backgroundHover: '#999',
    shadowBottom: '0px 5px 5px -5px rgba(0, 0, 0, 0.2)',
    shadowStandard: '0px 5px 10px 2px rgba(34, 60, 80, 0.2)',
    baseColor: '#0452EA',
    hoverColor: '#6C86E2',
    focusColor: '#314692',
    btnColorDefault: '#FFF',
    checkboxBorderColor: '#d9d9d9',
    native: '#000',
    mainFolderColor: '#0452EA',
    docBackground: '#f3efef',
    docBorder: 'none',
    disabled: '#f5f5f5',
    disabledText: '#696e79',
    sidebarBg: '#FFF',
    lightBtn: '#0452EA',
    lightBtnHover: '#6C86E2',
    lightBtnFocus: '#314692',
    shadowColors: {
        colorLight: '#3f51b5',
        colorMiddle: '#3f51b5a6',
        colorDark: '#3f51b559'
    },
    menuText: 'rgba(0, 0, 0, 0.85)',
    toggleBtn: {
        color: '#FFF'
    },
    transitiveСolor: '#0452EA',
    activeBgColor: '#f0f6ff',
    filter: 'invert(0)',
    tooltip: {
        background: '#f8f6f6',
        color: '#4a4d53'
    },
    svgThemes: {
        fill: '#fff',
        invert: '#384650',
        selected: '#fff'
    }
};
exports.darkTheme = {
    body: '#232529',
    text: '#FAFAFA',
    textDark: '#d0d0d0',
    toggleBorder: '#FFF',
    background: '#999',
    shadowBottom: '0px 5px 5px -5px rgb(249 249 249 / 39%)',
    shadowStandard: '0px 5px 10px 2px rgb(249 249 249 / 39%)',
    baseColor: '#0452EA',
    hoverColor: '#6C86E2',
    focusColor: '#314692',
    btnColorDefault: '#FFF',
    checkboxBorderColor: '#0452EA',
    native: '#FFF',
    backgroundHover: '#999',
    mainFolderColor: '#FFF',
    docBackground: '#232529',
    docBorder: '1px solid #d0d0d0',
    disabled: '#696e79',
    disabledText: '#f5f5f5',
    sidebarBg: '#232529',
    lightBtn: '#0452EA',
    lightBtnHover: '#6C86E2',
    lightBtnFocus: '#314692',
    shadowColors: {
        colorLight: '#3f51b5',
        colorMiddle: '#3f51b5a6',
        colorDark: '#3f51b559'
    },
    menuText: '#FFF',
    toggleBtn: {
        color: '#FFF'
    },
    transitiveСolor: '#FFF',
    activeBgColor: '#4a4d53',
    filter: 'invert(100%)',
    tooltip: {
        background: '#4a4d53',
        color: '#f8f6f6'
    },
    svgThemes: {
        fill: '#384650',
        invert: '#fff',
        selected: '#fff'
    }
};
exports.colorTheme = {
    body: '#FFF',
    text: 'rgba(0, 0, 0, 0.45)',
    textDark: 'rgba(0, 0, 0, 0.85)',
    toggleBorder: '#f6f6f6',
    background: 'linear-gradient(#39598A, #79D7ED)',
    backgroundHover: '#999',
    shadowBottom: '0px 5px 5px -5px rgba(0, 0, 0, 0.2)',
    shadowStandard: '0px 5px 10px 2px rgba(34, 60, 80, 0.2)',
    baseColor: '#FF8A00',
    hoverColor: '#E38163',
    focusColor: '#E38163',
    btnColorDefault: '#FFF',
    checkboxBorderColor: '#d9d9d9',
    native: '#000',
    mainFolderColor: '#FFF',
    docBackground: '#f3efef',
    docBorder: 'none',
    disabled: '#f5f5f5',
    disabledText: '#696e79',
    sidebarBg: '#FCBB14',
    lightBtn: '#FFF',
    lightBtnHover: '#d9d9d9',
    lightBtnFocus: '#FFF',
    shadowColors: {
        colorLight: '#95a1a7',
        colorMiddle: '#7b848a',
        colorDark: '#636363'
    },
    menuText: '#FFF',
    toggleBtn: {
        color: '#FF8A00'
    },
    transitiveСolor: '#FF8A00',
    activeBgColor: '#ffece6',
    filter: 'invert(0)',
    tooltip: {
        background: '#f8f6f6',
        color: '#4a4d53'
    },
    svgThemes: {
        fill: '#fff',
        invert: '#384650',
        selected: '#fff'
    }
};
