"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertBlockType = void 0;
var constants_1 = require("../../../constants");
var BlockModel_1 = require("../Blocks/BlockModel");
var BlockTypes_1 = require("../BlockTypes");
var textToList_1 = require("./textToList");
var textTypes = [BlockTypes_1.blockTypes.text.type, BlockTypes_1.blockTypes.header2.type];
function convertBlockType(block, newType) {
    var _a;
    if (newType === BlockTypes_1.blockTypes.attachment.type) {
        return BlockModel_1.BlockContentModel;
    }
    // нужно удалить обертку со стилями форматирования текста
    if (block.type === 'text' && newType !== block.type) {
        var node = document.getElementById(block.id);
        var textStyle = node === null || node === void 0 ? void 0 : node.querySelector(constants_1.CLASSES.textStyle);
        if (node && textStyle && textStyle.parentElement) {
            (_a = textStyle.parentElement).append.apply(_a, Array.from(textStyle.childNodes));
            textStyle.remove();
            return __assign(__assign({}, block.content), { html: node.innerHTML });
        }
    }
    if (textTypes.includes(block.type) && newType === BlockTypes_1.blockTypes.list.type) {
        return (0, textToList_1.convertBlock)(block.content, 'list');
    }
    if (block.type === BlockTypes_1.blockTypes.list.type && textTypes.includes(newType)) {
        return (0, textToList_1.convertBlock)(block.content, 'text');
    }
    return null;
}
exports.convertBlockType = convertBlockType;
