"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPhInsertion = exports.getDeletedSections = exports.getDeletedPlaceholders = exports.getTemplateId = exports.getAggregations = exports.getSections = exports.getPlaceholders = exports.getTemplatedListBlocks = exports.getBlocks = exports.getPresentState = exports.getDeletedBlocks = exports.getHolderPhLibraryItem = exports.getCurrentPlaceholderACL = exports.getCreatingFolderModal = exports.getSelectedSectionId = exports.getSelectedPlaceholder = exports.getModalStatus = exports.getPrevActionGroup = exports.getUndoRedoKey = exports.getSelectedBlocks = exports.getHasUnsavedText = exports.getCanRedo = exports.getCanUndo = exports.getTemplateInfo = exports.getDocumentKinds = exports.getSystemsCatalogueList = exports.getLibraryTemplates = exports.getFormulaTypes = exports.getPlaceholderTypes = exports.getDraftTemplateVersion = exports.getDraftTemplateName = exports.getDraftTemplateId = exports.getOrders = exports.getStructure = exports.getFilters = exports.getPageSize = exports.getOrdersListData = exports.getPage = exports.getTableSize = exports.getLoading = void 0;
var root_redux_1 = require("../root.redux");
var getLoading = function (state) { return state[root_redux_1.moduleName].present.fetching; };
exports.getLoading = getLoading;
var getTableSize = function (state) { return state[root_redux_1.moduleName].present.pageInfo.tableSize; };
exports.getTableSize = getTableSize;
var getPage = function (state) { return state[root_redux_1.moduleName].present.pageInfo.page; };
exports.getPage = getPage;
var getOrdersListData = function (state) {
    return state[root_redux_1.moduleName].present.data.rows;
};
exports.getOrdersListData = getOrdersListData;
var getPageSize = function (state) { return state[root_redux_1.moduleName].present.pageSize; };
exports.getPageSize = getPageSize;
var getFilters = function (state) { return state[root_redux_1.moduleName].present.data.filters; };
exports.getFilters = getFilters;
var getStructure = function (state) { return state[root_redux_1.moduleName].present.structure; };
exports.getStructure = getStructure;
var getOrders = function (state) { return state[root_redux_1.moduleName].present.data.orders; };
exports.getOrders = getOrders;
var getDraftTemplateId = function (state) {
    return state[root_redux_1.moduleName].present.data.draftTemplateId;
};
exports.getDraftTemplateId = getDraftTemplateId;
var getDraftTemplateName = function (state) {
    return state[root_redux_1.moduleName].present.data.draftTemplateName;
};
exports.getDraftTemplateName = getDraftTemplateName;
var getDraftTemplateVersion = function (state) {
    return state[root_redux_1.moduleName].present.data.draftTemplateVersion;
};
exports.getDraftTemplateVersion = getDraftTemplateVersion;
var getPlaceholderTypes = function (state) {
    return state[root_redux_1.moduleName].present.data.placeholderTypes;
};
exports.getPlaceholderTypes = getPlaceholderTypes;
var getFormulaTypes = function (state) {
    return state[root_redux_1.moduleName].present.data.formulaTypes;
};
exports.getFormulaTypes = getFormulaTypes;
var getLibraryTemplates = function (state) { return state[root_redux_1.moduleName].present.templates; };
exports.getLibraryTemplates = getLibraryTemplates;
var getSystemsCatalogueList = function (state) {
    return state[root_redux_1.moduleName].present.data.systemsCatalogue;
};
exports.getSystemsCatalogueList = getSystemsCatalogueList;
var getDocumentKinds = function (state) {
    return state[root_redux_1.moduleName].present.data.documentKinds;
};
exports.getDocumentKinds = getDocumentKinds;
var getTemplateInfo = function (state) {
    return state[root_redux_1.moduleName].present.templateInfo;
};
exports.getTemplateInfo = getTemplateInfo;
var getCanUndo = function (state) { return state[root_redux_1.moduleName].past.length > 1; };
exports.getCanUndo = getCanUndo;
var getCanRedo = function (state) { return state[root_redux_1.moduleName].future.length > 0; };
exports.getCanRedo = getCanRedo;
var getHasUnsavedText = function (state) { return state[root_redux_1.moduleName].present.hasUnsavedText; };
exports.getHasUnsavedText = getHasUnsavedText;
var getSelectedBlocks = function (state) {
    return state[root_redux_1.moduleName].present.selectedBlocks;
};
exports.getSelectedBlocks = getSelectedBlocks;
var getUndoRedoKey = function (state) { return state[root_redux_1.moduleName].present.undoRedoKey; };
exports.getUndoRedoKey = getUndoRedoKey;
var getPrevActionGroup = function (state) { return state[root_redux_1.moduleName].present.prevActionGroup; };
exports.getPrevActionGroup = getPrevActionGroup;
var getModalStatus = function (state) { return state[root_redux_1.moduleName].present.isModalOpen; };
exports.getModalStatus = getModalStatus;
var getSelectedPlaceholder = function (state) {
    return state[root_redux_1.moduleName].present.isPlaceholderSelected;
};
exports.getSelectedPlaceholder = getSelectedPlaceholder;
var getSelectedSectionId = function (state) {
    return state[root_redux_1.moduleName].present.selectedSectionId;
};
exports.getSelectedSectionId = getSelectedSectionId;
var getCreatingFolderModal = function (state) {
    return state[root_redux_1.moduleName].present.creatingFolderModal;
};
exports.getCreatingFolderModal = getCreatingFolderModal;
var getCurrentPlaceholderACL = function (state) {
    return state[root_redux_1.moduleName].present.currentPlaceholderACL;
};
exports.getCurrentPlaceholderACL = getCurrentPlaceholderACL;
var getHolderPhLibraryItem = function (state) {
    return state[root_redux_1.moduleName].present.holderForCreatedPhLibraryItem;
};
exports.getHolderPhLibraryItem = getHolderPhLibraryItem;
var getDeletedBlocks = function (state) { return state[root_redux_1.moduleName].present.deletedBlocks; };
exports.getDeletedBlocks = getDeletedBlocks;
// TODO [Mouseee] Выяснить насчет present и конкретизировать
var getPresentState = function (state) { return state[root_redux_1.moduleName].present; };
exports.getPresentState = getPresentState;
var getBlocks = function (state) {
    if (state[root_redux_1.moduleName].present.blocks) {
        return state[root_redux_1.moduleName].present.blocks.filter(function (block) { return block.action !== 'delete'; });
    }
    return [];
};
exports.getBlocks = getBlocks;
var getTemplatedListBlocks = function (state) {
    if (state[root_redux_1.moduleName].present.templatedListBlocks) {
        return state[root_redux_1.moduleName].present.templatedListBlocks.filter(function (block) { return block.action !== 'delete'; });
    }
    return [];
};
exports.getTemplatedListBlocks = getTemplatedListBlocks;
var getPlaceholders = function (state) {
    if (state[root_redux_1.moduleName].present.placeholders) {
        var placeholders = state[root_redux_1.moduleName].present.placeholders.filter(function (placeholder) { return placeholder.action !== 'delete'; });
        return placeholders;
    }
    return [];
};
exports.getPlaceholders = getPlaceholders;
var getSections = function (state) {
    if (state[root_redux_1.moduleName].present.sections) {
        return state[root_redux_1.moduleName].present.sections.filter(function (section) { return section.action !== 'delete'; });
    }
    return [];
};
exports.getSections = getSections;
var getAggregations = function (state) {
    var aggregations = state[root_redux_1.moduleName].present.aggregations;
    return aggregations;
};
exports.getAggregations = getAggregations;
var getTemplateId = function (state) { return state[root_redux_1.moduleName].present.templateId; };
exports.getTemplateId = getTemplateId;
var getDeletedPlaceholders = function (state) {
    return state[root_redux_1.moduleName].present.deletedPlaceholders;
};
exports.getDeletedPlaceholders = getDeletedPlaceholders;
var getDeletedSections = function (state) {
    return state[root_redux_1.moduleName].present.deletedSections;
};
exports.getDeletedSections = getDeletedSections;
var getPhInsertion = function (state) { return state[root_redux_1.moduleName].present.placeholderForInsertion; };
exports.getPhInsertion = getPhInsertion;
