"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UtilityDOM = void 0;
/**
 *  Утилиты для работы с объектной моделью документа
 *  Здесь происходит получение логических данных из объектной модели документа на основе применяемых классов и структуры отображения.
 *  Если классы или структуры отображения изменяться необходимо будет скорректировать
 */
var UtilityDOM = /** @class */ (function () {
    function UtilityDOM() {
    }
    /**
     * Получение тела документа содержащего все сущности
     * @param isDoc Режим документа (предпросмотра)
     * @returns
     */
    UtilityDOM.getBodyDocument = function (isDoc) {
        if (isDoc) {
            return document.getElementsByClassName('document-body document-editor').item(0);
        }
        else {
            var body = document.getElementById('js-template-content-wrapper');
            return body;
        }
    };
    /**
     * Проверка класса элемента на то, что он является обверткой DnD (используется для перетаскивания)
     * @returns
     */
    UtilityDOM.isDnDWrapper = function (elem) {
        return elem.classList.contains('drag-container');
    };
    /**
     * Проверка класса элемента на то, что он является библиотечным блоком
     * @param elem Элемент DOM
     * @returns
     */
    UtilityDOM.isLibrary = function (elem) {
        return elem.classList.contains('library-container');
    };
    /**
     * Проверка класса элемента на то, что он является секцией
     * @param elem Элемент DOM
     * @returns
     */
    UtilityDOM.isSection = function (elem) {
        return elem.classList.contains('block-wrapper') && elem.classList.contains('section-wrapper');
    };
    /**
     * Проверка класса элемента на то, что он является секцией в библиотеке
     * @param elem Элемент DOM
     * @returns
     */
    UtilityDOM.isSectionInLibrary = function (elem) {
        return elem.classList.contains('block-wrapper')
            && elem.classList.contains('section-wrapper')
            && elem.classList.contains('library-section');
    };
    /**
     * Проверка класса элемента на то, что он является секцией в секции
     * @param elem Элемент DOM
     * @returns
     */
    UtilityDOM.isSectionInSection = function (elem) {
        return elem.classList.contains('section') && elem.classList.contains('section-wrapper');
    };
    /**
     * Проверка класса элемента на то, что он является блоком
     * @param elem Элемент DOM
     * @returns
     */
    UtilityDOM.isBlock = function (elem) {
        return elem.classList.contains('block-wrapper') && (elem.classList.contains('section-wrapper') === false) &&
            (elem.classList.contains('library-section') === false);
    };
    /**
     * Проверка класса элемента на то, что он является блоком в библиотеке
     * @param elem Элемент DOM
     * @returns
     */
    UtilityDOM.isBlockInLibrary = function (elem) {
        return elem.classList.contains('block-wrapper') && (elem.classList.contains('library-section') &&
            (elem.classList.contains('section-wrapper') === false));
    };
    /**
     * Проверка класса элемента на то, что он является блоком в секции
     * @param elem Элемент DOM
     * @returns
     */
    UtilityDOM.isBlockInSection = function (elem) {
        return elem.classList.contains('section') && (elem.classList.contains('section-wrapper') === false);
    };
    /**
     * Проверка содержимого класса блока на то, что он является простым текстовым блоком
     * @param isDoc Режим документа (предпросмотра)
     * @param block Элемент DOM (div) представляющий блок
     * @returns
     */
    UtilityDOM.isBlockContentText = function (isDoc, block) {
        if (isDoc) {
            return block.classList.contains('block') && block.classList.contains('block--text');
        }
        else {
            var blockData = block.getElementsByClassName('block block--text');
            if (blockData && blockData.length > 0) {
                return true;
            }
        }
        return false;
    };
    /**
     * Проверка содержимого класса блока на то, что он является приложением
     * @param block Элемент DOM (div) представляющий блок
     * @returns
     */
    UtilityDOM.isBlockContentAttachment = function (block) {
        var blockData1 = block.getElementsByClassName('block block--text block--attachment');
        if (blockData1 && blockData1.length > 0) {
            return true;
        }
        var blockData2 = block.getElementsByClassName('block block--attachment');
        if (blockData2 && blockData2.length > 0) {
            return true;
        }
        return false;
    };
    /**
     * Проверка содержимого класса блока на то, что он является списком
     * @param block Элемент DOM (div) представляющий блок
     * @returns
     */
    UtilityDOM.isBlockContentList = function (block) {
        var blockData = block.getElementsByClassName('block block--list');
        if (blockData && blockData.length > 0) {
            return true;
        }
        return false;
    };
    /**
     * Проверка содержимого класса блока на то, что он является таблицей
     * @param block Элемент DOM (div) представляющий блок
     * @returns
     */
    UtilityDOM.isBlockContentTable = function (block) {
        var blockData = block.getElementsByClassName('block block--table');
        if (blockData && blockData.length > 0) {
            return true;
        }
        return false;
    };
    /**
     * Проверка класса на то, что он является списком приложений
     * @param elem Элемент DOM (div) представляющий список приложений
     * @returns
     */
    UtilityDOM.isAttachmentList = function (elem) {
        return elem.classList.contains('attachment-list');
    };
    /**
     * Проверка класса на то, что он является приложением
     * @param elem Элемент DOM (div) представляющий приложение
     * @returns
     */
    UtilityDOM.isAttachment = function (elem) {
        return elem.classList.contains('placeholder') && elem.classList.contains('aggregation');
    };
    /**
     * Получение заголовка секции
     * @param section Элемент DOM(div) представляющий секцию
     * @returns
     */
    UtilityDOM.getSectionTitle = function (section) {
        var sectionHeader = section.getElementsByClassName('section-header');
        if (sectionHeader && sectionHeader.length > 0) {
            var sectionTitle = sectionHeader[0].getElementsByClassName('title');
            if (sectionTitle && sectionTitle.length > 0) {
                return sectionTitle[0].innerHTML;
            }
        }
        return '';
    };
    /**
     * Получение названия группы секции
     * @param group Элемент DOM(div) представляющий группу
     * @returns
     */
    UtilityDOM.getGroupSectionName = function (group) {
        var section = group.getElementsByClassName('section');
        if (section && section.length > 0) {
            var option = section[0].getElementsByClassName('option_header');
            if (option && option.length > 0) {
                var title = option[0].getElementsByClassName('option_title');
                if (title && title.length > 0 && title[0]) {
                    if (title[0].firstElementChild) {
                        return title[0].firstElementChild.firstElementChild.innerHTML;
                    }
                }
            }
        }
        return '';
    };
    /**
     * Получение названия библиотечного блока
     * @param library Элемент DOM(div) представляющий начало библиотечного блока
     * @returns
     */
    UtilityDOM.getLibraryName = function (library) {
        var libraryName = library.getElementsByClassName('library-name');
        if (libraryName && libraryName.length > 0) {
            var title = libraryName[0].getElementsByClassName('title');
            if (title && title.length > 0) {
                return title[0].innerHTML;
            }
        }
        return '';
    };
    return UtilityDOM;
}());
exports.UtilityDOM = UtilityDOM;
