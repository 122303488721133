"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkAllOptionsInSetting = void 0;
/**
 * Проверка на связанность всех опций дочернего элемента с родительским элементом
 * @param settings Связи родительского элемента (у ПХ поле settings, у секций поле content.links)
 * @param idChild Идентификатор дочернего элемента
 * @param parentOptions Список родительских опций (у ПХ есть (поле options), у секций надо формировать)
 * @param childOptions Список дочерних опций (у ПХ есть (поле options), у секций надо формировать)
 * @returns
 */
var checkAllOptionsInSetting = function (settings, idChild, parentOptions, childOptions) {
    var countOption = childOptions.length;
    var settingItem = settings[idChild];
    var currentLinkOption = 0;
    if (settingItem) {
        childOptions.forEach(function (oc) {
            parentOptions.forEach(function (op) {
                var linksItems = settingItem[op.id];
                if (linksItems) {
                    var find = linksItems.find(function (x) { return x.id === oc.id; });
                    if (find) {
                        currentLinkOption++;
                    }
                }
            });
        });
    }
    if (currentLinkOption >= countOption)
        return true;
    return false;
};
exports.checkAllOptionsInSetting = checkAllOptionsInSetting;
