"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibraryTemplateInfo = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var antd_1 = require("antd");
var icons_1 = require("@ant-design/icons");
var PlaceholderTemplateAggregation_1 = require("../../PlaceholderTemplateAggregation/PlaceholderTemplateAggregation");
var placeholderValidationUtils_1 = require("../../../../shared/utils/placeholderValidationUtils");
var PlaceholderTemplateApi_1 = require("../../../../api/placeholderLibraryApi/PlaceholderTemplate/PlaceholderTemplateApi");
var PlaceholderTemplateFolderApi_1 = require("../../../../api/placeholderLibraryApi/PlaceholderTemplateFolder/PlaceholderTemplateFolderApi");
var placeholdersLibraryUtils_1 = require("../../../../shared/utils/placeholdersLibraryUtils");
var rootGetters_1 = require("../../../../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../../../../Root/redux/rootActionCreators/rootActionCreators");
var PlaceholderLibraryTemplateInfo = function (_a) {
    var props = __rest(_a, []);
    var openPlaceholderDrawer = props.openPlaceholderDrawer, setAclModal = props.setAclModal, activePlaceholderRecord = props.activePlaceholderRecord, currentFolder = props.currentFolder;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_1.useState)(null), savePlaceholder = _b[0], setSavePlaceholder = _b[1];
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var currentPlaceholderData = presentState === null || presentState === void 0 ? void 0 : presentState.currentTemplatePlaceholderData;
    var currentPlaceholderACL = presentState === null || presentState === void 0 ? void 0 : presentState.currentPlaceholderACL;
    var currentPlaceholderDataName = "".concat((currentPlaceholderData === null || currentPlaceholderData === void 0 ? void 0 : currentPlaceholderData.name) || '');
    var currentFolderType = currentFolder === null || currentFolder === void 0 ? void 0 : currentFolder.typeKey;
    var disableEditButton = currentPlaceholderACL !== 8 && currentPlaceholderACL !== 4;
    var placeholderTemplateId = activePlaceholderRecord === null || activePlaceholderRecord === void 0 ? void 0 : activePlaceholderRecord.key;
    var onCloseDrawer = function () {
        dispatch((0, rootActionCreators_1.setOpenPlaceholderDrawer)(false));
    };
    var aclOptionsSet = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, placeholdersLibraryUtils_1.getPlaceholderTemplateACL)(placeholderTemplateId, dispatch)];
                case 1:
                    _a.sent();
                    setAclModal(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var savePlaceholderEditData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var success, validationFunctions, _i, validationFunctions_1, validationFunc, validationResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    success = true;
                    validationFunctions = [
                        placeholderValidationUtils_1.validateAttributes,
                        placeholderValidationUtils_1.validateAttachmentDocuments,
                        placeholderValidationUtils_1.validateAttachmentTemplates,
                        placeholderValidationUtils_1.validateTemplatedList,
                    ];
                    for (_i = 0, validationFunctions_1 = validationFunctions; _i < validationFunctions_1.length; _i++) {
                        validationFunc = validationFunctions_1[_i];
                        if (savePlaceholder) {
                            validationResult = validationFunc([savePlaceholder], currentFolderType);
                            if (!validationResult.success) {
                                antd_1.notification.warning({
                                    message: 'Ошибка!',
                                    description: validationResult.error,
                                });
                            }
                            success = success && validationResult.success;
                        }
                    }
                    if (!success) return [3 /*break*/, 3];
                    return [4 /*yield*/, (0, PlaceholderTemplateApi_1.placeholderTemplateEdit)({
                            // @ts-ignore
                            data: savePlaceholder,
                            id: activePlaceholderRecord === null || activePlaceholderRecord === void 0 ? void 0 : activePlaceholderRecord.key,
                            // @ts-ignore
                            name: savePlaceholder === null || savePlaceholder === void 0 ? void 0 : savePlaceholder.initial_title,
                        })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, (0, PlaceholderTemplateFolderApi_1.placeholderTemplateFolderContent)({ id: currentFolder === null || currentFolder === void 0 ? void 0 : currentFolder.id }).then(function (response) {
                            return dispatch((0, rootActionCreators_1.setPlaceholderTemplateFolderContent)(response));
                        })];
                case 2:
                    _a.sent();
                    dispatch((0, rootActionCreators_1.setOpenPlaceholderDrawer)(false));
                    return [3 /*break*/, 4];
                case 3:
                    antd_1.notification.error({
                        message: 'Ошибка',
                        description: 'Произошла ошибка в редактировании данных',
                    });
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsxs)(antd_1.Drawer, __assign({ title: currentPlaceholderDataName, placement: "right", onClose: onCloseDrawer, open: openPlaceholderDrawer, extra: (0, jsx_runtime_1.jsx)(antd_1.Space, { children: (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ className: "phadm-users", title: "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0438", onClick: function () { return aclOptionsSet(); }, style: { fontSize: 18 } }, { children: (0, jsx_runtime_1.jsx)(icons_1.UsergroupAddOutlined, {}) })) }) }, { children: [(0, jsx_runtime_1.jsx)(PlaceholderTemplateAggregation_1.PlaceholderTemplateAggregation, { currentFolder: currentFolder, activePlaceholderRecord: activePlaceholderRecord, currentPlaceholderData: currentPlaceholderData, setSavePlaceholder: setSavePlaceholder }), (0, jsx_runtime_1.jsx)("div", __assign({ className: 'panel-bottom' }, { children: (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: "primary", size: "large", disabled: disableEditButton, onClick: savePlaceholderEditData }, { children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F" })) }))] })));
};
exports.PlaceholderLibraryTemplateInfo = PlaceholderLibraryTemplateInfo;
