"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMaxNumDepth = exports.getNumDepth = exports.calculateDocumentTree = void 0;
// import { cloneDeep } from 'lodash';
var BlockTypes_1 = require("../components/TemplateRender/BlockTypes");
var utilBlocks_1 = require("../components/TemplateRender/utils/utilBlocks");
var DocumentEntities_1 = require("../viewEntities/DocumentEntities");
var sort_1 = require("./sort");
// получаем позиции блоков секций внутри групп секций
// возвращает объект типа
//  {
//    индексГруппыВнутриСекции: [ idПервогоБлокаВГруппе, idВторокгоБлокаВГруппе, ... ],
//    ...
//   }
function getSectionOrders(sectionId, autonumSections, docFlag) {
    var secId = docFlag ? sectionId : "wrapper_".concat(sectionId);
    var section = document.getElementById(secId);
    if (!section)
        return null;
    var sectionOrders = {};
    var sectionGroups = Array.from(section.childNodes).filter(function (node) { return node.classList && node.classList.contains('group'); });
    if (!sectionGroups.length)
        return null;
    sectionGroups.forEach(function (group, index) {
        var blocksInsideGroup = Array.from(group.childNodes).filter(function (node) { return node.classList && (node.classList.contains('section') || node.classList.contains('aggregation')); });
        sectionOrders[index] = blocksInsideGroup
            // обрезаем приставку wrapper_ для шаблонов
            .map(function (node) {
            var nodeId = node.getAttribute('id');
            return docFlag ? nodeId : nodeId.slice(8);
        })
            .filter(function (id) { return autonumSections.find(function (sec) { return sec.id === id; }); });
    });
    return sectionOrders;
}
// Специальный тип нумерации для вложенных секций
function calcBlockNumerationNestedSection(block, blocksList, blocks, sections, tree) {
    var numeration_depth = block.numeration_depth, prevBlockIndex = block.prevBlockIndex;
    var numeration = [];
    var prevNumBlockNumeration = tree[blocksList[prevBlockIndex].id];
    var section = sections.find(function (x) { return x.id === block.sectionId; });
    // Убеждаемся что это внутренняя секция (вариативность)
    if (section && section.sectionId) {
        // Находим родительскую вариативность и смотрим тип нумерации
        var parentSection = blocks.find(function (x) { return x.id === section.sectionId; });
        if (parentSection && (parentSection.subType !== 'multi' || parentSection.sectionVariant !== 'multi')) {
            //
            // Отдельная логика нумерации
            //
            // Текущая группа
            var groupId = block.groupId;
            // Если для данной группы это первый блок
            var checkBlock = (0, utilBlocks_1.getFirstBlockInSectionByGroup)(section.id, groupId, sections);
            if (checkBlock && checkBlock.id === block.id) {
                // Нужен первый блок родительской секции вариативности
                var firstBlock = (0, utilBlocks_1.getFirstBlockInSection)(parentSection.id, sections);
                // Нумерацию для него берем от первого родительского блока, а не от предыдущего элемента
                var index = tree[firstBlock.id];
                if (index) {
                    // Берем соответствующий уровень нумерации
                    if (index[numeration_depth]) {
                        return index[numeration_depth];
                        // eslint-disable-next-line no-else-return
                    }
                    else {
                        return index;
                    }
                    // eslint-disable-next-line no-else-return
                }
                else {
                    return 1;
                }
            }
            else {
                var childIndex = prevNumBlockNumeration[numeration_depth] + 1;
                return childIndex;
            }
        }
    }
    return prevNumBlockNumeration[numeration_depth] + 1;
}
function calcBlockNumeration(block, blocksList, blocks, sections, tree, docFlag) {
    var numeration_depth = block.numeration_depth, prevBlockIndex = block.prevBlockIndex;
    var numeration = [];
    // не нашли элемент с нумерацией, перед нашим элементом
    if (prevBlockIndex === null) {
        return [1];
    }
    // нумерация блока имеет формат [1, 2, 3, 5]
    // индекс в массиве соответствует numeration_depth
    var prevNumBlockNumeration = tree[blocksList[prevBlockIndex].id];
    if (!prevNumBlockNumeration)
        return [];
    // const prevNumBlockDepth = prevNumBlockNumeration.length;
    numeration = prevNumBlockNumeration.slice(0, numeration_depth);
    if (prevNumBlockNumeration[numeration_depth]) {
        if (!docFlag) {
            // Частный случай нумерации для режима редактирования
            // Во вложенной секции (вариативности) в другую секцию (вариативность), при выборе  только одного значений в родительской нумерации,
            // нумерация вариативности должна быть по задаче DC-4065
            if (block.sectionId && block.orderInSection) {
                numeration[numeration_depth] = calcBlockNumerationNestedSection(block, blocksList, blocks, sections, tree);
            }
            else {
                numeration[numeration_depth] = prevNumBlockNumeration[numeration_depth] + 1;
            }
        }
        else {
            numeration[numeration_depth] = prevNumBlockNumeration[numeration_depth] + 1;
        }
    }
    else {
        numeration[numeration_depth] = 1;
    }
    // убираем разрыв нумерации, если такой случился
    numeration = numeration.filter(function (index) { return index; });
    return numeration;
}
function setPrevIndex(blocksList, numDepth) {
    var blocksListLength = blocksList.length;
    if (blocksListLength) {
        var prevBlockSection_1 = blocksList[blocksListLength - 1].sectionId;
        // если перед элементом находится секция
        if (prevBlockSection_1) {
            var sectionBlocks = blocksList.filter(function (block) { return block.sectionId && block.sectionId === prevBlockSection_1; });
            var sameDepthSections = sectionBlocks.filter(function (b) { return b.numeration_depth === numDepth; });
            // в секции есть блоки с такой же глубиной нумерации
            // блок должен ссылаться на блок секции с максимальными значениями уровней нумерации
            if (sameDepthSections.length) {
                var maxOrder_1 = Math.max.apply(Math, sameDepthSections.map(function (sec) { return sec.order; }));
                var itemToSetIndex_1 = sameDepthSections.find(function (sec) { return sec.order === maxOrder_1; });
                return blocksList.findIndex(function (b) { return b.id === itemToSetIndex_1.id; });
            }
            // в секции нет блоков с такой же глубиной нумерации
            var orders_1 = sectionBlocks.map(function (block) { return block.order; });
            var maxOrderIndexInSection = blocksList.findIndex(function (block) { return block.order === Math.max.apply(Math, orders_1) && block.sectionId && block.sectionId === prevBlockSection_1; });
            return maxOrderIndexInSection;
        }
        return blocksList.length - 1;
    }
    return null;
}
function handleSectionBlockNumeration(block, blocksList, sections, docFlag) {
    var isMultiSection = block.subType === 'multi' || block.sectionVariant === 'multi';
    var sectionOrders = getSectionOrders(block.id, sections, docFlag);
    if (!sectionOrders)
        return;
    var lastBlockBeforeSectionIndex = blocksList.length ? blocksList.length - 1 : null;
    Object.keys(sectionOrders).forEach(function (groupIndex) {
        var blockIds = sectionOrders[groupIndex];
        blockIds.forEach(function (blockId, index) {
            var lastBlockIndex = blocksList.length ? blocksList.length - 1 : null;
            var sectionBlock = sections.find(function (sec) { return sec.id === blockId; });
            if (sectionBlock.type === BlockTypes_1.blockTypes.section.type) {
                handleSectionBlockNumeration(sectionBlock, blocksList, sections, docFlag);
                return;
            }
            // первый блок внутри группы
            // должен ссылаться на блок перед секцией
            // если у нас секция с мульти-выбором, то все блоки внутри групп
            // ссылаются на предыдущий блок независимо от их группы
            var prevIndex = !index && !isMultiSection ? lastBlockBeforeSectionIndex : lastBlockIndex;
            var newBlock = {
                id: sectionBlock.id,
                sectionId: sectionBlock.sectionId,
                // order: (prevIndex !== null)
                order: prevIndex !== null && prevIndex !== -1 ? blocksList[prevIndex].order + 1 : 0,
                numeration_depth: sectionBlock.numeration_depth,
                prevBlockIndex: prevIndex === -1 ? null : prevIndex,
                orderInSection: sectionBlock.order,
                groupId: sectionBlock.groupId,
            };
            blocksList.push(newBlock);
        });
    });
}
// возвращает связное дерево узлов
// каждый узел имеет порядковый номер order
// и ссылку на индекс (prevIndex) предыдущего узла в массиве blocksList
function makeBlocksList(blocks, sections, docFlag) {
    var blocksList = [];
    blocks.forEach(function (block) {
        // если блок - секция
        if (block.type === BlockTypes_1.blockTypes.section.type) {
            // последний блок перед секцией
            handleSectionBlockNumeration(block, blocksList, sections, docFlag);
        }
        else {
            // самый обычный блок
            blocksList.push({
                id: block.id,
                order: block.order,
                numeration_depth: block.numeration_depth,
                prevBlockIndex: setPrevIndex(blocksList, block.numeration_depth),
                sectionId: block.sectionId,
            });
        }
    });
    return blocksList;
}
function calculateDocumentTree(sections, blocks, docFlag) {
    if (docFlag === void 0) { docFlag = false; }
    try {
        var tree_1 = {};
        var autonumBlocks_1 = __spreadArray([], blocks, true).filter(function (block) { return block.autonumeric; })
            // отсеиваем блоки аттачей и таблиц, которым была проставлена нумерация
            .filter(function (block) { return ![BlockTypes_1.blockTypes.table.type, BlockTypes_1.blockTypes.attachment.type].includes(block.type); });
        var autonumSections_1 = __spreadArray([], sections, true).filter(function (sec) { return sec.autonumeric; });
        autonumSections_1.forEach(function (section) {
            var sectionBlockIsAutonum = autonumBlocks_1.find(function (_a) {
                var id = _a.id;
                return id === section.sectionId;
            });
            if (!sectionBlockIsAutonum) {
                var findingSection = __spreadArray([], blocks, true).find(function (_a) {
                    var id = _a.id;
                    return id === section.sectionId;
                });
                if (findingSection)
                    autonumBlocks_1.push(findingSection);
            }
        });
        var blocksList_1 = makeBlocksList(autonumBlocks_1.sort(sort_1.sortByOrderField), autonumSections_1, docFlag);
        // Корректировка списка блоков задача DC-4065
        // Проблема - после формирования списка блоков - порядковый номер у блоков которые расположены во внутренних секции неправильный,
        // нужно чтобы порядковый номер был такой же как и у других элементов родительской секции
        blocksList_1.forEach(function (item) {
            var _a, _b;
            var section = (_a = autonumSections_1.find(function (x) { return x.id === item.sectionId; })) !== null && _a !== void 0 ? _a : autonumBlocks_1.find(function (x) { return x.id === item.sectionId; });
            // Если это внутренняя секция
            if (section && section.sectionId) {
                // Только для первого блока внутренней секции
                var firstBlock = (0, utilBlocks_1.getFirstBlockInSectionByGroup)(item.sectionId || '', item.groupId, autonumSections_1);
                if (firstBlock.id === item.id) {
                    var parentSection = (_b = autonumSections_1.find(function (x) { return x.id === section.sectionId; })) !== null && _b !== void 0 ? _b : autonumBlocks_1.find(function (x) { return x.id === section.sectionId; });
                    // Если есть родительская секции и тип один вариант
                    if (parentSection && (parentSection.subType !== 'multi' || parentSection.sectionVariant !== 'multi')) {
                        // Первый блок родительской секции
                        var firstBlockParent_1 = (0, utilBlocks_1.getFirstBlockInSection)(parentSection.id, autonumSections_1);
                        if (firstBlockParent_1) {
                            // Смотрим какой у него индек
                            var firstDataIndex = blocksList_1.find(function (x) { return x.id === firstBlockParent_1.id; });
                            if (firstDataIndex) {
                                item.order = firstDataIndex.order;
                                item.prevBlockIndex = firstDataIndex.prevBlockIndex;
                            }
                        }
                    }
                }
            }
        });
        // считаем нумерацию
        blocksList_1.forEach(function (block) {
            tree_1[block.id] = calcBlockNumeration(block, blocksList_1, autonumBlocks_1, autonumSections_1, tree_1, docFlag);
        });
        var documentEntities = DocumentEntities_1.DocumentEntities.createFromDom(docFlag, blocks, sections);
        var newTree = documentEntities.calcTreeNumeration();
        return { tree: newTree, blocksList: blocksList_1 };
    }
    catch (error) {
        console.error('Не удалось рассчитать нумерацию блоков: ', error);
        return { tree: {} };
    }
}
exports.calculateDocumentTree = calculateDocumentTree;
// возвращает значение numeration_depth того блока, из которого блок был создан
// если создали из блока без нумерации, то блок тоже будет без нумерации
// была задумка рекурсивно находить первый блок с нумерацией до нового блока,
// но ее пока не реализуем
function getNumDepth(focusedBlock) {
    try {
        var defautlNumDepth = 0;
        if (!focusedBlock)
            return defautlNumDepth;
        var blockNumDepth = focusedBlock.numeration_depth;
        return blockNumDepth || defautlNumDepth;
    }
    catch (err) {
        console.error(err);
        return 0;
    }
}
exports.getNumDepth = getNumDepth;
function getMaxNumDepth(tree, blocksList, item) {
    try {
        if (!item)
            return 0;
        var id_1 = item.id, autonumeric = item.autonumeric, type = item.type;
        if (!autonumeric || type === BlockTypes_1.blockTypes.section.type)
            return 0;
        var blockIndex = blocksList.findIndex(function (b) { return b.id === id_1; });
        if (blockIndex <= 0)
            return 0;
        var maxNumDepth = 0;
        var prevBlockList = blocksList[blockIndex - 1];
        var prevBlockNumeration = tree[prevBlockList.id];
        // По умолчанию максимальный уровень нумерации – глубина нумерации предыдущего блока
        if (prevBlockNumeration) {
            maxNumDepth = prevBlockNumeration.length;
        }
        // Если блок в секции
        if (item.sectionId) {
            // Если в одно группе несколько блоков - то логика аналогичная
            if (prevBlockNumeration && prevBlockList.sectionId && prevBlockList.groupId === item.groupId) {
                maxNumDepth = prevBlockNumeration.length;
            }
            else {
                // В противном случае берем первый блок вне секции и от него считаем глубину нумерации
                for (var i = blockIndex; i > 0; i -= 1) {
                    var prevBlock = blocksList[i - 1];
                    var prevBlockNumeration_1 = tree[prevBlock.id];
                    if (prevBlockNumeration_1 && (prevBlock.sectionId === undefined || prevBlock.sectionId === null)) {
                        maxNumDepth = prevBlockNumeration_1.length;
                        return maxNumDepth;
                    }
                }
            }
        }
        return maxNumDepth;
    }
    catch (error) {
        console.error('Unable to resolve numeration depth', error);
        return 0;
    }
}
exports.getMaxNumDepth = getMaxNumDepth;
