"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityUtility = void 0;
var UtilityDOM_1 = require("./UtilityDOM");
var EntityBlock_1 = require("./Entities/EntityBlock");
var EntityBlockAttachment_1 = require("./Entities/EntityBlockAttachment");
var EntityBlockLibrary_1 = require("./Entities/EntityBlockLibrary");
var EntityBlockList_1 = require("./Entities/EntityBlockList");
var EntityBlockTable_1 = require("./Entities/EntityBlockTable");
var EntityConstans_1 = require("./EntityConstans");
var EntitySection_1 = require("./Entities/EntitySection");
var EntitySectionGroup_1 = require("./Entities/EntitySectionGroup");
/**
 *  Утилиты для работы c сущностями
 */
var EntityUtility = /** @class */ (function () {
    function EntityUtility() {
    }
    /**
     * Создание блока соответствующего типа
     * @param block Элемент DOM (div) представляющий блок
     * @param isDoc Режим документа (предпросмотра)
     * @param parent Родительская сущность
     * @returns
     */
    EntityUtility.createBlockOfType = function (block, isDoc, parent) {
        var _a;
        if (parent === void 0) { parent = null; }
        var id = (_a = block.getAttribute('id')) !== null && _a !== void 0 ? _a : EntityConstans_1.EntityConstans.ID_NULL;
        var result = null;
        // Проверяем от более специализированных к менее специализированным
        if (UtilityDOM_1.UtilityDOM.isBlockContentAttachment(block)) {
            result = new EntityBlockAttachment_1.EntityBlockAttachment(id, parent);
            result.createFromDOM(isDoc);
            if (parent)
                parent.addChild(result);
        }
        else if (UtilityDOM_1.UtilityDOM.isBlockContentList(block)) {
            result = new EntityBlockList_1.EntityBlockList(id, parent);
            result.createFromDOM(isDoc);
            if (parent)
                parent.addChild(result);
        }
        else if (UtilityDOM_1.UtilityDOM.isBlockContentTable(block)) {
            result = new EntityBlockTable_1.EntityBlockTable(id, parent);
            result.createFromDOM(isDoc);
            if (parent)
                parent.addChild(result);
        }
        else if (UtilityDOM_1.UtilityDOM.isBlockContentText(isDoc, block)) {
            result = new EntityBlock_1.EntityBlock(id, parent);
            result.createFromDOM(isDoc);
            if (parent)
                parent.addChild(result);
        }
        return result;
    };
    /**
     * Создание секции
     * @param section Элемент DOM (div) представляющий секцию
     * @param isDoc Режим документа (предпросмотра)
     * @param parent Родительская сущность
     * @returns
     */
    EntityUtility.createSection = function (section, isDoc, parent) {
        var _a;
        if (parent === void 0) { parent = null; }
        var id = (_a = section.getAttribute('id')) !== null && _a !== void 0 ? _a : EntityConstans_1.EntityConstans.ID_NULL;
        var entitySection = new EntitySection_1.EntitySection(id, parent);
        entitySection.createFromDOM(isDoc);
        if (parent)
            parent.addChild(entitySection);
        return entitySection;
    };
    /**
     * Создание группы секции
     * @param group Элемент DOM (div) представляющий группу
     * @param isDoc Режим документа (предпросмотра)
     * @param parent Родительская сущность
     * @returns
     */
    EntityUtility.createSectionGroup = function (group, isDoc, parent) {
        var _a;
        if (parent === void 0) { parent = null; }
        // Получить id нет возможности
        // TODO Добавить id в div группу
        var id = (_a = group.getAttribute('id')) !== null && _a !== void 0 ? _a : EntityConstans_1.EntityConstans.ID_NULL;
        var entitySectionGroup = new EntitySectionGroup_1.EntitySectionGroup(id, parent);
        entitySectionGroup.name = UtilityDOM_1.UtilityDOM.getGroupSectionName(group);
        entitySectionGroup.createFromDOM(isDoc);
        if (parent)
            parent.addChild(entitySectionGroup);
        return entitySectionGroup;
    };
    /**
     * Создание библиотечного блока
     * @param library Элемент DOM (div) представляющий начало библиотечного блока
     * @param isDoc Режим документа (предпросмотра)
     * @param parent Родительская сущность
     * @returns
     */
    EntityUtility.createLibraryBlock = function (library, isDoc, parent) {
        if (parent === void 0) { parent = null; }
        var entityBlockLibrary = new EntityBlockLibrary_1.EntityBlockLibrary(EntityConstans_1.EntityConstans.ID_NULL, parent);
        entityBlockLibrary.name = UtilityDOM_1.UtilityDOM.getLibraryName(library);
        entityBlockLibrary.createFromDOM(isDoc);
        if (parent)
            parent.addChild(entityBlockLibrary);
        return entityBlockLibrary;
    };
    return EntityUtility;
}());
exports.EntityUtility = EntityUtility;
