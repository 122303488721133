"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFormulaButtons = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var CustomFormulaButtonsView_1 = require("./CustomFormulaButtonsView");
var react_redux_1 = require("react-redux");
var CustomFormulaResultUtils_1 = require("../CustomFormulaResult/CustomFormulaResultUtils");
var rootGetters_1 = require("../../../../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../../../../Root/redux/rootActionCreators/rootActionCreators");
var CustomFormulaButtons = function (_a) {
    var _b, _c, _d;
    var currentFormula = _a.currentFormula, setCurrentFormula = _a.setCurrentFormula, enableOperationResult = _a.enableOperationResult, isEditing = _a.isEditing;
    var dispatch = (0, react_redux_1.useDispatch)();
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var currentCustomFormulaCaret = presentState === null || presentState === void 0 ? void 0 : presentState.customFormulaCaret;
    var currentFormulaView = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.formula;
    var caretIndex = currentCustomFormulaCaret === 0 ? currentCustomFormulaCaret : currentCustomFormulaCaret - 1;
    var caretSymbol = currentFormulaView[caretIndex];
    var isOperator = CustomFormulaResultUtils_1.AllOperationsValues === null || CustomFormulaResultUtils_1.AllOperationsValues === void 0 ? void 0 : CustomFormulaResultUtils_1.AllOperationsValues.some(function (value) { return value === caretSymbol; });
    var currentSymbol = new RegExp(/[0-9]/);
    var isOperand = currentSymbol === null || currentSymbol === void 0 ? void 0 : currentSymbol.test(currentFormulaView[caretIndex]);
    var disableCondition = (currentCustomFormulaCaret === null || (!isOperand && !(caretSymbol === '(' || caretSymbol === ')') || (((_b = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.parameters) === null || _b === void 0 ? void 0 : _b.length) === 10))) || isEditing;
    var disableRightBrackets = (currentCustomFormulaCaret === null || (((_c = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.formula) === null || _c === void 0 ? void 0 : _c.length) === 0 || enableOperationResult || isOperator && caretIndex === 0)) || isEditing;
    var disableLeftBrackets = (currentCustomFormulaCaret === null || (((_d = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.formula) === null || _d === void 0 ? void 0 : _d.length) > 0 && !enableOperationResult && !(caretIndex === 0))) || isEditing;
    var selectOperation = function (operation) {
        if (operation !== 'delete') {
            var caretIndex_1 = currentCustomFormulaCaret === 0 ? currentCustomFormulaCaret : currentCustomFormulaCaret - 1;
            var updatedCaretIndex = caretIndex_1 + 1;
            // вставка данных в конец формулы по умолчанию
            var currentFormulaView_1 = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.formula;
            // формула строки пустая или каретка находится в конце строки
            if ((currentFormulaView_1 === null || currentFormulaView_1 === void 0 ? void 0 : currentFormulaView_1.length) === 0
                || (caretIndex_1 === (currentFormulaView_1 === null || currentFormulaView_1 === void 0 ? void 0 : currentFormulaView_1.length) - 1)) {
                currentFormulaView_1 = currentFormulaView_1.concat(operation);
                var insertStartIndex = caretIndex_1 === 0 ? caretIndex_1 : caretIndex_1 + 1;
                updatedCaretIndex = insertStartIndex + 1;
            }
            else {
                var formulaSplit = currentFormulaView_1 === null || currentFormulaView_1 === void 0 ? void 0 : currentFormulaView_1.split('');
                var insertStartIndex = caretIndex_1 === 0 ? caretIndex_1 : caretIndex_1 + 1;
                formulaSplit.splice(insertStartIndex, 0, operation);
                currentFormulaView_1 = formulaSplit === null || formulaSplit === void 0 ? void 0 : formulaSplit.join('');
                updatedCaretIndex = insertStartIndex + 1;
            }
            var changedCurrentFormula = __assign(__assign({}, currentFormula), { formula: currentFormulaView_1 });
            setCurrentFormula(changedCurrentFormula);
            dispatch((0, rootActionCreators_1.setCustomFormulaCaret)(updatedCaretIndex));
        }
        if (operation === 'delete') {
            var caretIndex_2 = currentCustomFormulaCaret === 0 ? currentCustomFormulaCaret : currentCustomFormulaCaret - 1;
            var formula = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.formula;
            var parameters = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.parameters;
            var currentSymbol_1 = formula[caretIndex_2];
            // удаляем операнд вида Pn
            if (currentSymbol_1 >= '0' && currentSymbol_1 <= '9' && caretIndex_2 !== 0) {
                var currentPart = currentSymbol_1;
                var prevPart = formula[caretIndex_2 - 1];
                var createdValue_1 = prevPart.concat(currentPart);
                var filterParameters = parameters === null || parameters === void 0 ? void 0 : parameters.filter(function (parameter) { return (parameter === null || parameter === void 0 ? void 0 : parameter.alias) !== createdValue_1; });
                var filterFormula_1 = formula === null || formula === void 0 ? void 0 : formula.replace(createdValue_1, '');
                filterParameters === null || filterParameters === void 0 ? void 0 : filterParameters.forEach(function (parameter, index) {
                    var alias = parameter === null || parameter === void 0 ? void 0 : parameter.alias;
                    var newAlias = "p".concat(index);
                    filterFormula_1 = filterFormula_1 === null || filterFormula_1 === void 0 ? void 0 : filterFormula_1.replace(alias, newAlias);
                    parameter.alias = newAlias;
                });
                var changedCurrentFormula = __assign(__assign({}, currentFormula), { parameters: filterParameters, formula: filterFormula_1 });
                setCurrentFormula(changedCurrentFormula);
                // убрали операнд вида Pn, значит уменьшаем индекс на 2
                var changedCaretIndex = caretIndex_2 - 1;
                var settingIndex = changedCaretIndex <= 0 ? 0 : changedCaretIndex;
                dispatch((0, rootActionCreators_1.setCustomFormulaCaret)(settingIndex));
            }
            if ((CustomFormulaResultUtils_1.AllOperationsValues === null || CustomFormulaResultUtils_1.AllOperationsValues === void 0 ? void 0 : CustomFormulaResultUtils_1.AllOperationsValues.some(function (value) { return value === currentSymbol_1; })) && caretIndex_2 !== 0) {
                var createdValue_2 = currentSymbol_1;
                var formulaArray = formula === null || formula === void 0 ? void 0 : formula.split('');
                var formulaArrayEdited = formulaArray === null || formulaArray === void 0 ? void 0 : formulaArray.filter(function (oper, index) {
                    if (oper === createdValue_2 && index === caretIndex_2) {
                        return false;
                    }
                    else {
                        return true;
                    }
                });
                var filterFormula = formulaArrayEdited === null || formulaArrayEdited === void 0 ? void 0 : formulaArrayEdited.join('');
                var changedCurrentFormula = __assign(__assign({}, currentFormula), { formula: filterFormula });
                setCurrentFormula(changedCurrentFormula);
                var changedCaretIndex = caretIndex_2;
                var settingIndex = changedCaretIndex;
                dispatch((0, rootActionCreators_1.setCustomFormulaCaret)(settingIndex));
            }
            if ((CustomFormulaResultUtils_1.AllOperationsValues === null || CustomFormulaResultUtils_1.AllOperationsValues === void 0 ? void 0 : CustomFormulaResultUtils_1.AllOperationsValues.some(function (value) { return value === currentSymbol_1; })) && caretIndex_2 === 0) {
                var filterFormula = formula === null || formula === void 0 ? void 0 : formula.replace(currentSymbol_1, '');
                var changedCurrentFormula = __assign(__assign({}, currentFormula), { formula: filterFormula });
                setCurrentFormula(changedCurrentFormula);
            }
        }
    };
    return ((0, jsx_runtime_1.jsx)(CustomFormulaButtonsView_1.CustomFormulaButtonsView, { disableCondition: disableCondition, disableLeftBrackets: disableLeftBrackets, disableRightBrackets: disableRightBrackets, selectOperation: selectOperation, isEditing: isEditing }));
};
exports.CustomFormulaButtons = CustomFormulaButtons;
