"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBreadcrumbsAlias = exports.placeholderLibraryControls = void 0;
exports.placeholderLibraryControls = {
    reader: 2,
    writer: 4,
    owner: 8,
};
var test = [];
var breadcrumbsCreate = function (key, placeholderTemplateFolders) {
    var currentFolder = placeholderTemplateFolders === null || placeholderTemplateFolders === void 0 ? void 0 : placeholderTemplateFolders.find(function (folder) { return folder.id === key; });
    if (key && currentFolder) {
        var kind = currentFolder === null || currentFolder === void 0 ? void 0 : currentFolder.kind;
        var parentId = currentFolder === null || currentFolder === void 0 ? void 0 : currentFolder.parentId;
        if (kind !== 'perimeter') {
            test.push(currentFolder);
            return breadcrumbsCreate(parentId, placeholderTemplateFolders);
        }
        else {
            test.push(currentFolder);
            return test === null || test === void 0 ? void 0 : test.reverse();
        }
    }
    else {
        return test;
    }
};
var createBreadcrumbsAlias = function (key, placeholderTemplateFolders) {
    test = [];
    var breadcrumbsAlias = breadcrumbsCreate(key, placeholderTemplateFolders) || [];
    return breadcrumbsAlias;
};
exports.createBreadcrumbsAlias = createBreadcrumbsAlias;
