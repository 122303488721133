"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageKinds = exports.DEFAULT_PAGINATION = exports.CHANNEL_IDS = exports.CHANNEL_GUIDS = void 0;
exports.CHANNEL_GUIDS = {
    document: CHANNEL_DC_ID,
    tpl: CHANNEL_TPL_ID,
};
exports.CHANNEL_IDS = {
    document: 'dcDocument',
    tpl: 'dc',
};
exports.DEFAULT_PAGINATION = {
    page: 1,
    pageSize: 4,
    total: 0,
};
var MessageKinds;
(function (MessageKinds) {
    MessageKinds["Unlimited"] = "unlimited";
    MessageKinds["Urgent"] = "urgent";
})(MessageKinds = exports.MessageKinds || (exports.MessageKinds = {}));
