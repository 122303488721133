"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSidePanelWidth = void 0;
var padding = 20;
var colCount = 24;
var colGap = 20;
var sideBarColCount = 6;
var defaultWidth = 320;
function getSidePanelWidth() {
    var w = window;
    var d = document;
    var e = d.documentElement;
    var g = d.getElementsByTagName('body')[0];
    var screenWidth = w.innerWidth || e.clientWidth || g.clientWidth;
    if (!screenWidth)
        return defaultWidth;
    return (((screenWidth - (padding * 2) - ((colCount - 1) * colGap))
        / colCount) * sideBarColCount)
        + ((sideBarColCount - 1) * colGap);
}
exports.getSidePanelWidth = getSidePanelWidth;
