"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearEmptyBlocksArtefacts = exports.domParser = exports.contentAllign = exports.isDisabledCopyBlocks = void 0;
// копирование/добавление в библиотеку выбранных блоков
var isDisabledCopyBlocks = function (blocks, selectedBlockIds) {
    if (!(selectedBlockIds === null || selectedBlockIds === void 0 ? void 0 : selectedBlockIds.length)) {
        return false;
    }
    // блок с типом список полномочий должен быть всегда один, если в выбранных блоках есть блок с типом список полномочий, копировать блоки нельзя
    var authorityTypeBlock = blocks.find(function (item) { return item.type === 'authority'; });
    var canCopyAuthority = !authorityTypeBlock || !selectedBlockIds.includes(authorityTypeBlock.id);
    // если в выбранных блоках есть блок с суб типом withTL (шапка для блока с типом templatedList), копировать блоки нельзя
    var canCopyWithTL = !blocks.some(function (item) { return item.subType === 'withTL' && selectedBlockIds.includes(item.id); });
    return !canCopyAuthority || !canCopyWithTL;
};
exports.isDisabledCopyBlocks = isDisabledCopyBlocks;
/* TODO: need refactoring */
var contentAllign = function (id) {
    var _a;
    var value = (_a = document.getElementById(id)) === null || _a === void 0 ? void 0 : _a.classList.value;
    if (value === null || value === void 0 ? void 0 : value.includes('position')) {
        var trimIndex = value === null || value === void 0 ? void 0 : value.indexOf('position');
        return value === null || value === void 0 ? void 0 : value.slice(trimIndex);
    }
    return;
};
exports.contentAllign = contentAllign;
var domParser = function (node, selector) {
    return new DOMParser().parseFromString(node, 'text/html').querySelector(selector);
};
exports.domParser = domParser;
var clearEmptyBlocksArtefacts = function (deletePhsAlias, templatedListBlocks, updatedBlocks, blocks) {
    deletePhsAlias.forEach(function (ph) {
        var phDataValue = ph.dataValue;
        if (phDataValue) {
            if (templatedListBlocks === null || templatedListBlocks === void 0 ? void 0 : templatedListBlocks.length) {
                var filteredBlocksByGuid = templatedListBlocks.filter(function (block) { return block.templateElement.template_id.guid === phDataValue; });
                filteredBlocksByGuid.forEach(function (block) {
                    var _a, _b;
                    if (((_a = block.templateElement.template_id) === null || _a === void 0 ? void 0 : _a.guid) === phDataValue) {
                        var blockContentId_1 = (_b = (0, exports.domParser)(JSON.parse(block === null || block === void 0 ? void 0 : block.templateElement.content).html, '.text-paragraph')) === null || _b === void 0 ? void 0 : _b.id;
                        var blocksArtefacts_1 = [];
                        blocks.forEach(function (element) {
                            var _a;
                            var currentBlockId = (_a = (0, exports.domParser)(element === null || element === void 0 ? void 0 : element.content.html, '.text-paragraph')) === null || _a === void 0 ? void 0 : _a.id;
                            if (currentBlockId) {
                                blocksArtefacts_1.push({ currentId: element.id, currentBlockId: currentBlockId });
                            }
                        });
                        var currentElem_1 = blocksArtefacts_1.find(function (item) { return item.currentBlockId === blockContentId_1; });
                        if (currentElem_1) {
                            var finrenderedBlock = blocks === null || blocks === void 0 ? void 0 : blocks.find(function (item) { return item.id === (currentElem_1 === null || currentElem_1 === void 0 ? void 0 : currentElem_1.currentId); });
                            if (finrenderedBlock) {
                                finrenderedBlock.action = 'delete';
                            }
                        }
                    }
                });
            }
            else {
                var filteredBlocksByGuid = updatedBlocks.filter(function (block) { var _a; return (_a = block.template_id) === null || _a === void 0 ? void 0 : _a.guid; });
                filteredBlocksByGuid.forEach(function (block) {
                    var _a;
                    if (((_a = block.template_id) === null || _a === void 0 ? void 0 : _a.guid) === phDataValue) {
                        block.action = 'delete';
                    }
                });
            }
        }
    });
};
exports.clearEmptyBlocksArtefacts = clearEmptyBlocksArtefacts;
