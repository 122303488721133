"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableConstants = void 0;
var TableConstants = /** @class */ (function () {
    function TableConstants() {
    }
    TableConstants.TechnicalRowKey = 'TR';
    TableConstants.TechnicalColumnKey = 'TC';
    TableConstants.PrefixRowKey = 'r_';
    TableConstants.PrefixColumnKey = 'c_';
    /**
     * Стандартная ширина таблицы в мм
     */
    TableConstants.TableWidth = 207;
    /**
     * Минимальная высота строки в мм
     */
    TableConstants.RowMinHeight = 3;
    /**
     * Максимальная высота строки в мм
     */
    TableConstants.RowMaxHeight = 150;
    /**
     * Ширина столбца действий в мм
     */
    TableConstants.ColumnTechWidth = 6;
    /**
     * Минимальная ширина столбца в мм
     */
    TableConstants.ColumnMinWidth = 8;
    /**
     * Коэффициент для перевода пикселей в мм
     */
    TableConstants.CoeffPixelToMm = 0.264583333333334;
    return TableConstants;
}());
exports.TableConstants = TableConstants;
