"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeBreadcrumbs = void 0;
var makeBreadcrumbs = function (folderId, folders, breadcrumbs) {
    var folder = folders.find(function (item) { return item.id === folderId; });
    if (folder) {
        breadcrumbs.push({ id: folder.id, name: folder.name });
        if (folder.parentFolderId) {
            (0, exports.makeBreadcrumbs)(folder.parentFolderId, folders, breadcrumbs);
        }
    }
    return breadcrumbs.slice(0).reverse();
};
exports.makeBreadcrumbs = makeBreadcrumbs;
