"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SavingModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var react_1 = require("react");
var getPlaceholderEntity_1 = require("../../../utils/getPlaceholderEntity");
var routes_1 = require("../../../../routes");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var placeholdersLibraryUtils_1 = require("../../../utils/placeholdersLibraryUtils");
var rootGetters_1 = require("../../../../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../../../../Root/redux/rootActionCreators/rootActionCreators");
var SavingModal = function (_a) {
    var props = __rest(_a, []);
    var selectedPlaceholder = props.selectedPlaceholder, address = props.address, onDirectorySave = props.onDirectorySave;
    var history = (0, react_router_dom_1.useHistory)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_1.useState)(false), confirmLoading = _b[0], setConfirmLoading = _b[1];
    var historyLocationState = history.location.state || {};
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var templateId = presentState === null || presentState === void 0 ? void 0 : presentState.templateInfo.id;
    var templateType = presentState === null || presentState === void 0 ? void 0 : presentState.templateInfo.type;
    var currentPerimeterId = presentState === null || presentState === void 0 ? void 0 : presentState.templateInfo.perimeterId;
    var templateSaveModal = presentState === null || presentState === void 0 ? void 0 : presentState.templateSaveModal;
    var systemsCatalogue = (0, react_redux_1.useSelector)(rootGetters_1.getSystemsCatalogueList);
    var placeholders = (0, react_redux_1.useSelector)(rootGetters_1.getPlaceholders);
    var hiddenPhAlias = (0, react_redux_1.useSelector)(function (state) {
        // @ts-ignore
        var hide = state.root.present.hide;
        return hide;
    });
    var isHiddenPh = hiddenPhAlias.find(function (item) { return item.ph_id === (selectedPlaceholder === null || selectedPlaceholder === void 0 ? void 0 : selectedPlaceholder.id); });
    var saveModalCancel = function () {
        dispatch((0, rootActionCreators_1.setTemplateSaveModal)(false));
    };
    var openAddingPlaceholderAdmin = function () {
        setConfirmLoading(true);
        var savingStatus = onDirectorySave();
        setTimeout(function () {
            dispatch((0, rootActionCreators_1.setTemplateSaveModal)(false));
            if (savingStatus) {
                if (selectedPlaceholder && templateId) {
                    var placeholderData = (0, getPlaceholderEntity_1.getPlaceholdersEntities)([selectedPlaceholder], templateId, true);
                }
                var isPlaceholderSelectedId = (selectedPlaceholder === null || selectedPlaceholder === void 0 ? void 0 : selectedPlaceholder.id) || '';
                var findSelectedSystem = (0, placeholdersLibraryUtils_1.getSelectedSystem)(placeholders, systemsCatalogue);
                var isPlaceholderSelectedSystem = (findSelectedSystem === null || findSelectedSystem === void 0 ? void 0 : findSelectedSystem.id) || '';
                var createHistoryState = __assign(__assign({}, historyLocationState), { templateId: "".concat(templateId), templateType: templateType, placeholderId: "".concat(isPlaceholderSelectedId), address: address, currentPerimeterId: "".concat(currentPerimeterId), isHiddenPh: isHiddenPh, fromSystem: isPlaceholderSelectedSystem });
                // @ts-ignore
                history.push(routes_1.routeGenerator.placeholderLibraryAdminEditLink(''), createHistoryState);
                location.reload();
            }
            setConfirmLoading(false);
        }, 4000);
    };
    return ((0, jsx_runtime_1.jsx)(antd_1.Modal, __assign({ open: templateSaveModal, title: 'Сохранить', width: '600px', confirmLoading: confirmLoading, onCancel: saveModalCancel, onOk: openAddingPlaceholderAdmin }, { children: "\u041D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0441\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0443 \u0432\u043E \u0438\u0437\u0431\u0435\u0436\u0430\u043D\u0438\u0435 \u043F\u043E\u0442\u0435\u0440\u0438 \u0434\u0430\u043D\u043D\u044B\u0445" })));
};
exports.SavingModal = SavingModal;
