"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataForSelect = void 0;
var lodash_1 = require("lodash");
// TODO: [Mouseee] Вернуться при рефакторинге функционала фильтров папок на главной
var dataForSelect = function (folderContents) {
    var categoryName = folderContents.filter(function (i) { return i.categories[0].name; });
    var docDate = '';
    var docNumber = '';
    var name = folderContents.filter(function (i) { return i.name; });
    var parentDocDate = '';
    var parentDocNumber = '';
    var parentName = '';
    var dataToFilter = {
        docDate: (0, lodash_1.uniqBy)(docDate, 'docDate'),
        docNumber: (0, lodash_1.uniqBy)(docNumber, 'docNumber'),
        name: (0, lodash_1.uniqBy)(name, 'name'),
        parentDocDate: (0, lodash_1.uniqBy)(parentDocDate, 'parentDocDate'),
        parentDocNumber: (0, lodash_1.uniqBy)(parentDocNumber, 'parentDocNumber'),
        parentName: (0, lodash_1.uniqBy)(parentName, 'parentName'),
    };
    return dataToFilter;
};
exports.dataForSelect = dataForSelect;
