"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readObjectOther = exports.readObject = exports.findElements = exports.findElement = void 0;
// универсальная функция поиска элемента в любом объекте с любым заданным полем
var findElement = function (obj, field, value) {
    for (var key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            if (key === field && obj[key] === value) {
                return obj;
            }
            else if (typeof obj[key] === 'object') {
                var result = (0, exports.findElement)(obj[key], field, value);
                if (result) {
                    return result;
                }
            }
            else if (Array.isArray(obj[key])) {
                for (var i = 0; i < obj[key].length; i++) {
                    var result = (0, exports.findElement)(obj[key][i], field, value);
                    if (result) {
                        return result;
                    }
                }
            }
        }
    }
    return null;
};
exports.findElement = findElement;
var findElements = function (obj, field, value) {
    var results = [];
    for (var key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            if (key === field && obj[key] === value) {
                // @ts-ignore
                results.push(obj);
            }
            else if (typeof obj[key] === 'object') {
                (0, exports.findElements)(obj[key], field, value);
            }
            else if (Array.isArray(obj[key])) {
                for (var i = 0; i < obj[key].length; i++) {
                    var results_1 = (0, exports.findElements)(obj[key][i], field, value);
                    if (results_1) {
                        return results_1;
                    }
                }
            }
        }
    }
    return results;
};
exports.findElements = findElements;
// собираем subparts
var readObject = function (arr, subpart) {
    arr.forEach(function (item) {
        if (item.kind !== 'Subpart') {
            (0, exports.readObject)(item.children, subpart);
        }
        else {
            subpart.push(item);
            return subpart;
        }
    });
};
exports.readObject = readObject;
var readObjectOther = function (arr, subpart) {
    arr.forEach(function (item) {
        subpart.push(item);
        if (item.children.length) {
            (0, exports.readObjectOther)(item.children, subpart);
        }
        else {
            return subpart;
        }
    });
};
exports.readObjectOther = readObjectOther;
