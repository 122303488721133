"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearPlaceholderLink = void 0;
var clearPlaceholderLink = function (aggregation, aggregations) {
    if (aggregation.type === 'section') {
        var updatedAggregations = aggregations.map(function (aggr) {
            if (aggr.id === aggregation.id && aggr.settings) {
                delete aggr.settings;
            }
            return aggr;
        });
        return updatedAggregations;
    }
    else {
        if (!aggregation.parent_id)
            return aggregations;
        var updatedAggregations = aggregations.map(function (aggr) {
            if (aggr.id === aggregation.parent_id && aggr.settings) {
                delete aggr.settings[aggregation.id];
            }
            return aggr;
        });
        return updatedAggregations;
    }
};
exports.clearPlaceholderLink = clearPlaceholderLink;
