"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibrarySiderMenu = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var PlaceholderLibraryLogo_1 = require("./PlaceholderLibraryLogo/PlaceholderLibraryLogo");
var PlaceholdersLibraryTree_1 = require("../PlaceholdersLibraryTree/PlaceholdersLibraryTree");
var Sider = antd_1.Layout.Sider;
var PlaceholderLibrarySiderMenu = function (_a) {
    var props = __rest(_a, []);
    var folders = props.folders, templateType = props.templateType;
    return ((0, jsx_runtime_1.jsxs)(Sider, __assign({ collapsible: false, width: 300, style: { minHeight: '100vh' } }, { children: [(0, jsx_runtime_1.jsx)(PlaceholderLibraryLogo_1.PlaceholderLibraryLogo, {}), (0, jsx_runtime_1.jsx)(PlaceholdersLibraryTree_1.PlaceholdersLibraryTree, { placeholderTemplateFolders: folders, insertion: false, templateType: templateType })] })));
};
exports.PlaceholderLibrarySiderMenu = PlaceholderLibrarySiderMenu;
