"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFormulaMainView = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var CustomFormulaSelect_1 = require("../CustomFormulaSelect/CustomFormulaSelect");
var CustomFormulaCalculator_1 = require("../\u0421ustomFormulaCalculator/CustomFormulaCalculator");
var CustomFormulaMainView = function (_a) {
    var sortedCustomFormulaAggregations = _a.sortedCustomFormulaAggregations, currentFormula = _a.currentFormula, setCurrentFormula = _a.setCurrentFormula, enableOperationResult = _a.enableOperationResult, aggregation = _a.aggregation, updateAggregations = _a.updateAggregations;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(CustomFormulaSelect_1.CustomFormulaSelect
            // @ts-ignore
            , { 
                // @ts-ignore
                sortedCustomFormulaAggregations: sortedCustomFormulaAggregations, setCurrentFormula: setCurrentFormula, currentFormula: currentFormula, enableOperationResult: enableOperationResult }), (0, jsx_runtime_1.jsx)(CustomFormulaCalculator_1.CustomFormulaCalculator, { setCurrentFormula: setCurrentFormula, currentFormula: currentFormula, enableOperationResult: enableOperationResult, aggregation: aggregation, updateAggregations: updateAggregations, sortedCustomFormulaAggregations: sortedCustomFormulaAggregations })] }));
};
exports.CustomFormulaMainView = CustomFormulaMainView;
