"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationUtils = void 0;
var history_1 = require("../../../history");
var LocationUtils = /** @class */ (function () {
    function LocationUtils() {
    }
    LocationUtils.isEditDocument = function () { return history_1.history.location.pathname.includes('editDocument'); };
    LocationUtils.isPreviewDocument = function () { return history_1.history.location.pathname.includes('previewDocument'); };
    return LocationUtils;
}());
exports.LocationUtils = LocationUtils;
