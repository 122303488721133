"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectedNodeList = void 0;
var common_1 = require("../../../utils/common");
var nodeUtils_1 = require("./nodeUtils");
function getSelectedNodeList(selectedPlaceholder) {
    var _a;
    if (!selectedPlaceholder) {
        return common_1.GetUtils.getSelectedNodeList();
    }
    var selectedNodeList = [];
    if (selectedPlaceholder.subType === 'boundary') {
        var newNodes_1 = common_1.DomUtils.selectBoundaryContentsById(selectedPlaceholder.id);
        newNodes_1.forEach(function (node) {
            var _a;
            var newNode = node.cloneNode(true);
            if (newNodes_1.length > 3) {
                var wrapper = (_a = common_1.GetUtils.getParentElement(node, common_1.CheckUtils.isParagraphNode)) === null || _a === void 0 ? void 0 : _a.cloneNode(false);
                if (common_1.TypeUtils.isElement(wrapper)) {
                    wrapper.append(newNode);
                    selectedNodeList.push(wrapper);
                }
            }
            else {
                selectedNodeList.push(newNode);
            }
        });
    }
    else {
        var id = common_1.AttrUtils.createPhId(selectedPlaceholder.id);
        var newNode = (_a = document.getElementById(id)) === null || _a === void 0 ? void 0 : _a.cloneNode(true);
        if (common_1.TypeUtils.isElement(newNode)) {
            selectedNodeList.push((0, nodeUtils_1.createNewInlineText)(), newNode, (0, nodeUtils_1.createNewInlineText)());
        }
    }
    return selectedNodeList;
}
exports.getSelectedNodeList = getSelectedNodeList;
