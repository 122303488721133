"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityBase = void 0;
/**
 * Базовый сущность размещенная в шаблоне/документе
 */
var EntityBase = /** @class */ (function () {
    //#endregion
    //#region Конструкторы
    function EntityBase(id, parent) {
        if (parent === void 0) { parent = null; }
        /**
         * Идентификатор
         */
        this.id = '';
        /**
         * Наименование
         */
        this.name = '';
        /**
         * Контент
         */
        this.content = '';
        //#region Нумерация
        /**
         * Поддерживает ли сущность нумерацию
         */
        this.isNumericSupport = false;
        /**
         * Порядковый номер
         * Совпадает c индексом в массиве childs
         */
        this.index = 0;
        /**
         * Вычисленный порядковый номер
         * Представляет собой массив, где индекс это порядковый номер глубины, а значение - уровень глубины
         */
        this.orderCalc = [];
        /**
         * Включение/отключение нумерации
         */
        this.autonumeric = false;
        /**
         * Глубина нумерации
         */
        this.numerationDepth = 0;
        //#endregion
        //#region Отношения
        /**
         * Родительская сущность
         */
        this.parent = null;
        /**
         * Дочерние сущности
         */
        this.childs = null;
        this.setId(id);
        this.parent = parent;
    }
    //#endregion
    EntityBase.prototype.setId = function (id) {
        if (id) {
            if (id.indexOf('wrapper_') > -1) {
                this.id = id.slice(8);
            }
            else if (id.indexOf('ph_wrap_') > -1) {
                this.id = id.slice(8);
            }
            else if (id.indexOf('ph_') > -1) {
                this.id = id.slice(3);
            }
            else {
                this.id = id;
            }
        }
    };
    EntityBase.prototype.addChild = function (child) {
        if (this.childs === null) {
            this.childs = [];
        }
        child.index = this.childs.length;
        this.childs.push(child);
    };
    /**
     * Заполнить параметры сущности на основе данных
     * @param dictonary Словарь объектов(блока или секции), где id объекта ключ
     */
    EntityBase.prototype.fillFromData = function (dictonary) {
        var data = dictonary[this.id];
        if (data) {
            this.numerationDepth = data.numeration_depth;
            this.autonumeric = data.autonumeric;
            this.fillFromElement(data);
        }
        if (!this.childs || this.childs.length === 0)
            return;
        this.childs.forEach(function (element) {
            element.fillFromData(dictonary);
        });
    };
    /**
     * Посещении сущности
     * @param callbackVisit Делегат
     */
    EntityBase.prototype.visit = function (callbackVisit) {
        callbackVisit(this);
        if (!this.childs || this.childs.length === 0)
            return;
        this.childs.forEach(function (element) {
            element.visit(callbackVisit);
        });
    };
    return EntityBase;
}());
exports.EntityBase = EntityBase;
