"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadFileButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var icons_1 = require("@ant-design/icons");
var DropdownMenu = function (_a) {
    var action = _a.action;
    return ((0, jsx_runtime_1.jsxs)(antd_1.Menu, __assign({ onClick: function (e) { return action({ fileExtension: e.key }); } }, { children: [(0, jsx_runtime_1.jsx)(antd_1.Menu.Item, { children: "\u0432 \u0444\u043E\u0440\u043C\u0430\u0442\u0435 PDF" }, "pdf"), (0, jsx_runtime_1.jsx)(antd_1.Menu.Item, { children: "\u0432 \u0444\u043E\u0440\u043C\u0430\u0442\u0435 DOCX" }, "docx")] })));
};
var DownloadFileButton = function (_a) {
    var text = _a.text, disabled = _a.disabled, onClick = _a.onClick;
    var menu = (0, jsx_runtime_1.jsx)(DropdownMenu, { action: onClick });
    return ((0, jsx_runtime_1.jsx)(antd_1.Dropdown, __assign({ className: "document-button", overlayStyle: { zIndex: 9999 }, overlay: menu, disabled: disabled }, { children: (0, jsx_runtime_1.jsxs)(antd_1.Button, { children: [text, (0, jsx_runtime_1.jsx)(icons_1.DownOutlined, {})] }) })));
};
exports.DownloadFileButton = DownloadFileButton;
