"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-unused-expressions */
var constants_1 = require("../utils/constants");
var commonModels_1 = require("../../../../Root/utils/api/models/common/commonModels");
var constants_2 = require("./constants");
var initialState = {
    folders: [],
    filterOptions: __assign({}, constants_1.initialStateFilter),
    filtersValues: __assign({}, constants_1.initialFiltersValues),
    sortOptions: __assign({}, constants_1.initialSortOptions),
    selectedFolder: {
        content: [],
        breadcrumbs: [],
        typeKey: '',
        name: '',
        folderId: '',
        isArchive: false,
        perimeterId: '',
        categories: [],
        kind: commonModels_1.FolderKind.None,
    },
    expandedKeys: [],
    modals: {
        moveEntities: {
            opened: false,
            expandedKeys: [],
            selectedKey: '',
        },
        editFolder: {
            opened: false,
        },
        newTemplate: {
            opened: false,
        },
        newDocument: {
            opened: false,
        },
        copyTemplate: {
            opened: false,
        },
        infoTemplate: {
            opened: false,
        },
        aclFolder: {
            opened: false,
        },
    },
    bulkedEntities: [],
    folderCategories: [],
    singleEntityAction: undefined,
    showArchive: false,
    fetching: false,
    _error: false,
    _errorMessage: '',
    searchData: [],
    searchValue: '',
    searching: false,
    documents: {
        items: [],
        total: 0,
    },
    knownFolders: []
};
function explorer(state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case constants_2.ExplorerTypes.GET_DATA:
            return __assign(__assign({}, state), { fetching: true, _error: false });
        case constants_2.ExplorerTypes.GET_FOLDER_DATA_FAILED:
            return __assign(__assign({}, state), { fetching: false, _error: true, _errorMessage: action.errorMessage });
        case constants_2.ExplorerTypes.GET_DOCUMENTS_DATA_START:
            return __assign(__assign({}, state), { fetching: true, _error: false, _errorMessage: '' });
        case constants_2.ExplorerTypes.GET_DOCUMENTS_DATA_SUCCESS:
            return __assign(__assign({}, state), { fetching: false, documents: action.payload });
        case constants_2.ExplorerTypes.GET_DOCUMENTS_DATA_FAILED:
            return __assign(__assign({}, state), { fetching: false, _error: true, _errorMessage: action.payload });
        case constants_2.ExplorerTypes.SEARCH_START:
            return __assign(__assign({}, state), { fetching: true });
        case constants_2.ExplorerTypes.SEARCH_SUCCESS:
            return __assign(__assign({}, state), { searchData: action.payload.data, searchValue: action.payload.searchValue, fetching: false, _error: false, _errorMessage: '' });
        case constants_2.ExplorerTypes.SEARCH_FAILED:
            return __assign(__assign({}, state), { fetching: false, _error: true, _errorMessage: action.payload });
        case constants_2.ExplorerTypes.SET_SORT_OPTIONS:
            return __assign(__assign({}, state), { sortOptions: __assign(__assign({}, state.sortOptions), action.payload) });
        case constants_2.ExplorerTypes.SET_FILTER_OPTIONS:
            return __assign(__assign({}, state), { filterOptions: __assign(__assign({}, state.filterOptions), action.payload) });
        case constants_2.ExplorerTypes.SET_FILTERS_VALUES:
            return __assign(__assign({}, state), { filtersValues: __assign(__assign({}, state.filtersValues), action.payload) });
        case constants_2.ExplorerTypes.GET_FOLDER_LIST_SUCCESS:
            return __assign(__assign({}, state), { folders: action.payload, fetching: false, _error: false, _errorMessage: '' });
        case constants_2.ExplorerTypes.GET_FOLDER_LIST_FAILED:
            return __assign(__assign({}, state), { fetching: false, _error: true, _errorMessage: action.errorMessage });
        case constants_2.ExplorerTypes.GET_KNOWN_FOLDERS_SUCCESS:
            return __assign(__assign({}, state), { knownFolders: action.payload, fetching: false, _error: false, _errorMessage: '' });
        case constants_2.ExplorerTypes.GET_KNOWN_FOLDERS_FAILED:
            return __assign(__assign({}, state), { fetching: false, _error: true, _errorMessage: action.payload });
        case constants_2.ExplorerTypes.SELECT_FOLDER_SUCCESS:
            return __assign(__assign({}, state), { selectedFolder: action.payload, fetching: false, _error: false, _errorMessage: '' });
        case constants_2.ExplorerTypes.SET_EXPANDED_KEYS:
            return __assign(__assign({}, state), { expandedKeys: action.payload });
        case constants_2.ExplorerTypes.SET_MOVE_MODAL_EXPANDED_KEYS:
            return __assign(__assign({}, state), { modals: __assign(__assign({}, state.modals), { moveEntities: __assign(__assign({}, state.modals.moveEntities), { expandedKeys: action.payload }) }) });
        case constants_2.ExplorerTypes.SET_MOVE_MODAL_SELECTED_KEY:
            return __assign(__assign({}, state), { modals: __assign(__assign({}, state.modals), { moveEntities: __assign(__assign({}, state.modals.moveEntities), { selectedKey: action.payload }) }) });
        case constants_2.ExplorerTypes.TOGGLE_MODAL:
            return __assign(__assign({}, state), { modals: __assign(__assign({}, state.modals), (_a = {}, _a[action.modal] = __assign(__assign({}, state.modals[action.modal]), { opened: !state.modals[action.modal].opened }), _a)) });
        case constants_2.ExplorerTypes.BULK_ENTITY:
            return __assign(__assign({}, state), { bulkedEntities: action.payload });
        case constants_2.ExplorerTypes.CLEAR_BULKED_ENTITIES:
            return __assign(__assign({}, state), { bulkedEntities: [] });
        case constants_2.ExplorerTypes.FILTER_BULKED_ENTITIES:
            return __assign(__assign({}, state), { bulkedEntities: action.payload });
        case constants_2.ExplorerTypes.SET_SINGLE_ENTITY_ACTION:
            return __assign(__assign({}, state), { singleEntityAction: action.payload });
        case constants_2.ExplorerTypes.SET_FOLDER_CATEGORIES:
            return __assign(__assign({}, state), { folderCategories: action.payload });
        case constants_2.ExplorerTypes.TOGGLE_SHOW_ARCHIVE:
            return __assign(__assign({}, state), { showArchive: action.payload });
        case constants_2.ExplorerTypes.SET_FETCHING: {
            return __assign(__assign({}, state), { fetching: action.value });
        }
        case constants_2.ExplorerTypes.SEARCHING_STATUS: {
            return __assign(__assign({}, state), { searching: action.payload });
        }
        default:
            return state;
    }
}
exports.default = explorer;
