"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntitySectionGroup = void 0;
var EntityBase_1 = require("./EntityBase");
var EntityType_1 = require("./EntityType");
/**
 * Группа для размещения в секции
 */
var EntitySectionGroup = /** @class */ (function (_super) {
    __extends(EntitySectionGroup, _super);
    //#region Конструкторы
    function EntitySectionGroup(id, parent) {
        if (parent === void 0) { parent = null; }
        var _this = _super.call(this, id, parent) || this;
        /**
         * Тип
         */
        _this.type = EntityType_1.TEntityType.SectionGroup;
        // Группа НЕ может участвовать в нумерации
        _this.isNumericSupport = false;
        return _this;
    }
    //#endregion
    /**
     * Создание дочерних сущностей на основание DOM
     * id должен быть корректным
     * @param isDoc Режим документа (предпросмотра)
     */
    EntitySectionGroup.prototype.createFromDOM = function (isDoc) {
        // Нет реализации потому то невозможно получить id группы
    };
    /**
     * Заполнить параметры сущности на основе объекта (блока или секции)
     * @param element Блок и секция
     */
    EntitySectionGroup.prototype.fillFromElement = function (element) {
    };
    /**
     * Вычисления(заполнения) дерева нумерации элементов
     * @param tree Дерево нумерации элементов
     * @param currentNumeration Структура для нумерации
     */
    EntitySectionGroup.prototype.calcTreeNumeration = function (tree, numeration) {
        if (!this.childs || this.childs.length === 0)
            return;
        this.childs.forEach(function (element) {
            element.calcTreeNumeration(tree, numeration);
        });
    };
    return EntitySectionGroup;
}(EntityBase_1.EntityBase));
exports.EntitySectionGroup = EntitySectionGroup;
