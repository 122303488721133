"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAttachmentName = void 0;
var attachmentsPlaceholdersUtils_1 = require("../../../utils/attachmentsPlaceholdersUtils");
function getAttachmentName(nameStr) {
    var attachNameArray = [attachmentsPlaceholdersUtils_1.ATTACH_LABEL, attachmentsPlaceholdersUtils_1.NUM_DELIMETER, '', attachmentsPlaceholdersUtils_1.NAME_DELIMETER, ''];
    var prefixRegExp = "".concat(attachNameArray[0], " ").concat(attachNameArray[1], " ").concat(attachNameArray[0], " ").concat(attachNameArray[0], " "); // "Приложение №  -"
    try {
        if (!nameStr || typeof nameStr !== 'string')
            return attachNameArray;
        var prefixPattern = "^".concat(attachmentsPlaceholdersUtils_1.ATTACH_LABEL, " ").concat(attachmentsPlaceholdersUtils_1.NUM_DELIMETER, " "); // "^Приложение № "
        var prefixNum = new RegExp("".concat(prefixPattern, "\\d*")).exec(nameStr) || [nameStr]; // "^Приложение № \d"
        var attachmentNum = prefixNum[0].replace(new RegExp("".concat(prefixPattern)), '');
        var attachmentName = nameStr.replace(new RegExp("".concat(prefixPattern, "\\d*\\s*-\\s*")), ''); // "^Приложение № \d* - "
        attachNameArray[2] = attachmentNum;
        attachNameArray[4] = attachmentName;
        return attachNameArray;
    }
    catch (err) {
        console.error(err);
        return attachNameArray;
    }
}
exports.getAttachmentName = getAttachmentName;
