"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeDuplicates = void 0;
function removeDuplicates(myArr, prop) {
    var groupedItems = myArr.reduce(function (groups, element) {
        if (element[prop]) {
            if (groups[element[prop]]) {
                groups[element[prop]].push(element);
            }
            else {
                // eslint-disable-next-line
                groups[element[prop]] = new Array(element);
            }
        }
        else {
            // eslint-disable-next-line
            groups[element.id] = new Array(element);
        }
        return groups;
    }, {});
    return Object.keys(groupedItems).map(function (key) {
        var sorted = groupedItems[key].sort(function (a, b) { return a.order.split('_')[1] - b.order.split('_')[1]; });
        return sorted[0];
    });
}
exports.removeDuplicates = removeDuplicates;
