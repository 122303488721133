"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSearchingStatus = exports.getFiltersValues = exports.getSortOptions = exports.getFilterOptions = exports.getFolderCategories = exports.getShowArchive = exports.getSingleEntityAction = exports.getMoveModalSelectedKey = exports.getMoveModalExpandedKeys = exports.getBulkedEntities = exports.getModals = exports.getExpandedKeys = exports.getSelectedFolder = exports.getKnownFolders = exports.getSearchData = exports.getFolders = exports.getSearchValue = exports.getDocuments = exports.getFetching = void 0;
/* eslint-disable indent */
var reselect_1 = require("reselect");
var explorerSelector = function (state) {
    var explorer = state.explorer;
    return explorer;
};
exports.getFetching = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var fetching = _a.fetching;
    return fetching;
});
exports.getDocuments = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var documents = _a.documents;
    return documents;
});
exports.getSearchValue = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var searchValue = _a.searchValue;
    return searchValue;
});
exports.getFolders = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var folders = _a.folders;
    return folders;
});
exports.getSearchData = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var searchData = _a.searchData;
    return searchData;
});
exports.getKnownFolders = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var knownFolders = _a.knownFolders;
    return knownFolders;
});
exports.getSelectedFolder = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var selectedFolder = _a.selectedFolder;
    return selectedFolder;
});
exports.getExpandedKeys = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var expandedKeys = _a.expandedKeys;
    return expandedKeys;
});
exports.getModals = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var modals = _a.modals;
    return modals;
});
exports.getBulkedEntities = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var bulkedEntities = _a.bulkedEntities;
    return bulkedEntities;
});
exports.getMoveModalExpandedKeys = (0, reselect_1.createSelector)(exports.getModals, function (_a) {
    var moveEntities = _a.moveEntities;
    return moveEntities.expandedKeys;
});
exports.getMoveModalSelectedKey = (0, reselect_1.createSelector)(exports.getModals, function (_a) {
    var moveEntities = _a.moveEntities;
    return moveEntities.selectedKey;
});
exports.getSingleEntityAction = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var singleEntityAction = _a.singleEntityAction;
    return singleEntityAction;
});
exports.getShowArchive = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var showArchive = _a.showArchive;
    return showArchive;
});
exports.getFolderCategories = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var folderCategories = _a.folderCategories;
    return folderCategories;
});
exports.getFilterOptions = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var filterOptions = _a.filterOptions;
    return filterOptions;
});
exports.getSortOptions = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var sortOptions = _a.sortOptions;
    return sortOptions;
});
exports.getFiltersValues = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var filtersValues = _a.filtersValues;
    return filtersValues;
});
exports.getSearchingStatus = (0, reselect_1.createSelector)(explorerSelector, function (_a) {
    var searching = _a.searching;
    return searching;
});
