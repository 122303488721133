"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFormulaResult = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var CustomFormulaResultView_1 = require("./CustomFormulaResultView");
var CustomFormulaResultUtils_1 = require("./CustomFormulaResultUtils");
var react_redux_1 = require("react-redux");
var rootGetters_1 = require("../../../../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../../../../Root/redux/rootActionCreators/rootActionCreators");
var CustomFormulaResult = function (_a) {
    var currentFormula = _a.currentFormula, validationStatus = _a.validationStatus, isEditing = _a.isEditing;
    var dispatch = (0, react_redux_1.useDispatch)();
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var currentCustomFormulaCaret = presentState === null || presentState === void 0 ? void 0 : presentState.customFormulaCaret;
    var formulaText = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.formula;
    var formulaParameters = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.parameters;
    var splittedItems = (0, CustomFormulaResultUtils_1.formulaItemsSplitting)(formulaText);
    var inputRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        return function () {
            // Your code you want to run on unmount.
            dispatch((0, rootActionCreators_1.setCustomFormulaCaret)(null));
        };
    }, []);
    var onBlur = function () {
        var _a, _b;
        // @ts-ignore
        var newCaret = (_b = (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.resizableTextArea) === null || _b === void 0 ? void 0 : _b.textArea.selectionEnd;
        var currentSymbol = formulaText[newCaret];
        // операнды считываем как за единое целое
        if (currentSymbol >= '0' && currentSymbol <= '9') {
            newCaret = newCaret + 1;
        }
    };
    (0, react_1.useEffect)(function () {
        var _a;
        var currentSymbol = formulaText[currentCustomFormulaCaret];
        var newCaret = currentCustomFormulaCaret;
        // операнды считываем как за единое целое
        if (currentSymbol >= '0' && currentSymbol <= '9') {
            newCaret = newCaret + 1;
        }
        // @ts-ignore
        inputRef.current.resizableTextArea.textArea.selectionEnd = newCaret;
        // @ts-ignore
        inputRef.current.resizableTextArea.textArea.selectionStart = newCaret;
        // @ts-ignore
        (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [inputRef, formulaText, currentCustomFormulaCaret]);
    var onFocus = function () {
        var _a, _b, _c;
        // @ts-ignore
        var newCaret = (_b = (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.resizableTextArea) === null || _b === void 0 ? void 0 : _b.textArea.selectionEnd;
        var currentSymbol = formulaText[newCaret];
        // // операнды считываем как за единое целое
        if (currentSymbol >= '0' && currentSymbol <= '9') {
            newCaret = newCaret + 1;
        }
        dispatch((0, rootActionCreators_1.setCustomFormulaCaret)(newCaret));
        // @ts-ignore
        inputRef.current.resizableTextArea.textArea.selectionEnd = newCaret;
        // @ts-ignore
        inputRef.current.resizableTextArea.textArea.selectionStart = newCaret;
        // @ts-ignore
        (_c = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _c === void 0 ? void 0 : _c.focus();
    };
    return ((0, jsx_runtime_1.jsx)(CustomFormulaResultView_1.CustomFormulaResultView, { formulaText: formulaText, formulaParameters: formulaParameters, splittedItems: splittedItems, inputRef: inputRef, onBlur: onBlur, onFocus: onFocus, validationStatus: validationStatus, isEditing: isEditing }));
};
exports.CustomFormulaResult = CustomFormulaResult;
