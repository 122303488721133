"use strict";
// https://stackoverflow.com/a/3710226
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkJsonString = void 0;
function checkJsonString(str) {
    try {
        JSON.parse(str);
    }
    catch (e) {
        return false;
    }
    return true;
}
exports.checkJsonString = checkJsonString;
