"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibrarySearch = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var PhListItem_1 = require("./PhListItem");
var PlaceholderLibrarySearch = function (_a) {
    var structure = _a.structure, addLibraryPlaceholder = _a.addLibraryPlaceholder;
    // eslint-disable-next-line no-prototype-builtins
    var phTemplates = structure.filter(function (item) { return item.hasOwnProperty('type'); });
    // eslint-disable-next-line no-prototype-builtins
    var phFolders = structure.filter(function (item) { return item.hasOwnProperty('kind'); });
    return ((0, jsx_runtime_1.jsx)(antd_1.List, { itemLayout: "horizontal", className: 'ph-items-wrapper', dataSource: phTemplates, renderItem: function (item) { return ((0, jsx_runtime_1.jsx)(PhListItem_1.PhListItem, { item: item, folders: phFolders, addLibraryPlaceholder: addLibraryPlaceholder })); } }));
};
exports.PlaceholderLibrarySearch = PlaceholderLibrarySearch;
