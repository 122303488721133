"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.bulkEntity = exports.createOrEditFolder = exports.getKnownFoldersList = exports.getFolderList = exports.getDocumentsList = exports.selectFolder = exports.searchAllTemplates = exports.setSingleEntityAction = exports.loadFolderCategories = exports.setSearchFailed = exports.setSearchStart = exports.setSearchData = exports.setSingleEntityActionAC = exports.setFiltersValuesAC = exports.setFilterOptionsAC = exports.setSortOptionsAC = exports.setFetching = exports.toggleShowArchive = exports.filterBulkedEntities = exports.clearBulkedEntities = exports.bulkEntityAC = exports.toggleModal = exports.setMoveModalSelectedKey = exports.setMoveModalExpandedKeys = exports.setExpandedKeys = exports.selectFolderSuccessAC = exports.setFolderCategories = exports.getKnownFoldersFailed = exports.getKnownFoldersSuccess = exports.getFolderListFailedAC = exports.getFolderListSuccessAC = exports.getFolderDataFailedAC = exports.GetDocumentsDataFailed = exports.GetDocumentsDataSuccess = exports.GetDocumentsDataStart = exports.getDataAC = void 0;
var constants_1 = require("./constants");
var commonModels_1 = require("../../../../Root/utils/api/models/common/commonModels");
var constants_2 = require("../utils/constants");
var http_1 = require("../utils/http");
var selectors_1 = require("./selectors");
var api_v1_Folder_1 = require("../../../../Root/utils/api/api.v1.Folder");
var breadcrumbs_1 = require("../utils/breadcrumbs");
var sort_1 = require("../utils/sort");
var dataForFilterValue_1 = require("../utils/dataForFilterValue");
var ExploreHelper_1 = require("../ExploreHelper");
var getDataAC = function () { return ({ type: constants_1.ExplorerTypes.GET_DATA }); };
exports.getDataAC = getDataAC;
var GetDocumentsDataStart = function () { return ({ type: constants_1.ExplorerTypes.GET_DOCUMENTS_DATA_START }); };
exports.GetDocumentsDataStart = GetDocumentsDataStart;
var GetDocumentsDataSuccess = function (payload) { return ({
    type: constants_1.ExplorerTypes.GET_DOCUMENTS_DATA_SUCCESS,
    payload: payload,
}); };
exports.GetDocumentsDataSuccess = GetDocumentsDataSuccess;
var GetDocumentsDataFailed = function (payload) { return ({
    type: constants_1.ExplorerTypes.GET_DOCUMENTS_DATA_FAILED,
    payload: payload,
}); };
exports.GetDocumentsDataFailed = GetDocumentsDataFailed;
var getFolderDataFailedAC = function (errorMessage) { return ({
    type: constants_1.ExplorerTypes.GET_FOLDER_DATA_FAILED,
    errorMessage: errorMessage,
}); };
exports.getFolderDataFailedAC = getFolderDataFailedAC;
var getFolderListSuccessAC = function (payload) { return ({
    type: constants_1.ExplorerTypes.GET_FOLDER_LIST_SUCCESS,
    payload: payload,
}); };
exports.getFolderListSuccessAC = getFolderListSuccessAC;
var getFolderListFailedAC = function (errorMessage) { return ({
    type: constants_1.ExplorerTypes.GET_FOLDER_LIST_FAILED,
    errorMessage: errorMessage,
}); };
exports.getFolderListFailedAC = getFolderListFailedAC;
var getKnownFoldersSuccess = function (payload) { return ({
    type: constants_1.ExplorerTypes.GET_KNOWN_FOLDERS_SUCCESS,
    payload: payload,
}); };
exports.getKnownFoldersSuccess = getKnownFoldersSuccess;
var getKnownFoldersFailed = function (payload) { return ({
    type: constants_1.ExplorerTypes.GET_KNOWN_FOLDERS_FAILED,
    payload: payload,
}); };
exports.getKnownFoldersFailed = getKnownFoldersFailed;
var setFolderCategories = function (payload) { return ({
    type: constants_1.ExplorerTypes.SET_FOLDER_CATEGORIES,
    payload: payload,
}); };
exports.setFolderCategories = setFolderCategories;
var selectFolderSuccessAC = function (payload) { return ({
    type: constants_1.ExplorerTypes.SELECT_FOLDER_SUCCESS,
    payload: payload,
}); };
exports.selectFolderSuccessAC = selectFolderSuccessAC;
var setExpandedKeys = function (payload) { return ({
    type: constants_1.ExplorerTypes.SET_EXPANDED_KEYS,
    payload: payload,
}); };
exports.setExpandedKeys = setExpandedKeys;
var setMoveModalExpandedKeys = function (payload) { return ({
    type: constants_1.ExplorerTypes.SET_MOVE_MODAL_EXPANDED_KEYS,
    payload: payload,
}); };
exports.setMoveModalExpandedKeys = setMoveModalExpandedKeys;
var setMoveModalSelectedKey = function (payload) { return ({
    type: constants_1.ExplorerTypes.SET_MOVE_MODAL_SELECTED_KEY,
    payload: payload,
}); };
exports.setMoveModalSelectedKey = setMoveModalSelectedKey;
var toggleModal = function (modal) { return ({ type: constants_1.ExplorerTypes.TOGGLE_MODAL, modal: modal }); };
exports.toggleModal = toggleModal;
var bulkEntityAC = function (payload) { return ({
    type: constants_1.ExplorerTypes.BULK_ENTITY,
    payload: payload,
}); };
exports.bulkEntityAC = bulkEntityAC;
var clearBulkedEntities = function () { return ({ type: constants_1.ExplorerTypes.CLEAR_BULKED_ENTITIES }); };
exports.clearBulkedEntities = clearBulkedEntities;
var filterBulkedEntities = function (payload) { return ({
    type: constants_1.ExplorerTypes.FILTER_BULKED_ENTITIES,
    payload: payload,
}); };
exports.filterBulkedEntities = filterBulkedEntities;
var toggleShowArchive = function (payload) { return ({
    type: constants_1.ExplorerTypes.TOGGLE_SHOW_ARCHIVE,
    payload: payload,
}); };
exports.toggleShowArchive = toggleShowArchive;
var setFetching = function (value) { return ({ type: constants_1.ExplorerTypes.SET_FETCHING, value: value }); };
exports.setFetching = setFetching;
// TODO: [Mouseee] Типизировать после приведения в порядок функционала фильтров на главной
var setSortOptionsAC = function (payload) { return ({
    type: constants_1.ExplorerTypes.SET_SORT_OPTIONS,
    payload: payload,
}); };
exports.setSortOptionsAC = setSortOptionsAC;
var setFilterOptionsAC = function (payload) { return ({
    type: constants_1.ExplorerTypes.SET_FILTER_OPTIONS,
    payload: payload,
}); };
exports.setFilterOptionsAC = setFilterOptionsAC;
var setFiltersValuesAC = function (payload) { return ({
    type: constants_1.ExplorerTypes.SET_FILTERS_VALUES,
    payload: payload,
}); };
exports.setFiltersValuesAC = setFiltersValuesAC;
var setSingleEntityActionAC = function (payload) { return ({
    type: constants_1.ExplorerTypes.SET_SINGLE_ENTITY_ACTION,
    payload: payload,
}); };
exports.setSingleEntityActionAC = setSingleEntityActionAC;
var setSearchData = function (payload) { return ({
    type: constants_1.ExplorerTypes.SEARCH_SUCCESS,
    payload: payload,
}); };
exports.setSearchData = setSearchData;
var setSearchStart = function () { return ({ type: constants_1.ExplorerTypes.SEARCH_START }); };
exports.setSearchStart = setSearchStart;
var setSearchFailed = function (payload) { return ({ type: constants_1.ExplorerTypes.SEARCH_FAILED, payload: payload }); };
exports.setSearchFailed = setSearchFailed;
var loadFolderCategories = function (perimeterId, callback) {
    return function (dispatch) {
        dispatch((0, exports.getDataAC)());
        (0, http_1.fetchFolderCategories)(perimeterId)
            .then(function (_a) {
            var payload = _a.payload;
            dispatch((0, exports.setFolderCategories)(payload));
            callback(payload);
        })
            .catch(function (error) {
            console.error('error in loadFolderCategories: ', error);
            dispatch((0, exports.getFolderDataFailedAC)(error.errorMessage));
        });
    };
};
exports.loadFolderCategories = loadFolderCategories;
var setSingleEntityAction = function (entity) {
    return function (dispatch) {
        dispatch((0, exports.setSingleEntityActionAC)(entity));
    };
};
exports.setSingleEntityAction = setSingleEntityAction;
var searchAllTemplates = function (value, token) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var state, selectedFolder, folderId, payload, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (ExploreHelper_1.ExploreHelper.canSearch(value) === false) {
                    dispatch({ type: constants_1.ExplorerTypes.SEARCH_FAILED, payload: '' });
                    dispatch({ type: constants_1.ExplorerTypes.SEARCHING_STATUS, payload: false });
                    return [2 /*return*/];
                }
                state = getState();
                selectedFolder = (0, selectors_1.getSelectedFolder)(state);
                folderId = selectedFolder.folderId;
                dispatch((0, exports.setSearchStart)());
                dispatch({ type: constants_1.ExplorerTypes.SEARCHING_STATUS, payload: true });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, (0, api_v1_Folder_1.v1FolderContentSearch)({ folderId: folderId, pattern: value }, token)];
            case 2:
                payload = _a.sent();
                dispatch((0, exports.setSearchData)({ searchValue: value, data: payload }));
                dispatch({ type: constants_1.ExplorerTypes.SEARCHING_STATUS, payload: false });
                return [3 /*break*/, 4];
            case 3:
                error_1 = _a.sent();
                // @ts-ignore
                dispatch((0, exports.setSearchFailed)(error_1.errorMessage));
                dispatch({ type: constants_1.ExplorerTypes.SEARCHING_STATUS, payload: false });
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
exports.searchAllTemplates = searchAllTemplates;
var selectFolder = function (folderId) {
    return function (dispatch, getState) {
        var state = getState();
        dispatch((0, exports.getDataAC)());
        folderId &&
            (0, api_v1_Folder_1.v1FolderContent)({ folderId: folderId })
                .then(function (payload) {
                var folderContent = payload || {};
                var childrenFolders = folderContent.childrenFolders, status = folderContent.status, kind = folderContent.kind, templates = folderContent.templates;
                // Общий контент папки и шаблоны
                var folderContents = [];
                if (childrenFolders) {
                    folderContents = folderContents.concat(childrenFolders.map(function (folder) {
                        var folderItem = {
                            id: folder.id,
                            name: folder.name,
                            status: folder.status,
                            prevStatus: '',
                            currentUserControls: folder.currentUserControls,
                            categories: folder.categories && folder.categories.length > 0 ? folder.categories : [{ id: '', name: '' }],
                            authorName: folder.authorName,
                            version: '',
                            description: '',
                            latestChangeDate: folder.latestChangeDate,
                            typeKey: folder.typeKey,
                            kind: folder.kind,
                        };
                        return folderItem;
                    }));
                }
                if (templates) {
                    folderContents = folderContents.concat(templates.map(function (entity) {
                        var _a;
                        var folderItem = {
                            id: entity.id,
                            name: entity.name,
                            status: entity.status,
                            prevStatus: entity.status,
                            currentUserControls: entity.currentUserControls,
                            categories: entity.categories && entity.categories.length > 0 ? entity.categories : [{ id: '', name: '' }],
                            authorName: entity.authorName,
                            version: entity.version,
                            description: (_a = entity.description) !== null && _a !== void 0 ? _a : '',
                            latestChangeDate: entity.latestChangeDate,
                            typeKey: entity.typeKey,
                            kind: commonModels_1.FolderKind.None,
                        };
                        return folderItem;
                    }));
                }
                var folders = (0, selectors_1.getFolders)(state);
                var breadcrumbs = (0, breadcrumbs_1.makeBreadcrumbs)(folderId, folders, []);
                var folder = {
                    name: folderContent.name,
                    // function sortFolderContent(content, nameOrder, typeOrder)
                    content: (0, sort_1.sortFolderContent)(folderContents, 'asc', 'asc'),
                    folderId: folderId,
                    breadcrumbs: breadcrumbs,
                    isArchive: status === 'Архив',
                    typeKey: folderContent.typeKey,
                    typeId: folderContent.typeId,
                    perimeterId: folderContent.perimeterId,
                    categories: folderContent.categories,
                    kind: kind,
                };
                dispatch((0, exports.setFilterOptionsAC)((0, dataForFilterValue_1.dataForSelect)(folder.content)));
                // reset bulk when we go to folder with different entityType
                var selectedFolder = (0, selectors_1.getSelectedFolder)(state);
                if (folder.typeKey !== selectedFolder.typeKey) {
                    dispatch((0, exports.clearBulkedEntities)());
                }
                if (folder.isArchive) {
                    dispatch((0, exports.toggleShowArchive)(true));
                }
                var expandedKeys = (0, selectors_1.getExpandedKeys)(state);
                dispatch((0, exports.selectFolderSuccessAC)(folder));
                dispatch((0, exports.setExpandedKeys)(__spreadArray(__spreadArray([], expandedKeys, true), breadcrumbs.map(function (item) { return item.id; }), true)));
                dispatch((0, exports.setSingleEntityAction)(undefined));
                dispatch((0, exports.setFilterOptionsAC)(constants_2.initialStateFilter));
                dispatch((0, exports.setFiltersValuesAC)(constants_2.initialFiltersValues));
                dispatch((0, exports.setSortOptionsAC)(constants_2.initialSortOptions));
            })
                .catch(function (error) {
                console.error(error);
                dispatch((0, exports.getFolderDataFailedAC)(error.errorMessage));
            });
    };
};
exports.selectFolder = selectFolder;
// TODO: [Mouseee] Фильтры типизировать позже после их рефакторинга
var getDocumentsList = function (folderId, page, pageSize, filtersValues) {
    return function (dispatch) {
        dispatch((0, exports.GetDocumentsDataStart)());
        (0, http_1.fetchDocumentsData)(folderId, page, pageSize, filtersValues)
            .then(function (_a) {
            var payload = _a.payload;
            var total = payload.total, documents = payload.documents;
            dispatch((0, exports.GetDocumentsDataSuccess)({
                items: documents,
                total: total,
            }));
            if (!filtersValues)
                dispatch((0, exports.setFilterOptionsAC)((0, dataForFilterValue_1.dataForSelect)(documents)));
        })
            .catch(function (error) { return dispatch((0, exports.GetDocumentsDataFailed)(error)); });
    };
};
exports.getDocumentsList = getDocumentsList;
// здесь применить изменения
var getFolderList = function (openedFolderId, shouldSelectFolder, perimeterId) {
    if (shouldSelectFolder === void 0) { shouldSelectFolder = true; }
    return function (dispatch, getState) {
        dispatch((0, exports.getDataAC)());
        (0, api_v1_Folder_1.v1FolderList)({ perimeterId: perimeterId })
            .then(function (_a) {
            var folders = _a.folders;
            // move sort to utils
            var sortedPayload = folders.sort(function (a, b) {
                if (a.parentFolderId && b.parentFolderId) {
                    if (a.parentFolderId > b.parentFolderId) {
                        return 1;
                    }
                    else if (a.parentFolderId < b.parentFolderId) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            });
            dispatch((0, exports.getFolderListSuccessAC)(sortedPayload));
            if (!shouldSelectFolder)
                return;
            if (sortedPayload.some(function (folder) { return folder.id === openedFolderId; })) {
                dispatch((0, exports.selectFolder)(openedFolderId));
            }
            // TODO: [Mouseee] Избыточный функционал
            // else {
            //
            //   const state = getState();
            //   console.log(`!!state`, state);
            //
            //   const knownFolders = getKnownFolders(state);
            //   console.log(`!!knownFolders`, knownFolders);
            //
            //   const firstPerimeter = Object.keys(knownFolders)[0];
            //   console.log(`!!firstPerimeter`, firstPerimeter);
            //
            //   const { root } = knownFolders[firstPerimeter].root;
            //   console.log(`!!root`, root);
            //
            //   dispatch(selectFolder(root));
            //
            // }
        })
            .catch(function (error) {
            dispatch((0, exports.getFolderListFailedAC)(error.errorMessage));
        });
    };
};
exports.getFolderList = getFolderList;
// menu category
var getKnownFoldersList = function () { return function (dispatch) {
    (0, api_v1_Folder_1.v1FolderMenu)()
        .then(function (data) {
        // const knownFoldersByPerimeter = data.folders.reduce((obj, item) => ({ ...obj, [item.perimeterId]: item }), {});
        var knownFoldersByPerimeter = data.folders;
        dispatch((0, exports.getKnownFoldersSuccess)(knownFoldersByPerimeter));
    })
        .catch(function (e) {
        dispatch((0, exports.getKnownFoldersFailed)(e));
    });
}; };
exports.getKnownFoldersList = getKnownFoldersList;
var createOrEditFolder = function (payload) {
    return function (dispatch) {
        dispatch((0, exports.getDataAC)());
        if (payload.action === 'create') {
            (0, api_v1_Folder_1.v1FolderCreate)({
                categories: payload.categories,
                name: payload.name,
                parentId: payload.parentId,
            })
                .then(function () {
                dispatch((0, exports.getFolderList)(payload.parentId));
            })
                .catch(function (error) { return dispatch((0, exports.getFolderListFailedAC)(error.errorMessage)); });
        }
        if (payload.action === 'edit') {
            (0, api_v1_Folder_1.v1FolderEdit)({
                categories: payload.categories,
                id: payload.id,
                name: payload.name,
            })
                .then(function () {
                dispatch((0, exports.getFolderList)(payload.parentId));
                payload.onAfterEditFolder();
            })
                .catch(function (error) { return dispatch((0, exports.getFolderListFailedAC)(error.errorMessage)); });
        }
    };
};
exports.createOrEditFolder = createOrEditFolder;
var bulkEntity = function (entity) {
    return function (dispatch, getState) {
        var state = getState();
        var alreadyBulked = (0, selectors_1.getBulkedEntities)(state);
        if (entity.checked) {
            if (alreadyBulked.some(function (item) { return item.id === entity.id; })) {
                return;
            }
            else {
                dispatch((0, exports.bulkEntityAC)(alreadyBulked.concat([entity])));
            }
        }
        else {
            dispatch((0, exports.bulkEntityAC)(alreadyBulked.filter(function (bulked) { return bulked.id !== entity.id; })));
        }
    };
};
exports.bulkEntity = bulkEntity;
