"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FolderKind = exports.IntegrationSystems = void 0;
var IntegrationSystems;
(function (IntegrationSystems) {
    IntegrationSystems[IntegrationSystems["Udms"] = 0] = "Udms";
    IntegrationSystems[IntegrationSystems["All"] = 1] = "All";
})(IntegrationSystems = exports.IntegrationSystems || (exports.IntegrationSystems = {}));
var FolderKind;
(function (FolderKind) {
    FolderKind["None"] = "none";
    FolderKind["Perimeter"] = "perimeter";
    FolderKind["Partition"] = "partition";
    FolderKind["Subpart"] = "subpart";
    FolderKind["Folder"] = "folder";
})(FolderKind = exports.FolderKind || (exports.FolderKind = {}));
