"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterOptions = void 0;
var filterOptions = function (input, option) {
    if (!!option && option.props.children) {
        if (typeof option.props.children === 'string') {
            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
        else {
            var element = option.props.children;
            if (element && element.props && element.props.children && typeof element.props.children === 'string') {
                var text = element.props.children;
                return text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
        }
    }
    return false;
};
exports.filterOptions = filterOptions;
