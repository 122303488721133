"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootReduxActionTypes = void 0;
var RootReduxActionTypes;
(function (RootReduxActionTypes) {
    RootReduxActionTypes["GET_DATA_REQUEST"] = "@root/GET_DATA_REQUEST";
    RootReduxActionTypes["GET_DATA_SUCCESS"] = "@$root/GET_DATA_SUCCESS";
    RootReduxActionTypes["GET_DATA_FAILURE"] = "@root/GET_DATA_FAILURE";
    RootReduxActionTypes["SET_PAGE"] = "@root/SET_PAGE";
    RootReduxActionTypes["SET_PAGE_SIZE"] = "@root/SET_PAGE_SIZE";
    RootReduxActionTypes["SET_FILTERS"] = "@root/SET_FILTERS";
    RootReduxActionTypes["SET_DRAFT_TEMPLATE_ID"] = "@root/SET_DRAFT_TEMPLATE_ID";
    RootReduxActionTypes["SET_DRAFT_TEMPLATE_NAME"] = "@root/SET_DRAFT_TEMPLATE_NAME";
    RootReduxActionTypes["SET_DRAFT_TEMPLATE_VERSION"] = "@root/SET_DRAFT_TEMPLATE_VERSION";
    RootReduxActionTypes["SET_PLACEHOLDER_TYPES"] = "@root/SET_PLACEHOLDER_TYPES";
    RootReduxActionTypes["SET_FORMULA_TYPES"] = "@root/SET_FORMULA_TYPES";
    RootReduxActionTypes["SET_LIBRARY_TEMPLATES_LIST"] = "@root/SET_LIBRARY_TEMPLATES_LIST";
    RootReduxActionTypes["ADD_LIBRARY_TEMPLATE"] = "@root/ADD_LIBRARY_TEMPLATE";
    RootReduxActionTypes["SET_TEMPLATE_INFO"] = "@root/SET_TEMPLATE_INFO";
    RootReduxActionTypes["SET_TEMPLATE_STATE"] = "@root/SET_TEMPLATE_STATE";
    RootReduxActionTypes["SET_OBJECT_BLOCKS"] = "@root/SET_OBJECT_BLOCKS";
    RootReduxActionTypes["SET_OBJECT_TEMPLATED_LIST_BLOCKS"] = "@root/SET_OBJECT_TEMPLATED_LIST_BLOCKS";
    RootReduxActionTypes["SET_OBJECT_PLACEHOLDERS"] = "@root/SET_OBJECT_PLACEHOLDERS";
    RootReduxActionTypes["SET_OBJECT_AGGREGATIONS"] = "@root/SET_OBJECT_AGGREGATIONS";
    RootReduxActionTypes["SET_OBJECT_SECTIONS"] = "@root/SET_OBJECT_SECTIONS";
    RootReduxActionTypes["SET_SYSTEMS_CATALOGUE"] = "@root/SET_SYSTEMS_CATALOGUE";
    RootReduxActionTypes["SET_DOCUMENT_KINDS"] = "@root/SET_DOCUMENT_KINDS";
    RootReduxActionTypes["SET_LOADING"] = "@root/SET_LOADING";
    RootReduxActionTypes["SET_DELETED_BLOCKS"] = "@root/SET_DELETED_BLOCKS";
    RootReduxActionTypes["SET_DELETED_SECTIONS"] = "@root/SET_DELETED_SECTIONS";
    RootReduxActionTypes["SET_DELETED_PLACEHOLDERS"] = "@root/SET_DELETED_PLACEHOLDERS";
    RootReduxActionTypes["SET_SELECTED_BLOCK_ID"] = "@root/SET_SELECTED_BLOCK_ID";
    RootReduxActionTypes["SET_SELECTED_BLOCKS"] = "@root/SET_SELECTED_BLOCKS";
    RootReduxActionTypes["SET_PLACEHOLDER_TEMPLATE_FOLDER_LIST"] = "@root/SET_PLACEHOLDER_TEMPLATE_FOLDER_LIST";
    RootReduxActionTypes["DELETE_PLACEHOLDER_TEMPLATE_FOLDER_LIST"] = "@root/DELETE_PLACEHOLDER_TEMPLATE_FOLDER_LIST";
    RootReduxActionTypes["EDIT_PLACEHOLDER_TEMPLATE_FOLDER_LIST"] = "@root/EDIT_PLACEHOLDER_TEMPLATE_FOLDER_LIST";
    RootReduxActionTypes["ADD_PLACEHOLDER_TEMPLATE_FOLDER_LIST"] = "@root/ADD_PLACEHOLDER_TEMPLATE_FOLDER_LIST";
    RootReduxActionTypes["SET_PLACEHOLDER_TEMPLATE_FOLDER_CONTENT"] = "@root/SET_PLACEHOLDER_TEMPLATE_FOLDER_CONTENT";
    RootReduxActionTypes["SET_CURRENT_TEMPLATE_PLACEHOLDER_DATA"] = "@root/SET_CURRENT_TEMPLATE_PLACEHOLDER_DATA";
    RootReduxActionTypes["SET_PLACEHOLDER_FOR_INSERTION"] = "@root/SET_PLACEHOLDER_FOR_INSERTION";
    RootReduxActionTypes["SET_HOLDER_FOR_CREATED_PH_LIBRARY_ITEM"] = "@root/SET_HOLDER_FOR_CREATED_PH_LIBRARY_ITEM";
    RootReduxActionTypes["SET_TEMPLATE_SAVE_MODAL"] = "@root/SET_TEMPLATE_SAVE_MODAL";
    RootReduxActionTypes["ADD_HIDDEN_PLACEHOLDER"] = "@root/HIDDEN_PLACEHOLDER";
    RootReduxActionTypes["ADD_HIDDEN_PLACEHOLDERS"] = "@root/HIDDEN_PLACEHOLDERS";
    RootReduxActionTypes["IS_OPEN_MODAL"] = "@root/IS_OPEN_MODAL";
    RootReduxActionTypes["IS_PLACEHOLDER_ADMIN_OPEN"] = "@root/IS_PLACEHOLDER_ADMIN_OPEN";
    RootReduxActionTypes["SET_HAS_UNSAVED_TEXT"] = "@root/SET_HAS_UNSAVED_TEXT";
    RootReduxActionTypes["SET_SELECTED_SECTION_ID"] = "@root/SET_SELECTED_SECTION_ID";
    RootReduxActionTypes["SET_SELECTED_SECTIONS_BLOCK"] = "@root/SET_SELECTED_SECTIONS_BLOCK";
    RootReduxActionTypes["SET_UNDO_REDO_KEY"] = "@root/SET_UNDO_REDO_KEY";
    RootReduxActionTypes["SET_PREV_ACTION_GROUP"] = "@root/SET_PREV_ACTION_GROUP";
    RootReduxActionTypes["SET_PLACEHOLDER_SELECT_STATE"] = "@root/SET_PLACEHOLDER_SELECT_STATE";
    RootReduxActionTypes["SET_SELECTED_PLACEHOLDER"] = "@root/SET_SELECTED_PLACEHOLDER";
    RootReduxActionTypes["SET_CURRENT_TEMPLATE_ACL_INFO"] = "@root/SET_CURRENT_TEMPLATE_ACL_INFO";
    RootReduxActionTypes["UPDATE_CURRENT_TEMPLATE_ACL_INFO"] = "@root/UPDATE_CURRENT_TEMPLATE_ACL_INFO";
    RootReduxActionTypes["SET_PLACEHOLDER_ADMIN_BREADCRUMBS"] = "@root/SET_PLACEHOLDER_ADMIN_BREADCRUMBS";
    RootReduxActionTypes["SET_CREATING_FOLDER_MODAL"] = "@root/SET_CREATING_FOLDER_MODAL";
    RootReduxActionTypes["SET_CREATING_PLACEHOLDER_MODAL"] = "@root/SET_CREATING_PLACEHOLDER_MODAL";
    RootReduxActionTypes["SET_ACTIVE_PLACEHOLDER_RECORD"] = "@root/SET_ACTIVE_PLACEHOLDER_RECORD";
    RootReduxActionTypes["SET_OPEN_PLACEHOLDER_DRAWER"] = "@root/SET_OPEN_PLACEHOLDER_DRAWER";
    RootReduxActionTypes["SET_CURRENT_PLACEHOLDER_ACL"] = "@root/SET_CURRENT_PLACEHOLDER_ACL";
    RootReduxActionTypes["SET_ACTUALLY_PLACEHOLDERS_STRUCTURE"] = "@root/SET_ACTUALLY_PLACEHOLDERS_STRUCTURE";
    RootReduxActionTypes["SET_PLACEHOLDERS_LIBRARY_TREE"] = "@root/SET_PLACEHOLDERS_LIBRARY_TREE";
    RootReduxActionTypes["SET_ADD_PLACEHOLDER_ID"] = "@root/SET_ADD_PLACEHOLDER_ID";
    RootReduxActionTypes["SET_SEARCH_PH_TEMPLATES_LIST"] = "@root/SET_SEARCH_PH_TEMPLATES_LIST";
    RootReduxActionTypes["SET_PH_TEMPLATES_LIST_EXEMPLARS"] = "@root/SET_PH_TEMPLATES_LIST_EXEMPLARS";
    RootReduxActionTypes["SET_TARGETS"] = "@root/SET_TARGETS";
    RootReduxActionTypes["SET_CUSTOM_FORMULA_ATTRIBUTES"] = "@root/SET_CUSTOM_FORMULA_ATTRIBUTES";
    RootReduxActionTypes["EDIT_CUSTOM_FORMULA_ATTRIBUTES"] = "@root/EDIT_CUSTOM_FORMULA_ATTRIBUTES";
    RootReduxActionTypes["DELETE_CUSTOM_FORMULA_ATTRIBUTES"] = "@root/DELETE_CUSTOM_FORMULA_ATTRIBUTES";
    RootReduxActionTypes["SET_CARET_CUSTOM_FORMULA"] = "@root/SET_CARET_CUSTOM_FORMULA";
    RootReduxActionTypes["CLEAR_STATE"] = "@root/CLEAR_STATE";
})(RootReduxActionTypes = exports.RootReduxActionTypes || (exports.RootReduxActionTypes = {}));
