"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColumns = exports.changeLocation = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var react_1 = require("react");
var history_1 = require("../../../../history");
var routes_1 = require("../../../../routes");
var entityTypes_1 = __importStar(require("../../../utils/entityTypes"));
var TemplateStatus_1 = require("../../../utils/TemplateStatus");
var time_1 = require("../../../utils/time");
var responsibilities_1 = __importStar(require("../../../utils/responsibilities"));
var commonModels_1 = require("../../../../Root/utils/api/models/common/commonModels");
var FolderItemMenu_1 = require("../FolderItemMenu");
require("./FolderItem.less");
var react_redux_1 = require("react-redux");
var actions_1 = require("../redux/actions");
;
var changeLocation = function (itemId, typeKey, kind, selectFolder, setSearchValue) {
    if (kind !== commonModels_1.FolderKind.None) {
        return function () {
            selectFolder(itemId);
            history_1.history.push(routes_1.routeGenerator.selectFolder(itemId));
            setSearchValue('');
        };
    }
    return function () {
        window.open(location.origin + routes_1.routeGenerator["".concat(typeKey, "EditLink")](itemId), '_blank');
    };
};
exports.changeLocation = changeLocation;
var renderCustomRow = function (text, row, index, setSearchValue, selectFolder) {
    var latestChangeDate = row.latestChangeDate, name = row.name, authorName = row.authorName, id = row.id, status = row.status, prevStatus = row.prevStatus, typeKey = row.typeKey, kind = row.kind;
    var statusLabel = (kind === commonModels_1.FolderKind.None) ? ((status === TemplateStatus_1.templateStatus.archive.status) ? "".concat(status, " (").concat(prevStatus, ")") : status) : status;
    var templateName = name || 'Неизвестно';
    var renderDate = (kind === commonModels_1.FolderKind.None || kind === commonModels_1.FolderKind.Folder) ? (!!latestChangeDate && (0, time_1.formatDate)(latestChangeDate, 'DD.MM.YY HH:mm')) : null;
    var showPopover = templateName.length > window.innerWidth / 15;
    var folderLabel = (kind === commonModels_1.FolderKind.Folder) ? 'Дата создания: ' : 'Дата изменения: ';
    var nameElement = ((0, jsx_runtime_1.jsx)("div", __assign({ className: 'item-title-name', onClick: (0, exports.changeLocation)(id, typeKey, kind, selectFolder, setSearchValue) }, { children: (0, jsx_runtime_1.jsx)("p", __assign({ id: 'table-name', style: { margin: 0 } }, { children: templateName })) })));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showPopover ? ((0, jsx_runtime_1.jsx)(antd_1.Popover, __assign({ overlayStyle: { maxWidth: 400 }, placement: 'topLeft', content: (0, jsx_runtime_1.jsx)("p", { children: templateName }) }, { children: nameElement }))) : (nameElement), statusLabel && ((0, jsx_runtime_1.jsx)("div", __assign({ className: 'folder-item__row' }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: 'item-row-text-bottom' }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: 'item-row-text__label' }, { children: "\u0421\u0442\u0430\u0442\u0443\u0441:" })), "\u00A0", statusLabel] })) }))), authorName && ((0, jsx_runtime_1.jsx)("div", __assign({ className: 'folder-item__row' }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: 'item-row-text left' }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: 'item-row-text__label' }, { children: "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C:" })), "\u00A0", authorName] })) }))), (0, jsx_runtime_1.jsx)("div", __assign({ className: 'folder-item__row' }, { children: !!renderDate && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: 'item-row-text left' }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: 'item-row-text__label' }, { children: folderLabel })), ' ', renderDate] }))) }))] }));
};
var renderName = function (setSearchValue, selectFolder) { return ({
    title: 'Наименование',
    dataIndex: 'name',
    render: function (text, record, index) {
        return renderCustomRow(text, record, index, setSearchValue, selectFolder);
    },
    sorter: function (a, b) { return a.name.localeCompare(b.name); },
}); };
var renderDescription = {
    title: 'Описание',
    dataIndex: 'description',
    sorter: function (obj1, obj2) {
        var _a, _b;
        var obj1Descr = (_a = obj1.description) !== null && _a !== void 0 ? _a : '';
        var obj2Descr = (_b = obj2.description) !== null && _b !== void 0 ? _b : '';
        return obj1Descr.localeCompare(obj2Descr);
    },
    render: function (text, _a) {
        var description = _a.description;
        var showPopover = (description === null || description === void 0 ? void 0 : description.length) > window.innerWidth / 9.2;
        var decriptionElement = ((0, jsx_runtime_1.jsx)("div", __assign({ className: 'item-title-word-break' }, { children: (0, jsx_runtime_1.jsx)("p", { children: description }) })));
        if (showPopover)
            return ((0, jsx_runtime_1.jsx)(antd_1.Popover, __assign({ placement: 'topLeft', content: (0, jsx_runtime_1.jsx)("p", { children: description }), overlayStyle: { maxWidth: 400 } }, { children: decriptionElement })));
        else
            return decriptionElement;
    },
};
var renderCategory = {
    title: 'Категории',
    dataIndex: 'categories',
    width: '20%',
    ellipsis: true,
    render: function (categories) {
        var _a;
        return (_a = categories === null || categories === void 0 ? void 0 : categories.map(function (category) { return category.name; })) === null || _a === void 0 ? void 0 : _a.join('; ');
    },
};
var renderVersion = {
    title: 'Версия',
    dataIndex: 'version',
    ellipsis: true,
    width: '10%',
    render: function (text, row) { return ((0, jsx_runtime_1.jsx)("div", __assign({ className: 'item-version', style: { textAlign: 'left' } }, { children: row.version && row.version !== 'NaN' && "v".concat(row.version) }))); },
};
var renderMenu = function (props) {
    var upperLevelFolder = props.upperLevelFolder, canEdit = props.canEdit, selectedFolder = props.selectedFolder, searchValue = props.searchValue;
    return {
        title: '',
        width: '5%',
        align: 'right',
        render: function (text, row) {
            var isInRoot = selectedFolder.folderId === (upperLevelFolder === null || upperLevelFolder === void 0 ? void 0 : upperLevelFolder.id);
            var showDropDownMenu = !isInRoot &&
                selectedFolder.kind !== commonModels_1.FolderKind.Perimeter &&
                selectedFolder.kind !== commonModels_1.FolderKind.Partition &&
                !(0, entityTypes_1.isAttachmentType)(row.typeKey) &&
                selectedFolder.typeKey !== entityTypes_1.default.document;
            if (searchValue && searchValue.length > 2) {
                if (row.kind === commonModels_1.FolderKind.None || row.kind === commonModels_1.FolderKind.Folder) {
                    showDropDownMenu = !(0, entityTypes_1.isAttachmentType)(row.typeKey) && selectedFolder.typeKey !== entityTypes_1.default.document;
                }
            }
            if (showDropDownMenu)
                return ((0, jsx_runtime_1.jsx)(antd_1.Dropdown, __assign({ overlay: function () { return (0, jsx_runtime_1.jsx)(FolderItemMenu_1.FolderItemMenu, { columns: props, folderItem: row }); }, trigger: ['click'], placement: 'bottom', overlayClassName: 'more__options more__options_arrow' }, { children: (0, jsx_runtime_1.jsx)("div", { className: 'folder-item__more' }) })));
        },
    };
};
var getColumns = function (props) {
    var setSearchValue = props.setSearchValue, selectFolder = props.selectFolder, selectedFolder = props.selectedFolder;
    var name = renderName(setSearchValue, selectFolder);
    var dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(function () {
        dispatch((0, actions_1.toggleShowArchive)(false));
    }, [selectedFolder.folderId]);
    var isEdit = (0, responsibilities_1.checkUserPermissions)(responsibilities_1.default.edit[props.selectedFolder.typeKey]);
    var menu = isEdit ? renderMenu(props) : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    return [name, renderDescription, renderCategory, renderVersion, menu];
};
exports.getColumns = getColumns;
