"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FolderItemFilter = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var icons_1 = require("@ant-design/icons");
var antd_1 = require("antd");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var selectors_1 = require("../redux/selectors");
var SelectFilterType_1 = require("../SelectFilterType");
var constants_1 = require("../utils/constants");
var emptyFn_1 = require("../../../utils/emptyFn");
require("./FolderItemFilter.less");
var FolderItemFilter = function (props) {
    var _a = props.selectedEntity, selectedEntity = _a === void 0 ? '' : _a, _b = props.filterOptions, filterOptions = _b === void 0 ? {} : _b, _c = props.setFiltered, setFiltered = _c === void 0 ? emptyFn_1.emptyFn : _c, _d = props.folderId, folderId = _d === void 0 ? '' : _d, _e = props.getDocumentsList, getDocumentsList = _e === void 0 ? emptyFn_1.emptyFn : _e, _f = props.choosedFilters, choosedFilters = _f === void 0 ? [] : _f, _g = props.setChoosedFilter, setChoosedFilter = _g === void 0 ? emptyFn_1.emptyFn : _g, _h = props.selectedFields, selectedFields = _h === void 0 ? [] : _h, _j = props.setSelectedFields, setSelectedFields = _j === void 0 ? emptyFn_1.emptyFn : _j;
    (0, react_1.useEffect)(function () {
        setChoosedFilter === null || setChoosedFilter === void 0 ? void 0 : setChoosedFilter([filterOptions]);
    }, [filterOptions]);
    var changeFilterField = (0, react_1.useCallback)(function (value, key) {
        var nameField = constants_1.fieldsForFilter[selectedEntity].filter(function (_a) {
            var valueField = _a.valueField;
            return valueField === value;
        })[0].nameField;
        var changingFilters = {
            filterField: value,
            filterFieldLabel: nameField,
            filterValue: constants_1.defaultFilterValue,
            filterFieldSelected: true,
            filterValueSelected: false,
        };
        selectedFields[key] = value;
        setSelectedFields(selectedFields);
        var updatedList = choosedFilters.map(function (item, index) {
            if (index === key) {
                return __assign(__assign({}, item), changingFilters);
            }
            return item;
        });
        setFiltered(false);
        setChoosedFilter(updatedList);
    }, [choosedFilters]);
    var changeFilterValue = (0, react_1.useCallback)(function (value, key) {
        var changingFilters = {
            filterValue: value,
            filterFieldSelected: true,
            filterValueSelected: true
        };
        var updatedList = choosedFilters.map(function (item, index) {
            if (index === key) {
                return __assign(__assign({}, item), changingFilters);
            }
            return item;
        });
        var hasUnSelectable = updatedList.filter(function (_a) {
            var filterField = _a.filterField, filterValue = _a.filterValue;
            return filterField === constants_1.defaultFieldValue || filterValue === constants_1.defaultFilterValue;
        });
        if (choosedFilters.length < 3 && hasUnSelectable.length === 0) {
            updatedList.push(filterOptions);
        }
        setFiltered(false);
        setChoosedFilter(updatedList);
    }, [choosedFilters]);
    var disableFilteringButton = (0, react_1.useCallback)(function () {
        return choosedFilters.filter(function (elem) { return (elem.filterFieldSelected && elem.filterValueSelected) || elem.isSent; });
    }, [choosedFilters]);
    var resetFilter = (0, react_1.useCallback)(function () {
        setChoosedFilter([filterOptions]);
        setFiltered(false);
        getDocumentsList(folderId, 1, 10);
    }, [filterOptions]);
    var filter = (0, react_1.useCallback)(function () {
        var sendingFilters = [];
        choosedFilters.forEach(function (_a) {
            var filterField = _a.filterField, filterValue = _a.filterValue;
            if (filterField !== constants_1.defaultFieldValue && filterValue !== constants_1.defaultFilterValue) {
                sendingFilters[filterField] = filterValue;
            }
        });
        getDocumentsList(folderId, 1, 10, sendingFilters);
        setFiltered(true);
    }, [choosedFilters]);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "filter-block" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "filter__wrapper" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "filter__wrapper-left" }, { children: choosedFilters.map(function (item, key) { return ((0, jsx_runtime_1.jsx)(SelectFilterType_1.SelectFilterType, { handleChange: function (value) { return changeFilterField(value, key); }, selectedEntity: selectedEntity, value: item.filterFieldLabel, selectedFields: selectedFields })); }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "filter__wrapper-center" }, { children: choosedFilters.map(function (item, key) { return ((0, jsx_runtime_1.jsx)(antd_1.Input, { style: { marginBottom: '5px' }, placeholder: constants_1.defaultFilterValue, value: item.filterValue !== constants_1.defaultFilterValue ? item.filterValue : undefined, onChange: function (_a) {
                            var target = _a.target;
                            return changeFilterValue(target.value, key);
                        } })); }) })), (0, jsx_runtime_1.jsx)(icons_1.CloseOutlined, { className: "filter__icon-close", onClick: resetFilter }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "filter__wrapper-right" }, { children: (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: "primary", onClick: filter, disabled: disableFilteringButton().length === 0 }, { children: " \u0424\u0438\u043B\u044C\u0442\u0440\u043E\u0432\u0430\u0442\u044C " })) }))] })) })));
};
exports.FolderItemFilter = FolderItemFilter;
var mapStateToProps = function (state) { return ({
    filterOptions: (0, selectors_1.getFilterOptions)(state),
}); };
exports.default = (0, react_redux_1.connect)(mapStateToProps, {})(exports.FolderItemFilter);
