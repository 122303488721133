"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortFolderContent = exports.sortByType = exports.sortByName = void 0;
var types = {
    folder: 0,
    document: 1,
    library: 1,
    template: 1,
    attachment: 1,
    additionalAgreement: 1,
    powerOfAttorney: 1,
    revokePowerOfAttorney: 1,
    authority: 1,
};
var order = {
    asc: 1,
    desc: -1,
};
var sortByName = function (a, b, nameOrder) {
    if (a.name > b.name) {
        return order[nameOrder];
    }
    else if (a.name < b.name) {
        return -order[nameOrder];
    }
    return 0;
};
exports.sortByName = sortByName;
var sortByType = function (a, b, typeOrder) {
    if (types[a.type] > types[b.type]) {
        return order[typeOrder];
    }
    else if (types[a.type] < types[b.type]) {
        return -order[typeOrder];
    }
    return 0;
};
exports.sortByType = sortByType;
// export const sortByDate = (a, b, dateOrder) => {
//   if (a.dateCreated > b.dateCreated) {
//     return order[dateOrder];
//   } else if (a.dateCreated < b.dateCreated) {
//     return -order[dateOrder];
//   }
//   return 0;
// };
var sortFolderContent = function (content, nameOrder, typeOrder) { return content
    .sort(function (a, b) { return (0, exports.sortByName)(a, b, nameOrder); })
    .sort(function (a, b) { return (0, exports.sortByType)(a, b, typeOrder); }); };
exports.sortFolderContent = sortFolderContent;
// .sort((a, b) => sortByDate(a, b, dateOrder));
