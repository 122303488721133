"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formulaItemsSplitting = exports.AllOperationsValues = exports.AllOperations = void 0;
var AllOperations;
(function (AllOperations) {
    AllOperations["Sum"] = "+";
    AllOperations["Sub"] = "-";
    AllOperations["Mult"] = "*";
    AllOperations["Div"] = "/";
    AllOperations["LeftBr"] = "(";
    AllOperations["RightBr"] = ")";
})(AllOperations = exports.AllOperations || (exports.AllOperations = {}));
exports.AllOperationsValues = [
    AllOperations.Sum,
    AllOperations.Sub,
    AllOperations.Mult,
    AllOperations.Div,
    AllOperations.LeftBr,
    AllOperations.RightBr
];
var formulaItemsSplitting = function (formulaText) {
    var formulaTextCopy = formulaText;
    var renderNodes = [];
    var _loop_1 = function () {
        var firstValue = formulaTextCopy[0];
        // save operator
        if (exports.AllOperationsValues === null || exports.AllOperationsValues === void 0 ? void 0 : exports.AllOperationsValues.some(function (value) { return value === firstValue; })) {
            renderNodes === null || renderNodes === void 0 ? void 0 : renderNodes.push(firstValue);
            formulaTextCopy = formulaTextCopy === null || formulaTextCopy === void 0 ? void 0 : formulaTextCopy.substring(1);
            // save element
        }
        else {
            var element = formulaTextCopy.slice(0, 2);
            renderNodes === null || renderNodes === void 0 ? void 0 : renderNodes.push(element);
            formulaTextCopy = formulaTextCopy === null || formulaTextCopy === void 0 ? void 0 : formulaTextCopy.substring(2);
        }
    };
    while ((formulaTextCopy === null || formulaTextCopy === void 0 ? void 0 : formulaTextCopy.length) > 0) {
        _loop_1();
    }
    return renderNodes;
};
exports.formulaItemsSplitting = formulaItemsSplitting;
