"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractSectionGroups = void 0;
var extractSectionGroups = function (availableSections) { return availableSections.reduce(function (sections, current, i, array) {
    if (!current.groupId) {
        sections.push(current);
    }
    if (!sections.some(function (sct) { return sct.groupId === current.groupId; })) {
        var subtypes = array.filter(function (item) { return item.sectionId === current.sectionId && item.groupId === current.groupId && item.groupName && item.type === 'text'; });
        if (subtypes.length > 0) {
            // Если в группе(варианте) только один блок то используем его 
            if (subtypes.length === 1 && subtypes[0]) {
                sections.push(subtypes[0]);
            }
            else {
                // Для правильно сортировки в дальнейшем нужно найти элемент группы (варианта) с минимальным порядковым номером
                subtypes.sort(function (a, b) {
                    var l = Number(a.order.substring(37));
                    var r = Number(b.order.substring(37));
                    return (l - r);
                });
                // Добавляем элемент с минимальным порядковым номером, в отсортированном массиве он первый
                sections.push(subtypes[0]);
            }
        }
        else {
            sections.push(current);
        }
    }
    return sections;
}, []); };
exports.extractSectionGroups = extractSectionGroups;
