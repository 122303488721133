"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TEntityType = void 0;
/**
 * Тип сущности
 */
var TEntityType;
(function (TEntityType) {
    TEntityType["Block"] = "Block";
    TEntityType["BlockAttachment"] = "BlockAttachment";
    TEntityType["BlockList"] = "BlockList";
    TEntityType["BlockTable"] = "BlockTable";
    TEntityType["BlockLibrary"] = "BlockLibrary";
    TEntityType["Section"] = "Section";
    TEntityType["SectionGroup"] = "SectionGroup";
    TEntityType["Attachment"] = "Attachment";
})(TEntityType = exports.TEntityType || (exports.TEntityType = {}));
