"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParamValue = exports.extractParams = void 0;
// Возвращает массив объектов типа [{ имяПараметра : значение }, ... ]
var extractParams = function (url) {
    // первый параметр может быть мусором
    var splittedUrl = url.split('&');
    var paramsArray = splittedUrl.slice(1).map(function (paramStr) {
        var _a;
        var splittedParam = paramStr.split('=');
        return _a = {},
            _a[splittedParam[0]] = splittedParam[1],
            _a;
    });
    return paramsArray;
};
exports.extractParams = extractParams;
// возвращает null, если нет параметра paramName или значение type не определено
// возвращает string, если есть параметр paramName и для него задано значение
var getParamValue = function (params, paramName) {
    if (!params || !params.length || typeof paramName !== 'string') {
        return null;
    }
    var paramObj = params.find(function (parameter) { return parameter[paramName]; });
    if (!paramObj || !(typeof paramObj[paramName] === 'string')) {
        return null;
    }
    return paramObj[paramName];
};
exports.getParamValue = getParamValue;
