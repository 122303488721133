"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.templateStatus = void 0;
exports.templateStatus = {
    archive: {
        status: 'Архив',
    },
    draft: {
        status: 'Черновик',
    },
    onEditing: {
        status: 'На редактировании',
    },
    published: {
        status: 'Опубликован',
    },
    fake: {
        status: 'fake',
    },
};
