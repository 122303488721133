"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTooltipControls = exports.hideBoundaryTooltip = exports.showBoundaryTooltip = exports.showTooltip = exports.createTooltip = void 0;
var common_1 = require("../../../utils/common");
var createTooltip = function (doc, withControls) {
    if (doc === void 0) { doc = document; }
    var node = doc.createElement('div');
    node.style.display = 'none';
    node.style.top = '0';
    node.style.left = '0';
    node.classList.add('placeholder-tooltip');
    if (withControls) {
        node.classList.add('tooltip-with-controls');
    }
    doc.body.appendChild(node);
    return node;
};
exports.createTooltip = createTooltip;
var showTooltip = function (phTooltip, targetNode) {
    if (!common_1.TypeUtils.isHtmlElement(targetNode) || !common_1.TypeUtils.isHtmlElement(phTooltip)) {
        return;
    }
    var _a = targetNode.getBoundingClientRect(), top = _a.top, left = _a.left;
    phTooltip.style.top = "".concat(top - 32, "px");
    phTooltip.style.left = "".concat(left, "px");
    phTooltip.style.display = 'block';
};
exports.showTooltip = showTooltip;
var showBoundaryTooltip = function (node) {
    return function (boundaryId) {
        var leftBoundary = common_1.DomUtils.selectPlaceholder(boundaryId);
        if (!common_1.TypeUtils.isHtmlElement(node) || !leftBoundary) {
            return;
        }
        try {
            var placeholderTitle = leftBoundary.innerText;
            var firstBoundarySpan = leftBoundary.nextSibling;
            if (!firstBoundarySpan)
                return;
            var _a = firstBoundarySpan.getBoundingClientRect(), top_1 = _a.top, left = _a.left;
            node.style.top = "".concat(top_1 - 32, "px");
            node.style.left = "".concat(left, "px");
            node.style.display = 'block';
            node.innerText = placeholderTitle;
        }
        catch (err) {
            console.error(err);
        }
    };
};
exports.showBoundaryTooltip = showBoundaryTooltip;
var hideBoundaryTooltip = function (node) { return function () {
    if (common_1.TypeUtils.isHtmlElement(node)) {
        node.style.display = 'none';
    }
}; };
exports.hideBoundaryTooltip = hideBoundaryTooltip;
var createTooltipControls = function (controls) {
    if (!controls || !controls.length)
        return [];
    return controls.map(function (_a) {
        var text = _a.text, className = _a.className, onClick = _a.onClick;
        var button = document.createElement('button');
        button.addEventListener('click', onClick);
        button.innerText = text;
        button.setAttribute('class', className);
        return button;
    });
};
exports.createTooltipControls = createTooltipControls;
