"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFormulaSelect = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var CustomFormulaSelectView_1 = require("./CustomFormulaSelectView");
var react_redux_1 = require("react-redux");
var CustomFormulaCalculatorUtils_1 = require("../\u0421ustomFormulaCalculator/CustomFormulaCalculatorUtils");
var rootGetters_1 = require("../../../../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../../../../Root/redux/rootActionCreators/rootActionCreators");
var CustomFormulaSelect = function (_a) {
    var sortedCustomFormulaAggregations = _a.sortedCustomFormulaAggregations, currentFormula = _a.currentFormula, setCurrentFormula = _a.setCurrentFormula;
    var dispatch = (0, react_redux_1.useDispatch)();
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var aggregations = presentState === null || presentState === void 0 ? void 0 : presentState.aggregations;
    var currentCustomFormulaCaret = presentState === null || presentState === void 0 ? void 0 : presentState.customFormulaCaret;
    var options = (sortedCustomFormulaAggregations === null || sortedCustomFormulaAggregations === void 0 ? void 0 : sortedCustomFormulaAggregations.map(function (aggregation) { return ({ value: aggregation.id, label: "".concat(aggregation.order, ".").concat(aggregation.name) }); })) || [];
    var currentFormulaView = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.formula;
    var currentParameters = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.parameters;
    var caretIndex = currentCustomFormulaCaret === 0 ? currentCustomFormulaCaret : currentCustomFormulaCaret - 1;
    var caretSymbol = currentFormulaView[caretIndex];
    var currentSymbol = new RegExp(/[0-9]/);
    var isOperand = currentSymbol === null || currentSymbol === void 0 ? void 0 : currentSymbol.test(currentFormulaView[caretIndex]);
    var isDisabled = currentCustomFormulaCaret === null
        || (currentParameters === null || currentParameters === void 0 ? void 0 : currentParameters.length) === 10 || (isOperand
        || (caretSymbol === '('
            && caretIndex !== 0
            && caretIndex !== (currentFormulaView === null || currentFormulaView === void 0 ? void 0 : currentFormulaView.length) - 1)
        || caretSymbol === ')');
    var onCustomFormulaChange = function (relatedPlaceholderId) {
        var _a;
        var relatedAggregation = aggregations === null || aggregations === void 0 ? void 0 : aggregations.find(function (aggregation) { return (aggregation === null || aggregation === void 0 ? void 0 : aggregation.id) === relatedPlaceholderId; });
        // последний индекс
        var lastIndex = currentParameters === null || currentParameters === void 0 ? void 0 : currentParameters.length;
        var relatedParameters = {
            alias: "p".concat(lastIndex),
            placeholderId: relatedAggregation === null || relatedAggregation === void 0 ? void 0 : relatedAggregation.id,
        };
        // вставка операнда по умолчанию в конец
        var formulaParameters = __spreadArray([], currentParameters, true);
        var currentFormulaView = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.formula;
        if ((currentFormulaView === null || currentFormulaView === void 0 ? void 0 : currentFormulaView.length) === 0
            || (caretIndex === (currentFormulaView === null || currentFormulaView === void 0 ? void 0 : currentFormulaView.length) - 1)) {
            currentFormulaView = currentFormulaView.concat(relatedParameters === null || relatedParameters === void 0 ? void 0 : relatedParameters.alias);
            formulaParameters = __spreadArray(__spreadArray([], currentParameters, true), [relatedParameters], false);
            var insertStartIndex = caretIndex === 0 ? caretIndex : caretIndex + 1;
            var updatedCaretIndex = insertStartIndex + 2;
            dispatch((0, rootActionCreators_1.setCustomFormulaCaret)(updatedCaretIndex));
        }
        else {
            // вставка в середину
            var formulaSplit = currentFormulaView === null || currentFormulaView === void 0 ? void 0 : currentFormulaView.split('');
            var insertStartIndex = caretIndex === 0 ? caretIndex : caretIndex + 1;
            formulaSplit.splice(insertStartIndex, 0, relatedParameters === null || relatedParameters === void 0 ? void 0 : relatedParameters.alias);
            currentFormulaView = formulaSplit === null || formulaSplit === void 0 ? void 0 : formulaSplit.join('');
            formulaParameters === null || formulaParameters === void 0 ? void 0 : formulaParameters.splice(insertStartIndex, 0, relatedParameters);
            var formulaSplitted = (0, CustomFormulaCalculatorUtils_1.formulaItemsSplitting)(currentFormulaView);
            var IndexMatching = formulaSplitted === null || formulaSplitted === void 0 ? void 0 : formulaSplitted.map(function (operand) {
                var operandElement = operand;
                if (operandElement === null || operandElement === void 0 ? void 0 : operandElement.startsWith('p')) {
                    var currentFormulaParameters = formulaParameters === null || formulaParameters === void 0 ? void 0 : formulaParameters.find(function (parameter) { return (parameter === null || parameter === void 0 ? void 0 : parameter.alias) === operandElement; });
                    return {
                        oldOperand: operand,
                        parameter: currentFormulaParameters,
                    };
                }
                else {
                    return {
                        oldOperand: operand,
                        parameter: null,
                    };
                }
            });
            var newParameters = IndexMatching === null || IndexMatching === void 0 ? void 0 : IndexMatching.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.parameter) !== null; });
            var newParametersWithNames_1 = newParameters === null || newParameters === void 0 ? void 0 : newParameters.map(function (item, index) { return (__assign(__assign({}, item), { newOperand: "p".concat(index) })); });
            var newIndexMatching = IndexMatching === null || IndexMatching === void 0 ? void 0 : IndexMatching.map(function (item) {
                var itemOldOperand = item === null || item === void 0 ? void 0 : item.oldOperand;
                var isCurrent = newParametersWithNames_1 === null || newParametersWithNames_1 === void 0 ? void 0 : newParametersWithNames_1.find(function (element) { return (element === null || element === void 0 ? void 0 : element.oldOperand) === itemOldOperand; });
                if (isCurrent) {
                    return __assign(__assign({}, item), { newOperand: isCurrent === null || isCurrent === void 0 ? void 0 : isCurrent.newOperand });
                }
                else {
                    return __assign(__assign({}, item), { newOperand: itemOldOperand });
                }
            });
            currentFormulaView = (_a = newIndexMatching === null || newIndexMatching === void 0 ? void 0 : newIndexMatching.map(function (item) { return item === null || item === void 0 ? void 0 : item.newOperand; })) === null || _a === void 0 ? void 0 : _a.join('');
            formulaParameters = newParametersWithNames_1 === null || newParametersWithNames_1 === void 0 ? void 0 : newParametersWithNames_1.map(function (item) {
                var newOperand = item.newOperand;
                var parameter = item.parameter;
                var newParameter = {
                    alias: newOperand,
                    placeholderId: parameter === null || parameter === void 0 ? void 0 : parameter.placeholderId,
                };
                return newParameter;
            });
            var updatedCaretIndex = insertStartIndex + 2;
            dispatch((0, rootActionCreators_1.setCustomFormulaCaret)(updatedCaretIndex));
        }
        var changedCurrentFormula = __assign(__assign({}, currentFormula), { formula: currentFormulaView, parameters: formulaParameters });
        setCurrentFormula(changedCurrentFormula);
    };
    return ((0, jsx_runtime_1.jsx)(CustomFormulaSelectView_1.CustomFormulaSelectView, { options: options, onCustomFormulaChange: onCustomFormulaChange, currentFormula: currentFormula, isDisabled: isDisabled }));
};
exports.CustomFormulaSelect = CustomFormulaSelect;
