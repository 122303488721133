"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFormulaResultView = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var TextArea = antd_1.Input.TextArea;
var CustomFormulaResultView = function (_a) {
    var formulaText = _a.formulaText, inputRef = _a.inputRef, onBlur = _a.onBlur, onFocus = _a.onFocus, validationStatus = _a.validationStatus, isEditing = _a.isEditing;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: 'textarea_wrapper' }, { children: [(0, jsx_runtime_1.jsx)("label", __assign({ htmlFor: 'custom_formula', className: 'place_holder_formula' }, { children: "\u0418\u0442\u043E\u0433 \u0444\u043E\u0440\u043C\u0443\u043B\u044B" })), (0, jsx_runtime_1.jsx)("div", __assign({ className: 'panel__textarea' }, { children: (0, jsx_runtime_1.jsx)(TextArea, { id: 'custom_formula', status: validationStatus ? 'error' : '', value: formulaText, ref: inputRef, onKeyDown: function (e) { return e.preventDefault(); }, onBlur: onBlur, onClick: onFocus, disabled: isEditing }) }))] })));
};
exports.CustomFormulaResultView = CustomFormulaResultView;
