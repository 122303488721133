"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var antd_1 = require("antd");
var actions_1 = require("../../../store/login/actions");
var selectors_1 = require("../../../store/login/selectors");
require("./style.css");
var LoginPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var errorMessage = (0, react_redux_1.useSelector)(selectors_1.errorAuthMessageSelector);
    var OIDC_Instance = (0, react_redux_1.useSelector)(selectors_1.oidsInstanceSelector);
    (0, react_1.useEffect)(function () {
        dispatch((0, actions_1.fetchOidSettings)());
    }, []);
    var onLoginPressed = (0, react_1.useCallback)(function () {
        dispatch((0, actions_1.login)(OIDC_Instance));
    }, [OIDC_Instance]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "login-form" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "header" }, { children: "\u041A\u043E\u043D\u0441\u0442\u0440\u0443\u043A\u0442\u043E\u0440 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u043E\u0432" })), (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "login-description" }, { children: "\u041D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0432\u044B\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u0432\u0445\u043E\u0434 \u0432 \u0441\u0438\u0441\u0442\u0435\u043C\u0443. \u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u0442\u0438\u043F \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u0438:" })), !!errorMessage && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "login-form-error" }, { children: errorMessage }))), (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ className: "login-button oid-login-button", disabled: !OIDC_Instance, onClick: onLoginPressed, type: "primary" }, { children: "\u0412\u043E\u0439\u0442\u0438 \u0441 \u0443\u0447\u0435\u0442\u043D\u043E\u0439 \u0437\u0430\u043F\u0438\u0441\u044C\u044E \u041F\u0418\u041A" }))] })] })));
};
exports.default = LoginPage;
