"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibraryAdd = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
require("../PlaceholderLibraryModalsStyles.less");
var antd_1 = require("antd");
var react_router_dom_1 = require("react-router-dom");
var react_redux_1 = require("react-redux");
var PlaceholderLibraryAddTree_1 = require("./PlaceholderLibraryAddTree");
var searchInObject_1 = require("../../../../shared/utils/searchInObject");
var PlaceholdersLibraryCreatingFolder_1 = require("../PlaceholdersLibraryCreatingFolder/PlaceholdersLibraryCreatingFolder");
var api_v1_Placeholder_1 = require("../../../../Root/utils/api/api.v1.Placeholder");
var PlaceholderTemplateApi_1 = require("../../../../api/placeholderLibraryApi/PlaceholderTemplate/PlaceholderTemplateApi");
var PlaceholderTemplateFolderApi_1 = require("../../../../api/placeholderLibraryApi/PlaceholderTemplateFolder/PlaceholderTemplateFolderApi");
var routes_1 = require("../../../../routes");
var placeholdersLibraryUtils_1 = require("../../../placeholdersLibraryUtils");
var rootGetters_1 = require("../../../../Root/redux/rootGetters/rootGetters");
var placeholderLibraryUtils_1 = require("./placeholderLibraryUtils");
var rootActionCreators_1 = require("../../../../Root/redux/rootActionCreators/rootActionCreators");
var PlaceholderLibraryAdd = function () {
    var _a, _b;
    // @ts-ignore
    var history = (0, react_router_dom_1.useHistory)();
    var dispatch = (0, react_redux_1.useDispatch)();
    // @ts-ignore
    var historyLocationState = (_a = history === null || history === void 0 ? void 0 : history.location) === null || _a === void 0 ? void 0 : _a.state;
    var placeholderId = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.placeholderId;
    var fromSystem = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.fromSystem;
    var templateType = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.templateType;
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var placeholdersLibraryTree = presentState === null || presentState === void 0 ? void 0 : presentState.placeholdersLibraryTree;
    var foldersList = presentState === null || presentState === void 0 ? void 0 : presentState.placeholderTemplateFolderList;
    var openedFolderContent = presentState === null || presentState === void 0 ? void 0 : presentState.placeholderTemplateFolderContent;
    var contentTemplatesList = openedFolderContent === null || openedFolderContent === void 0 ? void 0 : openedFolderContent.templates;
    var partitions = (0, searchInObject_1.findElement)(placeholdersLibraryTree, 'kind', 'partition');
    var rootFolders = fromSystem
        ? (_b = partitions === null || partitions === void 0 ? void 0 : partitions.children) === null || _b === void 0 ? void 0 : _b.filter(function (folder) { return (folder === null || folder === void 0 ? void 0 : folder.system) === fromSystem; })
        : partitions === null || partitions === void 0 ? void 0 : partitions.children;
    if (templateType === 'library' || templateType === 'attachment') {
        var perimeter = (0, searchInObject_1.findElement)(placeholdersLibraryTree, 'kind', 'perimeter');
        rootFolders = perimeter === null || perimeter === void 0 ? void 0 : perimeter.children;
    }
    var _c = (0, react_1.useState)(!!placeholderId), open = _c[0], setOpen = _c[1];
    var _d = (0, react_1.useState)(null), selectedFolder = _d[0], setSelectedFolder = _d[1];
    var _e = (0, react_1.useState)(null), currentFolder = _e[0], setCurrentFolder = _e[1];
    var _f = (0, react_1.useState)(true), disable = _f[0], setDisable = _f[1];
    var _g = (0, react_1.useState)(null), selectedKind = _g[0], setSelectedKind = _g[1];
    (0, react_1.useEffect)(function () {
        if (selectedFolder) {
            (0, PlaceholderTemplateFolderApi_1.placeholderTemplateFolderContent)({ id: selectedFolder }).then(function (response) {
                return dispatch((0, rootActionCreators_1.setPlaceholderTemplateFolderContent)(response));
            });
        }
    }, [selectedFolder]);
    var onCancel = function () { return __awaiter(void 0, void 0, void 0, function () {
        var lastExpandedKey, createHistoryState, createHistoryState;
        return __generator(this, function (_a) {
            lastExpandedKey = selectedFolder;
            if (lastExpandedKey) {
                createHistoryState = __assign(__assign({}, historyLocationState), { folderId: "".concat(lastExpandedKey), isHiddenPh: null, placeholderId: null });
                // @ts-ignore
                history === null || history === void 0 ? void 0 : history.push(routes_1.routeGenerator.placeholderLibraryFolderEditLink("".concat(lastExpandedKey)), createHistoryState);
            }
            else {
                createHistoryState = __assign(__assign({}, historyLocationState), { isHiddenPh: null, placeholderId: null });
                // @ts-ignore
                history.push(routes_1.routeGenerator.placeholderLibraryAdminEditLink(''), createHistoryState);
            }
            return [2 /*return*/];
        });
    }); };
    var onOk = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var lastExpandedKey, placeholderId, templateId, _placeholders, currentPlaceholder, data, currentPlaceholderName, nameForPh, findEqualName, findWithEqualName, findAllSimilarNames, lastPartsOfNames, lastPartsOfNamesRemoveEmpty, aliasWithOrders_1, currentNames, maxNumber, nextOrder, saveData, createdPhTemplate, record, createHistoryState, createHistoryState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    lastExpandedKey = selectedFolder;
                    placeholderId = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.placeholderId;
                    templateId = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.templateId;
                    return [4 /*yield*/, (0, api_v1_Placeholder_1.v1PlaceholderByTemplate)({ templateId: templateId })];
                case 1:
                    _placeholders = _a.sent();
                    currentPlaceholder = _placeholders === null || _placeholders === void 0 ? void 0 : _placeholders.find(function (_placeholder) { return _placeholder.id === placeholderId; });
                    data = (0, placeholderLibraryUtils_1.placeholderReader)(currentPlaceholder);
                    currentPlaceholderName = data === null || data === void 0 ? void 0 : data.initial_title;
                    nameForPh = currentPlaceholderName || '';
                    findEqualName = contentTemplatesList === null || contentTemplatesList === void 0 ? void 0 : contentTemplatesList.some(function (template) { return template.name === currentPlaceholderName; });
                    if (findEqualName) {
                        findWithEqualName = contentTemplatesList === null || contentTemplatesList === void 0 ? void 0 : contentTemplatesList.find(function (template) { return template.name === currentPlaceholderName; });
                        if (findWithEqualName) {
                            findAllSimilarNames = contentTemplatesList === null || contentTemplatesList === void 0 ? void 0 : contentTemplatesList.filter(function (template) {
                                return template.name.includes(currentPlaceholderName);
                            });
                            lastPartsOfNames = findAllSimilarNames === null || findAllSimilarNames === void 0 ? void 0 : findAllSimilarNames.map(function (template) {
                                return template === null || template === void 0 ? void 0 : template.name.replace(currentPlaceholderName, '');
                            });
                            lastPartsOfNamesRemoveEmpty = lastPartsOfNames === null || lastPartsOfNames === void 0 ? void 0 : lastPartsOfNames.filter(function (name) { return name !== ''; });
                            aliasWithOrders_1 = [];
                            currentNames = lastPartsOfNamesRemoveEmpty === null || lastPartsOfNamesRemoveEmpty === void 0 ? void 0 : lastPartsOfNamesRemoveEmpty.filter(function (name) {
                                var start = name === null || name === void 0 ? void 0 : name.startsWith('(');
                                var end = name === null || name === void 0 ? void 0 : name.endsWith(')');
                                if (start && end) {
                                    var contentOfBrackets = name === null || name === void 0 ? void 0 : name.replace('(', '').replace(')', '');
                                    var intoNumber = +contentOfBrackets;
                                    if (intoNumber && typeof intoNumber === 'number') {
                                        aliasWithOrders_1 === null || aliasWithOrders_1 === void 0 ? void 0 : aliasWithOrders_1.push(intoNumber);
                                    }
                                }
                            });
                            if (aliasWithOrders_1 === null || aliasWithOrders_1 === void 0 ? void 0 : aliasWithOrders_1.length) {
                                maxNumber = Math.max.apply(Math, aliasWithOrders_1);
                                nextOrder = maxNumber + 1;
                                nameForPh = "".concat(currentPlaceholderName, "(").concat(nextOrder, ")");
                            }
                            else {
                                nameForPh = "".concat(currentPlaceholderName, "(").concat(1, ")");
                            }
                        }
                    }
                    if (!data) return [3 /*break*/, 4];
                    saveData = __assign(__assign({}, data), { initial_title: nameForPh, name: nameForPh, title: nameForPh });
                    return [4 /*yield*/, (0, PlaceholderTemplateApi_1.placeholderTemplateCreate)({
                            // @ts-ignore
                            data: saveData,
                            folderId: selectedFolder,
                            name: nameForPh,
                        })];
                case 2:
                    createdPhTemplate = _a.sent();
                    dispatch((0, rootActionCreators_1.setPhLibraryCreatedItem)(createdPhTemplate));
                    return [4 /*yield*/, (0, PlaceholderTemplateFolderApi_1.placeholderTemplateFolderContent)({ id: selectedFolder }).then(function (response) {
                            return dispatch((0, rootActionCreators_1.setPlaceholderTemplateFolderContent)(response));
                        })];
                case 3:
                    _a.sent();
                    antd_1.message.success("\u041F\u0425 \u0441 \u0438\u043C\u0435\u043D\u0435\u043C [".concat(nameForPh, "] \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0441\u043E\u0437\u0434\u0430\u043D\u043E"));
                    record = { key: createdPhTemplate === null || createdPhTemplate === void 0 ? void 0 : createdPhTemplate.id, name: nameForPh, template: true };
                    dispatch((0, rootActionCreators_1.setActivePlaceholderRecord)(record));
                    dispatch((0, rootActionCreators_1.setOpenPlaceholderDrawer)(true));
                    setOpen(false);
                    _a.label = 4;
                case 4:
                    if (lastExpandedKey) {
                        createHistoryState = __assign(__assign({}, historyLocationState), { folderId: "".concat(lastExpandedKey), isHiddenPh: null, placeholderId: null });
                        // @ts-ignore
                        history === null || history === void 0 ? void 0 : history.push(routes_1.routeGenerator.placeholderLibraryFolderEditLink("".concat(lastExpandedKey)), createHistoryState);
                    }
                    else {
                        createHistoryState = __assign(__assign({}, historyLocationState), { isHiddenPh: null, placeholderId: null });
                        // @ts-ignore
                        history.push(routes_1.routeGenerator.placeholderLibraryAdminEditLink(''), createHistoryState);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [selectedFolder, currentFolder, contentTemplatesList]);
    var onFolderCreate = function () {
        dispatch((0, rootActionCreators_1.setCreatingFolderModal)(true));
        var folderKey = '';
        if (selectedFolder) {
            folderKey = selectedFolder;
        }
        var findFolder = foldersList === null || foldersList === void 0 ? void 0 : foldersList.find(function (folder) { return (folder === null || folder === void 0 ? void 0 : folder.id) === folderKey; });
        setCurrentFolder(findFolder);
    };
    (0, react_1.useEffect)(function () {
        // @ts-ignore
        if (selectedFolder && selectedKind !== 'subpart' && selectedKind !== 'partition' && selectedKind !== 'perimeter') {
            setDisable(false);
            var breadcrumbsAlias = (0, placeholdersLibraryUtils_1.createBreadcrumbsAlias)(selectedFolder, placeholdersLibraryTree) || [];
            dispatch((0, rootActionCreators_1.setPlaceholderAdminBreadcrumbs)(breadcrumbsAlias));
        }
        else {
            setDisable(true);
        }
    }, [selectedFolder]);
    var folderCreateAvailability = !!selectedFolder && (selectedKind === 'subpart' || selectedKind === 'folder');
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(antd_1.Modal, __assign({ title: (0, jsx_runtime_1.jsxs)("div", __assign({ className: 'popup-header' }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: 'popup-header__info' }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: 'popup-header__info-title' }, { children: "\u0414\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u041F\u0425" })), (0, jsx_runtime_1.jsx)("span", __assign({ className: 'popup-header__info-description' }, { children: "\u041D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u043E\u0434\u0445\u043E\u0434\u044F\u0449\u0443\u044E \u043F\u0430\u043F\u043A\u0443 \u0434\u043B\u044F \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u0438\u044F \u043F\u0445 \u0438\u043B\u0438 \u0441\u043E\u0437\u0434\u0430\u0442\u044C \u043D\u043E\u0432\u0443\u044E \u043F\u0430\u043F\u043A\u0443" })), !selectedFolder && (0, jsx_runtime_1.jsx)("span", __assign({ className: 'popup-header__info-warning' }, { children: "\u041D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u043F\u043E\u0434\u0440\u0430\u0437\u0434\u0435\u043B" }))] })), (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: 'primary', onClick: onFolderCreate, disabled: !folderCreateAvailability }, { children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u043F\u0430\u043F\u043A\u0443" }))] })), open: open, closable: false, footer: [
                    (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ onClick: onCancel }, { children: "\u041E\u0442\u043C\u0435\u043D\u0430" }), "cancel"),
                    (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: 'primary', onClick: onOk, disabled: disable }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }), "ok"),
                ] }, { children: (0, jsx_runtime_1.jsx)(PlaceholderLibraryAddTree_1.PlaceholderLibraryAddTree, { rootFolders: rootFolders, setSelectedFolder: setSelectedFolder, setSelectedKind: setSelectedKind }) })), (0, jsx_runtime_1.jsx)(PlaceholdersLibraryCreatingFolder_1.PlaceholdersLibraryCreatingFolder, { currentFolder: currentFolder })] }));
};
exports.PlaceholderLibraryAdd = PlaceholderLibraryAdd;
