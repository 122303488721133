"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FolderViewList = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var react_1 = require("react");
var constants_1 = require("../utils/constants");
var columns_1 = require("./columns");
var CheckboxListItem_1 = require("../CheckboxListItem");
var FolderViewList = function (props) {
    var selectedFolder = props.selectedFolder, isBulkCheckboxMixed = props.isBulkCheckboxMixed, setSearchValue = props.setSearchValue, userId = props.userId, bulkEntity = props.bulkEntity, list = props.list, isDocument = props.isDocument, getDocumentsList = props.getDocumentsList, choosedFilters = props.choosedFilters, toggleAllBulked = props.toggleAllBulked, upperLevelFolder = props.upperLevelFolder, selectedFolderBulked = props.selectedFolderBulked, canEdit = props.canEdit, selectFolder = props.selectFolder, toggleModal = props.toggleModal, setSingleEntityAction = props.setSingleEntityAction, page = props.page, pageSize = props.pageSize, setPage = props.setPage, setPageSize = props.setPageSize, searchValue = props.searchValue;
    var items = isDocument ? list.items : list;
    var _a = (0, react_1.useState)(false), saving = _a[0], setSaving = _a[1];
    var renderedColumns = (0, columns_1.getColumns)({
        userId: userId,
        selectedFolder: selectedFolder,
        upperLevelFolder: upperLevelFolder,
        canEdit: canEdit,
        selectFolder: selectFolder,
        toggleModal: toggleModal,
        setSingleEntityAction: setSingleEntityAction,
        setSaving: setSaving,
        setSearchValue: setSearchValue,
        searchValue: searchValue,
    });
    var handlePagination = function (pagination) {
        var pageSize = pagination.pageSize, current = pagination.current;
        setPage(current);
        setPageSize(pageSize);
        if (isDocument) {
            var sendingFilters_1 = [];
            choosedFilters.forEach(function (_a) {
                var filterField = _a.filterField, filterValue = _a.filterValue;
                if (filterField !== constants_1.defaultFieldValue &&
                    filterValue !== constants_1.defaultFilterValue) {
                    sendingFilters_1[filterField] = filterValue;
                }
            });
            getDocumentsList(selectedFolder.folderId, page, pageSize, sendingFilters_1);
        }
    };
    var tablePagination = {
        pageSize: pageSize || 10,
        current: page || 1,
        total: isDocument ? list.total : items.length,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        showSizeChanger: true,
        showSinglePage: false,
    };
    return ((0, jsx_runtime_1.jsx)(antd_1.Table, { loading: saving, rowSelection: {
            renderCell: function (checked, record) {
                return (0, CheckboxListItem_1.CheckboxListItem)(record, isBulkCheckboxMixed, selectedFolderBulked, canEdit, bulkEntity);
            },
            onChange: function (keys, selectedRows) {
                return toggleAllBulked(selectedRows.length > 0);
            },
            getCheckboxProps: function () { return ({
                disabled: !canEdit,
            }); },
        }, dataSource: items, 
        // @ts-ignore
        columns: renderedColumns, rowKey: function (record) { return record.id; }, pagination: tablePagination, onChange: handlePagination, size: 'middle' }));
};
exports.FolderViewList = FolderViewList;
