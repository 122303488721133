"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibraryHeader = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var antd_1 = require("antd");
var icons_1 = require("@ant-design/icons");
var placeholdersLibraryUtils_1 = require("../../../shared/utils/placeholdersLibraryUtils");
var Header = antd_1.Layout.Header;
var PlaceholderLibraryHeader = function () {
    var history = (0, react_router_dom_1.useHistory)();
    var goBack = (0, react_1.useCallback)(function () {
        var _a;
        // @ts-ignore
        var path = (_a = history.location.state) === null || _a === void 0 ? void 0 : _a.address;
        history.replace(path);
        location.reload();
    }, []);
    return ((0, jsx_runtime_1.jsx)(Header, __assign({ className: "phadm-site-layout-background", style: { padding: "".concat(0, " ").concat(30, "px") } }, { children: (0, jsx_runtime_1.jsx)(antd_1.Row, __assign({ justify: 'end' }, { children: (0, jsx_runtime_1.jsxs)(antd_1.Col, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: 'phadm-user' }, { children: placeholdersLibraryUtils_1.userName })), (0, jsx_runtime_1.jsxs)(antd_1.Button, __assign({ type: "primary", size: 'large', onClick: function () { return goBack(); } }, { children: [(0, jsx_runtime_1.jsx)(icons_1.PoweroffOutlined, {}), "\u0412\u044B\u0445\u043E\u0434"] }))] }) })) })));
};
exports.PlaceholderLibraryHeader = PlaceholderLibraryHeader;
