"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../routes");
var storage_1 = require("../../../../shared/utils/storage");
var store_1 = require("../../../../store");
var actions_1 = require("../../../store/login/actions");
var LoginOidCallbackPage = function () {
    var dispatch = (0, store_1.useAppDispatch)();
    var history = (0, react_router_dom_1.useHistory)();
    (0, react_1.useEffect)(function () {
        dispatch((0, actions_1.checkOIDAuth)()).then(function (_a) {
            var payload = _a.payload;
            if (payload && payload.result.responsibilities) {
                var oldPath = (0, storage_1.getLocalStorage)(['redirect_url']).redirect_url;
                history.replace("".concat(routes_1.routes.home).concat(oldPath));
                (0, storage_1.removeFromLocalStorage)(['redirect_url']);
            }
            else
                history.push(routes_1.routes.login);
        });
    }, []);
    return (0, jsx_runtime_1.jsx)(antd_1.Spin, { size: "large" });
};
exports.default = LoginOidCallbackPage;
