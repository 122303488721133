"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectedSectionGroups = void 0;
var parseSections_1 = require("./parseSections");
var getSelectedSectionGroups = function (selectedBlocks, sections) {
    var getSectionGroups = function (parsedSections, id, result) {
        for (var _i = 0, _a = parsedSections[id]; _i < _a.length; _i++) {
            var block = _a[_i];
            if (block.type === 'section') {
                getSectionGroups(parsedSections, block.id, result);
            }
            else {
                result.push(block);
            }
        }
    };
    var parsedSections = (0, parseSections_1.parseSections)(sections);
    var selectedIds = Object.keys(parsedSections).filter(function (x) { return selectedBlocks.includes(x); });
    var result = [];
    selectedIds.forEach(function (id) {
        getSectionGroups(parsedSections, id, result);
    });
    return result;
};
exports.getSelectedSectionGroups = getSelectedSectionGroups;
