"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var UploadIcon = function (_a) {
    var _b = _a.style, style = _b === void 0 ? {} : _b, 
    // fill = '#fff',
    _c = _a.width, 
    // fill = '#fff',
    width = _c === void 0 ? '100%' : _c, _d = _a.className, className = _d === void 0 ? '' : _d, _e = _a.height, height = _e === void 0 ? '100%' : _e, _f = _a.viewBox, viewBox = _f === void 0 ? '0 0 18 18' : _f;
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: width, style: style, height: height, viewBox: viewBox, className: className, xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, { children: [(0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsx)("path", { id: "a", d: "M8 8.588V2H2h6V0h2v2h6-6v6.612l1.602-1.587 1.414 1.414L9 12.397 5.005 8.415 6.42 7.001 8 8.588zM18 6v8.003a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6v2h2v6.003h14V8h2V6z" }) }), (0, jsx_runtime_1.jsxs)("g", __assign({ fill: "none", fillRule: "evenodd" }, { children: [(0, jsx_runtime_1.jsx)("mask", __assign({ id: "b", fill: "#fff" }, { children: (0, jsx_runtime_1.jsx)("use", { xlinkHref: "#a" }) })), (0, jsx_runtime_1.jsx)("use", { fill: "#D8D8D8", xlinkHref: "#a" }), (0, jsx_runtime_1.jsx)("g", __assign({ className: "upload-icon", mask: "url(#b)" }, { children: (0, jsx_runtime_1.jsx)("path", { d: "M-7-8h32v32H-7z" }) }))] }))] })));
};
exports.UploadIcon = UploadIcon;
exports.UploadIcon.defaultProps = {
    style: {},
    // fill: '#fff',
    width: '100%',
    className: '',
    height: '100%',
    viewBox: '0 0 18 18',
};
