"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.moduleName = void 0;
var DocumentTypes_1 = require("./DocumentTypes/DocumentTypes");
exports.moduleName = 'document';
var initialState = {
    data: {
        rows: [],
        filters: [],
        orders: [],
        chosenFolderId: 'null',
        openCalculationPage: false,
    },
    documentId: null,
    templateId: null,
    elementId: null,
    blocks: [],
    sections: [],
    placeholders: [],
    childPlaceholders: [],
    selectedSections: [],
    templateInfo: {},
    pdcList: [],
    pdcListAdditionalInfo: {},
    isPDCListLoading: false,
    isDocumentPDCChanging: false,
    placeholderValues: [],
    isPlaceholderUpdating: false,
    structure: 'template',
    pageInfo: {
        page: 0,
        pageSize: 10,
        totalPage: 1,
        tableSize: 0,
    },
    pageSize: 10,
    fetching: false,
    _error: false,
    _errorMessage: '',
    isPlaceholderLoading: false,
    loadingId: '',
    sectionActiveOptions: null,
    directoryDictionary: {},
};
function root(state, action) {
    var _a, _b, _c;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DocumentTypes_1.DocumentTypes.GET_DATA_REQUEST:
            return __assign(__assign({}, state), { fetching: true, _error: false });
        case DocumentTypes_1.DocumentTypes.GET_DATA_SUCCESS:
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { rows: action.payload.rows }), pageInfo: __assign(__assign({}, action.payload.pageInfo), { page: action.payload.pageInfo.currentPage }), fetching: false, _error: false, _errorMessage: '' });
        case DocumentTypes_1.DocumentTypes.GET_DATA_FAILURE:
            // @ts-ignore
            return __assign(__assign({}, state), { fetching: false, _error: true, _errorMessage: action.payload.errorMessage });
        case DocumentTypes_1.DocumentTypes.SET_PAGE:
            return __assign(__assign({}, state), { pageInfo: __assign(__assign({}, state.pageInfo), { page: action.payload }) });
        case DocumentTypes_1.DocumentTypes.SET_PAGE_SIZE:
            return __assign(__assign({}, state), { pageSize: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_LOADING:
            return __assign(__assign({}, state), { isPlaceholderLoading: action.payload.loading, loadingId: action.payload.id });
        case DocumentTypes_1.DocumentTypes.SET_DRAFT_TEMPLATE_NAME:
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { draftTemplateName: action.payload }) });
        case DocumentTypes_1.DocumentTypes.SET_TEMPLATE_INFO:
            return __assign(__assign({}, state), { templateInfo: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_PDC_LIST:
            return __assign(__assign({}, state), { pdcList: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_PDC_LIST_ADDITIONAL_INFO:
            return __assign(__assign({}, state), { pdcListAdditionalInfo: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_PDC_LIST_LOADING:
            return __assign(__assign({}, state), { isPDCListLoading: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_OBJECT_BLOCKS:
            return __assign(__assign({}, state), { blocks: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_OBJECT_SECTIONS:
            return __assign(__assign({}, state), { sections: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_OBJECT_PLACEHOLDERS:
            return __assign(__assign({}, state), { placeholders: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_PLACEHOLDER_VALUES:
            return __assign(__assign({}, state), { placeholderValues: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_PLACEHOLDER_UPDATING:
            return __assign(__assign({}, state), { isPlaceholderUpdating: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_CHILD_PLACEHOLDERS:
            return __assign(__assign({}, state), { childPlaceholders: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_TEMPLATE_ID:
            return __assign(__assign({}, state), { templateId: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_DOCUMENT_ID:
            return __assign(__assign({}, state), { documentId: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_ELEMENT_ID:
            return __assign(__assign({}, state), { elementId: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_SELECTED_SECTION: {
            return __assign(__assign({}, state), { selectedSections: __assign(__assign({}, state.selectedSections), (_a = {}, _a[action.payload.section] = action.payload.value, _a)) });
        }
        case DocumentTypes_1.DocumentTypes.SET_SELECTED_SECTIONS: {
            return __assign(__assign({}, state), { selectedSections: __assign(__assign({}, state.selectedSections), action.payload) });
        }
        case DocumentTypes_1.DocumentTypes.SET_DIRECTORY_DICTIONARY: {
            return __assign(__assign({}, state), { directoryDictionary: __assign(__assign({}, state.directoryDictionary), (_b = {}, _b[action.payload.key] = action.payload.dictionary, _b)) });
        }
        case DocumentTypes_1.DocumentTypes.CLEAR_STATE:
            return __assign(__assign({}, state), { blocks: [], sections: [], selectedSections: [], placeholderValues: [] });
        case DocumentTypes_1.DocumentTypes.CHANGE_PDC_REQUEST:
            return __assign(__assign({}, state), { isDocumentPDCChanging: true });
        case DocumentTypes_1.DocumentTypes.CHANGE_PDC_SUCCESS:
        case DocumentTypes_1.DocumentTypes.CHANGE_PDC_FAILURE:
            return __assign(__assign({}, state), { isDocumentPDCChanging: false });
        case DocumentTypes_1.DocumentTypes.SET_SECTION_ACTIVE_OPTIONS:
            return __assign(__assign({}, state), { sectionActiveOptions: action.payload });
        case DocumentTypes_1.DocumentTypes.SET_SECTION_ACTIVE_OPTION:
            return __assign(__assign({}, state), { sectionActiveOptions: __assign(__assign({}, state.sectionActiveOptions), (_c = {}, _c[action.payload.sectionId] = action.payload.value, _c)) });
        default:
            return state;
    }
}
exports.default = root;
