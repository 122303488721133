"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholdersLibraryMain = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var react_1 = require("react");
var S = __importStar(require("./PlaceholdersLibraryMainStyle"));
var PlaceholdersLibraryTree_1 = require("../PlaceholdersLibraryTree/PlaceholdersLibraryTree");
var react_redux_1 = require("react-redux");
var rootGetters_1 = require("../../../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../../../Root/redux/rootActionCreators/rootActionCreators");
var PlaceholderLibrarySearch_1 = require("../PlaceholdersLibraryTree/PlaceholderLibrarySearch");
var findItems = function (folders, entities) {
    var folderIds = [];
    var findParents = function (folderId) {
        var folder = folders.find(function (item) { return item.id === folderId; });
        // failed to find parent folder for entity
        if (!folder) {
            return;
        }
        if (!folderIds.some(function (item) { return item === folder.id; })) {
            folderIds.push(folder.id);
        }
        if (folder.parentId) {
            findParents(folder.parentId);
        }
    };
    // // recursively find parent folders for each entity
    entities === null || entities === void 0 ? void 0 : entities.forEach(function (entity) { return findParents(entity.parentId); });
    var placeholderIds = (entities === null || entities === void 0 ? void 0 : entities.map(function (entity) { return entity === null || entity === void 0 ? void 0 : entity.id; })) || [];
    return __spreadArray(__spreadArray([], folderIds, true), placeholderIds, true);
};
var searchMask = function (structureIds, item) { return structureIds === null || structureIds === void 0 ? void 0 : structureIds.includes(item.id); };
var PlaceholdersLibraryMain = function (props) {
    var _a;
    var _b = props.searchBy, searchBy = _b === void 0 ? '' : _b, onAction = props.onAction;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, react_1.useState)(''), searchValue = _c[0], setSearchValue = _c[1];
    var _d = (0, react_1.useState)([]), searchResults = _d[0], setSearchResults = _d[1];
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var placeholderTemplateFolderListObj = presentState === null || presentState === void 0 ? void 0 : presentState.placeholderTemplateFolderList;
    var placeholderForInsertion = (_a = presentState === null || presentState === void 0 ? void 0 : presentState.placeholderForInsertion) === null || _a === void 0 ? void 0 : _a.data;
    var actuallyPlaceholdersStructure = (presentState === null || presentState === void 0 ? void 0 : presentState.actuallyPlaceholdersStructure) || [];
    var phTemplatesListExemplars = presentState === null || presentState === void 0 ? void 0 : presentState.phTemplatesListExemplars;
    var folders = placeholderTemplateFolderListObj;
    // получаем по поиску данные и сохраняем шаблоны по текущему ключу поиска
    (0, react_1.useEffect)(function () {
        var searchTemplates = (phTemplatesListExemplars === null || phTemplatesListExemplars === void 0 ? void 0 : phTemplatesListExemplars.filter(function (template) { var _a; return ((_a = template === null || template === void 0 ? void 0 : template.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf(searchValue === null || searchValue === void 0 ? void 0 : searchValue.toLowerCase())) > -1; })) || [];
        setSearchResults(searchTemplates);
    }, [searchValue]);
    var onFinish = function (e) {
        var newValue = e.target.value;
        setSearchValue(newValue);
        dispatch((0, rootActionCreators_1.setPlaceholderForInsertion)(null));
    };
    var onPlaceholdersLibModalClose = (0, react_1.useCallback)(function () {
        dispatch((0, rootActionCreators_1.setModalType)(false));
        dispatch((0, rootActionCreators_1.setPlaceholderForInsertion)(null));
        dispatch((0, rootActionCreators_1.setTargetsInit)(null));
        dispatch((0, rootActionCreators_1.setSelectedPlaceholder)(null));
    }, []);
    (0, react_1.useEffect)(function () {
        var _a;
        var structureIds = findItems(folders, searchResults);
        var searchResultsByMask = (_a = __spreadArray(__spreadArray([], folders, true), searchResults, true)) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return searchMask(structureIds, item); });
        dispatch((0, rootActionCreators_1.setActuallyPlaceholdersStructure)(searchResultsByMask));
    }, [phTemplatesListExemplars, searchResults]);
    var addLibraryPlaceholder = function (e) {
        onAction('libraryPlaceholder', null, e, placeholderForInsertion);
        dispatch((0, rootActionCreators_1.setModalType)(false));
        dispatch((0, rootActionCreators_1.setPlaceholderForInsertion)(null));
        dispatch((0, rootActionCreators_1.setTargetsInit)(null));
        dispatch((0, rootActionCreators_1.setSelectedPlaceholder)(null));
    };
    return ((0, jsx_runtime_1.jsx)(S.Container, { children: (0, jsx_runtime_1.jsx)(S.ShadowContainer, { children: (0, jsx_runtime_1.jsxs)(S.ModalContainer, __assign({ className: 'insert-library-container' }, { children: [(0, jsx_runtime_1.jsxs)(S.ListContainer, { children: [(0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(S.Title, { children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u041F\u0425" }) }), searchBy && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "folder-tree__search" }, { children: (0, jsx_runtime_1.jsx)(antd_1.Form, __assign({ name: "basic", onChange: function (e) { return onFinish(e); }, autoComplete: "off", onKeyPress: function (e) { return console.log(e); } }, { children: (0, jsx_runtime_1.jsx)(antd_1.Form.Item, __assign({ name: "searchname" }, { children: (0, jsx_runtime_1.jsx)(antd_1.Input, {}) })) })) }))), (actuallyPlaceholdersStructure === null || actuallyPlaceholdersStructure === void 0 ? void 0 : actuallyPlaceholdersStructure.length) ? ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: searchValue ? ((0, jsx_runtime_1.jsx)(PlaceholderLibrarySearch_1.PlaceholderLibrarySearch, { structure: actuallyPlaceholdersStructure, addLibraryPlaceholder: addLibraryPlaceholder })) : ((0, jsx_runtime_1.jsx)(PlaceholdersLibraryTree_1.PlaceholdersLibraryTree, { placeholderTemplateFolders: actuallyPlaceholdersStructure, insertion: true, addLibraryPlaceholder: addLibraryPlaceholder })) })) : ((0, jsx_runtime_1.jsx)(antd_1.Empty, { image: antd_1.Empty.PRESENTED_IMAGE_SIMPLE }))] }), (0, jsx_runtime_1.jsx)(S.ActionsContainer, { children: (0, jsx_runtime_1.jsx)(S.ButtonsContainer, { children: (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ style: { marginRight: '1%' }, onClick: onPlaceholdersLibModalClose }, { children: "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C" })) }) })] })) }) }));
};
exports.PlaceholdersLibraryMain = PlaceholdersLibraryMain;
