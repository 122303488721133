"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibraryDelete = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var react_1 = require("react");
var PlaceholderTemplateApi_1 = require("../../../../api/placeholderLibraryApi/PlaceholderTemplate/PlaceholderTemplateApi");
var PlaceholderTemplateFolderApi_1 = require("../../../../api/placeholderLibraryApi/PlaceholderTemplateFolder/PlaceholderTemplateFolderApi");
var react_redux_1 = require("react-redux");
var rootActionCreators_1 = require("../../../../Root/redux/rootActionCreators/rootActionCreators");
var PlaceholderLibraryDelete = function (_a) {
    var props = __rest(_a, []);
    var currentFolder = props.currentFolder, openDeleteModal = props.openDeleteModal, setOpenDeleteModal = props.setOpenDeleteModal, removeItem = props.removeItem, setRemoveItem = props.setRemoveItem;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_1.useState)(false), deleteConfirmation = _b[0], setDeleteConfirmation = _b[1];
    var onDeletingCancel = function () {
        setOpenDeleteModal(false);
        setRemoveItem(null);
    };
    var onDeletingOk = function () {
        setOpenDeleteModal(false);
        setDeleteConfirmation(true);
    };
    var onConfirmationCancel = function () {
        setDeleteConfirmation(false);
        setRemoveItem(null);
    };
    var onConfirmationOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var key, type, id;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!removeItem) return [3 /*break*/, 4];
                    key = removeItem.key, type = removeItem.type, id = removeItem.id;
                    if (!(type === 'placeholder')) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, PlaceholderTemplateApi_1.placeholderTemplateDelete)({ id: id })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, (0, PlaceholderTemplateFolderApi_1.placeholderTemplateFolderDelete)({ id: key })];
                case 3:
                    _a.sent();
                    // убираем из структуры папок
                    dispatch((0, rootActionCreators_1.deletePlaceholderTemplateFolderList)(key));
                    _a.label = 4;
                case 4:
                    setDeleteConfirmation(false);
                    if (!currentFolder) return [3 /*break*/, 6];
                    return [4 /*yield*/, (0, PlaceholderTemplateFolderApi_1.placeholderTemplateFolderContent)({ id: currentFolder === null || currentFolder === void 0 ? void 0 : currentFolder.id }).then(function (response) {
                            return dispatch((0, rootActionCreators_1.setPlaceholderTemplateFolderContent)(response));
                        })];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6:
                    if ((removeItem === null || removeItem === void 0 ? void 0 : removeItem.type) === 'placeholder') {
                        antd_1.message.success('Плейсхолдер успешно удален!');
                    }
                    else {
                        antd_1.message.success('Папка с его содержимым успешно удалена!');
                    }
                    setRemoveItem(null);
                    return [2 /*return*/];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(antd_1.Modal, __assign({ open: openDeleteModal, title: 'Внимание!', onCancel: onDeletingCancel, onOk: onDeletingOk, width: '600px' }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: 'phadm-message' }, { children: (removeItem === null || removeItem === void 0 ? void 0 : removeItem.type) === 'placeholder'
                        ? 'Вы действительно хотите удалить ПХ?'
                        : 'Вы действительно хотите удалить папку?' })) })), (0, jsx_runtime_1.jsx)(antd_1.Modal, __assign({ open: deleteConfirmation, title: 'Внимание!', onCancel: onConfirmationCancel, onOk: onConfirmationOk, width: '600px' }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: 'phadm-message' }, { children: (removeItem === null || removeItem === void 0 ? void 0 : removeItem.type) === 'placeholder'
                        ? 'Ваш ПХ будет удален безвозвратно. Вы уверены?'
                        : 'Ваша папка будет удалена безвозвратно со всем содержимым, вы уверены?' })) }))] }));
};
exports.PlaceholderLibraryDelete = PlaceholderLibraryDelete;
