"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableColumn = void 0;
var TableConstants_1 = require("./TableConstants");
var TableColumn = /** @class */ (function () {
    //#endregion
    function TableColumn(ownerTable, key) {
        this.ownerTable = ownerTable;
        this.key = key;
        this.width = TableConstants_1.TableConstants.ColumnMinWidth;
    }
    //#region Static methods
    TableColumn.convertColumnOldName = function (dataIndex) {
        return dataIndex.replaceAll('index_', TableConstants_1.TableConstants.PrefixColumnKey);
    };
    TableColumn.fromColumnOld = function (ownerTable, columnOld) {
        var key = this.convertColumnOldName(columnOld.dataIndex);
        var column = new TableColumn(ownerTable, key);
        column.width = columnOld.width * TableConstants_1.TableConstants.CoeffPixelToMm;
        return column;
    };
    TableColumn.fromColumnsOld = function (ownerTable, columnsOld) {
        var columns = columnsOld.map(function (column) { return TableColumn.fromColumnOld(ownerTable, column); });
        return columns;
    };
    TableColumn.fromTableColumnDto = function (ownerTable, columnDto) {
        var column = new TableColumn(ownerTable, columnDto.key);
        column.width = columnDto.width;
        return column;
    };
    TableColumn.fromTableColumnsDto = function (ownerTable, columnsDto) {
        var columns = columnsDto.map(function (column) { return TableColumn.fromTableColumnDto(ownerTable, column); });
        return columns;
    };
    TableColumn.prototype.getCell = function (rowKey) {
        var _this = this;
        var rowTable = this.ownerTable.rows.find(function (x) { return x.key == rowKey; });
        if (rowTable) {
            return rowTable.cells.find(function (x) { return x.columnKey === _this.key; });
        }
        return undefined;
    };
    TableColumn.prototype.getCellByIndex = function (rowIndex) {
        var _this = this;
        var rowTable = this.ownerTable.rows[rowIndex];
        if (rowTable) {
            var columnIndex = this.ownerTable.columns.findIndex(function (x) { return x == _this; });
            return rowTable.cells[columnIndex];
        }
        return undefined;
    };
    TableColumn.prototype.copy = function (ownerTable) {
        var copyColumn = new TableColumn(ownerTable, this.key);
        copyColumn.width = this.width;
        return copyColumn;
    };
    TableColumn.prototype.toTableColumnDto = function () {
        var columnDto = {
            key: this.key,
            width: this.width,
        };
        return columnDto;
    };
    return TableColumn;
}());
exports.TableColumn = TableColumn;
