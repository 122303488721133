"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibraryEdit = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var PlaceholderTemplateApi_1 = require("../../../../api/placeholderLibraryApi/PlaceholderTemplate/PlaceholderTemplateApi");
var PlaceholderTemplateFolderApi_1 = require("../../../../api/placeholderLibraryApi/PlaceholderTemplateFolder/PlaceholderTemplateFolderApi");
var react_redux_1 = require("react-redux");
var rootGetters_1 = require("../../../../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../../../../Root/redux/rootActionCreators/rootActionCreators");
var CustomizedForm = function (_a) {
    var onChange = _a.onChange, fields = _a.fields;
    return ((0, jsx_runtime_1.jsx)(antd_1.Form, __assign({ name: "global_state", layout: "inline", fields: fields, onFieldsChange: function (_, allFields) {
            onChange(allFields);
        } }, { children: (0, jsx_runtime_1.jsx)(antd_1.Form.Item, __assign({ name: "foldername", rules: [{ required: true, message: 'Введите название папки!' }] }, { children: (0, jsx_runtime_1.jsx)(antd_1.Input, { maxLength: 250, style: { width: "300px" } }) })) })));
};
var PlaceholderLibraryEdit = function (_a) {
    var props = __rest(_a, []);
    var changeItemModal = props.changeItemModal, setChangeItemModal = props.setChangeItemModal, fields = props.fields, setFields = props.setFields, currentFolder = props.currentFolder, currentRecord = props.currentRecord;
    var dispatch = (0, react_redux_1.useDispatch)();
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var placeholderTemplateFolderList = presentState === null || presentState === void 0 ? void 0 : presentState.placeholderTemplateFolderList;
    var editModalCancel = function () {
        setChangeItemModal(false);
    };
    var editModalOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newName, result, currentTemplate, newData, findFolder, editedFolder;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    newName = (_a = fields[0]) === null || _a === void 0 ? void 0 : _a.value;
                    if (!newName) return [3 /*break*/, 6];
                    if (!(currentRecord === null || currentRecord === void 0 ? void 0 : currentRecord.template)) return [3 /*break*/, 3];
                    return [4 /*yield*/, (0, PlaceholderTemplateApi_1.placeholderTemplateAlias)([currentRecord === null || currentRecord === void 0 ? void 0 : currentRecord.key])];
                case 1:
                    result = _b.sent();
                    currentTemplate = result === null || result === void 0 ? void 0 : result.templates[0];
                    newData = {
                        // @ts-ignore
                        data: __assign(__assign({}, currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.data), { name: newName, title: newName, initial_title: newName }),
                        // @ts-ignore
                        id: currentRecord === null || currentRecord === void 0 ? void 0 : currentRecord.key,
                        name: newName,
                    };
                    return [4 /*yield*/, (0, PlaceholderTemplateApi_1.placeholderTemplateEdit)(__assign({}, newData))];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, (0, PlaceholderTemplateFolderApi_1.placeholderTemplateFolderEdit)({
                        id: currentRecord === null || currentRecord === void 0 ? void 0 : currentRecord.key,
                        name: newName,
                    })];
                case 4:
                    _b.sent();
                    findFolder = placeholderTemplateFolderList === null || placeholderTemplateFolderList === void 0 ? void 0 : placeholderTemplateFolderList.find(function (folder) { return folder.id === (currentRecord === null || currentRecord === void 0 ? void 0 : currentRecord.key); });
                    editedFolder = __assign(__assign({}, findFolder), { name: newName });
                    dispatch((0, rootActionCreators_1.editPlaceholderTemplateFolderList)(editedFolder));
                    _b.label = 5;
                case 5:
                    setChangeItemModal(false);
                    if (currentFolder) {
                        (0, PlaceholderTemplateFolderApi_1.placeholderTemplateFolderContent)({ id: currentFolder === null || currentFolder === void 0 ? void 0 : currentFolder.id }).then(function (response) {
                            return dispatch((0, rootActionCreators_1.setPlaceholderTemplateFolderContent)(response));
                        });
                    }
                    _b.label = 6;
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsx)(antd_1.Modal, __assign({ open: changeItemModal, title: 'Изменить', width: '600px', onCancel: editModalCancel, onOk: editModalOk }, { children: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(CustomizedForm, { fields: fields, onChange: function (newFields) {
                    setFields(newFields);
                } }) }) })));
};
exports.PlaceholderLibraryEdit = PlaceholderLibraryEdit;
