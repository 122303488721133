"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var TemplateMessage_1 = require("../Models/TemplateMessage");
var Controls_1 = require("../../models/Controls");
var signalR = __importStar(require("@microsoft/signalr"));
var SessionTemplate_1 = require("../../models/SessionTemplate");
/**
 * Хук для получение сообщений по шаблону по технологии SignalR
 * @returns Актуальное сообщение
 */
var useSignalRConnectorTemplate = function (currentUserControls) {
    var _a;
    var uri = "".concat(API_URL, "template");
    var token = localStorage.getItem('access_token');
    var userId = localStorage.getItem('userId');
    var templateId = (0, SessionTemplate_1.getTemplateIdFromLocation)();
    var sessionId = (_a = (0, SessionTemplate_1.getSessionTemplateId)(templateId)) !== null && _a !== void 0 ? _a : '';
    var _b = (0, react_1.useState)(null), message = _b[0], setMessage = _b[1];
    var connection;
    var initSignalR = function () {
        connection = new signalR.HubConnectionBuilder()
            .withUrl(uri, {
            withCredentials: false,
        })
            .withAutomaticReconnect()
            .build();
        // 2 часа
        connection.serverTimeoutInMilliseconds = 1000 * 60 * 60 * 2;
    };
    var invokeAttachToTemplate = function () {
        connection.invoke('AttachToTemplate', templateId, sessionId, userId)
            .then(function () {
            console.log('AttachToTemplate - Успешно');
        })
            .catch(function (err) { return console.error(err); });
    };
    var invokeDetachFromTemplate = function () {
        connection.invoke('DetachFromTemplate')
            .then(function () {
            console.log('DetachFromTemplate - Успешно');
        })
            .catch(function (err) { return console.error(err); });
    };
    (0, react_1.useEffect)(function () {
        if (!connection)
            initSignalR();
        if ((0, Controls_1.checkCanWriteControls)(currentUserControls)) {
            switch (connection.state) {
                case signalR.HubConnectionState.Connected:
                    {
                        invokeAttachToTemplate();
                    }
                    break;
                case signalR.HubConnectionState.Connecting:
                    {
                        setTimeout(function () { invokeAttachToTemplate(); }, 2000);
                    }
                    break;
                case signalR.HubConnectionState.Disconnected:
                    {
                        connection.start().then(function () {
                            invokeAttachToTemplate();
                        });
                    }
                    break;
            }
        }
        else {
            switch (connection.state) {
                case signalR.HubConnectionState.Connected:
                    {
                        invokeDetachFromTemplate();
                    }
                    break;
                case signalR.HubConnectionState.Connecting:
                    {
                        setTimeout(function () { invokeDetachFromTemplate(); }, 2000);
                    }
                    break;
                case signalR.HubConnectionState.Disconnected:
                    {
                        connection.start().then(function () {
                            invokeDetachFromTemplate();
                        });
                    }
                    break;
            }
        }
        connection.on("SendMessage", function (message) {
            if ((0, TemplateMessage_1.checkIsTemplateMessage)(message)) {
                setMessage(message);
                console.log('SignalRMessage:', message);
            }
        });
        return function () { if (connection)
            connection.stop(); };
    }, [token, currentUserControls]);
    return message;
};
exports.default = useSignalRConnectorTemplate;
