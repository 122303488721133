"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.errorHandler = void 0;
var antd_1 = require("antd");
var actions_1 = require("../Auth/store/login/actions");
var error401Handler = function () {
    var dispatch = window.store.dispatch;
    dispatch(actions_1.logout);
    location.reload();
};
var error500Handler = function (data) {
    console.error(data);
    antd_1.notification.error({
        message: "Ошибка",
        description: "Произошла ошибка на сервере"
    });
};
var error409Handler = function (data) {
    var _a;
    var errorInfo = data;
    if (errorInfo && errorInfo.errors && errorInfo.errors.length > 0) {
        for (var _i = 0, _b = errorInfo.errors; _i < _b.length; _i++) {
            var error = _b[_i];
            antd_1.notification.error({
                message: (_a = error.code) !== null && _a !== void 0 ? _a : 'Ошибка',
                description: error.description,
                duration: 5,
                style: { whiteSpace: 'pre-line' }
            });
        }
    }
    else {
        console.error(data);
        antd_1.notification.error({
            message: "Ошибка",
            description: "Конфликт запроса с текущим состоянием сервера"
        });
    }
};
var errorDefaultHandler = function (data) {
    var errors = data.errors;
    if (!errors)
        return;
    for (var _i = 0, errors_1 = errors; _i < errors_1.length; _i++) {
        var error = errors_1[_i];
        antd_1.notification.error({
            message: "Ошибка",
            description: error.description
        });
    }
};
var errorHandler = function (error) {
    var _a, _b, _c;
    var status = error && error.response && error.response.status;
    if (!status)
        return;
    switch (status) {
        case 401:
            error401Handler();
            break;
        case 409:
            error409Handler((_a = error.response) === null || _a === void 0 ? void 0 : _a.data);
            break;
        case 500:
            error500Handler((_b = error.response) === null || _b === void 0 ? void 0 : _b.data);
            break;
        default:
            errorDefaultHandler((_c = error.response) === null || _c === void 0 ? void 0 : _c.data);
            break;
    }
};
exports.errorHandler = errorHandler;
