// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sidebar-module__sidebar___9NRnj {\n    display: inline-block;\n    height: calc(100vh - 100px);\n    background-color: transparent;\n    overflow-y: auto;\n    flex-shrink: 0;\n    user-select: none;\n    direction: rtl;\n}\n\n.Sidebar-module__sidebar___9NRnj > * {\n  direction: ltr;\n}\n\n.Sidebar-module__wrapper___7K9cv {\n  margin: 0 0 50px;\n}\n\n.Sidebar-module__header___xJrCD {\n    margin: 5px 0px 25px 27px;\n}\n\n.Sidebar-module__name___ZEsMQ {\n    cursor: pointer;\n    display: inline-block;\n    font-size: 10px;\n    width: 179px;\n    margin: 15px;\n    font-family: \"Roboto\", sans-serif;\n    color: #ffffff;\n    width: auto;\n}\n\n.Sidebar-module__pages___AzScY a {\n  color: white;\n}\n\n.Sidebar-module__link___hbMAa {\n  margin: 6px 0px 15px 0px;\n  width: 234px;\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/shared/components/Sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,2BAA2B;IAC3B,6BAA6B;IAC7B,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,cAAc;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,qBAAqB;IACrB,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,iCAAiC;IACjC,cAAc;IACd,WAAW;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,YAAY;AACd","sourcesContent":[".sidebar {\n    display: inline-block;\n    height: calc(100vh - 100px);\n    background-color: transparent;\n    overflow-y: auto;\n    flex-shrink: 0;\n    user-select: none;\n    direction: rtl;\n}\n\n.sidebar > * {\n  direction: ltr;\n}\n\n.wrapper {\n  margin: 0 0 50px;\n}\n\n.header {\n    margin: 5px 0px 25px 27px;\n}\n\n.name {\n    cursor: pointer;\n    display: inline-block;\n    font-size: 10px;\n    width: 179px;\n    margin: 15px;\n    font-family: \"Roboto\", sans-serif;\n    color: #ffffff;\n    width: auto;\n}\n\n.pages a {\n  color: white;\n}\n\n.link {\n  margin: 6px 0px 15px 0px;\n  width: 234px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "Sidebar-module__sidebar___9NRnj",
	"wrapper": "Sidebar-module__wrapper___7K9cv",
	"header": "Sidebar-module__header___xJrCD",
	"name": "Sidebar-module__name___ZEsMQ",
	"pages": "Sidebar-module__pages___AzScY",
	"link": "Sidebar-module__link___hbMAa"
};
export default ___CSS_LOADER_EXPORT___;
