"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.moduleName = void 0;
var DocumentTableTypes_1 = require("./DocumentTypes/DocumentTableTypes");
exports.moduleName = 'documents';
var initialState = {
    data: {
        rows: [],
        filters: [],
        orders: [],
    },
    catalogs: {
        regions: [],
        rules: [],
        clusters: [],
        indicators: [],
        complex_indicators: [],
    },
    structure: 'document',
    pageInfo: {
        page: 0,
        pageSize: 10,
        totalPage: 1,
        tableSize: 0,
    },
    hiddenGroups: [],
    pageSize: 10,
    fetching: false,
    _error: false,
    _errorMessage: '',
};
function dataTable(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DocumentTableTypes_1.DocumentTableTypes.GET_DATA_REQUEST:
            return __assign(__assign({}, state), { fetching: true, _error: false });
        case DocumentTableTypes_1.DocumentTableTypes.GET_DATA_SUCCESS:
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { rows: action.payload.list }), pageInfo: __assign(__assign({}, action.payload.pageInfo), { page: action.payload.pageInfo.currentPage }), fetching: false, _error: false, _errorMessage: '' });
        case DocumentTableTypes_1.DocumentTableTypes.GET_DATA_FAILURE:
            // @ts-ignore
            return __assign(__assign({}, state), { fetching: false, _error: true, _errorMessage: action.payload.errorMessage });
        case DocumentTableTypes_1.DocumentTableTypes.SET_PAGE:
            return __assign(__assign({}, state), { pageInfo: __assign(__assign({}, state.pageInfo), { page: action.payload }) });
        case DocumentTableTypes_1.DocumentTableTypes.SET_PAGE_SIZE:
            return __assign(__assign({}, state), { pageSize: action.payload });
        case DocumentTableTypes_1.DocumentTableTypes.SET_FILTERS:
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { filters: action.payload }) });
        default:
            return state;
    }
}
exports.default = dataTable;
