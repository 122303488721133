"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultFilterValue = exports.defaultFieldValue = exports.fieldsForFilter = exports.excludingFilters = exports.initialFiltersValues = exports.initialSortOptions = exports.initialStateFilter = void 0;
exports.initialStateFilter = {
    filterField: 'Выберите фильтр',
    filterValue: 'Введите значение',
    filterFieldLabel: 'Выберите фильтр',
    filterFieldSelected: false,
    filterValueSelected: false,
    filteredData: [],
    checkedList: [],
    isSent: false,
    isFiltered: false,
};
exports.initialSortOptions = {
    dateCreated: undefined,
    latestChangeDate: undefined,
    dateCreatedInitial: true,
    latestChangeDateInitial: true,
    sortingField: '',
};
exports.initialFiltersValues = {
    categoryName: null,
    docDate: null,
    docNumber: null,
    name: null,
    parentDocDate: null,
    parentDocNumber: null,
    parentName: null,
};
exports.excludingFilters = {
    contractNumber: ['udmsCardId', 'parentContrNumb', 'parentCard'],
    udmsCardId: ['contractNumber', 'parentContrNumb', 'parentCard'],
    parentContrNumb: ['contractNumber', 'udmsCardId', 'parentCard'],
    parentCard: ['contractNumber', 'udmsCardId', 'parentContrNumb'],
};
exports.fieldsForFilter = {
    contract: [{
            nameField: 'Номер договора',
            valueField: 'contractNumber',
        }, {
            nameField: 'id ЕСУД',
            valueField: 'udmsCardId',
        }],
    additionalAgreement: [{
            nameField: 'Дата договора',
            valueField: 'docDate',
        }, {
            nameField: 'Номер договора',
            valueField: 'docNumber',
        },
        {
            nameField: 'Название договора',
            valueField: 'name',
        },
        {
            nameField: 'Дата родительского договора',
            valueField: 'parentDocDate',
        },
        {
            nameField: 'Номер родительского договора',
            valueField: 'parentDocNumber',
        },
        {
            nameField: 'Имя родительского договора',
            valueField: 'parentName'
        }],
};
exports.defaultFieldValue = 'Выберите фильтр';
exports.defaultFilterValue = 'Введите значение';
