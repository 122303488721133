"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFormulaSelectView = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var compatible_1 = require("@ant-design/compatible");
var antd_1 = require("antd");
var CustomFormulaSelectUtils_1 = require("./CustomFormulaSelectUtils");
var CustomFormulaSelectView = function (_a) {
    var props = __rest(_a, []);
    var options = props.options, onCustomFormulaChange = props.onCustomFormulaChange, currentFormula = props.currentFormula, isDisabled = props.isDisabled;
    var currentParameters = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.parameters;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "panel__row" }, { children: [(0, jsx_runtime_1.jsx)("label", __assign({ htmlFor: "place_holder_format" }, { children: " \u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043F\u043E\u043B\u0435 \u0441\u043E \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435\u043C " })), (0, jsx_runtime_1.jsx)(compatible_1.Form.Item, __assign({ className: "row_form__item form_item_wrapper" }, { children: (0, jsx_runtime_1.jsx)(antd_1.Select, { className: "panel__select", optionFilterProp: "children", filterOption: function (input, option) { return (0, CustomFormulaSelectUtils_1.filterOptions)(input, option); }, onChange: onCustomFormulaChange, disabled: isDisabled, showSearch: true, value: "", options: options }) })), (currentParameters === null || currentParameters === void 0 ? void 0 : currentParameters.length) === 10
                ? (0, jsx_runtime_1.jsx)(antd_1.Alert, { message: CustomFormulaSelectUtils_1.parametersCountNotification, type: "warning", showIcon: true, closable: true })
                : null] })));
};
exports.CustomFormulaSelectView = CustomFormulaSelectView;
