"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDirectualResponseOdd = exports.parseDirectualResponse = void 0;
var mergeFetchToObj_1 = require("./mergeFetchToObj");
function parseDirectualResponse(response) {
    var list = response.result.list;
    return __assign(__assign({}, response.result), { list: list.map(mergeFetchToObj_1.mergeFetchToObj) });
}
exports.parseDirectualResponse = parseDirectualResponse;
function parseDirectualResponseOdd(response) {
    var list = response.result.list;
    return __assign(__assign({}, response.result), { list: list.map(mergeFetchToObj_1.mergeFetchToObjOdd) });
}
exports.parseDirectualResponseOdd = parseDirectualResponseOdd;
