"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACTION_GROUPS = exports.ID_LENGTH = void 0;
exports.ID_LENGTH = 36;
exports.ACTION_GROUPS = {
    init: 'INIT',
    ignore: 'IGNORE',
    updateSections: 'UPDATE_SECTIONS',
    updateAggregations: 'UPDATE_AGGREGATIONS',
    updateTemplateState: 'UPDATE_TEMPLATE_STATE',
    saveTemplateFromRedux: 'SAVE_TEMPLATE_FROM_REDUX',
};
