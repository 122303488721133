"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFormulaMain = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var CustomFormulaMainUtils_1 = require("./CustomFormulaMainUtils");
var react_redux_1 = require("react-redux");
var CustomFormulaMainView_1 = require("./CustomFormulaMainView");
var rootGetters_1 = require("../../../../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../../../../Root/redux/rootActionCreators/rootActionCreators");
require("../CustomFormula.less");
var CustomFormulaMain = function (_a) {
    var availableAggregations = _a.availableAggregations, aggregation = _a.aggregation, updateAggregations = _a.updateAggregations, aggregationProps = _a.aggregationProps;
    var dispatch = (0, react_redux_1.useDispatch)();
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var customFormulaAttributes = (presentState === null || presentState === void 0 ? void 0 : presentState.customFormulaAttributes) || [];
    var aggregationId = aggregation === null || aggregation === void 0 ? void 0 : aggregation.id;
    var currentAggregationFormula = customFormulaAttributes === null || customFormulaAttributes === void 0 ? void 0 : customFormulaAttributes.find(function (formulaItem) { return (formulaItem === null || formulaItem === void 0 ? void 0 : formulaItem.phId) === aggregationId; });
    var startInitState = {
        phId: aggregation === null || aggregation === void 0 ? void 0 : aggregation.id,
        formula: '',
        parameters: [],
    };
    if (currentAggregationFormula) {
        startInitState = {
            phId: aggregation === null || aggregation === void 0 ? void 0 : aggregation.id,
            formula: currentAggregationFormula === null || currentAggregationFormula === void 0 ? void 0 : currentAggregationFormula.formula,
            parameters: currentAggregationFormula === null || currentAggregationFormula === void 0 ? void 0 : currentAggregationFormula.parameters,
        };
    }
    var _b = (0, react_1.useState)(startInitState), currentFormula = _b[0], setCurrentFormula = _b[1];
    var sortedCustomFormulaAggregations = (0, react_1.useMemo)(function () { return (0, CustomFormulaMainUtils_1.preparedCustomFormulaAggregations)(availableAggregations); }, [availableAggregations]);
    var currentFormulaView = startInitState === null || startInitState === void 0 ? void 0 : startInitState.formula;
    var enableOperationResult = (0, CustomFormulaMainUtils_1.enableOperation)(currentFormulaView);
    (0, react_1.useEffect)(function () {
        if (currentFormula) {
            // если формула раннее была сохранена, то редактируем ее
            if (currentAggregationFormula) {
                dispatch((0, rootActionCreators_1.editCustomFormulaAttributes)(currentFormula));
                // а если нет, то записываем новую
            }
            else {
                dispatch((0, rootActionCreators_1.setCustomFormulaAttributes)([currentFormula]));
            }
        }
    }, [currentFormula, currentAggregationFormula]);
    // @ts-ignore
    return ((0, jsx_runtime_1.jsx)(CustomFormulaMainView_1.CustomFormulaMainView, { currentFormula: startInitState, setCurrentFormula: setCurrentFormula, 
        // @ts-ignore
        sortedCustomFormulaAggregations: sortedCustomFormulaAggregations, enableOperationResult: enableOperationResult, 
        // @ts-ignore
        updateAggregations: updateAggregations, 
        // @ts-ignore
        aggregation: aggregation, 
        // @ts-ignore
        aggregationProps: aggregationProps }));
};
exports.CustomFormulaMain = CustomFormulaMain;
