"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteAllCookies = exports.deleteCookie = exports.setCookie = exports.getCookie = void 0;
// возвращает куки с указанным name,
// или undefined, если ничего не найдено
function getCookie(name) {
    var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
exports.getCookie = getCookie;
function setCookie(name, value, encodeValue, options) {
    if (encodeValue === void 0) { encodeValue = true; }
    if (options === void 0) { options = {}; }
    var currentOptions = __assign({ path: '/' }, options);
    if (currentOptions['expires'] instanceof Date) {
        currentOptions['expires'] = currentOptions['expires'].toUTCString();
    }
    var updatedCookie = encodeURIComponent(name) + "=" + (encodeValue ? encodeURIComponent(value) : value);
    for (var optionKey in currentOptions) {
        updatedCookie += "; " + optionKey;
        var optionValue = currentOptions[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }
    document.cookie = updatedCookie;
}
exports.setCookie = setCookie;
function deleteCookie(name, encodeValue, options) {
    if (encodeValue === void 0) { encodeValue = true; }
    if (options === void 0) { options = {}; }
    setCookie(name, "", encodeValue, __assign({ 'max-age': -1 }, options));
}
exports.deleteCookie = deleteCookie;
function deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name_1 = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name_1 + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
exports.deleteAllCookies = deleteAllCookies;
