"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var blocksStyles = {
    section: { backgroundColor: '#f0f6ff' },
    table: {
        simple: {
            table: 'width: 100%;',
            td: 'word-wrap: break-word;',
        },
        bordered: {
            table: 'border: 1px solid black; border-collapse: collapse;',
            td: 'border: 1px solid black;',
        },
    },
};
exports.default = blocksStyles;
