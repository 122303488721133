"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageTypes = void 0;
/**
 * Константы определяющие типы сообщений
 */
exports.MessageTypes = {
    /**
     * Сообщение о том что права на редактирование шаблона прекращены
     */
    RightsTerminated: 'RightsTerminated',
};
