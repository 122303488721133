"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var lodash_1 = require("lodash");
var react_1 = require("react");
var http_1 = require("../../shared/utils/http");
;
var AutocompleteLogin = function (_a) {
    var onChangeLogin = _a.onChangeLogin;
    var _b = (0, react_1.useState)(""), login = _b[0], setLogin = _b[1];
    var _c = (0, react_1.useState)(""), search = _c[0], setSearch = _c[1];
    var _d = (0, react_1.useState)(false), isLoading = _d[0], setLoading = _d[1];
    var _e = (0, react_1.useState)(), options = _e[0], setOptions = _e[1];
    var fetch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, payload, opts, exception_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    // Если меньше 2 символов запрос не делаем
                    if (search.length < 2) {
                        setLoading(false);
                        setOptions(undefined);
                        onChangeLogin('');
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    return [4 /*yield*/, (0, http_1.searchUsersByLoginPage)(search, 1000)];
                case 1:
                    response = _a.sent();
                    payload = response.payload;
                    if (payload && payload.length !== 0) {
                        opts = payload.map(function (user, index) {
                            var _a;
                            var item = {
                                label: (0, jsx_runtime_1.jsxs)("div", { children: [user.name, (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("b", { children: "email: " }), user.email, user.login && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("b", { children: "login: " }), "user.login"] }))] }),
                                value: ((_a = user.email) !== null && _a !== void 0 ? _a : user.login),
                                key: index.toString(),
                            };
                            return item;
                        });
                        setOptions(opts);
                    }
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    exception_1 = _a.sent();
                    setLoading(false);
                    setOptions(undefined);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        void fetch();
    }, [search]);
    var handlerSelect = function (val, option) {
        var selected = option;
        if (selected && selected.value) {
            var value = selected.value.toString();
            setLogin(value);
            onChangeLogin(value);
        }
        else {
            setLogin('');
            onChangeLogin('');
        }
    };
    var handlerSearch = function (value) {
        setLogin(value);
        setSearch(value);
    };
    return ((0, jsx_runtime_1.jsx)(antd_1.AutoComplete, { size: 'large', notFoundContent: isLoading ? (0, jsx_runtime_1.jsx)(antd_1.Spin, {}) : search.length < 2 ? 'Для поиска введите 2 и более символа' : 'Нет совпадений', options: options, value: login, onSelect: handlerSelect, onSearch: (0, lodash_1.debounce)(function (value) { return handlerSearch(value); }, 100), placeholder: '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043B\u043E\u0433\u0438\u043D/email/\u0424\u0418\u041E' }));
};
exports.default = AutocompleteLogin;
