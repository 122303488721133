"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityBlockList = void 0;
var EntityBlock_1 = require("./EntityBlock");
var EntityType_1 = require("./EntityType");
/**
 * Блок содержащий список(обычный или нумерованный)
 */
var EntityBlockList = /** @class */ (function (_super) {
    __extends(EntityBlockList, _super);
    //#region Конструкторы
    function EntityBlockList(id, parent) {
        if (parent === void 0) { parent = null; }
        var _this = _super.call(this, id, parent) || this;
        /**
         * Тип
         */
        _this.type = EntityType_1.TEntityType.BlockList;
        // Блок списка НЕ может участвовать в нумерации
        _this.isNumericSupport = false;
        return _this;
    }
    //#endregion
    /**
     * Создание дочерних сущностей на основание DOM
     * id должен быть корректным
     * @param isDoc Режим документа (предпросмотра)
     */
    EntityBlockList.prototype.createFromDOM = function (isDoc) {
        // Нет дочерних сущностей
    };
    /**
     * Заполнить параметры сущности на основе объекта (блока или секции)
     * @param element Блок и секция
     */
    EntityBlockList.prototype.fillFromElement = function (element) {
    };
    /**
     * Вычисления(заполнения) дерева нумерации элементов
     * @param tree Дерево нумерации элементов
     * @param currentNumeration Структура для нумерации
     */
    EntityBlockList.prototype.calcTreeNumeration = function (tree, numeration) {
    };
    return EntityBlockList;
}(EntityBlock_1.EntityBlock));
exports.EntityBlockList = EntityBlockList;
