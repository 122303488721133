"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectFilterType = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var uuid_1 = require("uuid");
var constants_1 = require("./utils/constants");
var emptyFn_1 = require("../../utils/emptyFn");
var SelectFilterType = function (_a) {
    var _b = _a.handleChange, handleChange = _b === void 0 ? emptyFn_1.emptyFn : _b, _c = _a.selectedEntity, selectedEntity = _c === void 0 ? '' : _c, _d = _a.value, value = _d === void 0 ? '' : _d, _e = _a.selectedFields, selectedFields = _e === void 0 ? [] : _e;
    var options = [];
    var filteredOptions = constants_1.fieldsForFilter[selectedEntity].filter(function (_a) {
        var valueField = _a.valueField;
        return !selectedFields.includes(valueField);
    });
    filteredOptions.map(function (item) {
        return options.push({
            label: item.nameField,
            value: item.valueField,
            key: (0, uuid_1.v4)()
        });
    });
    return ((0, jsx_runtime_1.jsx)("div", __assign({ style: { marginBottom: '5px' } }, { children: (0, jsx_runtime_1.jsx)(antd_1.Select, { value: value, onChange: handleChange, className: "filter__select", options: options }, (0, uuid_1.v4)()) }), (0, uuid_1.v4)()));
};
exports.SelectFilterType = SelectFilterType;
