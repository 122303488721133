"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertBlock = void 0;
var textEditUtils_1 = require("../utils/textEditUtils");
var common_1 = require("../../../utils/common");
var constants_1 = require("../../../constants");
/* TODO: need refactoring */
function convertNodes(nodes, toType) {
    if (!nodes || !nodes.length)
        return nodes;
    for (var i = 0; i < nodes.length; i += 1) {
        var n = nodes[i];
        if (common_1.CheckUtils.isParagraphNode(n)) {
            (0, textEditUtils_1.replaceNode)(n, toType === 'list' ? 'li' : 'div');
        }
    }
    return nodes;
}
/* TODO: need refactoring */
function convertBlock(content, toType) {
    var tmpWrapper = document.createElement('div');
    tmpWrapper.innerHTML = content.html;
    var blockNodeChildren = tmpWrapper.childNodes;
    if (!blockNodeChildren || !blockNodeChildren.length)
        return content;
    if (blockNodeChildren.length === 1 &&
        blockNodeChildren[0].classList &&
        blockNodeChildren[0].classList.contains('text-style')) {
        // избавляемся от обертки "text-style"
        (0, textEditUtils_1.appendNodes)(tmpWrapper, blockNodeChildren[0].childNodes, false);
        blockNodeChildren[0].remove();
    }
    if (toType === 'text') {
        // находим все li, подставляем их в блок и заменяем на div
        var liNodes = tmpWrapper.getElementsByClassName(constants_1.CLASS_NAMES.textParagraph);
        var tmpWrapper2 = document.createElement('div');
        (0, textEditUtils_1.appendNodes)(tmpWrapper2, liNodes, false);
        tmpWrapper = tmpWrapper2;
    }
    convertNodes(tmpWrapper.childNodes, toType);
    return __assign(__assign({}, content), { html: tmpWrapper.innerHTML });
}
exports.convertBlock = convertBlock;
