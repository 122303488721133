"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckboxOptions = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var antd_1 = require("antd");
var RadioGroup = antd_1.Radio.Group;
var CheckboxOptions = function (_a) {
    var placeholder = _a.placeholder, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var _c = (0, react_1.useState)(function () { return placeholder.options.length !== 0 ? placeholder.options : [{ checked: 1 }]; }), options = _c[0], setOptions = _c[1];
    var updatePlaceholder = function (options) {
        placeholder.options = options;
    };
    var onChange = function (e) {
        if (disabled)
            return;
        var optUpdated = [{ checked: e.target.value }];
        setOptions(optUpdated);
    };
    (0, react_1.useEffect)(function () {
        updatePlaceholder(options);
    }, [options]);
    return (0, jsx_runtime_1.jsx)(react_1.Fragment, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "placeholder_option_items", "aria-disabled": disabled }, { children: (0, jsx_runtime_1.jsx)(antd_1.Row, __assign({ gutter: 24, justify: "space-between", align: "middle" }, { children: (0, jsx_runtime_1.jsxs)(antd_1.Col, __assign({ span: 24, style: { textAlign: 'left' } }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: "text-muted" }, { children: "\u041E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F \u043B\u0438 \u0431\u043B\u043E\u043A \u043F\u043E \u0443\u043C\u043E\u043B\u0447\u0430\u043D\u0438\u044E?" })), (0, jsx_runtime_1.jsxs)(RadioGroup, __assign({ onChange: onChange, value: options[0].checked }, { children: [(0, jsx_runtime_1.jsx)(antd_1.Radio, __assign({ value: 1 }, { children: "\u0414\u0430" })), (0, jsx_runtime_1.jsx)(antd_1.Radio, __assign({ value: 0 }, { children: "\u041D\u0435\u0442" }))] }))] })) })) })) });
};
exports.CheckboxOptions = CheckboxOptions;
