"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reorderList = exports.sortByNumberField = exports.sortByOrderField = exports.sortFunc = void 0;
var lodash_1 = require("lodash");
var ParsingUtils_1 = require("./common/ParsingUtils");
/**
 * @param {number} a
 * @param {number} b
 * @param [asc=true] - по возрастанию - true, по убыванию - false
 */
var sortFunc = function (a, b, asc) {
    if (asc === void 0) { asc = true; }
    var diff = a - b;
    return asc ? diff : -diff;
};
exports.sortFunc = sortFunc;
/**
 * Функция сортировки объектов со свойством order (blocks и options)
 * @param prev - объект, в котором есть свойство order
 * @param next - объект, в котором есть свойство order
 */
var sortByOrderField = function (prev, next) {
    var prevVal = ParsingUtils_1.ParsingUtils.getOrder(prev.order);
    var nextVal = ParsingUtils_1.ParsingUtils.getOrder(next.order);
    return (0, exports.sortFunc)(prevVal, nextVal);
};
exports.sortByOrderField = sortByOrderField;
/**
 * Функция сортировки объектов по указанному полю, содержащему число
 * @param fieldPath - путь к полю в объекте, по которому будет проходить сортировка
 * @param [asc=true] - по возрастанию - true, по убыванию - false
 */
var sortByNumberField = function (fieldPath, asc) {
    if (asc === void 0) { asc = true; }
    /**
     * @param prev - объект, в котором есть свойство по указанному пути с типом number
     * @param next - объект, в котором есть свойство по указанному пути с типом number
     */
    return function (prev, next) {
        var prevVal = Number((0, lodash_1.get)(prev, fieldPath, '')) || 0;
        var nextVal = Number((0, lodash_1.get)(next, fieldPath, '')) || 0;
        return (0, exports.sortFunc)(prevVal, nextVal, asc);
    };
};
exports.sortByNumberField = sortByNumberField;
var reorderList = function (list, startIndex, endIndex) {
    var result = Array.from(list);
    var removed = result.splice(startIndex, 1)[0];
    result.splice(endIndex, 0, removed);
    return result;
};
exports.reorderList = reorderList;
