"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnfilledTemplatedListSections = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var UnfilledTemplatedListSections = function (_a) {
    var _b = _a.section, section = _b === void 0 ? [] : _b, _c = _a.templatedListBlocks, templatedListBlocks = _c === void 0 ? [] : _c, _d = _a.placeholders, placeholders = _d === void 0 ? [] : _d, index = _a.index;
    var sectionId = section === null || section === void 0 ? void 0 : section.id;
    var findTemplatedListBlocks = templatedListBlocks === null || templatedListBlocks === void 0 ? void 0 : templatedListBlocks.find(function (block) { return block.templateElement.id === sectionId; });
    var placeholderId = findTemplatedListBlocks === null || findTemplatedListBlocks === void 0 ? void 0 : findTemplatedListBlocks.placeholderId;
    var findFakePlaceholder = placeholders === null || placeholders === void 0 ? void 0 : placeholders.find(function (ph) { return ph.id === placeholderId; });
    var desc = findFakePlaceholder === null || findFakePlaceholder === void 0 ? void 0 : findFakePlaceholder.desc;
    return (0, jsx_runtime_1.jsxs)("div", { children: [index + 1, ". ", desc, ": \u0421\u0435\u043A\u0446\u0438\u044F \u0441 \u043D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435\u043C ", section === null || section === void 0 ? void 0 : section.name] });
};
exports.UnfilledTemplatedListSections = UnfilledTemplatedListSections;
