"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadTemplateAutosaveSettings = exports.saveTemplateAutosaveSettings = void 0;
var saveTemplateAutosaveSettings = function (settings) {
    if (settings.templateId) {
        var key = "TemplateAutosave_" + settings.templateId;
        var value = JSON.stringify(settings);
        localStorage.setItem(key, value);
    }
};
exports.saveTemplateAutosaveSettings = saveTemplateAutosaveSettings;
var loadTemplateAutosaveSettings = function (templateId) {
    var key = "TemplateAutosave_" + templateId;
    var value = localStorage.getItem(key);
    if (value) {
        var settings = JSON.parse(value);
        return settings;
    }
    var settingsDefault = {
        enabled: true,
        interval: 5,
        templateId: templateId
    };
    if (templateId) {
        var valueDefault = JSON.stringify(settingsDefault);
        localStorage.setItem(key, valueDefault);
    }
    return settingsDefault;
};
exports.loadTemplateAutosaveSettings = loadTemplateAutosaveSettings;
