"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExploreHelper = void 0;
var ExploreHelper = /** @class */ (function () {
    function ExploreHelper() {
    }
    ExploreHelper.canSearch = function (searchValue) {
        return (searchValue !== null && searchValue != undefined && searchValue.length >= ExploreHelper.MinCharSearch);
    };
    // Минимальное количество символов, после которых начинается поиск шаблона или папки
    ExploreHelper.MinCharSearch = 3;
    return ExploreHelper;
}());
exports.ExploreHelper = ExploreHelper;
