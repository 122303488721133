"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortAttachments = exports.getNextAttachOrder = exports.getAttachOrder = exports.createNewNameAttachment = exports.getAttachmentName = exports.NAME_DELIMETER = exports.NUM_DELIMETER = exports.ATTACH_LABEL = void 0;
exports.ATTACH_LABEL = 'Приложение';
exports.NUM_DELIMETER = '№';
exports.NAME_DELIMETER = '-';
function getAttachmentName(attachment) {
    var initialNameArr;
    var initialName;
    if (!attachment.initial_title) {
        initialName = '';
    }
    else {
        initialNameArr = attachment.initial_title.split('-');
        initialNameArr.splice(0, 1);
        // trim нужен для того, чтобы избавиться от пробелов по краям имени
        initialName = initialNameArr.join('-').trim();
    }
    return initialName;
}
exports.getAttachmentName = getAttachmentName;
function createNewNameAttachment(attachment, order) {
    // формирую новое имя приложения в соответсвии с новыми данными
    var newName = "".concat(exports.ATTACH_LABEL, " ").concat(exports.NUM_DELIMETER, " ").concat(order, " ").concat(exports.NAME_DELIMETER, " ").concat(getAttachmentName(attachment));
    // чтобы не мутировать первоначальные данные
    var attach = attachment;
    // назначаю новое имя
    attach.title = newName;
    // переназначаю имя
    attachment.setTitle(attachment.title);
}
exports.createNewNameAttachment = createNewNameAttachment;
var getAttachOrder = function (attachName) {
    if (attachName) {
        // ищу в строке индекс символа №
        var numDelIndx = attachName.indexOf(exports.NUM_DELIMETER);
        // ищу в строке индекс символа -
        var nameDelIndx = attachName.indexOf(exports.NAME_DELIMETER);
        // забираю номер приложения
        var attachmentNum = attachName.slice(numDelIndx + 1, nameDelIndx).trim();
        // разбиваю по точке на случай если приложение с уровнем вложенности
        var attachNumArr = attachmentNum.split('.');
        if (!attachNumArr.length)
            return [];
        return attachNumArr;
    }
};
exports.getAttachOrder = getAttachOrder;
var getNextAttachOrder = function (prevAttach) {
    // забираю ордер предыдущего приложения
    var prevAttachNum = (0, exports.getAttachOrder)(prevAttach.title);
    var nextAttachNumb;
    if (prevAttachNum.length) {
        // перебираю ордер предыдущего приложения
        nextAttachNumb = prevAttachNum.map(function (attachNum, index) {
            // увеличиваю на 1 первое число ордера предыдущего приложения
            if (index === 1)
                return Number(attachNum) + 1;
            return Number(attachNum);
        });
    }
    return nextAttachNumb.join('.');
};
exports.getNextAttachOrder = getNextAttachOrder;
var sortAttachments = function (placeholders) {
    // Реальные ПХ
    var actualPlaceholders = placeholders.filter(function (placeholder) { return placeholder.action !== 'delete'; });
    // забираю только пх-приложения
    var attachmentPlaceholders = actualPlaceholders.filter(function (placeholder) { return placeholder.type === 'attachment'; });
    var selectedAttachmentsIds = [];
    // забираю все блоки, содержащие в себе приложения
    var attachmentsBlocks = document.getElementsByClassName('attachment-list');
    if (!attachmentsBlocks.length || !attachmentPlaceholders.length) {
        return;
    }
    // перебираю блоки
    for (var i = 0; i < attachmentsBlocks.length; i += 1) {
        //  у каждого блока приложении забираю все пх
        var attachList = attachmentsBlocks[i].childNodes;
        // перебираю каждый пх
        attachList.forEach(function (node) {
            // Структура отображения приложения
            // <p id='ph_wrap_...'>
            //   <span>
            //   </span>
            // </p>
            // Почему-то могут выводиться "пустые" оболочки для ПХ
            // проверяем этот момент
            var elem = node;
            if (elem && elem.innerHTML !== '') {
                var id = elem.id.substring(8);
                if (id !== '') {
                    selectedAttachmentsIds.push(id);
                }
            }
        });
    }
    // так как счет приложений начинается с 1, а не 0, использую эту переменную
    var order = 1;
    // перебираю массив отрисованных пх из конкретного блока приложений
    selectedAttachmentsIds.forEach(function (element, index) {
        // забираю текущий пх-приложение
        var attachment = attachmentPlaceholders.find(function (attach) { return attach.id === element; });
        // ищу предыдущий пх-приложение
        var prevAttach = attachmentPlaceholders.find(function (attach) { return attach.id === selectedAttachmentsIds[index - 1]; });
        var attachNum;
        // если есть текущее приложение
        if (attachment) {
            // если текущее приложение самое первое
            if (index === 0) {
                // переопределяю номер пх-приложения
                attachNum = order;
                // если есть предыдущее приложение с автонумерацией и оно не является вторым по счету в блоке
            }
            else if (prevAttach && prevAttach.isAutonumbering && index !== 1) {
                // переопределяю оредер
                attachNum = !attachment.isAutonumbering ? order : (0, exports.getNextAttachOrder)(prevAttach);
                // если приложение с включенной автонумерацией
            }
            else if (attachment.isAutonumbering) {
                // забираю счетчик и шаблонизирую строку
                attachNum = "".concat(order, ".1");
                // увеличиваю счетчик на 1
                order += 1;
            }
            else {
                // увеличиваю счетчик на 1
                order += 1;
                // переопределяю номер пх-приложения
                attachNum = order;
            }
            // создаю новое имя приложеения
            createNewNameAttachment(attachment, attachNum);
        }
    });
};
exports.sortAttachments = sortAttachments;
