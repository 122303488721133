"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUpdatedInfoMessageList = exports.getClearedInfoMessageList = exports.getUpdatedInfoMessages = void 0;
var getUpdatedInfoMessages = function (guid, messages) {
    var index = messages.findIndex(function (v) { return v.guid === guid; });
    return index !== -1
        ? Array.from(messages).fill(__assign(__assign({}, messages[index]), { isRead: true }), index, index + 1)
        : messages;
};
exports.getUpdatedInfoMessages = getUpdatedInfoMessages;
var getClearedInfoMessageList = function (message, list) {
    return list.filter(function (v) { return v.guid !== message.guid; });
};
exports.getClearedInfoMessageList = getClearedInfoMessageList;
var getUpdatedInfoMessageList = function (message, list) {
    var filteredList = (0, exports.getClearedInfoMessageList)(message, list);
    if (message.action === 'create' || message.action === 'modify') {
        filteredList.unshift(__assign(__assign({}, message), { isRead: false }));
    }
    return filteredList;
};
exports.getUpdatedInfoMessageList = getUpdatedInfoMessageList;
