"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkIsTemplateMessage = void 0;
function checkIsTemplateMessage(value) {
    if (value == null) {
        return false;
    }
    if ('userId' in value &&
        'templateId' in value &&
        'messageType' in value &&
        typeof value['userId'] === 'string' &&
        typeof value['templateId'] === 'string' &&
        typeof value['messageType'] === 'string') {
        return true;
    }
    return false;
}
exports.checkIsTemplateMessage = checkIsTemplateMessage;
