"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var toolkit_1 = require("@reduxjs/toolkit");
var storage_1 = require("../../../shared/utils/storage");
var actions_1 = require("./actions");
var initialState = {
    isLoading: false,
    isLoggedIn: false,
    OIDC_Instance: {},
    error: "",
    responsibilities: "",
    user: {},
};
var errorPermissionZero = 'Нет ни одной установленной роли пользователя';
var errorNotRights = 'Недостаточно прав. Внимание!!! Для выдачи доступа к приложению “Конструктор документов” требуется заполнить служебную записку  на портале.';
var slice = (0, toolkit_1.createSlice)({
    name: "auth",
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(actions_1.setDisableAccessAction, function (state) {
            state.error = errorPermissionZero;
        });
        builder.addCase(actions_1.fetchOidSettings.pending, function (state) {
            state.isLoading = true;
        });
        builder.addCase(actions_1.fetchOidSettings.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isLoading = false;
            state.OIDC_Instance = payload;
        });
        builder.addCase(actions_1.fetchOidSettings.rejected, function (state) {
            state.isLoading = false;
            state.error = "Произошла ошибка с настройкой авторизации";
        });
        builder.addCase(actions_1.login.pending, function (state) {
            state.isLoading = true;
        });
        builder.addCase(actions_1.login.fulfilled, function (state) {
            state.isLoading = false;
        });
        builder.addCase(actions_1.login.rejected, function (state) {
            state.isLoading = false;
        });
        builder.addCase(actions_1.checkOIDAuth.rejected, function (state, action) {
            var _a;
            state.isLoading = false;
            // В данном контексте только этот код сообщает об отсутствии прав у пользователя
            var errorPermission = "code 403";
            var i = (_a = action.error.message) === null || _a === void 0 ? void 0 : _a.indexOf(errorPermission);
            if (i && i > -1) {
                state.error = errorPermissionZero;
            }
            else {
                state.error = "Произошла ошибка с получением пользователя";
            }
        });
        builder.addCase(actions_1.checkOIDAuth.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isLoading = false;
            state.isLoggedIn = true;
            var result = payload.result;
            if (!result.responsibilities)
                state.error = errorNotRights;
            else {
                state.responsibilities = result.responsibilities;
                state.user = result;
                (0, storage_1.setLocalStorage)({ responsibilities: result.responsibilities });
                (0, storage_1.setLocalStorage)({ userId: result.user_id });
                (0, storage_1.setLocalStorage)({ userName: result.username });
            }
        });
        builder.addCase(actions_1.logout, function (state) {
            state = initialState;
        });
    },
});
exports.default = slice.reducer;
