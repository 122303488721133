"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableRow = void 0;
var TableCell_1 = require("./TableCell");
var TableColumn_1 = require("./TableColumn");
var TableConstants_1 = require("./TableConstants");
var TableRow = /** @class */ (function () {
    //#endregion
    function TableRow(ownerTable, key) {
        this.ownerTable = ownerTable;
        this.key = key;
        this.cells = [];
    }
    //#region Static methods
    /**
     * Загрузка данных из строки в старом формате
     * @param ownerTable Владелец таблица
     * @param columnsOld Список столбцов в старом формате
     * @param rowIndex Текущий индекс загружаемой строки
     * @param rowOld Строка в старом формате
     * @returns Строка в новом формате или undefined если данные загрузить не удалось
     */
    TableRow.fromRowOld = function (ownerTable, columnsOld, rowIndex, rowOld) {
        var rowKey = 0;
        // Проверка ключа, его может и не быть по непонятным причинам, возможно потому что некоторые шаблоны сломаны
        if (rowOld.key) {
            var parsed = parseInt(rowOld.key.toString(), 10);
            rowKey = Number.isNaN(parsed) ? rowOld.key : parsed;
        }
        else {
            rowKey = rowIndex + 1;
        }
        var colKeys = Object.keys(rowOld);
        if (!colKeys || !colKeys.length)
            return undefined;
        var row = new TableRow(ownerTable, rowKey.toString());
        if (ownerTable.isEditMode()) {
            var technicalCell = row.createCell(TableConstants_1.TableConstants.TechnicalColumnKey, '');
            row.cells.push(technicalCell);
        }
        colKeys
            .filter(function (key) { return key !== 'key' && key !== 'tech'; })
            .forEach(function (colKey) {
            var colKeyCurrent = colKey;
            // Нужно убедиться что данный ключ есть в столбцах потому что некоторые шаблоны сломаны
            if (columnsOld.find(function (x) { return x.dataIndex === colKey; })) {
                if (colKeyCurrent.includes('index_')) {
                    colKeyCurrent = TableColumn_1.TableColumn.convertColumnOldName(colKey);
                }
                var cell = row.createCell(colKeyCurrent, rowOld[colKey]);
                cell.verticalAlign = 'top';
                row.cells.push(cell);
            }
            else {
                console.error("\u041E\u0448\u0438\u0431\u043A\u0430 \u0432 \u0442\u0430\u0431\u043B\u0438\u0446\u0435: ".concat(ownerTable.tableArgs.id, ", \u0434\u043B\u044F \u0441\u0442\u0440\u043E\u043A\u0438 ").concat(rowOld.key, ", \u0435\u0441\u0442\u044C \u0441\u0441\u044B\u043B\u043A\u0430 \u043D\u0430 \u043D\u0435\u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u044E\u0449\u0438\u0439 \u0441\u0442\u043E\u043B\u0431\u0435\u0446 ").concat(colKey));
            }
        });
        return row;
    };
    /**
     * Загрузка строк таблицы со старого формата
     * @param ownerTable Владелец таблица
     * @param columnsOld Список столбцов в старом формате
     * @param rowsOld Список строк в старом формате
     * @returns Список строк в новом формате
     */
    TableRow.fromRowsOld = function (ownerTable, columnsOld, rowsOld) {
        var rows = [];
        for (var index = 0; index < rowsOld.length; index++) {
            var rowOld = rowsOld[index];
            var rowNew = TableRow.fromRowOld(ownerTable, columnsOld, index, rowOld);
            if (rowNew) {
                rows.push(rowNew);
            }
        }
        return rows;
    };
    TableRow.fromTableRowDto = function (ownerTable, rowDto) {
        var row = new TableRow(ownerTable, rowDto.key);
        row.height = rowDto.height;
        row.cells = TableCell_1.TableCell.fromTableCellsDto(ownerTable, row, rowDto.cells);
        return row;
    };
    TableRow.fromTableRowsDto = function (ownerTable, rowsDto) {
        var rows = rowsDto.map(function (row) { return TableRow.fromTableRowDto(ownerTable, row); });
        return rows;
    };
    TableRow.prototype.createCell = function (columnKey, content) {
        var cell = new TableCell_1.TableCell(this.ownerTable, this.key, columnKey);
        cell.content = content;
        return cell;
    };
    TableRow.prototype.getCell = function (columnKey) {
        return this.cells.find(function (x) { return x.columnKey === columnKey; });
    };
    TableRow.prototype.getCellByIndex = function (columnIndex) {
        return this.cells[columnIndex];
    };
    TableRow.prototype.moveCell = function (oldIndex, newIndex) {
        var temp = this.cells[newIndex];
        this.cells[newIndex] = this.cells[oldIndex];
        this.cells[oldIndex] = temp;
    };
    TableRow.prototype.getSelectedCells = function () {
        var cells = [];
        this.cells.forEach(function (c) {
            if (c.selected) {
                cells.push(c);
            }
        });
        return cells;
    };
    TableRow.prototype.setAllBorders = function () {
        this.cells.forEach(function (c) {
            c.borderTop = true;
            c.borderLeft = true;
            c.borderRight = true;
            c.borderBottom = true;
        });
    };
    TableRow.prototype.copy = function (ownerTable) {
        var copyRow = new TableRow(ownerTable, this.key);
        copyRow.height = this.height;
        copyRow.cells = this.cells.map(function (c) { return c.copy(ownerTable); });
        return copyRow;
    };
    TableRow.prototype.toTableRowDto = function () {
        var rowDto = {
            key: this.key,
            height: this.height,
            cells: this.cells.filter(function (c) { return c.columnKey !== TableConstants_1.TableConstants.TechnicalColumnKey; }).map(function (c) { return c.toTableCellDto(); }),
        };
        return rowDto;
    };
    TableRow.prototype.printHtml = function () {
        var result = "<tr";
        if (this.height)
            result += " style=\"height: ".concat(this.height, "mm;");
        result += '">';
        for (var index = 0; index < this.cells.length; index++) {
            var cell = this.cells[index];
            if (cell.merged === false)
                result += cell.printHtml();
        }
        result += '</tr>';
        return result;
    };
    return TableRow;
}());
exports.TableRow = TableRow;
