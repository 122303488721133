"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_beautiful_dnd_1 = require("react-beautiful-dnd");
var templatedListConstants_1 = require("../../utils/templatedListConstants");
var common_1 = require("../../utils/common");
var TemplateContent = /** @class */ (function (_super) {
    __extends(TemplateContent, _super);
    function TemplateContent(props) {
        var _this = _super.call(this, props) || this;
        // subscriber for DOM changes to determine whether user deleted DOM-node via del or backspace
        _this.mutationObserver = new MutationObserver(_this.props.mutationHandler);
        return _this;
    }
    TemplateContent.prototype.componentDidMount = function () {
        var templateContentWrapper = document
            .getElementById('js-template-content-wrapper');
        if (templateContentWrapper) {
            this.mutationObserver.observe(templateContentWrapper, {
                subtree: true,
                attributes: true,
                childList: true,
                characterData: false,
                characterDataOldValue: false,
            });
        }
    };
    TemplateContent.prototype.componentWillUnMount = function () {
        this.mutationObserver.disconnect();
    };
    TemplateContent.prototype.render = function () {
        var _a = this.props, tree = _a.tree, blocks = _a.blocks, onDragEnd = _a.onDragEnd, totalBlock = _a.totalBlock, onDragStart = _a.onDragStart, resolveBlock = _a.resolveBlock;
        return ((0, jsx_runtime_1.jsx)(react_beautiful_dnd_1.DragDropContext, __assign({ onDragStart: onDragStart, onDragEnd: onDragEnd }, { children: (0, jsx_runtime_1.jsx)(react_beautiful_dnd_1.Droppable, __assign({ droppableId: 'someIdsome' }, { children: function (provided) { return ((0, jsx_runtime_1.jsxs)("div", __assign({ ref: provided.innerRef }, { children: [blocks.map(function (item, index) { return (
                        // @ts-ignore
                        (0, jsx_runtime_1.jsx)(react_beautiful_dnd_1.Draggable, __assign({ draggableId: common_1.AttrUtils.generateId(item.type, "".concat(index, "_dnd")), index: index, isDragDisabled: totalBlock || item.isLib || item.subType === templatedListConstants_1.options.templatedList.block }, { children: function (p) { return resolveBlock(p, item, index, tree); } }), common_1.AttrUtils.generateId(item.type, "".concat(index, "_dnd")))); }), provided.placeholder] }))); } }), 'droppable-key') })));
    };
    TemplateContent.defaultProps = {
        totalBlock: false,
    };
    return TemplateContent;
}(react_1.PureComponent));
exports.default = TemplateContent;
