"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AggregationModel = /** @class */ (function () {
    function AggregationModel(id, name, parent_id, options, settings, isLib, description, type, dataFormat, fromSystem, formula_id, format, isHidden, isSent, isReceived, target, emptyValue, external_params, initial_title, isRequired, attachmentDocumentID, attachmentTemplateID, subType, has_relations, groupName, order, fromPlaceholder_id, params, isAutonumbering, dataValue, elementId, customFormulaData) {
        if (name === void 0) { name = ''; }
        if (parent_id === void 0) { parent_id = ''; }
        if (options === void 0) { options = []; }
        if (settings === void 0) { settings = []; }
        if (isLib === void 0) { isLib = false; }
        if (description === void 0) { description = ''; }
        if (type === void 0) { type = ''; }
        if (dataFormat === void 0) { dataFormat = ''; }
        if (fromSystem === void 0) { fromSystem = ''; }
        if (formula_id === void 0) { formula_id = ''; }
        if (format === void 0) { format = ''; }
        if (isHidden === void 0) { isHidden = false; }
        if (isSent === void 0) { isSent = false; }
        if (isReceived === void 0) { isReceived = false; }
        if (target === void 0) { target = []; }
        if (emptyValue === void 0) { emptyValue = false; }
        if (external_params === void 0) { external_params = undefined; }
        if (initial_title === void 0) { initial_title = ''; }
        if (isRequired === void 0) { isRequired = true; }
        if (attachmentDocumentID === void 0) { attachmentDocumentID = undefined; }
        if (attachmentTemplateID === void 0) { attachmentTemplateID = null; }
        if (subType === void 0) { subType = ''; }
        if (has_relations === void 0) { has_relations = false; }
        if (groupName === void 0) { groupName = ''; }
        if (order === void 0) { order = 0; }
        if (fromPlaceholder_id === void 0) { fromPlaceholder_id = {}; }
        if (params === void 0) { params = {}; }
        if (isAutonumbering === void 0) { isAutonumbering = false; }
        if (dataValue === void 0) { dataValue = ''; }
        if (elementId === void 0) { elementId = ''; }
        if (customFormulaData === void 0) { customFormulaData = undefined; }
        this.id = id;
        this.name = name;
        this.parent_id = parent_id;
        this.options = options;
        this.settings = settings;
        this.isLib = isLib;
        this.description = description;
        this.type = type;
        this.dataFormat = dataFormat;
        this.fromSystem = fromSystem;
        this.formula_id = formula_id;
        this.format = format;
        this.isHidden = isHidden;
        this.isSent = isSent;
        this.isReceived = isReceived;
        this.target = target;
        this.emptyValue = emptyValue;
        this.external_params = external_params;
        this.initial_title = initial_title;
        this.isRequired = isRequired;
        this.attachmentDocumentID = attachmentDocumentID;
        this.attachmentTemplateID = attachmentTemplateID;
        this.subType = subType;
        this.has_relations = has_relations;
        this.groupName = groupName;
        this.order = order;
        this.fromPlaceholder_id = fromPlaceholder_id;
        this.params = params;
        this.isAutonumbering = isAutonumbering;
        this.dataValue = dataValue;
        this.elementId = elementId;
        this.customFormulaData = customFormulaData;
        this.id = id;
        this.name = name;
        this.parent_id = parent_id;
        this.options = options;
        this.settings = settings;
        this.isLib = isLib;
        this.description = description;
        this.type = type;
        this.dataFormat = dataFormat;
        this.fromSystem = fromSystem;
        this.formula_id = formula_id;
        this.format = format;
        this.isHidden = isHidden;
        this.isSent = isSent;
        this.isReceived = isReceived;
        this.target = target;
        this.emptyValue = emptyValue;
        this.external_params = external_params;
        this.initial_title = initial_title;
        this.isRequired = isRequired;
        this.attachmentDocumentID = attachmentDocumentID;
        this.attachmentTemplateID = attachmentTemplateID;
        this.subType = subType;
        this.has_relations = has_relations;
        this.groupName = groupName;
        this.order = order;
        this.fromPlaceholder_id = fromPlaceholder_id;
        this.params = params;
        this.isAutonumbering = isAutonumbering;
        this.dataValue = dataValue,
            this.elementId = elementId,
            this.customFormulaData = customFormulaData;
    }
    return AggregationModel;
}());
exports.default = AggregationModel;
