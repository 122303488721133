"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTemplatedListPhsAttrs = exports.isTlPlaceholderType = void 0;
var common_1 = require("./common");
var isTlPlaceholderType = function (type) {
    return common_1.PhValuesUtils.isTlDocumentPlaceholder(type);
};
exports.isTlPlaceholderType = isTlPlaceholderType;
var setTemplatedListPhsAttrs = function (placeholders) {
    if (placeholders === void 0) { placeholders = []; }
    placeholders === null || placeholders === void 0 ? void 0 : placeholders.forEach(function (placeholder) {
        var isRequired = placeholder.isRequired, title = placeholder.title, type = placeholder.type, dataFormat = placeholder.dataFormat;
        var node = document.getElementById("ph_".concat(placeholder.id));
        if (!node) {
            return;
        }
        node.setAttribute('data-templated-list-required', isRequired ? '1' : '0');
        node.setAttribute('data-templated-list-name', title);
        if ((0, exports.isTlPlaceholderType)(type)) {
            node.setAttribute('data-templated-list-ph', '1');
            node.setAttribute('data-placeholder-field', type !== null && type !== void 0 ? type : '');
            if (dataFormat) {
                node.setAttribute('data-placeholder-type', dataFormat);
            }
            else {
                node.removeAttribute('data-placeholder-type');
            }
        }
        if (type === 'radio' || type === 'cascader') {
            node.setAttribute('data-placeholder-options', JSON.stringify(placeholder.options));
        }
        else {
            node.removeAttribute('data-placeholder-options');
        }
    });
};
exports.setTemplatedListPhsAttrs = setTemplatedListPhsAttrs;
