"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkTheme = void 0;
var checkTheme = function (theme, lightTheme, darkTheme, colorTheme) {
    switch (theme) {
        case 'dark':
            return darkTheme;
            break;
        case 'color':
            return colorTheme;
            break;
        default:
            return lightTheme;
    }
};
exports.checkTheme = checkTheme;
