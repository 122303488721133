"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.domains = void 0;
exports.domains = {
    emails: [
        '@pik.ru',
        '@pik-industry.ru',
        '@pik.ru.test-google-a.com',
        '@alias,pik.ru',
        '@gm.pik.ru',
        '@asana.pik.ru',
        '@dsk2.ru',
        '@3rdtaxipark.ru',
        '@aostengineering.ru',
        '@aotechnostroy.ru',
        '@broadstroy.ru',
        '@docs.pik-kuban.ru',
        '@docs.pik-region.ru',
        '@docs.pik-volga.ru',
        '@docs.pik-zapad.ru',
        '@energoservis-pik.ru',
        '@gbiplant23.ru',
        '@grandolimp.ru',
        '@keyrealestate.ru',
        '@kovchegstroy.ru',
        '@mcieassociation.ru',
        '@mezhregionopttorg.ru',
        '@misteriaplus.ru',
        '@morton-rso.ru',
        '@morton-yug.ru',
        '@nationalbuilding.ru',
        '@ooolotan.ru',
        '@ooonikp.ru',
        '@oooproject2.ru',
        '@oooregioninvest.ru',
        '@picompany.ru',
        '@pik.email',
        '@pik-r.ru',
        '@profserviceorg.ru',
        '@prometey-city.ru',
        '@regionstroycomplex.ru',
        '@rivas-mo.ru',
        '@riviera-stroy.ru',
        '@rostovkapstroy.ru',
        '@rusbiznesinvest.ru',
        '@russtroygarant.ru',
        '@speccvetmed.ru',
        '@s-stroyperspectiva.ru',
        '@stroy-proj.ru',
        '@stroytriada.ru',
        '@tpredeast.ru',
        '@veystoun.ru',
        '@tsentrresurs.ru',
        '@tyrone.ru',
        '@zaomonetchik.ru',
        '@zagorodnaya-usadba.ru',
        '@zemproektstroy.ru',
        '@zemstroyalliance.ru',
        '@docs.pik-industry.ru',
        '@gradindustry.ru',
        '@dsk3.ru',
        '@dskgrad.ru',
        '@gm.pik-industry.ru',
        '@archive.pik.ru',
        '@ingstroyinn.ru',
        '@pik-element.ru',
        '@vostochnij.ru',
        '@lublinodev.ru',
        '@tender.lovit.ru',
        '@grad-lift.ru',
        '@market-co.com',
        '@stolichniy-complex.ru',
        '@v-spec.ru',
        '@lvgs.ru',
        '@bw-estate.ru',
        '@morton.ru',
        '@rubetek.ru',
        '@pik.media',
        '@oknapik.ru',
        '@pik-group.ru',
        '@mstroymoscow.ru',
        '@legrobuild.ru',
        '@contech.pik.ru',
        'lzsmk.ru',
    ],
};
