"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractEditorMode = void 0;
// this function extracts editor mode from url
function extractEditorMode(history) {
    var splittedUrl = history.location.pathname.split('/');
    var length = splittedUrl.length;
    return splittedUrl[length - 3];
}
exports.extractEditorMode = extractEditorMode;
