"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParsingUtils = void 0;
/**
 * Утилиты разбора строк
 */
var ParsingUtils = /** @class */ (function () {
    function ParsingUtils() {
    }
    /**
     * Получение порядкового номера блока из строки (секция) или числа (остальные блоки)
     * @param value - свойство order из IBaseBlock
     */
    ParsingUtils.getOrder = function (value) {
        if (typeof value === 'string') {
            return parseInt(value.split('_')[1], 10);
        }
        if (typeof value === 'number') {
            return value;
        }
        return 0;
    };
    return ParsingUtils;
}());
exports.ParsingUtils = ParsingUtils;
