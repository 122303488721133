"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMoveFolderOrTemplateEndedEvent = exports.MoveFolderOrTemplateEndedEvent = void 0;
/**
 * Событие об окончании перемещении папки или шаблона
 */
exports.MoveFolderOrTemplateEndedEvent = 'MoveFolderOrTemplateEnded';
var createMoveFolderOrTemplateEndedEvent = function () {
    var data = {};
    var event = new CustomEvent(exports.MoveFolderOrTemplateEndedEvent, { detail: data });
    return event;
};
exports.createMoveFolderOrTemplateEndedEvent = createMoveFolderOrTemplateEndedEvent;
