"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectedPlaceholderPanel = exports.placeholdersValidationCheck = exports.placeholderValidationCheck = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var placeholderValidationUtils_1 = require("../../utils/placeholderValidationUtils");
var react_redux_1 = require("react-redux");
require("./SelectedPlaceholderPanel.less");
require("./SelectedPlaceholderPanel.less");
var rootGetters_1 = require("../../../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../../../Root/redux/rootActionCreators/rootActionCreators");
var MenuButton_1 = require("../MenuButton");
var placeholderValidationCheck = function (placeholder) {
    var validationFunctions = [placeholderValidationUtils_1.validateAttributes, placeholderValidationUtils_1.validateAttachmentDocuments, placeholderValidationUtils_1.validateAttachmentTemplates];
    var validationInfoAlias = [];
    for (var _i = 0, validationFunctions_1 = validationFunctions; _i < validationFunctions_1.length; _i++) {
        var validationFunc = validationFunctions_1[_i];
        var validationResult = validationFunc([placeholder]);
        validationInfoAlias.push(validationResult);
    }
    return !(validationInfoAlias === null || validationInfoAlias === void 0 ? void 0 : validationInfoAlias.every(function (element) { return element.success; }));
};
exports.placeholderValidationCheck = placeholderValidationCheck;
var placeholdersValidationCheck = function (placeholders, templateType) {
    var success = true;
    var validationFunctions = [placeholderValidationUtils_1.validateTemplatedList];
    for (var _i = 0, validationFunctions_2 = validationFunctions; _i < validationFunctions_2.length; _i++) {
        var validationFunc = validationFunctions_2[_i];
        var validationResult = validationFunc(placeholders, templateType);
        success = success && validationResult.success;
    }
    return !success;
};
exports.placeholdersValidationCheck = placeholdersValidationCheck;
var SelectedPlaceholderPanel = function (_a) {
    var props = __rest(_a, []);
    var isPlaceholderSelected = props.isPlaceholderSelected, isDisabled = props.isDisabled;
    var dispatch = (0, react_redux_1.useDispatch)();
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var templateInfo = presentState === null || presentState === void 0 ? void 0 : presentState.templateInfo;
    var templateType = templateInfo === null || templateInfo === void 0 ? void 0 : templateInfo.type;
    var placeholders = (0, react_redux_1.useSelector)(rootGetters_1.getPlaceholders);
    var disableAddToPhsLibButton = (0, exports.placeholderValidationCheck)(isPlaceholderSelected) ||
        (0, exports.placeholdersValidationCheck)(placeholders, templateType) ||
        isDisabled;
    var saveModalOpen = function () {
        dispatch((0, rootActionCreators_1.setTemplateSaveModal)(true));
    };
    return ((0, jsx_runtime_1.jsx)(MenuButton_1.MenuButton, { disabled: disableAddToPhsLibButton, onClick: saveModalOpen, iconId: "placeholders-lib", text: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0432 \u0411\u0438\u0431\u043B\u0438\u043E\u0442\u0435\u043A\u0443 \u041F\u0425" }));
};
exports.SelectedPlaceholderPanel = SelectedPlaceholderPanel;
