"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElementTypeUtils = void 0;
var constants_1 = require("../../constants");
/**
 * Утилиты проверки свойства subType и type из IBaseBlock
 */
var ElementTypeUtils = /** @class */ (function () {
    function ElementTypeUtils() {
    }
    /**
     * Проверка является ли блок заголовком ПХ-списка
     * @param subType
     */
    ElementTypeUtils.isTplHeader = function (subType) {
        if (subType === void 0) { subType = ''; }
        return subType === constants_1.SubTypes.WithTL;
    };
    /**
     * Проверка является ли блок частью ПХ-списка
     * @param subType
     */
    ElementTypeUtils.isTplListBlock = function (subType) {
        if (subType === void 0) { subType = ''; }
        return subType === constants_1.SubTypes.TemplatedListBlock;
    };
    /**
     * Проверка является ли блок контейнером секции
     * @param type - elementType в ITemplateElementDto
     */
    ElementTypeUtils.isSectionBlock = function (type) {
        if (type === void 0) { type = ''; }
        return type === 'section';
    };
    return ElementTypeUtils;
}());
exports.ElementTypeUtils = ElementTypeUtils;
