"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractObjectType = void 0;
// this function extracts object type from url
function extractObjectType(history) {
    var splittedUrl = history.location.pathname.split('/');
    var length = splittedUrl.length;
    return splittedUrl[length - 2];
}
exports.extractObjectType = extractObjectType;
