"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionsAll = void 0;
/**
 * Все доступные разрешения
 */
exports.PermissionsAll = {
    // ОБЩИЕ ДЛЯ ВСЕХ РОЛЕЙ
    // Блоки
    /**
     * Создание библиотечного блока.
     */
    canCreateBlocks: "canCreateBlocks",
    /**
     * Редактирование библиотечного блока.
     */
    canEditBlocks: "canEditBlocks",
    /**
     * Просмотр библиотечного блока.
     */
    canViewBlocks: "canViewBlocks",
    // Приложение
    /**
     * Создание шаблона Приложения.
     */
    canCreateTemplateAttachment: "canCreateTemplateAttachment",
    /**
     * Редактирование шаблона Приложения.
     */
    canEditTemplateAttachment: "canEditTemplateAttachment",
    /**
     * Просмотр шаблона Приложения.
     */
    canViewTemplateAttachment: "canViewTemplateAttachment",
    /**
     * Создание документа Приложение.
     */
    canCreateDocumentAttachment: "canCreateDocumentAttachment",
    // Списки
    /**
     * Создание Списков.
     */
    canCreateTemplatedList: "canCreateTemplatedList",
    /**
     * Редактирование списков.
     */
    canEditTemplatedList: "canEditTemplatedList",
    /**
     * Просмотр списков.
     */
    canViewTemplatedList: "canViewTemplatedList",
    //
    // ДОГОВОРА (Юрист)
    //
    /**
     * Создание шаблона контракта.
     */
    canCreateTemplateContract: "canCreateTemplateContract",
    /**
     * Редактирование шаблона контракта.
     */
    canEditTemplateContract: "canEditTemplateContract",
    /**
     * Просмотр шаблона контракта.
     */
    canViewTemplateContract: "canViewTemplateContract",
    /**
     * Создание шаблона Соглашения о расторжении договора.
     */
    canCreateTemplateTerminationAgreement: "canCreateTemplateTerminationAgreement",
    /**
     * Редактирование шаблона Соглашения о расторжении договора.
     */
    canEditTemplateTerminationAgreement: "canEditTemplateTerminationAgreement",
    /**
     * Просмотр шаблона Соглашения о расторжении договора.
     */
    canViewTemplateTerminationAgreement: "canViewTemplateTerminationAgreement",
    /**
     * Создание шаблона Дополнительное соглашение.
     */
    canCreateTemplateAdditionalAgreement: "canCreateTemplateAdditionalAgreement",
    /**
     * Редактирование шаблона Дополнительное соглашение
     */
    canEditTemplateAdditionalAgreement: "canEditTemplateAdditionalAgreement",
    /**
     * Просмотр шаблона Дополнительное соглашение
     */
    canViewTemplateAdditionalAgreement: "canViewTemplateAdditionalAgreement",
    /**
     * Создание шаблона Трехстороннего договора.
     */
    canCreateTemplateTripartiteAgreement: "canCreateTemplateTripartiteAgreement",
    /**
     * Редактирование шаблона Трехстороннего договора
     */
    canEditTemplateTripartiteAgreement: "canEditTemplateTripartiteAgreement",
    /**
     * Просмотр шаблона Трехстороннего договора
     */
    canViewTemplateTripartiteAgreement: "canViewTemplateTripartiteAgreement",
    /**
     * Создание договорных документов.
     */
    canCreateContractualDocuments: "canCreateContractualDocuments",
    //
    // СУДЕБНЫЕ ДОКУМЕНТЫ (Юрист по судебным делам)
    //
    /**
     * Создание шаблона Искового заявления.
     */
    canCreateTemplateStatementOfClaim: "canCreateTemplateStatementOfClaim",
    /**
     * Редактирование шаблона Искового заявления.
     */
    canEditTemplateStatementOfClaim: "canEditTemplateStatementOfClaim",
    /**
     * Просмотр шаблона Искового заявления.
     */
    canViewTemplateStatementOfClaim: "canViewTemplateStatementOfClaim",
    /**
     * Создание шаблона Отзыва искового заявления.
     */
    canCreateTemplateRevocationStatementOfClaim: "canCreateTemplateRevocationStatementOfClaim",
    /**
     * Редактирование шаблона Отзыва искового заявления.
     */
    canEditTemplateRevocationStatementOfClaim: "canEditTemplateRevocationStatementOfClaim",
    /**
     * Просмотр шаблона Отзыва искового заявления.
     */
    canViewTemplateRevocationStatementOfClaim: "canViewTemplateRevocationStatementOfClaim",
    /**
     * Создание шаблона Ходатайства.
     */
    canCreateTemplatePetitions: "canCreateTemplatePetitions",
    /**
     * Редактирование шаблона Ходатайства.
     */
    canEditTemplatePetitions: "canEditTemplatePetitions",
    /**
     * Просмотр шаблона Ходатайства.
     */
    canViewTemplatePetitions: "canViewTemplatePetitions",
    /**
     * Создание шаблона Заявлений.
     */
    canCreateTemplateStatements: "canCreateTemplateStatements",
    /**
     * Редактирование шаблона Заявлений.
     */
    canEditTemplateStatements: "canEditTemplateStatements",
    /**
     * Просмотр шаблона Заявлений.
     */
    canViewTemplateStatements: "canViewTemplateStatements",
    /**
     * Создание судебных документов.
     */
    canCreateCourtDocuments: "canCreateCourtDocuments",
    //
    // ДОВЕРЕННОСТЬ И ПОЛНОМОЧИЯ
    //
    /**
     * Создание шаблона доверенности.
     */
    canCreatePowerOfAttorney: "canCreatePowerOfAttorney",
    /**
     * Редактирование шаблона доверенности.
     */
    canEditPowerOfAttorney: "canEditPowerOfAttorney",
    /**
     * Просмотр шаблона доверенности.
     */
    canViewPowerOfAttorney: "canViewPowerOfAttorney",
    /**
     * Создание шаблона отзыва доверенности.
     */
    canCreateRevokePowerOfAttorney: "canCreateRevokePowerOfAttorney",
    /**
     * Редактирование шаблона отзыва доверенности.
     */
    canEditRevokePowerOfAttorney: "canEditRevokePowerOfAttorney",
    /**
     * Просмотр шаблона отзыва доверенности.
     */
    canViewRevokePowerOfAttorney: "canViewRevokePowerOfAttorney",
    /**
     * Создание шаблона полномочия.
     */
    canCreateAuthority: "canCreateAuthority",
    /**
     * Редактирование шаблона полномочия.
     */
    canEditAuthority: "canEditAuthority",
    /**
     * Просмотр шаблона полномочия.
     */
    canViewAuthority: "canViewAuthority",
    /**
     * Создание документов доверенности.
     */
    canCreateOfAttorneyDocuments: "canCreateOfAttorneyDocuments",
    /**
     * Просмотр и редактирование админки добавления ПХ в Библиотеку ПХ
     */
    canEditPlaceholderLibraryAdmin: "canEditPlaceholderLibraryAdmin",
    canViewPlaceholderLibraryAdmin: "canViewPlaceholderLibraryAdmin"
};
