"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPropsFromPlaceholder = void 0;
var lodash_1 = require("lodash");
var phKeys = [
    'action',
    'attachmentDocumentID',
    'attachmentTemplateID',
    'customFormulaData',
    'dataFormat',
    'dataValue',
    'desc',
    'elementId',
    'external_options',
    'external_params',
    'format',
    'formula_id',
    'fromPlaceholder_id',
    'fromSystem',
    'id',
    'initial_title',
    'isAutonumbering',
    'isHidden',
    'isLib',
    'isReceived',
    'isRequired',
    'isSent',
    'libraryPlaceholder_id',
    'name',
    'options',
    'order',
    'params',
    'parent_id',
    'settings',
    'subType',
    'title',
    'target',
    'type',
    /* TODO: need test */
    'udmsOptions',
    'canBeEditedInPreview',
    'has_relations',
];
var getPropsFromPlaceholder = function (ph, values) {
    var phData = {};
    phKeys.forEach(function (id) {
        phData[id] = (0, lodash_1.cloneDeep)(ph[id]);
    });
    return Object.assign(phData, values);
};
exports.getPropsFromPlaceholder = getPropsFromPlaceholder;
