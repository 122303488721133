"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var BlockTypes_1 = require("../TemplateRender/BlockTypes");
var Option = antd_1.Select.Option;
var emptyValueText = 'Пустое значение';
var emptyValue = { label: 'Не выбрано', value: '', key: undefined };
var getLabeledValue = function (settings, childItem, parentPlaceholderOptions, isParentSection) {
    var textValue = '';
    var value = undefined;
    var linkIdKey = isParentSection ? 'groupId' : 'id';
    Object.keys(settings).forEach(function (item) {
        try {
            if (settings[item].find(function (link) { return link.id === childItem.groupId || link.id === childItem.id; })) {
                var exactItem = parentPlaceholderOptions.find(function (obj) { return obj[linkIdKey] === item; });
                value = isParentSection ? exactItem.groupId : exactItem === null || exactItem === void 0 ? void 0 : exactItem.id;
                textValue = (exactItem === null || exactItem === void 0 ? void 0 : exactItem.text) || (exactItem === null || exactItem === void 0 ? void 0 : exactItem.name);
            }
        }
        catch (error) {
            console.error(error);
        }
    });
    if (value) {
        return { label: textValue, value: value, key: value };
    }
    else {
        return emptyValue;
    }
};
var PlaceholderLinks = function (props) {
    // const getSavedPhIds = localStorage.getItem('PlaceholderLastIds');
    // const savedIdsArray = getSavedPhIds?.split(',') || [];
    var settings = {};
    var selectWrapper = null;
    if (props.settings["".concat(props.childId)]) {
        settings = props.settings["".concat(props.childId)];
    }
    // else 
    // {
    //   savedIdsArray.forEach((item) => {
    //     settings = {...props.settings[`${item}`]};
    //   });
    // }
    var childPlaceholderOptions = props.childPlaceholderOptions, parentPlaceholderOptions = props.parentPlaceholderOptions;
    var isParentSection = props.parentType === BlockTypes_1.blockTypes.section.type;
    // Фильтруем выбранные опции родительского пх
    // const parentOptions = parentPlaceholderOptions.filter(option => !settings[option.id]);
    var parentOptions = parentPlaceholderOptions;
    return ((0, jsx_runtime_1.jsx)("div", __assign({ ref: function (node) {
            selectWrapper = node;
        } }, { children: childPlaceholderOptions.map(function (childItem, index) { return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsxs)("label", __assign({ htmlFor: "place_holder_type", className: "placeholder-type-label" }, { children: [index + 1, ". ", childItem.text || childItem.name || emptyValueText] })), (0, jsx_runtime_1.jsxs)(antd_1.Select, __assign({ className: 'panel__select', style: { minWidth: '100px' }, onChange: function (value) { var _a; return (_a = props.onPlaceLinksSet) === null || _a === void 0 ? void 0 : _a.call(props, value, childItem.id); }, value: getLabeledValue(settings, childItem, parentPlaceholderOptions, isParentSection), disabled: props.isDisabled, getPopupContainer: function () { return selectWrapper; } }, { children: [(0, jsx_runtime_1.jsx)(Option, __assign({ value: emptyValue.value }, { children: "\u041D\u0435 \u0432\u044B\u0431\u0440\u0430\u043D\u043E" })), parentOptions.map(function (parentItem) { return ((0, jsx_runtime_1.jsx)(Option, __assign({ 
                            // для плейсхолдера передаем id опции
                            value: isParentSection ? parentItem.groupId : parentItem.id }, { children: parentItem.text || emptyValueText }), parentItem.id)); })] }))] }, childItem.id)); }) })));
};
PlaceholderLinks.defaultProps = {
    settings: {},
    childId: '',
    parentType: '',
    onPlaceLinksSet: function () {
    },
    isDisabled: false,
};
exports.default = PlaceholderLinks;
