"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckboxListItem = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var commonModels_1 = require("../../../../Root/utils/api/models/common/commonModels");
var Controls_1 = require("../../../../models/Controls");
require("../FolderViewList/FolderItem.less");
var CheckboxListItem = function (record, isBulkCheckboxMixed, selectedFolderBulked, canEdit, bulkEntity) {
    var typeKey = record.typeKey, kind = record.kind, currentUserControls = record.currentUserControls, status = record.status, id = record.id;
    var isFolder = (kind !== commonModels_1.FolderKind.None);
    var isItemChecked = selectedFolderBulked.some(function (entity) { return entity.id === id; });
    var canCheck = (0, Controls_1.checkCanWriteControls)(currentUserControls);
    var disabled = (!canEdit) || (!canCheck);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: ["folder-item__bulk", isBulkCheckboxMixed && "canBulk"].join(" ") }, { children: [(0, jsx_runtime_1.jsx)("div", { className: ["folder-item__type", isFolder && "type-folder"].join(" ") }), (0, jsx_runtime_1.jsx)(antd_1.Checkbox, { className: "folder-item__bulk-checkbox", checked: isItemChecked, disabled: disabled, onChange: function (e) { return bulkEntity(__assign(__assign({}, record), { checked: e.target.checked })); } })] })));
};
exports.CheckboxListItem = CheckboxListItem;
