"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibraryTop = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var PlaceholderLibraryBreadcrumbs_1 = require("../PlaceholderLibraryBreadcrumbs/PlaceholderLibraryBreadcrumbs");
var PlaceholderLibraryCreateButton_1 = require("../PlaceholderLibraryCreateButton/PlaceholderLibraryCreateButton");
var react_router_dom_1 = require("react-router-dom");
var PlaceholderLibraryTop = function (_a) {
    var props = __rest(_a, []);
    var folders = props.folders, currentFolder = props.currentFolder;
    var history = (0, react_router_dom_1.useHistory)();
    var historyLocationState = history.location.state;
    var placeholderId = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.placeholderId;
    var currentFolderKind = currentFolder === null || currentFolder === void 0 ? void 0 : currentFolder.kind;
    var renderButtonCondition = currentFolderKind === 'subpart' || currentFolderKind === 'folder';
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: 'phadm-top', style: { margin: '15px 0', minHeight: 22 } }, { children: [(0, jsx_runtime_1.jsx)(PlaceholderLibraryBreadcrumbs_1.PlaceholderLibraryBreadcrumbs, { folders: folders, currentFolder: currentFolder }), renderButtonCondition && ((0, jsx_runtime_1.jsx)(PlaceholderLibraryCreateButton_1.PlaceholderLibraryCreateButton, { currentFolder: currentFolder, historyLocationState: historyLocationState, placeholderId: placeholderId }))] })));
};
exports.PlaceholderLibraryTop = PlaceholderLibraryTop;
