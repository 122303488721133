"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var icons_1 = require("@ant-design/icons");
var CheckApplicationsButton_1 = require("../../../shared/components/DocumentHeader/CheckApplicationsButton");
var CopyingLinkDocumentButton_1 = require("../../../shared/components/DocumentHeader/CopyingLinkDocumentButton");
var DocumentButton_1 = require("../../../shared/components/DocumentHeader/DocumentButton");
var DownloadFileButton_1 = require("../../../shared/components/DocumentHeader/DownloadFileButton");
var HeaderButtons = function (props) {
    var showCreate = props.showCreate, createDisabled = props.createDisabled, showCopy = props.showCopy, onCopy = props.onCopy, showDownloadFile = props.showDownloadFile, onCreateFile = props.onCreateFile, showSendToUDMS = props.showSendToUDMS, showSendDocumentPublish = props.showSendDocumentPublish, 
    // TODO Поменять название позже [Mouseee]
    sentToUDSMDisabled = props.sentToUDSMDisabled, onSentToUDMS = props.onSentToUDMS, showCheckAttachment = props.showCheckAttachment, onCheckAttachment = props.onCheckAttachment, checkAttachmentDisabled = props.checkAttachmentDisabled, showSelectPDC = props.showSelectPDC, selectPDCDisabled = props.selectPDCDisabled, onSelectPDC = props.onSelectPDC, showDocumentVerify = props.showDocumentVerify, onDocumentVerify = props.onDocumentVerify, systemToSendName = props.systemToSendName, hasAttachmentsCheck = props.hasAttachmentsCheck, hasSelectPdc = props.hasSelectPdc, courtDocOptions = props.courtDocOptions;
    return ((0, jsx_runtime_1.jsxs)("div", { children: [showCopy && ((0, jsx_runtime_1.jsx)(CopyingLinkDocumentButton_1.CopyingLinkDocumentButton, { text: "\u0421\u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0441\u0441\u044B\u043B\u043A\u0443", onClick: onCopy })), showCreate && ((0, jsx_runtime_1.jsx)(DownloadFileButton_1.DownloadFileButton, { text: "\u0421\u0444\u043E\u0440\u043C\u0438\u0440\u043E\u0432\u0430\u0442\u044C", disabled: createDisabled, onClick: onCreateFile })), showDownloadFile && ((0, jsx_runtime_1.jsx)(DownloadFileButton_1.DownloadFileButton, { text: "\u0412\u044B\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u0444\u0430\u0439\u043B", onClick: onCreateFile })), showDocumentVerify && ((0, jsx_runtime_1.jsx)(DocumentButton_1.DocumentButton, { text: "\u041F\u0440\u043E\u0432\u0435\u0440\u0438\u0442\u044C \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442", onClick: onDocumentVerify })), (showSendToUDMS || showSendDocumentPublish) && ((0, jsx_runtime_1.jsx)(DocumentButton_1.DocumentButton, { text: systemToSendName, disabled: sentToUDSMDisabled, onClick: onSentToUDMS })), showCheckAttachment && (hasAttachmentsCheck || courtDocOptions) && ((0, jsx_runtime_1.jsx)(CheckApplicationsButton_1.CheckApplicationsButton, { onClick: onCheckAttachment, text: "\u041F\u0440\u043E\u0432\u0435\u0440\u0438\u0442\u044C \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u044F", disabled: checkAttachmentDisabled, icon: (0, jsx_runtime_1.jsx)(icons_1.DownloadOutlined, {}) })), showSelectPDC && hasSelectPdc && ((0, jsx_runtime_1.jsx)(DocumentButton_1.DocumentButton, { text: "\u0412\u044B\u0431\u043E\u0440 \u041F\u0414\u0426", disabled: selectPDCDisabled, onClick: onSelectPDC }))] }));
};
exports.default = HeaderButtons;
