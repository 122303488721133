"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortSectionsByGroups = exports.sortSections = void 0;
function sortSections(sections) {
    // здесь sections - блоки внутри секции
    // TODO: можно упростить сортировку
    if (!sections.length) {
        return sections;
    }
    var sectionId = sections[0].order.split('_')[0];
    var orderedSections = sections
        .map(function (item) {
        var parts = item.order.split('_');
        return {
            section: item,
            baseSection: parts[0],
            order: +parts[1],
        };
    })
        .filter(function (item) { return item.baseSection === sectionId; })
        .sort(function (a, b) { return a.order - b.order; })
        .map(function (item) { return item.section; });
    return orderedSections;
}
exports.sortSections = sortSections;
function sortSectionsByGroups(orderedGroups, sections) {
    var buffer = [];
    orderedGroups.forEach(function (groupBlock) {
        buffer = buffer.concat(sections.filter(function (sec) {
            if (groupBlock.groupId) {
                return sec.groupId === groupBlock.groupId;
            }
            return sec.id === groupBlock.id;
        }));
    });
    return buffer;
}
exports.sortSectionsByGroups = sortSectionsByGroups;
