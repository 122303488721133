"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityBlockAttachment = void 0;
var EntityConstans_1 = require("../EntityConstans");
var UtilityDOM_1 = require("../UtilityDOM");
var EntityAttachment_1 = require("./EntityAttachment");
var EntityBlock_1 = require("./EntityBlock");
var EntityType_1 = require("./EntityType");
/**
 * Блок содержащий список приложений
 * В качестве дочерних элементов содержит ТОЛЬКО EntityAttachment
 */
var EntityBlockAttachment = /** @class */ (function (_super) {
    __extends(EntityBlockAttachment, _super);
    //#region Конструкторы
    function EntityBlockAttachment(id, parent) {
        if (parent === void 0) { parent = null; }
        var _this = _super.call(this, id, parent) || this;
        /**
         * Тип
         */
        _this.type = EntityType_1.TEntityType.BlockAttachment;
        // Блок приложения НЕ может участвовать в нумерации
        _this.isNumericSupport = false;
        return _this;
    }
    //#endregion
    /**
     * Создание дочерних сущностей на основание DOM
     * id должен быть корректным
     * @param isDoc Режим документа (предпросмотра)
     */
    EntityBlockAttachment.prototype.createFromDOM = function (isDoc) {
        var _this = this;
        var idHTML = isDoc ? this.id : "wrapper_".concat(this.id);
        var blockHTML = document.getElementById(idHTML);
        if (!blockHTML)
            return;
        var attachmentList = blockHTML.getElementsByClassName('attachment-list');
        if (!attachmentList || attachmentList.length === 0)
            return;
        var attachmentElements = Array.from(attachmentList[0].childNodes).map(function (node) { return node && node; });
        attachmentElements.forEach(function (attachBlock) {
            var _a;
            if (attachBlock.childElementCount > 0) {
                var attach = attachBlock.childNodes[0];
                if (attach) {
                    if (UtilityDOM_1.UtilityDOM.isAttachment(attach)) {
                        var id = (_a = attach.getAttribute('id')) !== null && _a !== void 0 ? _a : EntityConstans_1.EntityConstans.ID_NULL;
                        var entityAttachment = new EntityAttachment_1.EntityAttachment(id, _this);
                        entityAttachment.name = attach.innerHTML;
                        _this.addChild(entityAttachment);
                    }
                }
            }
        });
    };
    /**
     * Заполнить параметры сущности на основе объекта (блока или секции)
     * @param element Блок и секция
     */
    EntityBlockAttachment.prototype.fillFromElement = function (element) {
    };
    /**
     * Вычисления(заполнения) дерева нумерации элементов
     * @param tree Дерево нумерации элементов
     * @param currentNumeration Структура для нумерации
     */
    EntityBlockAttachment.prototype.calcTreeNumeration = function (tree, numeration) {
    };
    return EntityBlockAttachment;
}(EntityBlock_1.EntityBlock));
exports.EntityBlockAttachment = EntityBlockAttachment;
