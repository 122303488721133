"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.placeholderReader = void 0;
function placeholderReader(ph) {
    try {
        var res = {
            action: ph.action,
            attachmentDocumentId: ph.attachmentDocumentId,
            attachmentTemplateId: ph.attachmentTemplateId,
            dataFormat: ph.dataFormat,
            dataValue: ph.dataValue,
            description: ph.description,
            element_id: ph.element_id,
            external_options: ph.external_options,
            external_params: typeof ph.external_params === 'string' ? JSON.parse(ph.external_params) : ph.external_params,
            formula_id: ph.formula_id,
            fromPlaceholder_id: ph.fromPlaceholder_id,
            fromSystem: ph.fromSystem,
            id: ph.id,
            initial_title: ph.initial_title,
            isAutonumbering: ph.isAutonumbering,
            isFooterOn: ph.isFooterOn,
            isHeaderOn: ph.isHeaderOn,
            isLib: ph.isLib,
            isReceived: ph.isReceived,
            isRequired: ph.isRequired,
            isSent: ph.isSent,
            linkedPlaceholders_ids: ph.linkedPlaceholders_ids,
            name: ph.name,
            options: typeof ph.options === 'string' ? JSON.parse(ph.options) : ph.options,
            order: ph.order,
            parentPlaceholder_id: ph.parentPlaceholder_id,
            settings: typeof ph.settings === 'string' ? JSON.parse(ph.settings) : ph.settings,
            subType: ph.subType,
            target: typeof ph.target === 'string' ? JSON.parse(ph.target) : ph.target,
            template_id: ph.template_id,
            type_id: ph.type_id,
            type: ph.type_id,
            customFormulaData: ph.customFormulaData,
            isHidden: ph.isHidden,
        };
        return res;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}
exports.placeholderReader = placeholderReader;
