"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Breadcrumbs = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
require("./Breadcrumbs.less");
var Breadcrumbs = function (_a) {
    var breadcrumbs = _a.breadcrumbs, changeLocation = _a.changeLocation;
    return ((0, jsx_runtime_1.jsx)(antd_1.Breadcrumb, __assign({ className: "breadcrumbs", separator: (0, jsx_runtime_1.jsx)("span", { className: 'breadcrumb-separator' }) }, { children: breadcrumbs.map(function (breadcrumb, index) { return ((0, jsx_runtime_1.jsx)(antd_1.Breadcrumb.Item, { children: (0, jsx_runtime_1.jsx)("a", __assign({ className: ['breadcrumb-link', index === breadcrumbs.length - 1 && 'current-breadcrumb'].join(' '), onClick: function () { return changeLocation(breadcrumb.id); } }, { children: breadcrumb.name || 'ROOT' })) }, "breadcrumb_".concat(breadcrumb.id))); }) })));
};
exports.Breadcrumbs = Breadcrumbs;
