"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeFromLocalStorage = exports.setLocalStorage = exports.getLocalStorage = void 0;
var getStorage = function (storage) { return function (fields) {
    if (fields === void 0) { fields = []; }
    var readBuffer = {};
    fields
        .forEach(function (field) {
        var _a;
        return Object.assign(readBuffer, (_a = {}, _a[field] = storage.getItem(field), _a));
    });
    return readBuffer;
}; };
var setStorage = function (storage) { return function (fields) {
    if (fields === void 0) { fields = {}; }
    Object
        .keys(fields)
        .forEach(function (field) { return storage.setItem(field, fields[field]); });
}; };
var removeFromStorage = function (storage) { return function (fields) {
    if (fields === void 0) { fields = []; }
    fields.forEach(function (field) { return storage.removeItem(field); });
}; };
exports.getLocalStorage = getStorage(localStorage);
exports.setLocalStorage = setStorage(localStorage);
exports.removeFromLocalStorage = removeFromStorage(localStorage);
