"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentMode = void 0;
var react_1 = require("react");
var useCurrentMode = function () {
    var _a = (0, react_1.useState)('light'), theme = _a[0], setTheme = _a[1];
    var _b = (0, react_1.useState)(false), mountedComponent = _b[0], setMountedComponent = _b[1];
    var localTheme = window.localStorage.getItem('theme');
    var setMode = (0, react_1.useCallback)(function (mode) {
        window.localStorage.setItem('theme', mode);
        setTheme(mode);
    }, []);
    var themeToggler = function (modeVariant) { return setMode(modeVariant); };
    (0, react_1.useEffect)(function () {
        // eslint-disable-next-line no-unused-expressions
        localTheme ? setTheme(localTheme) : setMode('light');
        setMountedComponent(true);
    }, []);
    return [theme, themeToggler, mountedComponent];
};
exports.useCurrentMode = useCurrentMode;
