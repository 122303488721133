"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.correctReliableSection = exports.correctReliablePlaceholders = exports.checkModelDefaultPhOrSectionOne = exports.checkModelDefaultPh = exports.inSameSection = exports.getPlacementAggregationModelLevel = exports.getPlacementAggregationModel = void 0;
var getPlacementAggregationModel = function (model, blocks, sections) {
    var result = model && { model: model, name: model.name };
    // Это ПХ
    if (model === null || model === void 0 ? void 0 : model.elementId) {
        // Есть ли в блоках
        var block = blocks.find(function (x) { return x.id === model.elementId; });
        if (block) {
            return result;
        }
        var group_1 = sections.find(function (x) { return x.id === model.elementId; });
        if (group_1) {
            // 1 уровень
            result.groupId = group_1.groupId;
            result.sectionId = group_1.sectionId;
            var groupParent_1 = sections.find(function (x) { return x.id === group_1.sectionId; });
            if (groupParent_1) {
                // 2 уровень
                result.parentGroupId = groupParent_1.groupId;
                result.parentSectionId = groupParent_1.sectionId;
                var grandGroupParent_1 = sections.find(function (x) { return x.id === groupParent_1.sectionId; });
                if (grandGroupParent_1) {
                    // 3 уровень
                    result.grandParentGroupId = grandGroupParent_1.groupId;
                    result.grandParentSectionId = grandGroupParent_1.sectionId;
                    var greatGrandGroupParent = sections.find(function (x) { return x.id === grandGroupParent_1.sectionId; });
                    if (greatGrandGroupParent) {
                        // 4 уровень
                        result.greatGrandParentGroupId = greatGrandGroupParent.groupId;
                        result.greatGrandParentSectionId = greatGrandGroupParent.sectionId;
                    }
                }
            }
        }
    }
    else {
        // Секция
        var block = model ? blocks.find(function (x) { return x.id === model.id; }) : null;
        if (block) {
            return result;
        }
        var group_2 = model ? sections.find(function (x) { return x.id === model.id; }) : null;
        if (group_2) {
            // 1 уровень
            result.groupId = group_2.groupId;
            result.sectionId = group_2.sectionId;
            // Где-то во вложенных секциях
            var groupParent_2 = sections.find(function (x) { return x.id === group_2.sectionId; });
            if (groupParent_2) {
                // 2 уровень
                result.parentGroupId = groupParent_2.groupId;
                result.parentSectionId = groupParent_2.sectionId;
                var grandGroupParent_2 = sections.find(function (x) { return x.id === groupParent_2.sectionId; });
                if (grandGroupParent_2) {
                    // 3 уровень
                    result.grandParentGroupId = grandGroupParent_2.groupId;
                    result.grandParentSectionId = grandGroupParent_2.sectionId;
                    var greatGrandGroupParent = sections.find(function (x) { return x.id === grandGroupParent_2.sectionId; });
                    if (greatGrandGroupParent) {
                        // 4 уровень
                        result.greatGrandParentGroupId = greatGrandGroupParent.groupId;
                        result.greatGrandParentSectionId = greatGrandGroupParent.sectionId;
                    }
                }
            }
        }
    }
    return result;
};
exports.getPlacementAggregationModel = getPlacementAggregationModel;
/**
 * Получить уровень расположения модели
 * @param model Модель
 * @returns Уровень расположения модели, где 0 модель располагается в блоке или в секции соответствующего уровня
 */
var getPlacementAggregationModelLevel = function (model) {
    if (model.greatGrandParentGroupId)
        return 4;
    if (model.grandParentGroupId)
        return 3;
    if (model.parentGroupId)
        return 2;
    if (model.groupId)
        return 1;
    return 0;
};
exports.getPlacementAggregationModelLevel = getPlacementAggregationModelLevel;
/**
 * Проверка моделей что они находятся в одной секции
 * @param model Модель
 * @param checkModel Проверяемая модель
 */
var inSameSection = function (model, checkModel) {
    if (!model || !checkModel)
        return true;
    var levelModel = (0, exports.getPlacementAggregationModelLevel)(model);
    var levelCheck = (0, exports.getPlacementAggregationModelLevel)(checkModel);
    switch (levelModel) {
        case 0: {
            // Можем связаться с любой моделью по глубине
            return true;
        }
        case 1:
            {
                switch (levelCheck) {
                    case 0: {
                        return true;
                    }
                    case 1: {
                        return model.groupId === checkModel.groupId;
                    }
                    case 2: {
                        return model.groupId === checkModel.parentGroupId;
                    }
                    case 3: {
                        return model.groupId === checkModel.grandParentGroupId;
                    }
                    case 4: {
                        return model.groupId === checkModel.greatGrandParentGroupId;
                    }
                }
            }
            break;
        case 2:
            {
                switch (levelCheck) {
                    case 0: {
                        return true;
                    }
                    case 1: {
                        return model.parentSectionId === checkModel.sectionId;
                    }
                    case 2: {
                        return model.groupId === checkModel.groupId;
                    }
                    case 3: {
                        return model.groupId === checkModel.parentGroupId;
                    }
                    case 4: {
                        return model.parentGroupId === checkModel.greatGrandParentGroupId;
                    }
                }
            }
            break;
        case 3:
            {
                switch (levelCheck) {
                    case 0: {
                        return true;
                    }
                    case 1: {
                        return model.grandParentSectionId === checkModel.sectionId;
                    }
                    case 2: {
                        return model.grandParentSectionId === checkModel.parentSectionId;
                    }
                    case 3: {
                        return model.groupId === checkModel.groupId;
                    }
                    case 4: {
                        return model.groupId === checkModel.parentGroupId;
                    }
                }
            }
            break;
        case 4:
            {
                switch (levelCheck) {
                    case 0: {
                        return true;
                    }
                    case 1: {
                        return model.greatGrandParentSectionId === checkModel.sectionId;
                    }
                    case 2: {
                        return model.greatGrandParentSectionId === checkModel.parentSectionId;
                    }
                    case 3: {
                        return model.greatGrandParentSectionId === checkModel.greatGrandParentSectionId;
                    }
                    case 4: {
                        return model.groupId === checkModel.groupId;
                    }
                }
            }
            break;
    }
    return false;
};
exports.inSameSection = inSameSection;
/**
 * Проверка модели что она является либо доступным ПХ
 * @param model Модель
 */
var checkModelDefaultPh = function (model) {
    if (model.model.type === 'cascader' ||
        model.model.type === 'radio' ||
        model.model.type === 'text' ||
        model.model.type === 'formula')
        return true;
    return false;
};
exports.checkModelDefaultPh = checkModelDefaultPh;
/**
 * Проверка модели что она является либо доступным ПХ или секция с одним вариантом
 * @param model Модель
 */
var checkModelDefaultPhOrSectionOne = function (model) {
    if (model.model.type === 'section' && (model.model.subType !== 'multi' || model.model.sectionVariant === 'multi'))
        return true;
    if ((0, exports.checkModelDefaultPh)(model))
        return true;
    return false;
};
exports.checkModelDefaultPhOrSectionOne = checkModelDefaultPhOrSectionOne;
/**
 * Корректировка возможных привязок в ПХ
 * @param model Модель
 * @param sourceModels Все возможные элементы к которым можно привязаться
 * @returns Скорректированный список моделей для привязки
 */
var correctReliablePlaceholders = function (model, sourceModels) {
    var resultReliable = [];
    switch (model.model.type) {
        // ПХ список
        case 'radio':
        case 'cascader':
            {
                sourceModels.forEach(function (modelLink) {
                    if ((0, exports.checkModelDefaultPhOrSectionOne)(modelLink)) {
                        resultReliable.push(modelLink.model);
                    }
                });
            }
            break;
        // ПХ список
        case 'text':
            {
                sourceModels.forEach(function (modelLink) {
                    if ((0, exports.checkModelDefaultPh)(modelLink)) {
                        resultReliable.push(modelLink.model);
                    }
                });
            }
            break;
    }
    return resultReliable.sort(function (a, b) {
        var _a, _b;
        return ((_a = a.order) !== null && _a !== void 0 ? _a : 0) - ((_b = b.order) !== null && _b !== void 0 ? _b : 0);
    });
};
exports.correctReliablePlaceholders = correctReliablePlaceholders;
/**
 * Корректировка возможных привязок в секции
 * @param model Модель
 * @param sourceModels Все возможные элементы к которым можно привязаться
 * @returns Скорректированный список моделей для привязки
 */
var correctReliableSection = function (model, sourceModels) {
    var resultReliable = [];
    sourceModels.forEach(function (modelLink) {
        if (modelLink.model.type === 'section') {
            resultReliable.push(modelLink.model);
        }
        if (modelLink.model.type === 'radio') {
            resultReliable.push(modelLink.model);
        }
        if (modelLink.model.type === 'cascader') {
            resultReliable.push(modelLink.model);
        }
    });
    return resultReliable.sort(function (a, b) {
        var _a, _b;
        return ((_a = a.order) !== null && _a !== void 0 ? _a : 0) - ((_b = b.order) !== null && _b !== void 0 ? _b : 0);
    });
};
exports.correctReliableSection = correctReliableSection;
