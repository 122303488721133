"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkAndUpdateExternalParams = void 0;
var checkAndUpdateExternalParams = function (newElement, oldElement, blocks, sections) {
    var newElementChilds = __spreadArray([], sections, true).filter(function (_a) {
        var sectionId = _a.sectionId;
        return sectionId === newElement.id;
    });
    var oldElementChilds = __spreadArray([], sections, true).filter(function (_a) {
        var sectionId = _a.sectionId;
        return sectionId === oldElement.id;
    });
    var hasNewChilds = newElementChilds && newElementChilds.length > 0;
    var hasOldChilds = oldElementChilds && oldElementChilds.length > 0;
    if (newElement.external_params.links && hasNewChilds && hasOldChilds) {
        Object.keys(newElement.external_params.links).forEach(function (key) {
            var childByKey = oldElementChilds.find(function (_a) {
                var groupId = _a.groupId;
                return groupId === key;
            });
            if (childByKey) {
                var childOrder_1 = childByKey.order.split("_")[1];
                var newChildForLink = newElementChilds.find(function (_a) {
                    var order = _a.order;
                    return order.split("_")[1] === childOrder_1;
                });
                newElement.external_params.links[newChildForLink.groupId] = newElement.external_params.links[key];
                delete newElement.external_params.links[key];
            }
        });
    }
    return newElement;
};
exports.checkAndUpdateExternalParams = checkAndUpdateExternalParams;
