"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.alignButtons = exports.styleButtons = void 0;
var constants_1 = require("../../../constants");
exports.styleButtons = [
    {
        title: 'Жирный',
        style: constants_1.NodeStyles.Bold,
    },
    {
        title: 'Курсив',
        style: constants_1.NodeStyles.Italic,
    },
    {
        title: 'Подчёркивание',
        style: constants_1.NodeStyles.Underline,
    },
];
exports.alignButtons = [
    {
        title: 'Выровнять по левому краю',
        style: constants_1.TextAlign.Left,
    },
    {
        title: 'Выровнять по центру',
        style: constants_1.TextAlign.Center,
    },
    {
        title: 'Выровнять по правому краю',
        style: constants_1.TextAlign.Right,
    },
    {
        title: 'Выровнять по ширине',
        style: constants_1.TextAlign.Justify,
    },
];
