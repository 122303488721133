"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemCataloguePanel = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var uuid_1 = require("uuid");
var SystemSelect_1 = require("./SystemSelect");
var placeholderValidationUtils_1 = require("../../../utils/placeholderValidationUtils");
var SystemCataloguePanel = function (_a) {
    var props = __rest(_a, []);
    var aggregation = props.aggregation, placeholder = props.placeholder, _b = props.disabled, disabled = _b === void 0 ? false : _b, updateAggregations = props.updateAggregations, _c = props.systemsCatalogue, systemsCatalogue = _c === void 0 ? [] : _c;
    var aggregationTarget = typeof aggregation.target === 'string' ? JSON.parse(aggregation.target) : aggregation.target;
    var onIsSentChange = function (checked) {
        aggregation.isSent = checked;
        aggregationTarget = checked ? addEmptySystem([]) : [];
        var aggregationChanged = __assign(__assign({}, aggregation), { target: aggregationTarget });
        updateAggregations([aggregationChanged]);
    };
    var onTargetSystemChange = function (targetSystemId, attrToSet) { return function (value) {
        aggregationTarget = aggregationTarget === null || aggregationTarget === void 0 ? void 0 : aggregationTarget.map(function (targetSystem) {
            var updatedSystem = targetSystem;
            if (targetSystem.id === targetSystemId) {
                updatedSystem[attrToSet] = value;
                if (attrToSet === 'system') {
                    updatedSystem.fields = null;
                }
            }
            return updatedSystem;
        });
        if (placeholder && (0, placeholderValidationUtils_1.isFilledAttributes)(placeholder)) {
            placeholder.resetInvalid(placeholderValidationUtils_1.InvalidEnum.Attributes);
        }
        var aggregationChanged = __assign(__assign({}, aggregation), { target: aggregationTarget });
        updateAggregations([aggregationChanged]);
    }; };
    var onSystemDelete = function (targetSystemId) { return function () {
        aggregationTarget = aggregationTarget === null || aggregationTarget === void 0 ? void 0 : aggregationTarget.filter(function (targetSystem) { return targetSystem.id !== targetSystemId; });
        if (!(aggregationTarget === null || aggregationTarget === void 0 ? void 0 : aggregationTarget.length)) {
            aggregation.isSent = false;
        }
        var aggregationChanged = __assign(__assign({}, aggregation), { target: aggregationTarget });
        updateAggregations([aggregationChanged]);
    }; };
    var onSystemAdd = function () {
        aggregationTarget = addEmptySystem(aggregationTarget);
        var aggregationChanged = __assign(__assign({}, aggregation), { target: aggregationTarget });
        updateAggregations([aggregationChanged]);
    };
    var addEmptySystem = function (target) {
        var newSystem = {
            id: (0, uuid_1.v4)(),
            system: '',
            fields: null,
        };
        return target === null || target === void 0 ? void 0 : target.concat(newSystem);
    };
    var isSent = aggregation.isSent;
    var systemsCatalogueLocation = systemsCatalogue[0].id;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: 'systems-catalogue' }, { children: [systemsCatalogueLocation !== 'authority' && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: 'related-switch-wrapper' }, { children: [(0, jsx_runtime_1.jsx)(antd_1.Switch, { size: 'small', checked: isSent, onChange: onIsSentChange, disabled: disabled || (aggregation.isSent && aggregation.type === 'exportContent') }), (0, jsx_runtime_1.jsx)("label", __assign({ className: 'related-switch-label', htmlFor: 'related_switch' }, { children: "\u041E\u0442\u043F\u0440\u0430\u0432\u043B\u044F\u0442\u044C \u043F\u043E\u043B\u0435 \u0432 \u0434\u0440\u0443\u0433\u0443\u044E \u0441\u0438\u0441\u0442\u0435\u043C\u0443" }))] }))), isSent &&
                (aggregationTarget === null || aggregationTarget === void 0 ? void 0 : aggregationTarget.map(function (targetSystem, index) { return ((0, jsx_runtime_1.jsx)(SystemSelect_1.SystemSelect, { index: index, disabled: disabled, isOneOfMany: (aggregationTarget === null || aggregationTarget === void 0 ? void 0 : aggregationTarget.length) > 1, targetSystem: targetSystem, systemsCatalogue: systemsCatalogue, onSystemDelete: onSystemDelete, onTargetSystemChange: onTargetSystemChange }, targetSystem.id)); })), isSent && !disabled && ((0, jsx_runtime_1.jsxs)("div", __assign({ onClick: onSystemAdd, "aria-disabled": disabled, className: "new-option-button" }, { children: [(0, jsx_runtime_1.jsx)("div", { className: "plus-icon" }), (0, jsx_runtime_1.jsx)("span", { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u0438\u0441\u0442\u0435\u043C\u0443" })] })))] })));
};
exports.SystemCataloguePanel = SystemCataloguePanel;
exports.default = exports.SystemCataloguePanel;
