"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.routeGenerator = exports.routes = void 0;
exports.routes = {
    home: '/',
    login: '/login',
    loginOidCallback: '/login/gate',
    editorCreate: '/templates/create/:id',
    contract: '/editContract',
    document: '/editDocument',
    library: '/editLibrary',
    udmsIntegrationResult: '/udmsIntegrationResult',
    contractEdit: '/editContract/:templateId',
    attachmentFooterEdit: '/editAttachmentFooter/:footerId',
    attachmentHeaderEdit: '/editAttachmentHeader/:headerId',
    documentEdit: '/editDocument/:documentId',
    documentPreview: '/previewDocument/:documentId',
    libraryEdit: '/editLibrary/:libraryId',
    attachmentEdit: '/editAttachment/:templateId',
    templatedListEdit: '/editTemplatedList/:templateId',
    revocationStatementOfClaimEdit: '/editRevocationStatementOfClaim/:templateId',
    statementOfClaimEdit: '/editStatementOfClaim/:templateId',
    statementsEdit: '/editStatements/:templateId',
    petitionsEdit: '/editPetitions/:templateId',
    additionalAgreementEdit: '/editAdditionalAgreement/:templateId',
    powerOfAttorney: '/editPowerOfAttorney/:templateId',
    revokePowerOfAttorney: '/editRevokePowerOfAttorney/:templateId',
    placeholderLibraryAdminEdit: '/placeholderLibraryAdmin',
    placeholderLibraryFolderEdit: '/placeholderLibraryAdmin/folder/:folderId',
    documents: '/documents',
    templates: '/templates',
    viewer: '/viewer/:pageId',
    templateSearch: '/templates-search',
    templatesFolder: '/templates/:folder',
    documentsFolder: '/documents/:folder',
    folder: '/:folder',
    libraryBlocks: '/library/:blocks',
    viewTemplate: '/viewTemplate/:templateId',
    authorityEdit: '/editAuthority/:templateId',
    relogin: '/relogin_private',
};
exports.routeGenerator = {
    // templateEditLink: (templateId: string) => `${routes.contractEdit.replace(':templateId', templateId)}`,
    contractEditLink: function (templateId) { return "".concat(exports.routes.contractEdit.replace(':templateId', templateId)); },
    viewTemplateLink: function (templateId) {
        return "".concat(exports.routes.viewTemplate.replace(':templateId', templateId));
    },
    documentEditLink: function (documentId) { return "".concat(exports.routes.documentEdit.replace(':documentId', documentId)); },
    documentPreviewLink: function (documentId) { return "".concat(exports.routes.documentPreview.replace(':documentId', documentId)); },
    libraryEditLink: function (librayId) { return "".concat(exports.routes.libraryEdit.replace(':libraryId', librayId)); },
    attachmentEditLink: function (templateId) { return "".concat(exports.routes.attachmentEdit.replace(':templateId', templateId)); },
    templatedListEditLink: function (templateId) { return "".concat(exports.routes.templatedListEdit.replace(':templateId', templateId)); },
    revocationStatementOfClaimEditLink: function (templateId) { return "".concat(exports.routes.revocationStatementOfClaimEdit.replace(':templateId', templateId)); },
    statementOfClaimEditLink: function (templateId) { return "".concat(exports.routes.statementOfClaimEdit.replace(':templateId', templateId)); },
    statementsEditLink: function (templateId) { return "".concat(exports.routes.statementsEdit.replace(':templateId', templateId)); },
    petitionsEditLink: function (templateId) { return "".concat(exports.routes.petitionsEdit.replace(':templateId', templateId)); },
    additionalAgreementEditLink: function (templateId) { return "".concat(exports.routes.additionalAgreementEdit.replace(':templateId', templateId)); },
    powerOfAttorneyEditLink: function (templateId) { return "".concat(exports.routes.powerOfAttorney.replace(':templateId', templateId)); },
    revokePowerOfAttorneyEditLink: function (templateId) { return "".concat(exports.routes.revokePowerOfAttorney.replace(':templateId', templateId)); },
    viewerEditLink: function (pageId) { return "".concat(exports.routes.viewer.replace(':pageId', pageId)); },
    editorCreateLink: function (id) { return "".concat(exports.routes.editorCreate.replace(':id', id)); },
    selectFolder: function (folderId) { return "".concat(exports.routes.folder.replace(':folder', folderId)); },
    terminationAgreementEditLink: function (documentId) { return "".concat(exports.routes.contractEdit.replace(':templateId', documentId)); },
    cessionEditLink: function (documentId) { return "".concat(exports.routes.contractEdit.replace(':templateId', documentId)); },
    attachmentFooterEditLink: function (footerId) { return "".concat(exports.routes.attachmentFooterEdit.replace(':footerId', footerId)); },
    attachmentHeaderEditLink: function (headerId) { return "".concat(exports.routes.attachmentHeaderEdit.replace(':headerId', headerId)); },
    authorityEditLink: function (templateId) { return "".concat(exports.routes.authorityEdit.replace(':templateId', templateId)); },
    placeholderLibraryAdminEditLink: function (templateId) { return "".concat(exports.routes.placeholderLibraryAdminEdit.replace(':templateId', templateId)); },
    placeholderLibraryFolderEditLink: function (folderId) { return "".concat(exports.routes.placeholderLibraryFolderEdit.replace(':folderId', folderId)); },
};
