"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AclFolderModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var compatible_1 = require("@ant-design/compatible");
var antd_1 = require("antd");
var store_1 = __importStar(require("../../../../store"));
var icons_1 = require("@ant-design/icons");
var uuid_1 = require("uuid");
var http_1 = require("../../../utils/http");
var api_v1_Folder_1 = require("../../../../Root/utils/api/api.v1.Folder");
var Controls_1 = require("../../../../models/Controls");
require("@ant-design/compatible/assets/index.css");
;
var AclFolderModal = function (props) {
    var visible = props.visible, onCancel = props.onCancel;
    // TODO: [Mouseee] Переписать
    var exploreState = store_1.default.getState().explorer;
    var dispatch = (0, store_1.useAppDispatch)();
    var selectedFolder = exploreState.selectedFolder;
    var singleEntityAction = exploreState.singleEntityAction;
    var _a = (0, react_1.useState)({
        dataSource: [],
        page: 1,
        perPage: 10,
        loading: false,
        isLoaded: false,
        options: []
    }), state = _a[0], setState = _a[1];
    var canOwnedTemplate = (singleEntityAction.currentUserControls & Controls_1.Controls.Ownership) === Controls_1.Controls.Ownership;
    var canWriteTemplate = (singleEntityAction.currentUserControls & Controls_1.Controls.Write) === Controls_1.Controls.Write || canOwnedTemplate;
    var disabled = (!canOwnedTemplate) && (singleEntityAction.currentUserControls !== Controls_1.Controls.None);
    var options = state.options, dataSource = state.dataSource, loading = state.loading;
    var getAcl = function (folderId) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, api_v1_Folder_1.v1FolderAclList)({ folderId: folderId })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result];
            }
        });
    }); };
    var handleSearch = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var searchResult, exisistUsers, options;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, http_1.searchUsersByLoginPage)(value, 15)];
                case 1:
                    searchResult = _a.sent();
                    exisistUsers = new Set(state.dataSource.map(function (x) { return x.userId; }));
                    options = searchResult.isOverflowed
                        ? []
                        : searchResult.payload
                            .filter(function (x) { return !exisistUsers.has(x.id); })
                            .map(function (x) { return ({
                            id: x.id,
                            value: x.name,
                            label: x.name
                        }); });
                    setState(__assign(__assign({}, state), { options: options }));
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSelect = function (_, option) {
        if (state.dataSource.some(function (x) { return x.userId === option.id; })) {
            return;
        }
        var newItem = {
            id: (0, uuid_1.v4)(),
            userId: option.id,
            userName: option.label,
            isWrite: false,
            isOwner: false
        };
        setState(__assign(__assign({}, state), { dataSource: __spreadArray(__spreadArray([], state.dataSource, true), [newItem], false) }));
    };
    var onChangeIsWrite = function (val, record) {
        record.isWrite = val;
        setState(__assign({}, state));
    };
    var onChangeIsOwner = function (val, record) {
        record.isOwner = val;
        setState(__assign({}, state));
    };
    var onDelete = function (record) {
        var data = state.dataSource.filter(function (x) { return x.id !== record.id; });
        setState(__assign(__assign({}, state), { dataSource: data }));
    };
    var convertAclToModels = function (acl) { return acl.map(function (x) {
        return {
            id: x.id,
            userId: x.userId,
            userName: x.userName || '',
            isWrite: (x.controls & Controls_1.Controls.Write) === Controls_1.Controls.Write,
            isOwner: (x.controls & Controls_1.Controls.Ownership) === Controls_1.Controls.Ownership
        };
    }); };
    var convertModelsToAcl = function (models) { return models.map(function (x) {
        var controls = (x.isOwner ? Controls_1.Controls.Ownership : Controls_1.Controls.None) | (x.isWrite ? Controls_1.Controls.Write : Controls_1.Controls.None);
        return {
            controls: controls,
            userId: x.userId
        };
    }); };
    var onOk = function (folderId, models) { return __awaiter(void 0, void 0, void 0, function () {
        var acl, e_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    acl = convertModelsToAcl(models);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, api_v1_Folder_1.v1FolderUpdateAcl)({ folderId: folderId, data: acl })];
                case 2:
                    _a.sent();
                    onCancel();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    errorMessage = e_1.response
                        && e_1.response
                        && e_1.response.data
                        && e_1.response.data.error
                        || 'Ошибка сохранения данных';
                    antd_1.message.error(errorMessage);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        function fetchAcl(templateId) {
            return __awaiter(this, void 0, void 0, function () {
                var dataSource, acl, e_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!props.visible && state.isLoaded) {
                                setState(__assign(__assign({}, state), { dataSource: [], isLoaded: false }));
                            }
                            if (!props.visible || state.isLoaded || state.loading) {
                                return [2 /*return*/];
                            }
                            setState(__assign(__assign({}, state), { loading: true }));
                            dataSource = [];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, getAcl(templateId)];
                        case 2:
                            acl = _a.sent();
                            dataSource = convertAclToModels(acl);
                            return [3 /*break*/, 5];
                        case 3:
                            e_2 = _a.sent();
                            antd_1.message.error(e_2.message);
                            throw e_2;
                        case 4:
                            setState(__assign(__assign({}, state), { dataSource: dataSource, isLoaded: true, loading: false }));
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        }
        ;
        fetchAcl(singleEntityAction.id);
    }, [props.visible, state]);
    return ((0, jsx_runtime_1.jsx)(antd_1.Modal, __assign({ title: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438 \u0434\u043E\u0441\u0442\u0443\u043F\u0430 \u0434\u043B\u044F \u043F\u0430\u043F\u043A\u0438 ".concat(singleEntityAction.name), open: visible, onOk: function () { return onOk(singleEntityAction.id, dataSource); }, onCancel: onCancel, okText: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F", cancelText: "\u041E\u0442\u043C\u0435\u043D\u0430", width: '800px' }, { children: (0, jsx_runtime_1.jsxs)(compatible_1.Form, __assign({ layout: "vertical" }, { children: [(0, jsx_runtime_1.jsx)(compatible_1.Form.Item, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F" }), (0, jsx_runtime_1.jsx)(compatible_1.Form.Item, { children: (0, jsx_runtime_1.jsx)(antd_1.AutoComplete, __assign({ disabled: disabled, allowClear: true, onSearch: function (val) { return handleSearch(val); }, onSelect: handleSelect, options: options }, { children: (0, jsx_runtime_1.jsx)(antd_1.Input, { size: "large", placeholder: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 email, \u043B\u043E\u0433\u0438\u043D \u0438\u043B\u0438 \u0438\u043C\u044F \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F" }) })) }), (0, jsx_runtime_1.jsx)(compatible_1.Form.Item, { children: (0, jsx_runtime_1.jsxs)(antd_1.Table, __assign({ loading: loading, dataSource: dataSource, rowKey: "id", size: "small", rowClassName: "table-row-small" }, { children: [(0, jsx_runtime_1.jsx)(antd_1.Table.Column, { title: '\u0424\u0418\u041E', dataIndex: 'userName' }, 'id'), (0, jsx_runtime_1.jsx)(antd_1.Table.Column, { title: '\u0420\u0435\u0434\u0430\u043A\u0442\u043E\u0440', dataIndex: 'isWrite', render: function (_, record) {
                                    return (0, jsx_runtime_1.jsx)(antd_1.Checkbox, { disabled: disabled, checked: record.isWrite, onChange: function (e) { return onChangeIsWrite(e.target.checked, record); } });
                                } }, 'id'), (0, jsx_runtime_1.jsx)(antd_1.Table.Column, { title: '\u0412\u043B\u0430\u0434\u0435\u043B\u0435\u0446', dataIndex: 'isOwner', render: function (_, record) {
                                    return (0, jsx_runtime_1.jsx)(antd_1.Checkbox, { disabled: disabled, checked: record.isOwner, onChange: function (e) { return onChangeIsOwner(e.target.checked, record); } });
                                } }, 'id'), (0, jsx_runtime_1.jsx)(antd_1.Table.Column, { dataIndex: '', render: function (_, record) {
                                    return (0, jsx_runtime_1.jsx)(antd_1.Button, { disabled: disabled, type: 'text', size: 'middle', danger: true, icon: (0, jsx_runtime_1.jsx)(icons_1.CloseOutlined, {}), onClick: function (_) { return onDelete(record); } });
                                } }, 'id')] })) })] })) })));
};
exports.AclFolderModal = AclFolderModal;
