"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFormulaButtonsView = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var icons_1 = require("@ant-design/icons");
var CustomFormulaButtonsView = function (_a) {
    var disableCondition = _a.disableCondition, selectOperation = _a.selectOperation, disableLeftBrackets = _a.disableLeftBrackets, disableRightBrackets = _a.disableRightBrackets, isEditing = _a.isEditing;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "button_group" }, { children: [(0, jsx_runtime_1.jsx)(antd_1.Button, { type: "primary", icon: (0, jsx_runtime_1.jsx)(icons_1.LeftSquareOutlined, {}), size: "small", disabled: isEditing, onClick: function () { return selectOperation("delete"); } }), (0, jsx_runtime_1.jsxs)(antd_1.Button.Group, __assign({ className: "buttons_table" }, { children: [(0, jsx_runtime_1.jsx)(antd_1.Button, { type: "primary", icon: (0, jsx_runtime_1.jsx)(icons_1.PlusOutlined, {}), size: "small", disabled: disableCondition, onClick: function () { return selectOperation("+"); } }), (0, jsx_runtime_1.jsx)(antd_1.Button, { type: "primary", icon: (0, jsx_runtime_1.jsx)(icons_1.MinusOutlined, {}), size: "small", disabled: disableCondition, onClick: function () { return selectOperation("-"); } }), (0, jsx_runtime_1.jsx)(antd_1.Button, { type: "primary", icon: (0, jsx_runtime_1.jsx)(icons_1.CloseOutlined, {}), size: "small", disabled: disableCondition, onClick: function () { return selectOperation("*"); } }), (0, jsx_runtime_1.jsx)(antd_1.Button, { type: "primary", icon: (0, jsx_runtime_1.jsx)(icons_1.PercentageOutlined, { rotate: 45 }), size: "small", disabled: disableCondition, onClick: function () { return selectOperation("/"); } }), (0, jsx_runtime_1.jsx)(antd_1.Button, { type: "primary", icon: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "(" }), size: "small", disabled: disableLeftBrackets, onClick: function () { return selectOperation("("); } }), (0, jsx_runtime_1.jsx)(antd_1.Button, { type: "primary", icon: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: ")" }), size: "small", disabled: disableRightBrackets, onClick: function () { return selectOperation(")"); } })] }))] })));
};
exports.CustomFormulaButtonsView = CustomFormulaButtonsView;
