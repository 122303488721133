"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AggregationPanelNotification = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
require("../AggregationPanel.less");
var AggregationPanelNotification = function (_a) {
    var _b;
    var props = __rest(_a, []);
    var _c = props.placeholders, placeholders = _c === void 0 ? [] : _c, _d = props.systemsCatalogue, systemsCatalogue = _d === void 0 ? [] : _d, _e = props.targetSystemValue, targetSystemValue = _e === void 0 ? null : _e;
    // находим все системные ПХ
    var findAllSystemPlaceholders = placeholders === null || placeholders === void 0 ? void 0 : placeholders.filter(function (placeholder) { return (placeholder === null || placeholder === void 0 ? void 0 : placeholder.type) === 'UDMS'; });
    // берем первое системное ПХ и определяем выбранную раннее для него систему
    var firstSystemPlaceholderName = (findAllSystemPlaceholders === null || findAllSystemPlaceholders === void 0 ? void 0 : findAllSystemPlaceholders.length) ? (_b = findAllSystemPlaceholders[0]) === null || _b === void 0 ? void 0 : _b.fromSystem : '';
    // находим эту систему среди систем
    var findSelectedSystem = systemsCatalogue === null || systemsCatalogue === void 0 ? void 0 : systemsCatalogue.find(function (system) { return (system === null || system === void 0 ? void 0 : system.id) === firstSystemPlaceholderName; });
    var findSelectedSystemName = findSelectedSystem === null || findSelectedSystem === void 0 ? void 0 : findSelectedSystem.name;
    var findSelectedSystemValue = findSelectedSystem === null || findSelectedSystem === void 0 ? void 0 : findSelectedSystem.id;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!targetSystemValue && (findAllSystemPlaceholders === null || findAllSystemPlaceholders === void 0 ? void 0 : findAllSystemPlaceholders.length) > 1
                ? (0, jsx_runtime_1.jsxs)("div", __assign({ className: 'system-notification system-notification_warning' }, { children: ["\u0420\u0430\u043D\u043D\u0435\u0435 \u0432\u044B\u0431\u0440\u0430\u043D\u043D\u0430\u044F \u0441\u0438\u0441\u0442\u0435\u043C\u0430 ", findSelectedSystemName] }))
                : null, targetSystemValue && targetSystemValue !== findSelectedSystemValue
                ? ((0, jsx_runtime_1.jsxs)("div", __assign({ className: 'system-notification system-notification_error' }, { children: ["\u041D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u043F\u043E\u043C\u0435\u043D\u044F\u0442\u044C \u0441\u0438\u0441\u0442\u0435\u043C\u044B \u043E\u0441\u0442\u0430\u043B\u044C\u043D\u044B\u0445 \u043F\u043B\u0435\u0439\u0441\u0445\u043E\u043B\u0434\u0435\u0440\u043E\u0432 \u0432 \u0441\u043E\u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0438\u0438 \u0441 \u0442\u0435\u043A\u0443\u0449\u0435\u0439: ", findSelectedSystemName] })))
                : null] }));
};
exports.AggregationPanelNotification = AggregationPanelNotification;
