"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removePlaceholderNode = exports.getNormalizedNodeList = exports.setElementsToDom = exports.getOnlyParagraphNodes = exports.changeBlockType = exports.getSelectedNodes = exports.getSelectionEdges = exports.findNodesForMove = exports.getTargetValues = exports.getCaretCharacterOffset = exports.replaceTagNameElement = exports.fixNodesAfterDelete = exports.fixNodesInWrapper = exports.fixNodesInList = exports.moveLiParagraph = exports.fixParagraph = exports.fixSpanNode = exports.fixStyleNode = exports.splitNode = exports.createNewParagraph = exports.createNewEmptyParagraph = exports.createNewInlineText = exports.appendChildNodes = void 0;
var uuid_1 = require("uuid");
var constants_1 = require("../../../constants");
var common_1 = require("../../../utils/common");
var BlockModel_1 = require("../Blocks/BlockModel");
var BlockTypes_1 = require("../BlockTypes");
var boundaryUtils_1 = require("./boundaryUtils");
var caret_1 = require("./caret");
var textEditUtils_1 = require("./textEditUtils");
function appendChildNodes(targetNode, node) {
    if (common_1.TypeUtils.isElement(node)) {
        targetNode.append.apply(targetNode, Array.from(node.childNodes));
    }
}
exports.appendChildNodes = appendChildNodes;
function createNewInlineText(innerText, tagName) {
    if (innerText === void 0) { innerText = ''; }
    if (tagName === void 0) { tagName = 'span'; }
    var s = document.createElement(tagName);
    s.setAttribute('id', (0, uuid_1.v4)());
    s.classList.add(constants_1.CLASS_NAMES.inlineText);
    s.innerHTML = common_1.TextUtils.fixZwnj(innerText);
    return s;
}
exports.createNewInlineText = createNewInlineText;
function createNewEmptyParagraph(blockType) {
    var tagName = blockType === BlockTypes_1.blockTypes.list.type ? 'li' : 'div';
    var p = document.createElement(tagName);
    p.setAttribute('id', (0, uuid_1.v4)());
    p.classList.add(constants_1.CLASS_NAMES.textParagraph);
    p.setAttribute(constants_1.ATTR_NAMES.paragraph, 'true');
    return p;
}
exports.createNewEmptyParagraph = createNewEmptyParagraph;
function createNewParagraph(blockType, innerText) {
    if (innerText === void 0) { innerText = ''; }
    var p = createNewEmptyParagraph(blockType);
    p.appendChild(createNewInlineText(innerText));
    return p;
}
exports.createNewParagraph = createNewParagraph;
function splitNode(targetValues) {
    // Так как мы разрезаем innerHTML в произвольном месте, нам необходимо нормализировать каждую из частей HTML перед вставкой в DOM
    var targetHTML = targetValues.tInlineText.innerHTML;
    var leftHTMLPart = targetHTML.slice(0, targetValues.pointOfInsert);
    var rightHTMLPart = targetHTML.slice(targetValues.pointOfInsert);
    var normalizedLeftPart = (0, textEditUtils_1.normalizeHtml)(leftHTMLPart);
    var normalizedRightPart = (0, textEditUtils_1.normalizeHtml)(rightHTMLPart);
    return [normalizedLeftPart, normalizedRightPart];
}
exports.splitNode = splitNode;
function fixStyleNode(node) {
    if (common_1.CheckUtils.isStyledNode(node)) {
        var n = document.createElement(node.tagName);
        n.innerHTML = common_1.TextUtils.removeZwnj(node.innerHTML);
        var newNode = createNewInlineText();
        newNode.append(n);
        return newNode;
    }
    return createNewInlineText();
}
exports.fixStyleNode = fixStyleNode;
function fixSpanNode(node) {
    if (!common_1.CheckUtils.isSpanNode(node)) {
        return node;
    }
    var newNode = createNewInlineText();
    newNode.innerHTML = common_1.TextUtils.fixZwnj(node);
    return newNode;
}
exports.fixSpanNode = fixSpanNode;
function fixParagraph(tPar) {
    if (common_1.TypeUtils.isElement(tPar)) {
        var newNode = createNewInlineText(tPar.textContent);
        tPar.innerHTML = '';
        tPar.append(newNode);
        (0, caret_1.setCaretEndPosition)(newNode);
    }
}
exports.fixParagraph = fixParagraph;
function fixNodesInParagraph(tPar) {
    var _a, _b;
    var count = tPar.childNodes.length;
    if (!count) {
        fixParagraph(tPar);
        return;
    }
    // есть испорченная разметка в старых шаблонах, в параграфе находится параграф
    if (count === 1 && common_1.TypeUtils.isElement(tPar) && common_1.TypeUtils.isParagraphNode(tPar.firstChild)) {
        var childNode = tPar.firstChild;
        tPar.before(childNode);
        tPar.remove();
        tPar = childNode;
    }
    // может появиться <br>, если зажать при удалении Backspace
    if (count === 1 && !common_1.CheckUtils.isInlineText(tPar.firstChild)) {
        fixParagraph(tPar);
    }
    for (var i = 0; i < count; i++) {
        var node = tPar.childNodes[i];
        // ПХ
        if (common_1.CheckUtils.isPlaceholder(node)) {
            // должны быть span с ПП/текстом слева
            if (!common_1.CheckUtils.isInlineText(node.previousSibling)) {
                node.before(createNewInlineText(''));
                i++;
            }
            // должны быть span с ПП/текстом справа
            if (!common_1.CheckUtils.isInlineText(node.nextSibling)) {
                node.after(createNewInlineText(''));
                i++;
            }
        }
        if (common_1.TypeUtils.isInlineText(node) && ((_a = node.textContent) === null || _a === void 0 ? void 0 : _a.charAt(0)) !== constants_1.U_ZWNJ) {
            node.prepend(constants_1.U_ZWNJ);
        }
        if (common_1.TypeUtils.isInlineText(node) && ((_b = node.textContent) === null || _b === void 0 ? void 0 : _b.charAt(1)) === constants_1.U_ZWNJ) {
            node.innerHTML = common_1.TextUtils.fixZwnj(node);
        }
        // при удалении текста, браузер может убрать из разметки обработчики
        if (common_1.CheckUtils.isPhOrBoundary(node)) {
            if (common_1.TypeUtils.isElement(node) && !node.getAttribute('onclick')) {
                common_1.AttrUtils.fixPlaceholderAttrs(node);
            }
            continue;
        }
        // b, i, u (могут появиться при удалении)
        if (common_1.TypeUtils.isElement(node) && common_1.CheckUtils.isStyledNode(node)) {
            tPar.replaceChild(fixStyleNode(node), node);
            node = tPar.childNodes[i];
        }
        // span со стилями (может появиться при удалении)
        if (common_1.TypeUtils.isElement(node) && common_1.CheckUtils.isSpanNode(node) && !common_1.CheckUtils.isInlineText(node)) {
            tPar.replaceChild(fixSpanNode(node), node);
            node = tPar.childNodes[i];
        }
        // два inlineText span рядом
        if (common_1.TypeUtils.isInlineTextWithoutBoundary(node) && common_1.TypeUtils.isInlineTextWithoutBoundary(node.previousSibling)) {
            node.innerHTML = common_1.TextUtils.removeZwnj(node);
            appendChildNodes(node.previousSibling, node);
            node.remove();
            i--;
        }
    }
}
function moveLiParagraph(node) {
    var firstChild = node.firstChild;
    // первый вложенный элемент списка не должен быть списком
    if (common_1.TypeUtils.isListElement(firstChild) && common_1.TypeUtils.isListElement(firstChild.parentElement)) {
        firstChild.before.apply(firstChild, Array.from(firstChild.childNodes));
        firstChild.remove();
        moveLiParagraph(node);
    }
}
exports.moveLiParagraph = moveLiParagraph;
function fixNodesInList(wrapper) {
    moveLiParagraph(wrapper);
    wrapper.childNodes.forEach(function (node) {
        if (common_1.CheckUtils.isLiParagraph(node)) {
            fixNodesInParagraph(node);
        }
        else {
            fixNodesInList(node);
        }
    });
}
exports.fixNodesInList = fixNodesInList;
function fixNodesInWrapper(parentNode) {
    var nodes;
    if (common_1.TypeUtils.isElement(parentNode.firstChild) && common_1.CheckUtils.isTextStyle(parentNode.firstChild)) {
        nodes = parentNode.firstChild.childNodes;
    }
    else {
        nodes = parentNode.childNodes;
    }
    var count = nodes.length;
    for (var i = 0; i < count; i++) {
        var node = nodes[i];
        if (common_1.TypeUtils.isElement(node)) {
            fixNodesInParagraph(node);
        }
        else {
            node.remove();
        }
    }
}
exports.fixNodesInWrapper = fixNodesInWrapper;
var fixNodesAfterDelete = function (wrapper) {
    if (!common_1.TypeUtils.isElement(wrapper) || !common_1.CheckUtils.isWrappable(wrapper)) {
        return;
    }
    // сначала исправление разметки передаваемого поля, оно может быть в нескольких параграфах
    (0, boundaryUtils_1.updateBoundaryNodes)(wrapper);
    // исправление разметки параграфов после удаления/разбиения нод
    if (common_1.TypeUtils.isWrappableList(wrapper)) {
        fixNodesInList(wrapper);
    }
    else {
        fixNodesInWrapper(wrapper);
    }
};
exports.fixNodesAfterDelete = fixNodesAfterDelete;
function replaceTagNameElement(element, oldTagName, newTagName) {
    var searchValue = new RegExp("^<".concat(oldTagName, "(.*)").concat(oldTagName, ">$"));
    return element.outerHTML.replace(searchValue, "<".concat(newTagName, "$1").concat(newTagName, ">"));
}
exports.replaceTagNameElement = replaceTagNameElement;
function getCaretCharacterOffset(element) {
    var caretOffset = 0;
    if (!common_1.TypeUtils.isNode(element)) {
        return caretOffset;
    }
    var doc = element.ownerDocument;
    var win = doc === null || doc === void 0 ? void 0 : doc.defaultView;
    var sel = win === null || win === void 0 ? void 0 : win.getSelection();
    if (!win || !sel) {
        return caretOffset;
    }
    if (sel.rangeCount > 0) {
        var range = sel.getRangeAt(0);
        var preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
    }
    return caretOffset;
}
exports.getCaretCharacterOffset = getCaretCharacterOffset;
function getTargetValues() {
    var selection = window.getSelection();
    if (!selection || !common_1.TypeUtils.isNode(selection.anchorNode)) {
        return null;
    }
    var baseNode = selection.anchorNode;
    var focusNode = selection.focusNode;
    var tInlineText = common_1.GetUtils.getParentElement(baseNode, common_1.CheckUtils.isInlineText);
    var tPar = common_1.GetUtils.getParentElement(baseNode, common_1.CheckUtils.isParagraphNode);
    if (!tInlineText || !tPar) {
        return null;
    }
    var caretOffset = getCaretCharacterOffset(tInlineText);
    return {
        baseNode: baseNode,
        caretOffset: caretOffset,
        focusNode: focusNode,
        tInlineText: tInlineText,
        tPar: tPar,
        baseCaretOffset: getCaretCharacterOffset(baseNode),
        pointOfInsert: (0, textEditUtils_1.calculateInsertPoint)(tInlineText, caretOffset), // точка вставки элементов
    };
}
exports.getTargetValues = getTargetValues;
function findNodesForMove(element) {
    var _a, _b;
    var siblings = [];
    if (!common_1.TypeUtils.isElement(element)) {
        return siblings;
    }
    var nextSibling = element.nextSibling;
    if (common_1.CheckUtils.isBoundaryContent(element) && nextSibling) {
        // ПП boundary_right
        siblings.push(nextSibling);
        nextSibling = (_a = nextSibling.nextSibling) !== null && _a !== void 0 ? _a : null;
    }
    while (nextSibling) {
        siblings.push(nextSibling);
        nextSibling = (_b = nextSibling.nextSibling) !== null && _b !== void 0 ? _b : null;
    }
    return siblings;
}
exports.findNodesForMove = findNodesForMove;
function getSelectionEdges() {
    var sel = window.getSelection();
    if (!sel || sel.isCollapsed) {
        return null;
    }
    var range = sel.getRangeAt(0);
    var rangeLeft = range.cloneRange();
    var rangeRight = range.cloneRange();
    rangeLeft.collapse(true);
    sel.removeAllRanges();
    sel.addRange(rangeLeft);
    var leftTargets = getTargetValues();
    rangeRight.collapse(false);
    sel.removeAllRanges();
    sel.addRange(rangeRight);
    var rightTargets = getTargetValues();
    if (!leftTargets ||
        !rightTargets ||
        (leftTargets.tInlineText === rightTargets.tInlineText && leftTargets.caretOffset === rightTargets.caretOffset)) {
        return null;
    }
    return [leftTargets, rightTargets];
}
exports.getSelectionEdges = getSelectionEdges;
/* временно старая функция для совместимости с кодом в ListBlock, у нод есть id */
/** @deprecated use getSelectedNodeList */
function getSelectedNodes() {
    if (window.getSelection) {
        var sel = window.getSelection();
        if (!(sel === null || sel === void 0 ? void 0 : sel.isCollapsed)) {
            return (sel === null || sel === void 0 ? void 0 : sel.getRangeAt(0).cloneContents().childNodes) || [];
        }
    }
    return [];
}
exports.getSelectedNodes = getSelectedNodes;
var BLOCK_TAGS = {
    div: 'block',
    ol: 'list',
    ul: 'list',
};
var changeBlockType = function (oldWrapper, tagName) {
    var parent = oldWrapper.parentElement;
    if (!parent) {
        return;
    }
    var newTagName = tagName.toLowerCase();
    var type = BLOCK_TAGS[newTagName];
    var newWrapper = document.createElement(tagName);
    newWrapper.innerHTML = oldWrapper.innerHTML;
    if (type === 'list') {
        newWrapper.classList.add(constants_1.CLASS_NAMES.listBlock);
        newWrapper.classList.remove(constants_1.CLASS_NAMES.textBlock);
        newWrapper.setAttribute('class', 'nested ul-position');
        newWrapper.setAttribute('wrappable', 'list');
        parent.replaceChild(newWrapper, oldWrapper);
    }
    if (type === 'block') {
        newWrapper.setAttribute('class', '');
        newWrapper.setAttribute('wrappable', 'block');
        newWrapper.classList.add(constants_1.CLASS_NAMES.textBlock);
        newWrapper.classList.remove(constants_1.CLASS_NAMES.listBlock);
        parent.replaceChild(newWrapper, oldWrapper);
    }
};
exports.changeBlockType = changeBlockType;
// для слияния нод b/u/i
var mergeStyledNodes = function (currentElement, node) {
    if (common_1.TypeUtils.isElement(node)) {
        node.removeAttribute('id');
        currentElement.innerHTML += node.outerHTML;
    }
    else {
        currentElement.innerHTML += node.textContent;
    }
};
var getOnlyParagraphNodes = function (elements) {
    var filteredElements = [];
    elements.forEach(function (element) {
        if (common_1.TypeUtils.isElement(element) && common_1.CheckUtils.isListTagName(element.tagName)) {
            filteredElements.push.apply(filteredElements, (0, exports.getOnlyParagraphNodes)(Array.from(element.childNodes)));
        }
        else {
            filteredElements.push(element);
        }
    });
    return filteredElements;
};
exports.getOnlyParagraphNodes = getOnlyParagraphNodes;
var setElementsToDom = function (elements, targetValues, block, tagName) {
    var _a, _b;
    var wrappableParent = common_1.GetUtils.getParentElement(targetValues.tPar, common_1.CheckUtils.isWrappable);
    var isWrappableList = common_1.CheckUtils.isWrappableList(wrappableParent);
    // копируется список
    if (wrappableParent && common_1.CheckUtils.isLiParagraph(elements[0])) {
        // если блок для вставки не ячейка таблицы и он пустой, div меняется на list
        if (!common_1.CheckUtils.isWrappableTableCell(wrappableParent) && common_1.CheckUtils.isEmptyBlock(wrappableParent)) {
            wrappableParent.innerHTML = '';
            wrappableParent.append.apply(wrappableParent, elements);
            (0, exports.changeBlockType)(wrappableParent, tagName);
            if (block) {
                // mutation
                block.autonumeric = false;
                block.content = __assign(__assign({}, BlockModel_1.BlockContentModel), { html: wrappableParent.innerHTML, string: tagName === 'ol' ? 'numeric' : null });
                block.type = BlockTypes_1.blockTypes.list.type;
            }
        }
        else {
            // если блок для вставки не список, li конвертируются в div
            if (!isWrappableList) {
                (_a = targetValues.tPar).after.apply(_a, (0, exports.getOnlyParagraphNodes)(elements));
                var paragraphs = wrappableParent === null || wrappableParent === void 0 ? void 0 : wrappableParent.querySelectorAll("li.text-paragraph");
                paragraphs === null || paragraphs === void 0 ? void 0 : paragraphs.forEach(function (elem) {
                    elem.outerHTML = replaceTagNameElement(elem, 'li', 'div');
                });
                if (common_1.CheckUtils.isEmptyParagraph(targetValues.tPar)) {
                    targetValues.tPar.remove();
                }
            }
            else {
                wrappableParent.append.apply(wrappableParent, elements);
            }
        }
        return;
    }
    // первый копируемый элемент параграф, остальные тоже параграфы
    if (common_1.CheckUtils.isDivParagraph(elements[0])) {
        (_b = targetValues.tPar).after.apply(_b, elements);
        if (common_1.CheckUtils.isEmptyParagraph(targetValues.tPar)) {
            targetValues.tPar.remove();
        }
        //  если родитель список, div конвертируются в li
        if (isWrappableList) {
            var paragraphs = wrappableParent === null || wrappableParent === void 0 ? void 0 : wrappableParent.querySelectorAll("div.text-paragraph");
            paragraphs === null || paragraphs === void 0 ? void 0 : paragraphs.forEach(function (elem) {
                elem.outerHTML = replaceTagNameElement(elem, 'div', 'li');
            });
        }
        return;
    }
    // копировались span (копирование разметки внутри одного параграфа)
    if (common_1.TypeUtils.isNode(elements[0])) {
        var _c = splitNode(targetValues), normalizedLeftPart = _c[0], normalizedRightPart = _c[1];
        targetValues.tInlineText.innerHTML = normalizedLeftPart;
        var currentNode_1 = targetValues.tInlineText;
        var pos = void 0;
        var nodes = Array.from(elements);
        var firstNode = nodes.shift();
        var lastNode = nodes.pop();
        if (!firstNode) {
            return;
        }
        currentNode_1.innerHTML = normalizedLeftPart;
        if (common_1.TypeUtils.isInlineText(firstNode)) {
            // ПП добавляется после выбранной ноды
            if (common_1.CheckUtils.isBoundaryContent(firstNode)) {
                currentNode_1.after(firstNode);
                currentNode_1 = firstNode;
            }
            else {
                // spans объединяются
                currentNode_1.innerHTML += common_1.TextUtils.removeZwnj(firstNode.innerHTML);
            }
        }
        else if (common_1.CheckUtils.isPlaceholder(firstNode)) {
            // ПП добавляется после выбранной ноды
            currentNode_1.after(firstNode);
            currentNode_1 = firstNode;
        }
        else {
            // обычный текст или текст в разметке b/u/i
            mergeStyledNodes(currentNode_1, firstNode);
            // копируется только одна нода
            if (!lastNode) {
                pos = (0, caret_1.getMaxCaretPosition)(currentNode_1);
                currentNode_1.append.apply(currentNode_1, getNormalizedNodeList(normalizedRightPart));
                (0, caret_1.setCaretStartPosition)(currentNode_1, pos);
                return;
            }
        }
        nodes.forEach(function (node) {
            if (common_1.TypeUtils.isElement(node) && (common_1.CheckUtils.isInlineText(node) || common_1.CheckUtils.isPlaceholder(node))) {
                currentNode_1.after(node);
                currentNode_1 = node;
            }
            else {
                mergeStyledNodes(currentNode_1, node);
            }
        });
        if (common_1.TypeUtils.isElement(lastNode)) {
            // последняя добавляемая нода - ПП/ПХ
            if (common_1.CheckUtils.isBoundaryContent(lastNode) || common_1.CheckUtils.isPlaceholder(lastNode)) {
                currentNode_1.after(lastNode);
                var newNode = createNewInlineText();
                newNode.innerHTML += common_1.TextUtils.removeZwnj(normalizedRightPart);
                lastNode.after(newNode);
                (0, caret_1.setCaretEndPosition)(lastNode);
                return;
            }
            // последняя добавленная нода - ПП/ПХ, добавляемая нода не ПХ/ПП, к ней добавляется правая часть целевой ноды
            if (common_1.CheckUtils.isBoundaryContent(currentNode_1) || common_1.CheckUtils.isPlaceholder(currentNode_1)) {
                var pos_1 = (0, caret_1.getMaxCaretPosition)(lastNode);
                lastNode.append.apply(lastNode, getNormalizedNodeList(normalizedRightPart));
                currentNode_1.after(lastNode);
                (0, caret_1.setCaretStartPosition)(lastNode, pos_1);
                return;
            }
            // текст/текстовые span объединяются
            mergeStyledNodes(currentNode_1, lastNode);
            (0, caret_1.setCaretEndPosition)(currentNode_1);
            currentNode_1.append.apply(currentNode_1, getNormalizedNodeList(normalizedRightPart));
        }
        return;
    }
};
exports.setElementsToDom = setElementsToDom;
function getNormalizedNodeList(normalizedNodes) {
    var tmpNode = document.createElement('span');
    tmpNode.innerHTML = common_1.TextUtils.removeZwnj(normalizedNodes);
    return Array.from(tmpNode.childNodes);
}
exports.getNormalizedNodeList = getNormalizedNodeList;
// ПХ, ПХ-приложение, ПХ передаваемое поле
function removePlaceholderNode(phNode) {
    if (!common_1.TypeUtils.isElement(phNode)) {
        return null;
    }
    var rightNode = phNode.nextSibling;
    var leftNode = phNode.previousSibling;
    phNode.remove();
    // Справа и слева ничего нет – значит это ПХ-приложение
    if (!common_1.TypeUtils.isElement(rightNode) && !common_1.TypeUtils.isElement(leftNode)) {
        return null;
    }
    if (common_1.TypeUtils.isElement(leftNode) && common_1.TypeUtils.isElement(rightNode)) {
        // Удаляем zwnj из правого span и переносим всех потомков в левый span
        rightNode.innerHTML = common_1.TextUtils.removeZwnj(rightNode);
        leftNode.append.apply(leftNode, Array.from(rightNode.childNodes));
        rightNode.remove();
    }
    return leftNode;
}
exports.removePlaceholderNode = removePlaceholderNode;
