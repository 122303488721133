"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumerationEntities = void 0;
/**
 * Структура для нумерации
 */
var NumerationEntities = /** @class */ (function () {
    function NumerationEntities() {
        this.orders = [];
        this.state = [];
    }
    /**
     * Принудительное добавление произвольной нумерации
     * Позволяет производить нумерацию не последовательно, а с любого другого уровня или глубины
     * @param order Структура нумерации
     */
    NumerationEntities.prototype.add = function (order) {
        this.orders.push({ depth: order });
    };
    /**
     * Добавление и получение текущей нумерации
     * @param numerationDepth Глубина нумерации
     * @param delta Приращение нумерации
     * @returns массив, где индекс это порядковый номер глубины, а значение - уровень глубины
     */
    NumerationEntities.prototype.next = function (numerationDepth, delta) {
        // Структура нумерации
        // [
        //   [1]
        //   [2]
        //   [2,1]
        //   [2,2]
        //   [3]
        // ]
        if (delta === void 0) { delta = 1; }
        // Алгоритм нумерации
        // 1. Берем предыдущие значение нумерации
        // 2. Если нумерация не совпадает по глубине, то заполняем нулями недостающую глубину
        // 3. Прибавляем шаг нумерации по соответствующие глубине
        // 3. Сохраняем и возвращаем 
        var result = [];
        if (this.orders.length === 0) {
            result.push(delta);
            this.orders.push({ depth: result });
        }
        else {
            // Предыдущие значение нумерации
            var prevOrder = this.orders[this.orders.length - 1].depth;
            // Разница в глубине нумерации
            var deltaDepth = numerationDepth - prevOrder.length;
            // Нужно ограничить глубину нумерации чтобы не было нулей
            var maxNumerationDepth = deltaDepth >= 1 ? prevOrder.length : numerationDepth;
            // Копируем значение с предыдущей
            for (var index = 0; index <= maxNumerationDepth; index++) {
                if (prevOrder.length > index) {
                    result.push(prevOrder[index]);
                }
                else {
                    result.push(0);
                }
            }
            // и увеличиваем на величину нумерации
            result[maxNumerationDepth] += delta;
            this.orders.push({ depth: result });
        }
        return result;
    };
    /**
     * Сохранение текущего значения нумерация.
     * Необходимо для нумерации секций
     */
    NumerationEntities.prototype.save = function () {
        this.state.push(this.orders.length);
    };
    /**
     * Восстановления нумерации
     * @returns Последняя нумерация глубины
     */
    NumerationEntities.prototype.restore = function () {
        if (this.orders.length > 0) {
            var last = this.orders[this.orders.length - 1].depth;
            var index = this.state.pop();
            this.orders = this.orders.slice(0, index);
            return last;
        }
        // Задачи [DC-4587], [DC-4589], 
        // Значит что первым нумерованным элементом была секция 
        // и притом в ее вариантах также отсутствует нумерация 
        return [0];
    };
    return NumerationEntities;
}());
exports.NumerationEntities = NumerationEntities;
