"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCourtDocumentEntity = exports.isEntityTypeAuthority = exports.isAttachmentType = exports.isTemplatedList = exports.isAttachmentFooter = exports.isAttachmentHeader = exports.ENTITY_TYPES = void 0;
exports.ENTITY_TYPES = {
    //
    // ОБЩИЕ ДЛЯ ВСЕХ РОЛЕЙ
    //
    /**
     * Библиотечные блоки
     */
    library: 'library',
    /**
     * Приложение
     */
    attachment: 'attachment',
    /**
     * Списки
     */
    templatedList: 'templatedList',
    //
    // ШАБЛОНЫ ДОГОВОРНЫХ ДОКУМЕНТОВ
    //
    /**
     * Договора
     */
    contract: 'contract',
    /**
     * Дополнительные соглашения
     */
    additionalAgreement: 'additionalAgreement',
    /**
     * Трехсторонние договоры
     */
    cession: 'cession',
    /**
     * Соглашение о расторжении
     */
    terminationAgreement: 'terminationAgreement',
    //
    // ШАБЛОНЫ ДОВЕРЕННОСТЕЙ
    //
    /**
     * Доверенности
     */
    powerOfAttorney: 'powerOfAttorney',
    /**
     * Уведомления об отзыве доверенностей
     */
    revokePowerOfAttorney: 'revokePowerOfAttorney',
    /**
     * Полномочия для доверенностей
     */
    authority: 'authority',
    //
    // ШАБЛОНЫ СУДЕБНЫХ ДОКУМЕНТОВ
    //
    /**
     * Исковые заявления
     */
    statementOfClaim: 'statementOfClaim',
    /**
     * Заявления
     */
    statements: 'statements',
    /**
     * Отзывы на исковые заявления
     */
    revocationStatementOfClaim: 'revocationStatementOfClaim',
    /**
     * Ходатайства
     */
    petitions: 'petitions',
    //
    // СЛУЖЕБНЫЕ СУЩНОСТИ
    //
    document: 'document',
    // [TODO] - Удалить
    folder: 'folder',
    subpart: 'subpart',
    attachmentHeader: 'attachmentHeader',
    attachmentFooter: 'attachmentFooter',
};
/** @deprecated use EntityUtils.isAttachmentHeader */
var isAttachmentHeader = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return entityType === exports.ENTITY_TYPES.attachmentHeader;
};
exports.isAttachmentHeader = isAttachmentHeader;
/** @deprecated use EntityUtils.isAttachmentFooter */
var isAttachmentFooter = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return entityType === exports.ENTITY_TYPES.attachmentFooter;
};
exports.isAttachmentFooter = isAttachmentFooter;
/** @deprecated use EntityUtils.isTemplatedList */
var isTemplatedList = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return entityType === exports.ENTITY_TYPES.templatedList;
};
exports.isTemplatedList = isTemplatedList;
/** @deprecated use EntityUtils.isAttachmentType */
var isAttachmentType = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return (0, exports.isAttachmentHeader)(entityType) || (0, exports.isAttachmentFooter)(entityType);
};
exports.isAttachmentType = isAttachmentType;
/** @deprecated use EntityUtils.isEntityTypeAuthority */
var isEntityTypeAuthority = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return entityType === 'authority' || entityType === 'powerOfAttorney' || entityType === 'revokePowerOfAttorney';
};
exports.isEntityTypeAuthority = isEntityTypeAuthority;
/** @deprecated use EntityUtils.isCourtDocumentEntity */
var isCourtDocumentEntity = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return entityType === 'statements' ||
        entityType === 'statementOfClaim' ||
        entityType === 'revocationStatementOfClaim' ||
        entityType === 'petitions';
};
exports.isCourtDocumentEntity = isCourtDocumentEntity;
exports.default = exports.ENTITY_TYPES;
