"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSignalRUrl = exports.getUserEmail = exports.parseJwt = void 0;
var constants_1 = require("../constants");
__exportStar(require("./infoMessagesData"), exports);
var parseJwt = function () {
    try {
        var token = localStorage.access_token.split('.')[1];
        var base64 = token.replace(/-/g, '+').replace(/_/g, '/');
        var decodedToken = decodeURIComponent(window
            .atob(base64)
            .split('')
            .map(function (c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); })
            .join(''));
        return JSON.parse(decodedToken);
    }
    catch (e) {
        return null;
    }
};
exports.parseJwt = parseJwt;
var getUserEmail = function () {
    var _a;
    var data = (0, exports.parseJwt)();
    return (_a = data === null || data === void 0 ? void 0 : data.email) !== null && _a !== void 0 ? _a : '';
};
exports.getUserEmail = getUserEmail;
var getSignalRUrl = function (id) {
    return "".concat(INFO_BOARD_API_URL, "channel/").concat(constants_1.CHANNEL_IDS[id]);
};
exports.getSignalRUrl = getSignalRUrl;
