"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addActiveOptionsForTL = exports.addActiveOptions = void 0;
var addActiveOptions = function (documentId, blocks, newSectionActiveOptions) {
    return blocks.map(function (block) {
        var _a;
        var modified = __assign({}, block);
        if (block.type === 'section') {
            var blockModelId = block.id;
            var currentSectionIds = newSectionActiveOptions[blockModelId];
            modified.content = __assign(__assign({}, modified.content), { activeOptions: currentSectionIds
                    ? (_a = {},
                        _a[documentId] = currentSectionIds,
                        _a) : {} });
        }
        return modified;
    });
};
exports.addActiveOptions = addActiveOptions;
var addActiveOptionsForTL = function (documentId, blocks, newSectionActiveOptions) {
    return blocks.map(function (block) {
        var _a;
        var templateElement = block.templateElement;
        var modified = __assign({}, templateElement);
        if (templateElement.type === 'section') {
            var blockModelId = templateElement.id;
            var currentSectionIds = newSectionActiveOptions[blockModelId];
            modified.content = __assign(__assign({}, modified.content), { activeOptions: currentSectionIds
                    ? (_a = {},
                        _a[documentId] = currentSectionIds,
                        _a) : {} });
        }
        var updated = {
            placeholderId: block.placeholderId,
            templateElement: __assign({}, modified),
        };
        return updated;
    });
};
exports.addActiveOptionsForTL = addActiveOptionsForTL;
