"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityBlock = void 0;
var EntityBase_1 = require("./EntityBase");
var EntityType_1 = require("./EntityType");
/**
 * Блок(обычный текстовый)
 */
var EntityBlock = /** @class */ (function (_super) {
    __extends(EntityBlock, _super);
    //#region Конструкторы
    function EntityBlock(id, parent) {
        if (parent === void 0) { parent = null; }
        var _this = _super.call(this, id, parent) || this;
        /**
         * Тип
         */
        _this.type = EntityType_1.TEntityType.Block;
        // Блок может участвовать в нумерации
        _this.isNumericSupport = true;
        return _this;
    }
    //#endregion
    /**
     * Создание дочерних сущностей на основание DOM
     * id должен быть корректным
     * @param isDoc Режим документа (предпросмотра)
     */
    EntityBlock.prototype.createFromDOM = function (isDoc) {
        // Нет дочерних сущностей
    };
    /**
     * Заполнить параметры сущности на основе объекта (блока или секции)
     * @param element Блок и секция
     */
    EntityBlock.prototype.fillFromElement = function (element) {
    };
    /**
     * Вычисления(заполнения) дерева нумерации элементов
     * @param tree Дерево нумерации элементов
     * @param currentNumeration Структура для нумерации
     */
    EntityBlock.prototype.calcTreeNumeration = function (tree, numeration) {
        if (this.autonumeric === false) {
            tree[this.id] = [-1];
        }
        else {
            this.orderCalc = numeration.next(this.numerationDepth);
            tree[this.id] = this.orderCalc;
        }
    };
    return EntityBlock;
}(EntityBase_1.EntityBase));
exports.EntityBlock = EntityBlock;
