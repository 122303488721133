"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeFetchToObjOdd = exports.mergeFetchToObj = void 0;
// Функция мерджит fetch поля внутрь поля по которому делался fetch
//
// Исходный объект {
//   obj: {
//     customsInvoiceNum: '9209567392M',
//     quantity: '',
//     paymentNum: '9777741',
//   },
//   fetch: {
//     paymentNum: {
//       paymentNum: {
//         id: '9777741',
//         payment_number: '10269',
//         payment_ammount_rur: '1.910855289E7',
//       },
//     },
//   },
// };
//
// Результат {
//   customsInvoiceNum: '9209567392M',
//   quantity: '',
//   paymentNum: {
//     id: '9777741',
//     payment_number: '10269',
//     payment_ammount_rur: '1.910855289E7',
//   },
// };
function mergeFetchToObj(item) {
    var newItem = __assign({}, item.obj);
    Object.keys(item.fetch).forEach(function (fetchKey) {
        newItem[fetchKey] = item.fetch[fetchKey][fetchKey];
    });
    return newItem;
}
exports.mergeFetchToObj = mergeFetchToObj;
// разница лишь в том, что если имя поле в fetch будет совпадать с полем
// в основной структуре, то они оба будут присутствовать результирующем
// объекте. Минус в том, что появляются составные ключи.
function mergeFetchToObjOdd(item) {
    var newItem = __assign({}, item.obj);
    Object.keys(item.fetch).forEach(function (fetchKey) {
        var fetchItemData = item.fetch[fetchKey][fetchKey];
        Object.keys(fetchItemData).forEach(function (key) { newItem["".concat(fetchKey, "__").concat(key)] = fetchItemData[key]; });
    });
    return newItem;
}
exports.mergeFetchToObjOdd = mergeFetchToObjOdd;
