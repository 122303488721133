"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FolderTree = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var routes_1 = require("../../../../routes");
var responsibilities_1 = __importStar(require("../../../utils/responsibilities"));
require("../FolderTree/FolderTree.less");
var selectors_1 = require("../redux/selectors");
var antd_1 = require("antd");
var searchInObject_1 = require("../../../utils/searchInObject");
var actions_1 = require("../redux/actions");
var commonModels_1 = require("../../../../Root/utils/api/models/common/commonModels");
var SubMenu = antd_1.Menu.SubMenu;
function getItem(name, id, icon, children, type) {
    return {
        id: id,
        icon: icon,
        children: children,
        name: name,
        type: type,
    };
}
function menuItemsParsing(treeData) {
    return treeData === null || treeData === void 0 ? void 0 : treeData.map(function (item) {
        return getItem(item.name, item.id, item.icon, item.children && item.children.length > 0 ? menuItemsParsing(item.children) : null);
    });
}
function writeFolders(knownFolders, folders) {
    var subpart = [];
    (0, searchInObject_1.readObject)(knownFolders, subpart);
    if (subpart === null || subpart === void 0 ? void 0 : subpart.length) {
        subpart.forEach(function (item) {
            // @ts-ignore
            var filteredFolders = folders.filter(function (folder) { return (folder === null || folder === void 0 ? void 0 : folder.parentFolderId) === (item === null || item === void 0 ? void 0 : item.id); });
            if (filteredFolders === null || filteredFolders === void 0 ? void 0 : filteredFolders.length) {
                filteredFolders.map(function (element) {
                    var _a, _b;
                    // @ts-ignore
                    if (!((_a = item === null || item === void 0 ? void 0 : item.children) === null || _a === void 0 ? void 0 : _a.includes(element))) {
                        // @ts-ignore
                        (_b = item.children) === null || _b === void 0 ? void 0 : _b.push(element);
                    }
                });
            }
        });
    }
}
var FolderTree = function (_a) {
    var setSearchValue = _a.setSearchValue, collapsed = _a.collapsed;
    var history = (0, react_router_1.useHistory)();
    var folders = (0, react_redux_1.useSelector)(selectors_1.getFolders);
    var selectedFolder = (0, react_redux_1.useSelector)(selectors_1.getSelectedFolder);
    var expandedKeys = (0, react_redux_1.useSelector)(selectors_1.getExpandedKeys);
    var showArchive = (0, react_redux_1.useSelector)(selectors_1.getShowArchive);
    var knownFolders = (0, react_redux_1.useSelector)(selectors_1.getKnownFolders);
    var dispatch = (0, react_redux_1.useDispatch)();
    var openedFolderId = history.location.pathname.slice(1);
    (0, react_1.useEffect)(function () {
        if (selectedFolder) {
            dispatch((0, actions_1.setExpandedKeys)(selectedFolder.breadcrumbs.map(function (item) { return item.id; })));
        }
        dispatch((0, actions_1.getKnownFoldersList)());
    }, []);
    (0, react_1.useEffect)(function () {
        dispatch((0, actions_1.getFolderList)(openedFolderId));
    }, [openedFolderId]);
    var expandCollapse = function (clickedKey) {
        if (expandedKeys.some(function (key) { return key === clickedKey; })) {
            dispatch((0, actions_1.setExpandedKeys)(expandedKeys.filter(function (key) { return key !== clickedKey; })));
        }
        else {
            dispatch((0, actions_1.setExpandedKeys)(__spreadArray(__spreadArray([], expandedKeys, true), [clickedKey], false)));
        }
    };
    var handleSelectFolder = function (selectedFolder) {
        var selectedFolderId = selectedFolder.key;
        dispatch((0, actions_1.selectFolder)(selectedFolderId));
        history.push(routes_1.routeGenerator.selectFolder(selectedFolderId));
        setTimeout(function () {
            setSearchValue('');
        }, 300);
    };
    var actuallyFolders = folders === null || folders === void 0 ? void 0 : folders.filter(function (folder) { return !folder.status || folder.status !== 'Архив'; });
    var foldersData = showArchive ? folders : actuallyFolders;
    var keyBreadcrumbs = selectedFolder === null || selectedFolder === void 0 ? void 0 : selectedFolder.breadcrumbs.map(function (item) { return item.id; });
    var visibleDocsFolder = (0, responsibilities_1.checkUserPermissions)(responsibilities_1.default.read.documents);
    if (visibleDocsFolder === false) {
        // TODO: Проверить может ли id иметь значение DOCUMENTS
        foldersData = foldersData.filter(function (item) { return item.id !== 'DOCUMENTS'; });
    }
    var treeData = [];
    var addFoldersToSubparts = function (folders, addFolders) {
        folders.forEach(function (folder) {
            if (folder.kind == commonModels_1.FolderKind.Subpart) {
                addFolders.forEach(function (addFolder) {
                    if (addFolder.parentFolderId == folder.id) {
                        var addFolderKnow = {
                            authorName: addFolder.authorName,
                            children: [],
                            id: addFolder.id,
                            kind: commonModels_1.FolderKind.Folder,
                            name: addFolder.name,
                            perimeterId: folder.perimeterId,
                            perimeterName: folder.perimeterName,
                            parentId: folder.id,
                            typeKey: folder.typeKey,
                        };
                        folder.children.push(addFolderKnow);
                    }
                });
            }
        });
        folders.forEach(function (folder) {
            addFoldersToSubparts(folder.children, addFolders);
        });
    };
    (0, react_1.useEffect)(function () {
        if (knownFolders && knownFolders.length && foldersData.length) {
            writeFolders(knownFolders, foldersData);
        }
    }, [knownFolders, foldersData]);
    // вот здесь сделать преобразование вложив папки все в subparts в качестве children-ов
    addFoldersToSubparts(knownFolders, foldersData);
    var newItems = menuItemsParsing(knownFolders);
    var menuTag = (0, react_1.useCallback)(function deep(menuData) {
        // Проверяем есть ли дубликаты по ключам id
        var hasDuplicateId = menuData === null || menuData === void 0 ? void 0 : menuData.some(function (item, index) {
            return menuData.findIndex(function (element) { return element.id === item.id; }) !== index;
        });
        // При необходимости избавляемся от дубликатов
        if (hasDuplicateId) {
            var uniqueArr = menuData.filter(function (item, index, self) { return index === self.findIndex(function (t) { return t.id === item.id; }); });
            menuData = uniqueArr;
        }
        if (menuData && menuData.length > 0) {
            return menuData.map(function (item) {
                if (item.children && item.children.length > 0) {
                    return ((0, jsx_runtime_1.jsx)(SubMenu, __assign({ icon: item.icon, title: item.name, onTitleClick: handleSelectFolder }, { children: deep(item.children) }), item.id));
                }
                else {
                    return ((0, jsx_runtime_1.jsx)(antd_1.Menu.Item, __assign({ icon: item.icon, onClick: handleSelectFolder }, { children: item.name }), item.id));
                }
            });
        }
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(antd_1.Menu, __assign({ defaultSelectedKeys: ['1'], defaultOpenKeys: ['sub1'], 
            // @ts-ignore
            // Настройки для автоматического открытия меню по ключам будут храниться здесь, можно настроить позднее согласно третьей итерации правок по редизайну от тестировщиков
            //   expandedKeys={[...expandedKeys, ...treeData.map(({ key }) => key)]}
            selectedKeys: [keyBreadcrumbs[keyBreadcrumbs.length - 1]], mode: "inline", theme: "light", inlineCollapsed: collapsed }, { children: menuTag(newItems) })) }));
};
exports.FolderTree = FolderTree;
