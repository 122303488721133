"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityConstans = void 0;
/**
 * Константы для сущностей
 */
var EntityConstans = /** @class */ (function () {
    function EntityConstans() {
    }
    /**
     * Нулевой идентификатор
     */
    EntityConstans.ID_NULL = 'undef';
    return EntityConstans;
}());
exports.EntityConstans = EntityConstans;
