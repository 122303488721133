"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkNoneControls = exports.checkOwnershipControls = exports.checkCanWriteControls = exports.Controls = void 0;
var Controls;
(function (Controls) {
    Controls[Controls["None"] = 0] = "None";
    Controls[Controls["Read"] = 1] = "Read";
    Controls[Controls["Write"] = 2] = "Write";
    Controls[Controls["Delete"] = 4] = "Delete";
    Controls[Controls["Ownership"] = 8] = "Ownership";
})(Controls = exports.Controls || (exports.Controls = {}));
/**
 * Проверка прав на запись
 * Запись может осуществлять либо владелец, либо тому кто имеет права на запись
 * @param controls Проверяемые права
 * @returns
 */
var checkCanWriteControls = function (controls) {
    return ((controls & Controls.Write) === Controls.Write || ((controls & Controls.Ownership) === Controls.Ownership));
};
exports.checkCanWriteControls = checkCanWriteControls;
var checkOwnershipControls = function (controls) {
    return ((controls & Controls.Ownership) === Controls.Ownership);
};
exports.checkOwnershipControls = checkOwnershipControls;
var checkNoneControls = function (controls) {
    return ((controls & Controls.None) === Controls.None);
};
exports.checkNoneControls = checkNoneControls;
