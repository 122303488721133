"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibraryAdminPanel = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var antd_1 = require("antd");
var react_router_dom_1 = require("react-router-dom");
var react_redux_1 = require("react-redux");
var PlaceholderTemplateFolderApi_1 = require("../api/placeholderLibraryApi/PlaceholderTemplateFolder/PlaceholderTemplateFolderApi");
var placeholdersLibraryUtils_1 = require("./placeholdersLibraryUtils");
var api_v1_Placeholder_1 = require("../Root/utils/api/api.v1.Placeholder");
var PlaceholdersLibraryCreatingFolder_1 = require("./components/PlaceholdersLibraryModals/PlaceholdersLibraryCreatingFolder/PlaceholdersLibraryCreatingFolder");
var PlaceholdersLibraryCreatingPlaceholder_1 = require("./components/PlaceholdersLibraryModals/PlaceholdersLibraryCreatingPlaceholder/PlaceholdersLibraryCreatingPlaceholder");
var PlaceholderLibrarySiderMenu_1 = require("./components/PlaceholderLibrarySiderMenu/PlaceholderLibrarySiderMenu");
var PlaceholderLibraryHeader_1 = require("./components/PlaceholderLibraryHeader/PlaceholderLibraryHeader");
var PlaceholderLibraryTop_1 = require("./components/PlaceholderLibraryTop/PlaceholderLibraryTop");
require("./PlaceholderLibraryAdminPanel.less");
var PlaceholderLibraryDashboard_1 = require("./components/PlaceholderLibraryDashboard/PlaceholderLibraryDashboard");
var PlaceholderLibraryAdd_1 = require("./components/PlaceholdersLibraryModals/PlaceholderLibraryAdd/PlaceholderLibraryAdd");
var routes_1 = require("../routes");
var rootGetters_1 = require("../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../Root/redux/rootActionCreators/rootActionCreators");
var Content = antd_1.Layout.Content;
var PlaceholderLibraryAdminPanel = function () {
    var history = (0, react_router_dom_1.useHistory)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var historyLocationState = history.location.state;
    var currentFolderId = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.folderId;
    var templateType = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.templateType;
    var findPerimeterId = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.currentPerimeterId;
    var placeholderId = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.placeholderId;
    var fromSystem = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.fromSystem;
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var placeholderTemplateFolderListObj = presentState === null || presentState === void 0 ? void 0 : presentState.placeholderTemplateFolderList;
    if (!historyLocationState) {
        history.push("".concat(routes_1.routes.home));
    }
    (0, react_1.useEffect)(function () {
        if (findPerimeterId) {
            (0, PlaceholderTemplateFolderApi_1.placeholderTemplateFolderList)({}).then(function (response) {
                var folders = response === null || response === void 0 ? void 0 : response.folders;
                if (findPerimeterId) {
                    folders = folders === null || folders === void 0 ? void 0 : folders.filter(function (folder) { return folder.perimeterId === findPerimeterId; });
                }
                if (findPerimeterId && templateType !== 'library' && templateType !== 'attachment') {
                    var mainSubpart = folders === null || folders === void 0 ? void 0 : folders.find(function (folder) { return folder.kind === 'subpart' && folder.typeKey === templateType; });
                    var currentPartitionId_1 = mainSubpart === null || mainSubpart === void 0 ? void 0 : mainSubpart.parentId;
                    folders = folders === null || folders === void 0 ? void 0 : folders.filter(function (folder) {
                        return (folder.perimeterId === findPerimeterId &&
                            ((folder === null || folder === void 0 ? void 0 : folder.typeKey) === templateType || folder.id === currentPartitionId_1 || folder.kind === 'perimeter'));
                    });
                    if (templateType === 'templatedList' && fromSystem) {
                        var otherFolders = folders === null || folders === void 0 ? void 0 : folders.filter(function (folder) { return folder.kind !== 'subpart'; });
                        // @ts-ignore
                        var subpartFolder = folders === null || folders === void 0 ? void 0 : folders.filter(function (folder) { return (folder === null || folder === void 0 ? void 0 : folder.system) === fromSystem && folder.kind === 'subpart'; });
                        folders = __spreadArray(__spreadArray([], subpartFolder, true), otherFolders, true);
                    }
                }
                else {
                    if (findPerimeterId && (templateType === 'library' || templateType === 'attachment') && fromSystem) {
                        var otherFolders = folders === null || folders === void 0 ? void 0 : folders.filter(function (folder) { return folder.kind !== 'subpart'; });
                        // @ts-ignore
                        var subpartFolder = folders === null || folders === void 0 ? void 0 : folders.filter(function (folder) { return (folder === null || folder === void 0 ? void 0 : folder.system) === fromSystem && folder.kind === 'subpart'; });
                        folders = __spreadArray(__spreadArray([], subpartFolder, true), otherFolders, true);
                    }
                    else {
                        folders = folders === null || folders === void 0 ? void 0 : folders.filter(function (folder) { return folder.perimeterId === findPerimeterId; });
                    }
                }
                // // нет типа док-та а значит мы пришли с главной страницы
                if (!templateType) {
                    folders = response === null || response === void 0 ? void 0 : response.folders;
                }
                dispatch((0, rootActionCreators_1.setPlaceholderTemplateFolderList)(folders));
            });
        }
    }, [findPerimeterId]);
    var currentFolder = currentFolderId && (placeholderTemplateFolderListObj === null || placeholderTemplateFolderListObj === void 0 ? void 0 : placeholderTemplateFolderListObj.length)
        ? placeholderTemplateFolderListObj === null || placeholderTemplateFolderListObj === void 0 ? void 0 : placeholderTemplateFolderListObj.find(function (folder) { return (folder === null || folder === void 0 ? void 0 : folder.id) === currentFolderId; })
        : null;
    (0, react_1.useEffect)(function () {
        var breadcrumbsAlias = (0, placeholdersLibraryUtils_1.createBreadcrumbsAlias)(currentFolderId, placeholderTemplateFolderListObj) || [];
        dispatch((0, rootActionCreators_1.setPlaceholderAdminBreadcrumbs)(breadcrumbsAlias));
    }, [currentFolderId, placeholderTemplateFolderListObj]);
    (0, react_1.useEffect)(function () {
        if (currentFolderId) {
            (0, PlaceholderTemplateFolderApi_1.placeholderTemplateFolderContent)({ id: currentFolderId }).then(function (response) {
                return dispatch((0, rootActionCreators_1.setPlaceholderTemplateFolderContent)(response));
            });
        }
    }, [currentFolderId]);
    (0, react_1.useEffect)(function () {
        var templateId = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.templateId;
        (0, api_v1_Placeholder_1.v1PlaceholderByTemplate)({ templateId: templateId }).then(function (r) { return r; });
    }, []);
    return ((0, jsx_runtime_1.jsxs)(antd_1.Layout, __assign({ style: { minHeight: '100vh' } }, { children: [(0, jsx_runtime_1.jsx)(PlaceholderLibrarySiderMenu_1.PlaceholderLibrarySiderMenu, { folders: placeholderTemplateFolderListObj, templateType: templateType }), (0, jsx_runtime_1.jsxs)(antd_1.Layout, __assign({ className: "phadm-site-layout" }, { children: [(0, jsx_runtime_1.jsx)(PlaceholderLibraryHeader_1.PlaceholderLibraryHeader, {}), (0, jsx_runtime_1.jsxs)(Content, __assign({ style: { margin: '0 30px' } }, { children: [(0, jsx_runtime_1.jsx)(PlaceholderLibraryTop_1.PlaceholderLibraryTop, { folders: placeholderTemplateFolderListObj, currentFolder: currentFolder }), (0, jsx_runtime_1.jsx)(PlaceholderLibraryDashboard_1.PlaceholderLibraryDashboard, { templateType: templateType, currentFolder: currentFolder }), (0, jsx_runtime_1.jsx)(PlaceholdersLibraryCreatingFolder_1.PlaceholdersLibraryCreatingFolder, { currentFolder: currentFolder }), (0, jsx_runtime_1.jsx)(PlaceholdersLibraryCreatingPlaceholder_1.PlaceholdersLibraryCreatingPlaceholder, { currentFolder: currentFolder }), placeholderId ? (0, jsx_runtime_1.jsx)(PlaceholderLibraryAdd_1.PlaceholderLibraryAdd, {}) : null] }))] }))] })));
};
exports.PlaceholderLibraryAdminPanel = PlaceholderLibraryAdminPanel;
