"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var DocumentButton = function (_a) {
    var text = _a.text, onClick = _a.onClick, disabled = _a.disabled, _b = _a.icon, icon = _b === void 0 ? null : _b;
    return ((0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: "default", disabled: disabled, onClick: onClick, icon: icon, className: "document-button" }, { children: text })));
};
exports.DocumentButton = DocumentButton;
