"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNewBlockType = exports.getAvailableBlockIds = exports.getAvailableBlocks = exports.getDepthLeftAction = exports.getDepthRightAction = exports.getAddAction = exports.getSelectionBlockProps = exports.isDisableAlignButton = exports.isDisableStyleButton = exports.togglePlaceholdersStyle = exports.isValidBoundarySelection = exports.canCreateBoundary = void 0;
var constants_1 = require("../../../constants");
var common_1 = require("../../../utils/common");
var BlockTypes_1 = require("../BlockTypes");
/**
 * Определяется можно ли в шаблоне создавать ПП
 * @param entityType - тип шаблона
 */
function canCreateBoundary(entityType) {
    return (!common_1.EntityUtils.isAttachmentType(entityType) &&
        !common_1.EntityUtils.isEntityTypeAuthority(entityType) &&
        !common_1.EntityUtils.isCourtDocumentEntity(entityType) &&
        !common_1.EntityUtils.isTemplatedList(entityType));
}
exports.canCreateBoundary = canCreateBoundary;
/**
 * Определяется есть ли выделенный текст, из которого можно создать ПП
 */
function isValidBoundarySelection() {
    var selection = window.getSelection();
    if (!selection || !selection.toString().length) {
        return false;
    }
    var hasPlaceholder = common_1.GetUtils.getParentElement(selection.anchorNode, common_1.CheckUtils.isPlaceholder) ||
        common_1.GetUtils.getParentElement(selection.focusNode, common_1.CheckUtils.isPlaceholder);
    return !hasPlaceholder;
}
exports.isValidBoundarySelection = isValidBoundarySelection;
/**
 * Переключение стилей bold/italic/underline
 * @param styleName - tag стиля, который переключается
 * @param selectedNodes - выбранные ноды
 * @param selectedPlaceholder - выбранный ПХ
 */
function togglePlaceholdersStyle(styleName, selectedNodes, selectedPlaceholder) {
    if (selectedPlaceholder && selectedPlaceholder.subType !== 'boundary') {
        var node = common_1.DomUtils.selectPlaceholder(selectedPlaceholder.id);
        node === null || node === void 0 ? void 0 : node.classList.toggle(styleName);
    }
    else {
        selectedNodes.forEach(function (node) {
            if (common_1.TypeUtils.isElement(node) && common_1.CheckUtils.isPlaceholder(node)) {
                node === null || node === void 0 ? void 0 : node.classList.toggle(styleName);
            }
        });
    }
}
exports.togglePlaceholdersStyle = togglePlaceholdersStyle;
/**
 * Проверка блокировки кнопок стилизации текста
 * @param style
 * @param blockType
 */
function isDisableStyleButton(style, blockType) {
    return (!common_1.BlockTypeUtils.isStyledBlock(blockType) || (style === constants_1.NodeStyles.Bold && blockType === BlockTypes_1.blockTypes.header2.type));
}
exports.isDisableStyleButton = isDisableStyleButton;
/**
 * Проверка блокировки кнопок форматирования текста
 * @param style
 * @param blockType
 */
function isDisableAlignButton(style, blockType) {
    return !common_1.BlockTypeUtils.isStyledBlock(blockType) || common_1.BlockTypeUtils.isHeader2(blockType);
}
exports.isDisableAlignButton = isDisableAlignButton;
/**
 * Извлечение свойств из модели блока, необходимых для меню
 * @param [props=] пропсы блока
 */
var getSelectionBlockProps = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return {
        autonumeric: (_a = props === null || props === void 0 ? void 0 : props.autonumeric) !== null && _a !== void 0 ? _a : false,
        blockType: (_b = props === null || props === void 0 ? void 0 : props.type) !== null && _b !== void 0 ? _b : '',
        isContentEditable: (_c = props === null || props === void 0 ? void 0 : props.isContentEditable) !== null && _c !== void 0 ? _c : false,
        isLib: (_d = props === null || props === void 0 ? void 0 : props.isLib) !== null && _d !== void 0 ? _d : false,
        isNumeric: ((_e = props === null || props === void 0 ? void 0 : props.content) === null || _e === void 0 ? void 0 : _e.string) === 'numeric',
        isSectionBlock: (_f = !!(props === null || props === void 0 ? void 0 : props.sectionId)) !== null && _f !== void 0 ? _f : false,
        numeration_depth: (_g = props === null || props === void 0 ? void 0 : props.numeration_depth) !== null && _g !== void 0 ? _g : 0,
        subType: (_h = props === null || props === void 0 ? void 0 : props.subType) !== null && _h !== void 0 ? _h : '',
    };
};
exports.getSelectionBlockProps = getSelectionBlockProps;
/**
 * Получение экшнена добавления блока
 * @param isLib - является ли ББ-блоком выбранный блок
 * @param isSection - является ли секцией выбранный блок
 */
var getAddAction = function (isLib, isSection) {
    if (isLib) {
        return 'addBlockAfterLibrary';
    }
    return isSection ? 'addBlockInSection' : 'addBlock';
};
exports.getAddAction = getAddAction;
/**
 * Увеличение порядка нумерации
 * @param blockType - тип выбранного блока
 */
var getDepthRightAction = function (blockType) { return (blockType === 'list' ? 'listShiftRight' : 'increaseDepth'); };
exports.getDepthRightAction = getDepthRightAction;
/**
 * Уменьшение порядка нумерации
 * @param blockType - тип выбранного блока
 */
var getDepthLeftAction = function (blockType) { return (blockType === 'list' ? 'listShiftLeft' : 'decreaseDepth'); };
exports.getDepthLeftAction = getDepthLeftAction;
/**
 * Получение списка блоков, которые можно вставить в выбранный блок (или после него) или заменить ими
 * @param blockType - тип блока
 */
function getAvailableBlocks(blockType) {
    switch (blockType) {
        case BlockTypes_1.blockTypes.authority.type:
        case BlockTypes_1.blockTypes.attachment.type:
        case BlockTypes_1.blockTypes.section.type:
        case BlockTypes_1.blockTypes.table.type: {
            return [BlockTypes_1.blockTypes.text.type];
        }
        case BlockTypes_1.blockTypes.header2.type:
        case BlockTypes_1.blockTypes.list.type: {
            return [BlockTypes_1.blockTypes.header2.type, BlockTypes_1.blockTypes.list.type, BlockTypes_1.blockTypes.text.type];
        }
        case BlockTypes_1.blockTypes.pageBreak.type: {
            return [BlockTypes_1.blockTypes.text.type];
        }
        case BlockTypes_1.blockTypes.text.type: {
            return [
                BlockTypes_1.blockTypes.authority.type,
                BlockTypes_1.blockTypes.attachment.type,
                BlockTypes_1.blockTypes.header2.type,
                BlockTypes_1.blockTypes.list.type,
                BlockTypes_1.blockTypes.section.type,
                BlockTypes_1.blockTypes.table.type,
                BlockTypes_1.blockTypes.text.type,
            ];
        }
        default: {
            return [];
        }
    }
}
exports.getAvailableBlocks = getAvailableBlocks;
/**
 * Получение отфильтрованного списка блоков, которые можно вставить в выбранный блок (или после него) или заменить ими
 * @param blockType
 * @param isSection
 */
function getAvailableBlockIds(blockType, isSection) {
    if (isSection === void 0) { isSection = false; }
    var blockTypeList = new Set(getAvailableBlocks(blockType));
    if (blockType === BlockTypes_1.blockTypes.text.type) {
        return blockTypeList;
    }
    blockTypeList.delete(BlockTypes_1.blockTypes.attachment.type);
    if (isSection) {
        blockTypeList.delete(BlockTypes_1.blockTypes.authority.type);
        blockTypeList.delete(BlockTypes_1.blockTypes.section.type);
    }
    return new Set(blockTypeList);
}
exports.getAvailableBlockIds = getAvailableBlockIds;
function getNewBlockType(currentBlockType, newBlockType, isNumeric) {
    if (newBlockType === BlockTypes_1.blockTypes.numlist.type) {
        if (currentBlockType === BlockTypes_1.blockTypes.list.type && isNumeric) {
            return { blockType: BlockTypes_1.blockTypes.text.type };
        }
        else {
            return { blockType: BlockTypes_1.blockTypes.list.type, payload: { numeric: true } };
        }
    }
    else if (newBlockType === BlockTypes_1.blockTypes.list.type) {
        if (currentBlockType === BlockTypes_1.blockTypes.list.type && !isNumeric) {
            return { blockType: BlockTypes_1.blockTypes.text.type };
        }
        else {
            return { blockType: BlockTypes_1.blockTypes.list.type };
        }
    }
    else {
        return { blockType: newBlockType === currentBlockType ? BlockTypes_1.blockTypes.text.type : newBlockType };
    }
}
exports.getNewBlockType = getNewBlockType;
