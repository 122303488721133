"use strict";
// Сортировка блоков с учетом расположения их в библиотеке
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortBlocks = void 0;
var templatedListConstants_1 = require("../../../utils/templatedListConstants");
// TODO: [Дементьев] Метод может давать сбои когда размещаются два и более одинаковых библиотечных блока
var sortBlocks = function (left, right) {
    if (left.libraryTemplate_id && right.libraryTemplate_id && left.libraryTemplate_id.id === right.libraryTemplate_id.id) {
        var a = left.libraryElemNumber;
        var b = right.libraryElemNumber;
        return a - b;
    }
    if (left.subType === templatedListConstants_1.options.templatedList.block && right.subType === templatedListConstants_1.options.templatedList.TlBlock &&
        left.templateListId && right.templateListId && left.templateListId === right.templateListId) {
        return -1;
    }
    if (left.subType === templatedListConstants_1.options.templatedList.TlBlock && right.subType === templatedListConstants_1.options.templatedList.block &&
        left.templateListId && right.templateListId && left.templateListId === right.templateListId) {
        return 1;
    }
    return left.order - right.order;
};
exports.sortBlocks = sortBlocks;
