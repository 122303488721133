"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSections = exports.getMinSectionsOrder = exports.getMaxSectionsOrder = void 0;
var ParsingUtils_1 = require("./common/ParsingUtils");
function getMaxSectionsOrder(sections) {
    var orders = sections.map(function (item) { return ParsingUtils_1.ParsingUtils.getOrder(item.order); });
    return orders.length ? Math.max.apply(Math, orders) : 0;
}
exports.getMaxSectionsOrder = getMaxSectionsOrder;
function getMinSectionsOrder(sections) {
    var orders = sections.map(function (item) { return ParsingUtils_1.ParsingUtils.getOrder(item.order); });
    return orders.length ? Math.min.apply(Math, orders) : 0;
}
exports.getMinSectionsOrder = getMinSectionsOrder;
function parseSections(sections) {
    var parsedSections = {};
    sections.forEach(function (section) {
        if (!section.sectionId) {
            return;
        }
        if (!parsedSections[section.sectionId]) {
            parsedSections[section.sectionId] = [];
        }
        parsedSections[section.sectionId].push(section);
    });
    return parsedSections;
}
exports.parseSections = parseSections;
