"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkArrayInArrayAny = void 0;
/**
 * Проверка на вхождение любого элемента проверяемого массива в исходном массиве
 * @param source Исходный массив
 * @param checked Проверяемый массив
 * @returns
 */
var checkArrayInArrayAny = function (source, checked) {
    var find = true;
    for (var index = 0; index < source.length; index++) {
        var element = source[index];
        find = checked.includes(element);
        if (find) {
            break;
        }
    }
    return find;
};
exports.checkArrayInArrayAny = checkArrayInArrayAny;
