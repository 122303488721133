"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableCell = void 0;
var nodeUtils_1 = require("../../shared/components/TemplateRender/utils/nodeUtils");
var TableConstants_1 = require("./TableConstants");
var TableCell = /** @class */ (function () {
    //#endregion
    function TableCell(ownerTable, rowKey, columnKey) {
        this.id = ownerTable.calcCellId(rowKey, columnKey);
        this.ownerTable = ownerTable;
        this.columnKey = columnKey;
        this.rowKey = rowKey;
        this.selected = false;
        this.rowSpan = 0;
        this.columnSpan = 0;
        this.merged = false;
        this.borderLeft = false;
        this.borderRight = false;
        this.borderTop = false;
        this.borderBottom = false;
        this.verticalAlign = 'middle';
        this.content = '';
    }
    //#region Static methods
    TableCell.fromTableCellDto = function (ownerTable, ownerRow, cellDto) {
        var cell = new TableCell(ownerTable, ownerRow.key, cellDto.columnKey);
        cell.rowSpan = cellDto.rowSpan === undefined ? 0 : cellDto.rowSpan;
        cell.columnSpan = cellDto.columnSpan === undefined ? 0 : cellDto.columnSpan;
        cell.merged = cellDto.merged === undefined ? false : cellDto.merged;
        cell.borderLeft = cellDto.borderLeft === undefined ? false : cellDto.borderLeft;
        cell.borderRight = cellDto.borderRight === undefined ? false : cellDto.borderRight;
        cell.borderTop = cellDto.borderTop === undefined ? false : cellDto.borderTop;
        cell.borderBottom = cellDto.borderBottom === undefined ? false : cellDto.borderBottom;
        cell.verticalAlign = cellDto.verticalAlign === undefined ? 'middle' : cellDto.verticalAlign;
        cell.content = cellDto.content;
        return cell;
    };
    TableCell.fromTableCellsDto = function (ownerTable, ownerRow, cellsDto) {
        var cells = cellsDto.map(function (cell) { return TableCell.fromTableCellDto(ownerTable, ownerRow, cell); });
        if (ownerTable.isEditMode()) {
            var technicalCell = ownerTable.createCell(ownerRow.key, TableConstants_1.TableConstants.TechnicalColumnKey, '');
            cells.unshift(technicalCell);
        }
        return cells;
    };
    TableCell.prototype.isSpan = function () {
        return this.rowSpan > 1 || this.columnSpan > 1;
    };
    TableCell.prototype.getPosition = function () {
        return this.ownerTable.getCellPosition(this.rowKey, this.columnKey);
    };
    TableCell.prototype.getRowSpanDelta = function () {
        if (this.rowSpan > 0)
            return this.rowSpan - 1;
        return 0;
    };
    TableCell.prototype.getColumnSpanDelta = function () {
        if (this.columnSpan > 0)
            return this.columnSpan - 1;
        return 0;
    };
    /**
     * Размер ячейки в "ячейках"
     */
    TableCell.prototype.getSizeSpan = function () {
        if (this.rowSpan > 0 && this.columnSpan > 0)
            return this.rowSpan * this.columnSpan;
        return 1 + this.getRowSpanDelta() + this.getColumnSpanDelta();
    };
    TableCell.prototype.setBorderLeft = function (value) {
        this.borderLeft = value;
        // Более правильный, но сложный вариант, с корректным информирование в интерфейсе, до лучших времен))
        // const pos = this.getPosition();
        // const leftCell = this.ownerTable.getCellByIndex(pos.rowIndex, pos.columnIndex - 1);
        // if (leftCell) {
        //   leftCell.borderRight = value;
        // }
    };
    TableCell.prototype.setBorderRight = function (value) {
        this.borderRight = value;
        // Более правильный, но сложный вариант, с корректным информирование в интерфейсе, до лучших времен))
        // const pos = this.getPosition();
        // const rightCell = this.ownerTable.getCellByIndex(pos.rowIndex, pos.columnIndex + 1);
        // if (rightCell) {
        //   rightCell.borderLeft = value;
        // }
    };
    TableCell.prototype.setBorderTop = function (value) {
        this.borderTop = value;
        // Более правильный, но сложный вариант, с корректным информирование в интерфейсе, до лучших времен))
        // const pos = this.getPosition();
        // const topCell = this.ownerTable.getCellByIndex(pos.rowIndex - 1, pos.columnIndex);
        // if (topCell) {
        //   topCell.borderBottom = value;
        // }
    };
    TableCell.prototype.setBorderBottom = function (value) {
        this.borderBottom = value;
        // Более правильный, но сложный вариант, с корректным информирование в интерфейсе, до лучших времен))
        // const pos = this.getPosition();
        // const bottomCell = this.ownerTable.getCellByIndex(pos.rowIndex + 1, pos.columnIndex);
        // if (bottomCell) {
        //   bottomCell.borderTop = value;
        // }
    };
    TableCell.prototype.setMerge = function () {
        this.merged = true;
        this.columnSpan = 0;
        this.rowSpan = 0;
        this.setBorderLeft(false);
        this.setBorderTop(false);
        this.setBorderRight(false);
        this.setBorderBottom(false);
        this.selected = false;
        this.content = '';
    };
    TableCell.prototype.setUnMerge = function () {
        this.merged = false;
        this.content = (0, nodeUtils_1.createNewParagraph)().outerHTML;
    };
    TableCell.prototype.copy = function (ownerTable) {
        var copyCell = new TableCell(ownerTable, this.rowKey, this.columnKey);
        copyCell.selected = this.selected;
        copyCell.rowSpan = this.rowSpan;
        copyCell.columnSpan = this.columnSpan;
        copyCell.merged = this.merged;
        copyCell.borderLeft = this.borderLeft;
        copyCell.borderTop = this.borderTop;
        copyCell.borderRight = this.borderRight;
        copyCell.borderBottom = this.borderBottom;
        copyCell.verticalAlign = this.verticalAlign;
        copyCell.content = this.content;
        return copyCell;
    };
    TableCell.prototype.toTableCellDto = function () {
        var cellDto = {
            columnKey: this.columnKey,
            rowSpan: this.rowSpan === 0 ? undefined : this.rowSpan,
            columnSpan: this.columnSpan === 0 ? undefined : this.columnSpan,
            merged: this.merged === false ? undefined : this.merged,
            borderLeft: this.borderLeft === false ? undefined : this.borderLeft,
            borderTop: this.borderTop === false ? undefined : this.borderTop,
            borderRight: this.borderRight === false ? undefined : this.borderRight,
            borderBottom: this.borderBottom === false ? undefined : this.borderBottom,
            verticalAlign: this.verticalAlign === 'middle' ? undefined : this.verticalAlign,
            content: this.content,
        };
        return cellDto;
    };
    TableCell.prototype.printHtml = function () {
        var width = this.ownerTable.getColumnWidth(this.columnKey);
        var result = "<td";
        if (this.columnSpan > 0)
            result += " colspan=\"".concat(this.columnSpan, "\"");
        if (this.rowSpan > 0)
            result += " rowspan=\"".concat(this.rowSpan, "\"");
        result += " style=\"word-wrap: break-word; width: ".concat(width, "mm; max-width: ").concat(width, "mm;");
        if (this.borderTop)
            result += " border-top-color: black; border-top-width: 1px; border-top-style: solid;";
        if (this.borderBottom)
            result += " border-bottom-color: black; border-bottom-width: 1px; border-bottom-style: solid;";
        if (this.borderLeft)
            result += " border-left-color: black; border-left-width: 1px; border-left-style: solid;";
        if (this.borderRight)
            result += " border-right-color: black; border-right-width: 1px; border-right-style: solid;";
        if (this.verticalAlign !== 'middle')
            result += " vertical-align: ".concat(this.verticalAlign, ";");
        result += '">';
        result += this.content;
        result += '</td>';
        return result;
    };
    TableCell.prototype.debugInfo = function () {
        var id = this.id.replaceAll(this.ownerTable.tableArgs.id + '_', '');
        return "R: ".concat(this.rowKey, ", C: ").concat(this.columnKey, ", id: ").concat(id);
    };
    return TableCell;
}());
exports.TableCell = TableCell;
