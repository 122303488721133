"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.options = void 0;
/* TODO: move to constants */
exports.options = {
    templatedList: {
        placeholder: 'TL',
        block: 'withTL',
        addedTlBlock: 'addTL',
        TlBlock: 'templatedListBlock',
        type: 'templatedList'
    },
};
