"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkSessionTemplate = exports.getSessionTemplateId = exports.getOrCreateSessionTemplate = exports.getTemplateIdFromLocation = void 0;
var uuid_1 = require("uuid");
/**
 * Получить идентификатор шаблон с текущего пути
 * @returns Идентификатор шаблона
 */
var getTemplateIdFromLocation = function () {
    // Получаем id шаблона
    var l = location.pathname.length;
    var templateId = location.pathname.slice(l - 36);
    return templateId;
};
exports.getTemplateIdFromLocation = getTemplateIdFromLocation;
/**
 * Получить сессию или создать новую для шаблона из location.pathname
 * @returns Сессия для шаблона
 */
var getOrCreateSessionTemplate = function () {
    var sessionTemplate = null;
    // Получаем id шаблона
    var templateId = (0, exports.getTemplateIdFromLocation)();
    // Смотрим все ключи
    for (var index = 0; index < sessionStorage.length; index++) {
        var key = sessionStorage.key(index);
        if (key) {
            var value = sessionStorage.getItem(key);
            if (value) {
                if (value.includes(templateId)) {
                    sessionTemplate = JSON.parse(value);
                    break;
                }
            }
        }
    }
    if (sessionTemplate == null) {
        var sessionId = (0, uuid_1.v4)();
        sessionTemplate = { id: sessionId, templateId: templateId };
        sessionStorage.setItem("session_".concat(sessionId), JSON.stringify(sessionTemplate));
    }
    return sessionTemplate;
};
exports.getOrCreateSessionTemplate = getOrCreateSessionTemplate;
/**
 * Получить идентификатор сессии для указанного шаблона
 * @param templateId Идентификатор шаблона
 * @returns Идентификатор сессии или null
 */
var getSessionTemplateId = function (templateId) {
    var sessionId = null;
    // Смотрим все ключи
    for (var index = 0; index < sessionStorage.length; index++) {
        var key = sessionStorage.key(index);
        if (key) {
            var value = sessionStorage.getItem(key);
            if (value) {
                if (value.includes(templateId)) {
                    var sessionTemplate = JSON.parse(value);
                    sessionId = sessionTemplate.id;
                    break;
                }
            }
        }
    }
    return sessionId;
};
exports.getSessionTemplateId = getSessionTemplateId;
/**
 * Проверка существовании сессии для указанного шаблона
 * @param templateId Идентификатор шаблона
 * @returns Ключ сессии или null
 */
var checkSessionTemplate = function (templateId) {
    var sessionKey = null;
    // Смотрим все ключи
    for (var index = 0; index < sessionStorage.length; index++) {
        var key = sessionStorage.key(index);
        if (key) {
            var value = sessionStorage.getItem(key);
            if (value) {
                if (value.includes(templateId)) {
                    sessionKey = key;
                    break;
                }
            }
        }
    }
    return sessionKey;
};
exports.checkSessionTemplate = checkSessionTemplate;
