"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCountFormatter = void 0;
var createCountFormatter = function (props) {
    var few = props.few, one = props.one, two = props.two;
    var titles = [one, two, few];
    return function (number) {
        var cases = [2, 0, 1, 1, 1, 2];
        return titles[number % 100 > 4 && number % 100 < 20
            ? 2
            : cases[number % 10 < 5 ? number % 10 : 5]];
    };
};
exports.createCountFormatter = createCountFormatter;
