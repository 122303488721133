"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockTypeUtils = void 0;
/**
 * Утилиты определения типа блока по свойству blockType из свойств блока
 */
var BlockTypeUtils = /** @class */ (function () {
    function BlockTypeUtils() {
    }
    BlockTypeUtils.isAttachment = function (blockType) {
        if (blockType === void 0) { blockType = ''; }
        return blockType === 'attachment';
    };
    BlockTypeUtils.isAuthority = function (blockType) {
        if (blockType === void 0) { blockType = ''; }
        return blockType === 'authority';
    };
    BlockTypeUtils.isHeader2 = function (blockType) {
        if (blockType === void 0) { blockType = ''; }
        return blockType === 'header2';
    };
    BlockTypeUtils.isList = function (blockType) {
        if (blockType === void 0) { blockType = ''; }
        return blockType === 'list';
    };
    BlockTypeUtils.isSection = function (blockType) {
        if (blockType === void 0) { blockType = ''; }
        return blockType === 'section';
    };
    BlockTypeUtils.isTable = function (blockType) {
        if (blockType === void 0) { blockType = ''; }
        return blockType === 'table';
    };
    BlockTypeUtils.isText = function (blockType) {
        if (blockType === void 0) { blockType = ''; }
        return blockType === 'text';
    };
    /**
     * Определение можно ли форматировать текст внутри выбранного блока
     * @param blockType - тип выбранного блока
     */
    BlockTypeUtils.isStyledBlock = function (blockType) {
        if (blockType === void 0) { blockType = ''; }
        return BlockTypeUtils.isText(blockType) || BlockTypeUtils.isTable(blockType) || BlockTypeUtils.isList(blockType);
    };
    return BlockTypeUtils;
}());
exports.BlockTypeUtils = BlockTypeUtils;
