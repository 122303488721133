"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBlockOrSectionById = void 0;
var getBlockOrSectionById = function (id, blocks, sections) {
    var _a, _b;
    if (!id) {
        return null;
    }
    return (_b = (_a = blocks === null || blocks === void 0 ? void 0 : blocks.find(function (block) { return block.id === id; })) !== null && _a !== void 0 ? _a : sections === null || sections === void 0 ? void 0 : sections.find(function (section) { return section.id === id; })) !== null && _b !== void 0 ? _b : null;
};
exports.getBlockOrSectionById = getBlockOrSectionById;
