"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractObjectId = void 0;
// this function extracts object id from url
function extractObjectId(history) {
    var splittedUrl = history.location.pathname.split('/');
    var length = splittedUrl.length;
    return splittedUrl[length - 1];
}
exports.extractObjectId = extractObjectId;
