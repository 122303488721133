"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentEntities = void 0;
var EntityType_1 = require("./Entities/EntityType");
var EntityUtility_1 = require("./EntityUtility");
var NumerationEntities_1 = require("./NumerationEntities");
var UtilityDOM_1 = require("./UtilityDOM");
/**
 * Документ представляет собой иерархический список сущностей, которые отражают текущие дерево DOM
 *
 */
var DocumentEntities = /** @class */ (function () {
    function DocumentEntities() {
        /**
         * Корневые сущности документа
         */
        this.roots = [];
    }
    DocumentEntities.prototype.addChild = function (child) {
        child.index = this.roots.length;
        this.roots.push(child);
    };
    /**
     * Создание дочерних сущностей на основании DOM шаблона
     */
    DocumentEntities.prototype.createFromDOM = function () {
        var _this = this;
        var body = UtilityDOM_1.UtilityDOM.getBodyDocument(false);
        if (!body)
            return;
        var innerBody = body.firstElementChild;
        if (!innerBody)
            return;
        var elems = Array.from(innerBody.childNodes).map(function (node) { return node; });
        var currentBlockLibrary = null;
        elems.forEach(function (elem) {
            var dnd = UtilityDOM_1.UtilityDOM.isDnDWrapper(elem);
            if (dnd) {
                var entity = elem.firstElementChild;
                if (entity) {
                    if (UtilityDOM_1.UtilityDOM.isLibrary(entity)) {
                        var entityLibrary = EntityUtility_1.EntityUtility.createLibraryBlock(entity, false, null);
                        if (entityLibrary) {
                            _this.addChild(entityLibrary);
                            currentBlockLibrary = entityLibrary;
                        }
                        var nextElem = entity.nextElementSibling;
                        if (nextElem) {
                            if (UtilityDOM_1.UtilityDOM.isBlockInLibrary(nextElem)) {
                                EntityUtility_1.EntityUtility.createBlockOfType(nextElem, false, currentBlockLibrary);
                            }
                            else if (UtilityDOM_1.UtilityDOM.isSectionInLibrary(nextElem)) {
                                EntityUtility_1.EntityUtility.createSection(nextElem, false, currentBlockLibrary);
                            }
                        }
                    }
                    else if (UtilityDOM_1.UtilityDOM.isBlock(entity)) {
                        var entityBlock = EntityUtility_1.EntityUtility.createBlockOfType(entity, false, null);
                        if (entityBlock) {
                            _this.addChild(entityBlock);
                            currentBlockLibrary = null;
                        }
                    }
                    else if (UtilityDOM_1.UtilityDOM.isBlockInLibrary(entity)) {
                        EntityUtility_1.EntityUtility.createBlockOfType(entity, false, currentBlockLibrary);
                    }
                    else if (UtilityDOM_1.UtilityDOM.isSection(entity)) {
                        var entitySection = EntityUtility_1.EntityUtility.createSection(entity, false, null);
                        if (entitySection) {
                            _this.addChild(entitySection);
                            currentBlockLibrary = null;
                        }
                    }
                }
            }
        });
    };
    /**
    * Создание дочерних сущностей на основании DOM документа
    */
    DocumentEntities.prototype.createFromDOMForDocument = function () {
        var body = UtilityDOM_1.UtilityDOM.getBodyDocument(true);
        if (!body)
            return;
        var allElements = body.getElementsByClassName('block block--text section');
        for (var index = 0; index < allElements.length; index++) {
            var element = allElements.item(index);
            if (element) {
                var entityBlock = EntityUtility_1.EntityUtility.createBlockOfType(element, true, null);
                if (entityBlock) {
                    this.addChild(entityBlock);
                }
            }
        }
    };
    /**
     * Заполнить параметры сущностей на основе данных
     * @param blocks Список блоков
     * @param sections Список секций
     */
    DocumentEntities.prototype.fillFromData = function (blocks, sections) {
        if (!this.roots || this.roots.length === 0)
            return;
        var dictonary = {};
        if (blocks) {
            blocks.forEach(function (element) {
                if (element.autonumeric) {
                    dictonary[element.id] = element;
                }
            });
        }
        if (sections) {
            sections.forEach(function (element) {
                dictonary[element.id] = element;
            });
        }
        this.roots.forEach(function (element) {
            element.fillFromData(dictonary);
        });
    };
    /**
     * Вычисления дерева нумерации элементов
     */
    DocumentEntities.prototype.calcTreeNumeration = function () {
        if (!this.roots || this.roots.length === 0)
            return {};
        // дерево нумерации это ассоциативный массив где ключ это идентификатор элемент,
        // а значение массив «нумерации» где индекс соответствуют глубине нумерации, а значение самой нумерации
        var tree = {};
        var currentNumeration = new NumerationEntities_1.NumerationEntities();
        this.roots.forEach(function (element) {
            element.calcTreeNumeration(tree, currentNumeration);
        });
        var resultTree = {};
        for (var key in tree) {
            var massive = tree[key];
            if (massive[0] !== -1) {
                resultTree[key] = tree[key];
            }
        }
        return resultTree;
    };
    DocumentEntities.prototype.getAttachments = function () {
        if (!this.roots || this.roots.length === 0)
            return [];
        var attachments = [];
        this.roots.forEach(function (element) {
            element.visit(function (elem) {
                if (elem.type === EntityType_1.TEntityType.Attachment) {
                    attachments.push(elem);
                }
            });
        });
        return attachments;
    };
    DocumentEntities.prototype.updateAttachmentOrders = function (blocks, sections) {
        var dictonary = {};
        if (blocks) {
            blocks.forEach(function (element) {
                dictonary[element.id] = element;
            });
        }
        if (sections) {
            sections.forEach(function (element) {
                dictonary[element.id] = element;
            });
        }
        var attachments = this.getAttachments();
        attachments.forEach(function (attach) {
            var elem = dictonary[attach.id];
            if (elem) {
                elem.order = attach.index + 1;
            }
        });
    };
    /**
     * Создание докумета через дерево DOM
     * @param isDoc Режим документа (предпросмотра)
     * @param blocks Список блоков
     * @param sections Список секций
     * @returns Документ
     */
    DocumentEntities.createFromDom = function (isDoc, blocks, sections) {
        var doc = new DocumentEntities();
        if (isDoc) {
            doc.createFromDOMForDocument();
        }
        else {
            doc.createFromDOM();
        }
        doc.fillFromData(blocks, sections);
        return doc;
    };
    return DocumentEntities;
}());
exports.DocumentEntities = DocumentEntities;
