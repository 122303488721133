"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentTableTypes = void 0;
var DocumentTableTypes;
(function (DocumentTableTypes) {
    DocumentTableTypes["GET_DATA_REQUEST"] = "@documents/GET_DATA_REQUEST";
    DocumentTableTypes["GET_DATA_SUCCESS"] = "@documents/GET_DATA_SUCCESS";
    DocumentTableTypes["GET_DATA_FAILURE"] = "@documents/GET_DATA_FAILURE";
    DocumentTableTypes["SET_PAGE"] = "@documents/SET_PAGE";
    DocumentTableTypes["SET_PAGE_SIZE"] = "@documents/SET_PAGE_SIZE";
    DocumentTableTypes["SET_FILTERS"] = "@documents/SET_FILTERS";
})(DocumentTableTypes = exports.DocumentTableTypes || (exports.DocumentTableTypes = {}));
