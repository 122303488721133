"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oldListCleaner = void 0;
// for old version lists
var oldListCleaner = function (elem) {
    var _a, _b, _c, _d;
    var tag = (_b = (_a = elem === null || elem === void 0 ? void 0 : elem.getElementsByTagName('div')[0]) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.tagName.toLowerCase();
    var currentListBlock = (_c = elem === null || elem === void 0 ? void 0 : elem.getElementsByTagName('div')[0]) === null || _c === void 0 ? void 0 : _c.closest(tag);
    if (currentListBlock) {
        var currentListWrapper = currentListBlock === null || currentListBlock === void 0 ? void 0 : currentListBlock.closest('.block--list');
        if (currentListWrapper) {
            // @ts-ignore
            currentListWrapper.append(currentListBlock);
            (_d = currentListWrapper.firstChild) === null || _d === void 0 ? void 0 : _d.remove();
            elem.innerHTML = currentListBlock.innerHTML;
        }
    }
};
exports.oldListCleaner = oldListCleaner;
