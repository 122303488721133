"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoPanel = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var signalr = __importStar(require("@microsoft/signalr"));
var React = __importStar(require("react"));
var antd_1 = require("antd");
var common_1 = require("../../shared/utils/common");
var constants_1 = require("./constants");
var api_1 = require("./helpers/api");
var helpers_1 = require("./helpers");
require("./InfoPanel.less");
var InfoPanel = function () {
    var channel = React.useRef(common_1.LocationUtils.isEditDocument() ? 'document' : 'tpl');
    var refConnection = React.useRef(new signalr.HubConnectionBuilder().withUrl((0, helpers_1.getSignalRUrl)(channel.current)).withAutomaticReconnect().build());
    var userEmail = React.useRef((0, helpers_1.getUserEmail)());
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = React.useState(constants_1.DEFAULT_PAGINATION), pagination = _b[0], setPagination = _b[1];
    var _c = React.useState([]), urgentMessages = _c[0], setUrgentMessages = _c[1];
    var _d = React.useState([]), newUnlimitedMessages = _d[0], setNewUnlimitedMessages = _d[1];
    var _e = React.useState([]), unlimitedMessages = _e[0], setUnlimitedMessages = _e[1];
    React.useEffect(function () {
        var _a, _b;
        (_a = refConnection.current) === null || _a === void 0 ? void 0 : _a.start();
        (_b = refConnection.current) === null || _b === void 0 ? void 0 : _b.on('ReceiveMessageAsync', function (message) {
            setMessage(message);
        });
    }, [refConnection.current]);
    React.useEffect(function () {
        // срочные сообщения
        (0, api_1.fetchMessages)(channel.current, userEmail.current, constants_1.MessageKinds.Urgent).then(function (response) {
            var _a;
            setUrgentMessages((_a = response.data.items) !== null && _a !== void 0 ? _a : []);
        });
        // бессрочные сообщения
        fetchUnlimitedMessages();
    }, []);
    React.useEffect(function () {
        if (isOpen) {
            setNewUnlimitedMessages([]);
            fetchUnlimitedMessages();
        }
    }, [isOpen, pagination.page, pagination.pageSize]);
    var filteredUrgentMessage = React.useMemo(function () {
        var values = {
            readMessages: [],
            unreadMessages: [],
        };
        urgentMessages.forEach(function (v) {
            if (v.isRead) {
                values.readMessages.push(v);
            }
            else {
                values.unreadMessages.push(v);
            }
        });
        return values;
    }, [urgentMessages]);
    var isUnreadMessage = React.useMemo(function () {
        return !!newUnlimitedMessages.length || unlimitedMessages.some(function (v) { return !v.isRead; });
    }, [unlimitedMessages, newUnlimitedMessages]);
    var fetchUnlimitedMessages = function () {
        (0, api_1.fetchMessages)(channel.current, userEmail.current, constants_1.MessageKinds.Unlimited, pagination.page - 1, pagination.pageSize).then(function (response) {
            var _a;
            setUnlimitedMessages((_a = response.data.items) !== null && _a !== void 0 ? _a : []);
            setPagination(function (state) { return (__assign(__assign({}, state), { total: response.data.total })); });
        });
    };
    var setMessage = function (message) {
        if (message.kind === constants_1.MessageKinds.Urgent) {
            setUrgentMessages(function (state) { return (0, helpers_1.getUpdatedInfoMessageList)(message, state); });
        }
        else {
            setUnlimitedMessages(function (state) { return (0, helpers_1.getClearedInfoMessageList)(message, state); });
            setNewUnlimitedMessages(function (state) { return (0, helpers_1.getUpdatedInfoMessageList)(message, state); });
        }
    };
    var handleToggleOpen = function () {
        setIsOpen(function (state) { return !state; });
    };
    var handleMessageSetRead = function (messageGuid, isUrgent) {
        if (isUrgent === void 0) { isUrgent = false; }
        return function () {
            (0, api_1.setReadMessage)(messageGuid, userEmail.current).then(function () {
                if (isUrgent) {
                    setUrgentMessages(function (state) {
                        return state.map(function (message) { return (message.guid !== messageGuid ? message : __assign(__assign({}, message), { isRead: true })); });
                    });
                }
                else {
                    setNewUnlimitedMessages(function (state) { return (0, helpers_1.getUpdatedInfoMessages)(messageGuid, state); });
                    setUnlimitedMessages(function (state) { return (0, helpers_1.getUpdatedInfoMessages)(messageGuid, state); });
                }
            });
        };
    };
    var handlePageChange = function (page) {
        setPagination(function (state) { return (__assign(__assign({}, state), { page: page })); });
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(antd_1.Button, { className: "info-panel", icon: (0, jsx_runtime_1.jsx)("b", { children: "i" }), shape: "circle", type: isUnreadMessage ? 'primary' : 'ghost', onClick: handleToggleOpen }), !!filteredUrgentMessage.unreadMessages.length && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "alert-wrp" }, { children: filteredUrgentMessage.unreadMessages.map(function (message) { return ((0, jsx_runtime_1.jsx)(antd_1.Alert, { closable: true, message: message.title, description: message.content ? (0, jsx_runtime_1.jsx)("div", { dangerouslySetInnerHTML: { __html: message.content } }) : undefined, onClose: handleMessageSetRead(message.guid, true) }, message.guid)); }) }))), (0, jsx_runtime_1.jsxs)(antd_1.Modal, __assign({ footer: [
                    (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: "primary", onClick: handleToggleOpen }, { children: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C" }), "submit"),
                ], title: "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u043E\u043D\u043D\u0430\u044F \u0434\u043E\u0441\u043A\u0430", open: isOpen, style: {
                    top: 60,
                    right: 20,
                    position: 'absolute',
                }, width: 800, onCancel: handleToggleOpen, onOk: handleToggleOpen }, { children: [(0, jsx_runtime_1.jsxs)(antd_1.Space, __assign({ direction: "vertical", size: "middle", style: { display: 'flex', marginBottom: 16 } }, { children: [newUnlimitedMessages.map(function (message) { return ((0, jsx_runtime_1.jsx)(antd_1.Card, __assign({ className: !message.isRead ? 'new-message' : undefined, extra: !message.isRead ? ((0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: "text", onClick: handleMessageSetRead(message.guid) }, { children: "\u041F\u0440\u043E\u0447\u0438\u0442\u0430\u043D\u043E" }))) : undefined, size: "small", title: message.title }, { children: (0, jsx_runtime_1.jsx)("div", { dangerouslySetInnerHTML: { __html: message.content } }) }), message.guid)); }), filteredUrgentMessage.readMessages.map(function (message) { return ((0, jsx_runtime_1.jsx)(antd_1.Card, __assign({ className: !message.isRead ? 'new-message' : undefined, extra: !message.isRead ? ((0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: "text", onClick: handleMessageSetRead(message.guid) }, { children: "\u041F\u0440\u043E\u0447\u0438\u0442\u0430\u043D\u043E" }))) : undefined, size: "small", title: message.title }, { children: (0, jsx_runtime_1.jsx)("div", { dangerouslySetInnerHTML: { __html: message.content } }) }), message.guid)); }), unlimitedMessages.map(function (message) { return ((0, jsx_runtime_1.jsx)(antd_1.Card, __assign({ className: !message.isRead ? 'new-message' : undefined, extra: !message.isRead ? ((0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: "text", onClick: handleMessageSetRead(message.guid) }, { children: "\u041F\u0440\u043E\u0447\u0438\u0442\u0430\u043D\u043E" }))) : undefined, size: "small", style: {
                                    opacity: message.isRead ? 0.7 : 1,
                                }, title: message.title }, { children: (0, jsx_runtime_1.jsx)("div", { dangerouslySetInnerHTML: { __html: message.content } }) }), message.guid)); })] })), pagination.pageSize < pagination.total && ((0, jsx_runtime_1.jsx)(antd_1.Pagination, { current: pagination.page, pageSize: pagination.pageSize, total: pagination.total, onChange: handlePageChange }))] }))] }));
};
exports.InfoPanel = InfoPanel;
