"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSectionActiveOptions = exports.getPDCListAdditionalInfo = exports.getPDCList = exports.getTemplateInfo = exports.getSelectedSections = exports.getPlaceholderValues = exports.getElementId = exports.getTemplateId = exports.getDocumentId = exports.getSections = exports.getPlaceholderUpdating = exports.getChildPlaceholders = exports.getPlaceholders = exports.getBlocks = exports.getIsDocumentPDCChanging = exports.getIsPDCListLoading = exports.getOpenCalculationPage = exports.getDraftTemplateVersion = exports.getDraftTemplateName = exports.getOrders = exports.getStructure = exports.getFilters = exports.getPageSize = exports.getOrdersListData = exports.getPage = exports.getLoadingId = exports.getPlaceholderLoading = exports.getLoading = void 0;
var documents_redux_1 = require("../documents.redux");
var getLoading = function (state) { return state[documents_redux_1.moduleName].fetching; };
exports.getLoading = getLoading;
var getPlaceholderLoading = function (state) { return state[documents_redux_1.moduleName].isPlaceholderLoading; };
exports.getPlaceholderLoading = getPlaceholderLoading;
var getLoadingId = function (state) { return state[documents_redux_1.moduleName].loadingId; };
exports.getLoadingId = getLoadingId;
var getPage = function (state) { return state[documents_redux_1.moduleName].pageInfo.page; };
exports.getPage = getPage;
var getOrdersListData = function (state) { return state[documents_redux_1.moduleName].data.rows; };
exports.getOrdersListData = getOrdersListData;
var getPageSize = function (state) { return state[documents_redux_1.moduleName].pageSize; };
exports.getPageSize = getPageSize;
var getFilters = function (state) { return state[documents_redux_1.moduleName].data.filters; };
exports.getFilters = getFilters;
var getStructure = function (state) { return state[documents_redux_1.moduleName].structure; };
exports.getStructure = getStructure;
var getOrders = function (state) { return state[documents_redux_1.moduleName].data.orders; };
exports.getOrders = getOrders;
var getDraftTemplateName = function (state) { return state[documents_redux_1.moduleName].data.draftTemplateName; };
exports.getDraftTemplateName = getDraftTemplateName;
var getDraftTemplateVersion = function (state) {
    return state[documents_redux_1.moduleName].data.draftTemplateVersion;
};
exports.getDraftTemplateVersion = getDraftTemplateVersion;
var getOpenCalculationPage = function (state) {
    return state[documents_redux_1.moduleName].data.openCalculationPage;
};
exports.getOpenCalculationPage = getOpenCalculationPage;
var getIsPDCListLoading = function (state) { return state[documents_redux_1.moduleName].isPDCListLoading; };
exports.getIsPDCListLoading = getIsPDCListLoading;
var getIsDocumentPDCChanging = function (state) {
    return state[documents_redux_1.moduleName].isDocumentPDCChanging;
};
exports.getIsDocumentPDCChanging = getIsDocumentPDCChanging;
var getBlocks = function (state) { return state[documents_redux_1.moduleName].blocks; };
exports.getBlocks = getBlocks;
var getPlaceholders = function (state) { return state[documents_redux_1.moduleName].placeholders; };
exports.getPlaceholders = getPlaceholders;
var getChildPlaceholders = function (state) { return state[documents_redux_1.moduleName].childPlaceholders; };
exports.getChildPlaceholders = getChildPlaceholders;
var getPlaceholderUpdating = function (state) { return state[documents_redux_1.moduleName].isPlaceholderUpdating; };
exports.getPlaceholderUpdating = getPlaceholderUpdating;
var getSections = function (state) { return state[documents_redux_1.moduleName].sections; };
exports.getSections = getSections;
var getDocumentId = function (state) { return state[documents_redux_1.moduleName].documentId; };
exports.getDocumentId = getDocumentId;
var getTemplateId = function (state) { return state[documents_redux_1.moduleName].templateId; };
exports.getTemplateId = getTemplateId;
var getElementId = function (state) { return state[documents_redux_1.moduleName].elementId; };
exports.getElementId = getElementId;
var getPlaceholderValues = function (state) {
    return state[documents_redux_1.moduleName].placeholderValues;
};
exports.getPlaceholderValues = getPlaceholderValues;
var getSelectedSections = function (state) { return state[documents_redux_1.moduleName].selectedSections; };
exports.getSelectedSections = getSelectedSections;
var getTemplateInfo = function (state) { return state[documents_redux_1.moduleName].templateInfo; };
exports.getTemplateInfo = getTemplateInfo;
var getPDCList = function (state) { return state[documents_redux_1.moduleName].pdcList; };
exports.getPDCList = getPDCList;
var getPDCListAdditionalInfo = function (state) {
    return state[documents_redux_1.moduleName].pdcListAdditionalInfo;
};
exports.getPDCListAdditionalInfo = getPDCListAdditionalInfo;
var getSectionActiveOptions = function (state) {
    return state[documents_redux_1.moduleName].sectionActiveOptions;
};
exports.getSectionActiveOptions = getSectionActiveOptions;
