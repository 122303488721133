"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.preparedCustomFormulaAggregations = exports.enableOperation = exports.availableDataFormats = void 0;
exports.availableDataFormats = ["money", "number"];
var Operation;
(function (Operation) {
    Operation["Sum"] = "+";
    Operation["Sub"] = "-";
    Operation["Mult"] = "*";
    Operation["Div"] = "/";
    Operation["RightBr"] = "(";
})(Operation || (Operation = {}));
var OperationValue = [
    Operation.Sum,
    Operation.Sub,
    Operation.Mult,
    Operation.Div,
    Operation.RightBr
];
var enableOperation = function (currentFormulaView) {
    var result = OperationValue === null || OperationValue === void 0 ? void 0 : OperationValue.some(function (operation) {
        return currentFormulaView === null || currentFormulaView === void 0 ? void 0 : currentFormulaView.endsWith(operation);
    });
    return result;
};
exports.enableOperation = enableOperation;
var preparedCustomFormulaAggregations = function (availableAggregations) {
    var customFormulaAggregations = availableAggregations === null || availableAggregations === void 0 ? void 0 : availableAggregations.filter(function (aggregation) { return exports.availableDataFormats === null || exports.availableDataFormats === void 0 ? void 0 : exports.availableDataFormats.includes(aggregation === null || aggregation === void 0 ? void 0 : aggregation.dataFormat); });
    var sortedCustomFormulaAggregations = customFormulaAggregations === null || customFormulaAggregations === void 0 ? void 0 : customFormulaAggregations.sort(function (a, b) { return a.order - b.order; });
    return sortedCustomFormulaAggregations;
};
exports.preparedCustomFormulaAggregations = preparedCustomFormulaAggregations;
